import React,{useState} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';

import hdimg from './assets/images/earthing/hd/hotdip_big.png';
import hdimg2x from './assets/images/earthing/hd/hotdip_big@2x.png';
    
    const AseelHdearthing = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
            <EarthingelectrodeSubMenu onImageClick={handleImageClick}/>
            <div className='w-full flex justify-center items-center my-3'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center'>
                        <img src={hdimg}  
                                                srcSet={`${hdimg} 1x,${hdimg2x} 2x`} alt=" ASEEl HD logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred tracking-wider vollkorn-sc-semibold font-semibold' >ASEEL HD <span className=' text-4xl px-1'>|</span> Hot-Dip Galvanized Electrode(<span style={{fontSize:'80%'}}>s</span>)</p>
                        <p className=' text-base text-justify p-common'>Aiding in increasing the electrical conductivity, service life and current with-stand capacity of the earthing electrode, a compound formula developed by <span className='vollkorn-sc-semibold'>Ashlok</span> constitutes elements picked up from nature and is anti-corrosive too. Hot-Dip Galvanized Electrode(s) both pipes as per IS 2629 Standards.</p>
                       
                    </div>
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                
                    <div className='flex  space-x-10'>
                    

                        <div className='w-1/2'>
                        <p className=' text-1xl text-ashred font-extrabold'>Features & Benefits</p>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2   my-1.5 pt-1"/>Galvanized to prevent corrosion</li>
                            <li className="flex items-center p-common py-1"><img src={boltico} className="mr-2   my-1.5 pt-1"/>Engineered for rapid dissipation of fault currents</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2   my-1.5"/>Minimal upkeep required for the earthing system</li>
                                             </ul>
                        </div>
                        
                        <div className='w-1/2'>
                            <ul className="list-none ml-2">
                            <li className="flex items-center p-common text-ashgray1">Simple and swift on-site installation</li>
                           <li className="flex items-center p-common"><img src={boltico} className="mr-2  my-2 pt-1"/>Simple and swift on-site installation</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 my-2 pt-1"/>Cathodic corrosion protection.</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2  mb-2 my-1.5 "/>Customisation is available as per customers requirement.</li>
                                 </ul>
                        </div>
                        <div className='w-1/4'></div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center '>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center  my-3'>
                <p className='text-xl vollkorn-sc-semibold'>Hot-Dip Galvanized Earthing Electrode (<span style={{fontSize:'80%'}}>s</span>) Variant</p>
                    <div className='flex'>
                        <div className='w-full  mb-1'>
                                    <table className=" w-full divide-y divide-gray-200 p-common">
                                        <thead className='w-full'>
                                            
                                            <tr className="bg-ashred">

                                            <th className=" px-4 py-2 text-white">Model</th>
                                            <th className=" px-4 py-2 text-white">Length (Mtr)</th>
                                            <th className="  px-4 py-2 text-white">Inner Pipe Dia  (mm)</th>
                                            <th className="  px-4 py-2 text-white">Outer Pipe Dia  (mm)</th>
                                          
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 192 HD</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48 </td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 193 HD</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48 </td>
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 392 HD</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">76</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 393 HD</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">76</td>
                                            </tr>
    
                                           
                                        
                                        </tbody>
                                    </table>
                        </div>
                        
                    </div>
                
                
                       
                       
                    
                </div>
                
            </div>
            </>
          )
    }
    
    export default AseelHdearthing
    