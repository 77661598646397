import React,{useRef,useEffect} from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import reliance from './assets/images/ashlok_way/clients/reliance.png';
import reliance2x from './assets/images/ashlok_way/clients/reliance@2x.png';

import aarti from './assets/images/ashlok_way/clients/aarti.png';
import aarti2x from './assets/images/ashlok_way/clients/aarti@2x.png';
import barc from './assets/images/ashlok_way/clients/barc.png';
import barc2x from './assets/images/ashlok_way/clients/barc@2x.png';
import fine_organics from './assets/images/ashlok_way/clients/fine-organics.png';
import fine_organics2x from './assets/images/ashlok_way/clients/fine-organics@2x.png';
import gits from './assets/images/ashlok_way/clients/gits.png';
import gits2x from './assets/images/ashlok_way/clients/gits@2x.png';

const handleDragStart = (e) => e.preventDefault();
const items = [
	<img src={reliance} onDragStart={handleDragStart} role="presentation" />,
	<img src={aarti} onDragStart={handleDragStart} role="presentation" />,
	<img src={barc} onDragStart={handleDragStart} role="presentation" />,
	<img src={fine_organics} onDragStart={handleDragStart} role="presentation" />,
	<img src={gits} onDragStart={handleDragStart} role="presentation" />,
];
const responsive = {
    0: {
      items: 3,
    },
    512: {
      items: 5,
    },
    1024: {
      items: 7,
    },
  };

const Scrollhorizontal =()=> {
    const carouselRef = useRef(null);
    useEffect(() => {
        const carousel = carouselRef.current;
    
        const scroll = () => {
          if (carousel) {
            carousel.style.transition = 'transform 0s';
            carousel.style.transform = `translateX(-100%)`;
            requestAnimationFrame(() => {
              carousel.style.transition = 'transform 20s linear';
              carousel.style.transform = `translateX(-${carousel.scrollWidth}px)`;
            });
          }
        };
    
        scroll();
      }, []);
  return (
    <div className=' container '>
        <AliceCarousel 
        items={items} 
        autoPlay  
        autoHeight
        infinite
        disableDotsControls
        disableButtonsControls
        responsive={responsive}
        transitionTimingFunction="linear"
        //animationDuration={1000}
        autoPlayInterval={500}
        />
    </div>
  )
}
export default Scrollhorizontal