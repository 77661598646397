import React from 'react'
import WhypageContent from './WhypageContent';
import GifLoader from './GifLoader';
import Footer from './Footer';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import banner1 from './assets/images/whypage/why_banner.jpg';
import banner1_2x from './assets/images/whypage/why_banner@2x.jpg';
import EarthingBanner from './EarthingBanner';

const WhyPage = ()=> {
  return (
    <div>
       <PreHeader/>
    
    <Header/>
    <Menu/>

    <EarthingBanner page="Why" banalt="Why ban1" bannertext="" ban1x={banner1} ban2x={banner1_2x}/>
        <WhypageContent/>
        <GifLoader/>
        <Footer/>
    </div>
  )
}

export default WhyPage;
