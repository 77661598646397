import React,{useEffect,useState} from 'react'
import partnersafty from './assets/images/your_partner_safety.png'
import partnersafty2x from './assets/images/your_partner_safety@2x.png'
//import sos from './assets/images/sos.png'
//import sos2x from './assets/images/sos@2x.png'
import firstind from './assets/images/First_Indigenous_icon.svg'
import manfacture from './assets/images/Manufacturer_icon.svg'
import redtest from './assets/images/tailored_solution_icon.svg'
import testico from './assets/images/Tested_icon.svg'

import styles from './HomeUltimateSolution.module.css';

const HomeUltimateSolution = ({page}) => {
    const [pageData, setPageData] = useState({});
 

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch('./data/data.json');
            const data = await response.json();
            setPageData(data);
                 // console.log(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []);
    
      const formatWord = (text, wordToFormat, className) => {
        const regex = new RegExp(`(${wordToFormat})`, 'gi');
        return text.split(regex).map((part, index) =>
          regex.test(part) ? <span key={index} className={className}>{part}</span> : part
        );
      };

      // Accessing the 'home_page_title' for the selected language
      const ulsolh1 = pageData[page]?.ulsol_h1 || '';
      const ulsolh2 = pageData[page]?.ulsol_h2 || '';
      const ptag1 = pageData[page]?.ulsol_ptag1 || '';
      const ptag2 = pageData[page]?.ulsol_ptag2 || '';
      const ptag3 = pageData[page]?.ulsol_ptag3 || '';
      const ptag4 = pageData[page]?.ulsol_ptag4 || '';

      const ulsol_b1_1 = pageData[page]?.ulsol_b1_1 || '';
      const ulsol_b1_2 = pageData[page]?.ulsol_b1_2 || '';
      const ulsol_b2_1 = pageData[page]?.ulsol_b2_1 || '';
      const ulsol_b2_2 = pageData[page]?.ulsol_b2_2 || '';
      const ulsol_b3_1 = pageData[page]?.ulsol_b3_1 || '';
      const ulsol_b3_2 = pageData[page]?.ulsol_b3_2 || '';
      const ulsol_b4_1 = pageData[page]?.ulsol_b4_1 || '';
      const ulsol_b4_2 = pageData[page]?.ulsol_b4_2 || '';




  return (
    <>
     <div className=' w-full   bg-ashred flex flex-col justify-center items-center my-3'>
        <div className=' text-3xl  text-white p-2 underline-offset-auto font-semibold' style={{ fontFamily: 'cinzel, sans-serif' }}>{ulsolh1}</div>
    </div>
    <div className=' bg-white'>
        <div className=' container mx-auto max-w-screen-xl flex  flex-col'> 
            <div>
                <div className=' flex flex-col basis-3/4 mb-3'>
                {/*
                    <div className=' text-black font-semibold text-2xl'><h1 data_ash_name="ulsolh1">{ulsolh1} </h1></div>*/}
                    <div className='  text-ashred font-semibold text-2xl'><h1 data_ash_name="ulsolh2">{ulsolh2}</h1></div>
                </div>
                <div className=' flex basis-1/4'>
                
                </div>
            </div>
            
           
            <div className='flex flex-row items-start justify-start'>
                <div className=' flex flex-col w-4/5'>
                    <div className='   text-base text-black text-justify' data_ash_name="ulsol_ptag1">{formatWord(ptag1,'ashlok','vollkorn-sc-semibold')}.</div>
                    <div className='text-base text-black text-justify my-3' data_ash_name="ulsol_ptag2"> {formatWord(ptag2,'ashlok Safe Earthing Electrode Limited','vollkorn-sc-semibold')}.</div>
                    


                    
                   
                </div>
                <div className=' flex w-1/5   items-center justify-end'>
                    
                    <img className=''   src={partnersafty} alt="partnersafet" 
                     srcSet={`${partnersafty} 1x, ${partnersafty2x} 2x`}
                    />
                    
                   
                </div>
                </div>
                <div className='text-base text-black text-justify mb-3' data_ash_name="ulsol_ptag3"> {formatWord(ptag3,'ashlok','vollkorn-sc-semibold')}.</div>
                <div className='text-base text-black text-justify mb-3' data_ash_name="ulsol_ptag4"> {formatWord(ptag4,'ashlok','vollkorn-sc-semibold')}.</div>


                
                
            {/* old commented conternt start 
             
            <div className='flex flex-row mt-5 mb-1'>
                <div className="flex flex-col basis-1/4 bg-ashgray1 justify-center items-center p-2">
                    <img src={firstind} alt="firstind"/>
                    <div className='text-base font-bold text-black text-justify p-1' data_ash_name="ulsol_b1_1">{ulsol_b1_1} </div>
                    <div className='text-base font-bold text-black text-justify' data_ash_name="ulsol_b1_2">{ulsol_b1_2}</div>
                </div>
                <div className="flex flex-col basis-1/4 bg-ashred justify-center items-center p-2">
                    <img src={manfacture} alt="manfacture"/>
                    <div className='text-base font-bold text-white text-justify  p-1' data_ash_name="ulsol_b2_1">{ulsol_b2_1}</div>
                    <div className='text-base font-bold text-white text-justify' data_ash_name="ulsol_b2_2">{ulsol_b2_2}</div>
                </div>
                <div className="flex flex-col basis-1/4 bg-ashgray2 justify-center items-center p-2">
                    <img src={redtest} alt="redtest"/>
                    <div className='text-base font-bold text-black text-justify  p-1' data_ash_name="ulsol_b3_1">{ulsol_b3_1} </div>
                    <div className='text-base font-bold text-black text-justify' data_ash_name="ulsol_b3_3">{ulsol_b3_2}</div>
                </div>
                <div className="flex flex-col basis-1/4 bg-ashblack justify-center items-center p-2">
                    <img src={testico} alt="testico"/>
                    <div className='text-base font-bold text-white text-justify p-1' data_ash_name="ulsol_b4_1">{ulsol_b4_1}</div>
                    <div className='text-base font-bold text-white text-justify' data_ash_name="ulsol_b4_2">{ulsol_b4_2}</div>
                </div>
            </div>
           
            <div className='flex flex-row mt-0.5'>
                <div className="flex flex-col basis-1/4">
                   
                </div>
                <div className="flex flex-col basis-1/4">
                   
                </div>
                <div className="flex flex-col basis-1/4">
                    </div>
                <div className="flex flex-col basis-1/4 justify-end items-end">
                    <div className='w-3/5'></div>
                    <div className=' w-2/5 h-8 bg-ashred text-xs text-white flex items-center justify-center'>Explore
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                    </svg>
                    </div>
                </div>
             </div> */}

        </div> 
      
    </div>
    </>
  )
}

export default HomeUltimateSolution
