import React from 'react'
import styles from './YearwiseDetails.module.css';
const  YearwiseDetails = ({ year, event, image,image2x, description }) => {
  const formatWord = (text, wordToFormat, className) => {
    const regex = new RegExp(`(${wordToFormat})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? <span key={index} className={className}>{part}</span> : part
    );
  };

    return (
        <div className={`${styles.timeline_item_content}`}>
          <div className=' w-full   bg-ashred flex flex-col justify-center items-center   rounded-lg'>
          <h2 className="text-2xl font-semibold my-2  text-white">{year} | {event}</h2>
          </div>
          <div className='flex'>
            <div className='w-2/6 border rounded-md my-2'>
           
                  <img src={image} alt={event} className="w-full object-cover mb-4" 
                   srcSet={`${image} 1x, ${image2x} 2x`}
                  />
                 
              </div>
              <div className='mx-2 w-4/6 flex justify-center text-justify my-2 border rounded-md '>  
              <p className='my-5 mx-10'>{ formatWord(description,'ashlok','vollkorn-sc-semibold')}</p>
              </div>
           </div>
        </div>
      );
}

export default YearwiseDetails;
