    import React from 'react'
    import appimg from './assets/images/installation/application_image.jpg';
    import appimg2 from './assets/images/installation/application_image@2x.jpg';
{/*
    import appbgimg from './assets/images/installation/application_bg.png';
import appbgimg2 from './assets/images/installation/application_bg@2x.png';*/}
    const InstallationBlock = () => {
       {/* const appbgimg="https://raw.githubusercontent.com/gobisn/Images/main/application_bg.png";
        const appbgimg2="https://raw.githubusercontent.com/gobisn/Images/main/application_bg@2x.png";
        const appimg="https://raw.githubusercontent.com/gobisn/Images/main/application_image.png";
        const appimg2="https://raw.githubusercontent.com/gobisn/Images/main/application_image2x.png";
    
        const divStyle={
            backgroundImage:`url(${appbgimg})`
            
        }*/}    
      return (
        <div className='w-full bg-white'>
            <div className='container mx-auto'>
               <div className='flex justify-center items-center'>
                        
                            {/*
                            <img src={appbgimg} className=' w-3/4'
      srcSet={`${appbgimg} 1x, ${appbgimg2} 2x`}/>*/}

                            <img src={appimg2} className='justify-start top-0 w-2/4'
                            srcSet={`${appimg} 1x, ${appimg2} 2x`}
                            alt="appimg2"/>
                        
                        </div>
                
            </div>
        </div>
      )
    }
    
    export default InstallationBlock
    