import React, { createContext, useState, useContext } from 'react';

const FontLoaderContext = createContext();

export const FontLoaderProvider = ({ children }) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const fontsToPreload = [
    'https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@500&display=swap',
    'https://fonts.googleapis.com/css2?family=Cinzel:wght@400;600&display=swap',
    'https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;600&display=swap',
    'https://fonts.googleapis.com/css2?family=Vollkorn+SC:wght@400;600;700;900&display=swap',
    
  ];
  const loadFonts = async () => {
    try {
        await Promise.all(
          fontsToPreload.map(async font => {
            const response = await fetch(font);
            if (response.ok) {
              const styleSheet = document.createElement('style');
              styleSheet.textContent = await response.text();
              document.head.appendChild(styleSheet);
            }
          })
        );
        setFontsLoaded(true);
      } catch (error) {
        console.error('Error loading fonts:', error);
      }
    
  };

  return (
    <FontLoaderContext.Provider value={{ fontsLoaded, loadFonts }}>
      {children}
    </FontLoaderContext.Provider>
  );
};

export const useFontLoader = () => useContext(FontLoaderContext);