    import React,{useState} from 'react'
// import DataTable from './DataTable';
import Footer from './Footer';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import { HelmetProvider } from 'react-helmet-async';
import banner1 from './assets/images/dealer/dealership-banner.jpg';
import banner1_2x from './assets/images/dealer/dealership-banner@2x.jpg';
// import ContactBanner from './ContactBanner';
import InstallationBanner from './InstallationBanner';
import dealer_gif from './assets/images/dealer/Dealer_Page_Animation.gif';


    const Dealers = () => {
       const [isSticky, setSticky] = useState(true);
      const displline=true;
        const data = [
            { name: 'ABHA CONTAINERS', city: 'Port Blair, Andaman', address: "A-10, Industrial Estate,Dollygunj, Port Blair,\n Andaman - 744103.",contact:"abhaelectrodes@yahoo.com" },
            { name: 'ARCON POWER SYSTEM', city: 'Kolkatta, West Bengal', address: '32, Ezra Street, 5th Floor, Room No.512, South Block, Kolkatta-1.',contact:"arcon512@gmail.com"},
            { name: 'ASHUTOSH MARKETING', city: 'Vadodra, Gujarat', address: 'A-401, Radhe Apartment, Pashabhai Park, Race Course Circle, Vadodara - 390007.',contact:"ashutoshmarketing01@gmail.com"},
         /*   { name: 'ATHARVA INFRASTRUCTURES', city: 'Pune, Maharastra', address: 'Setu, Plot No. 69, Bharatkunj Colony, No. 2 PWD Society, Erandwane, Kothrud, Pune - 411038.',contact:"atharvainfra@vsnl.net"},
            { name: 'B & B INC. PVT LTD', city: 'Patan, Nepal', address: 'DPO Box. 287, Kumaripati, Patan, Lalitpur, Nepal',contact:"rajesh.maharjan@bnbinc.com.np"},*/
            { name: 'ELMECHS', city: 'Kolkatta, West Bengal', address: '156, Sibhachal Road, Karanji Bhavan, Birati,\n Kolkatta - 700 051.',contact:" elmech_s@rediffmail.com"},
            { name: 'HITECH MARKETING SERVICES', city: 'Indore, Madhya Pradesh', address: 'C/o Sakshi, G-2, Vasant Plaza, 4, Nayapura, Indore (MP)- 452007.',contact:" htms_ind@yahoo.co.in"},
            { name: 'INVOTECH ENGINEERS & CONSULTANTS', city: 'Jalandhar, Punjab', address: 'Backside Bhagat Singh Colony, Near R.J.Cold Store, Amristar Bye Pass, G.T. Road, Jalandar - 144008.',contact:"invotechindia@rediffmail.com"},
            { name: 'LINK ELECTRICALS', city: 'Delhi', address: '1853/1, Hari Ram Market, Bhagirath Palace, \n Delhi - 110 006.',contact:"linkelectrical@gmail.com"},
       /*  { name: 'LINK SOLUTION', city: 'Delhi', address: '1853/1, Hari Ram Market, Bhagirath Palace, \n Delhi - 110 006.',contact:"linkelectrical@gmail.com"}, */
            { name: 'M.S.EARTHING SOLUTIONS', city: 'Coimbatore, Tamil Nadu', address: '83/174, Ankannan Devar Layout, 80 Feet Road, Ramanthapuram, Coimbatore - 641 045.',contact:"msearthingsolutions@gmail.com"},
            { name: 'NOBLE ENGINEERS', city: 'Jaipur, Rajastan', address: '2564, Vijay Vargiya Building, SMS Highway, Jaipur, Rajasthan - 302003.',contact:"ashoksuman22@yahoo.com"},
            { name: 'OM SAI ELECTRICALS', city: 'Goa', address: 'Rose Garden, Chogum Road, Green Valley, \n Alto Porovorium, Goa - 403521.',contact:"omsai_chodanker@yahoo.co.in"},
            { name: 'RELIABLE ELECTRICAL ENTERPRISES (RELIABLE ENTERPRISES)', city: ' Chennai, Tamil Nadu', address: 'No: 117, Govindappa Naicken Street, \n Chennai- 600001.',contact:"reliable_telecom@yahoo.com"},
      /*      { name: 'ROYAL REFRIGENERATION AND AIRCONDITIONING', city: 'Hubli, Karanataka', address: 'Opp. N.K.Complex, Keshwarpur Cicrle, \n Hubli - 580023. Karnataka',contact:"royalearthing@rediffmail.com"}, 
            { name: 'S.P.ENGINEERS & SUPPLIERS', city: 'Ichalkaranji, Maharastra', address: '12/581, DR.Sakharpe Hospital Chowk,\n Ichalkaranji - 416115.',contact:"heerasp@gmail.com"},*/
            { name: 'SARAVANA ELECTRICAL ENTERPRISES', city: ' Trichy, Tamilnadu', address: ' 73, Madurai Road, Near Chamber of Commerce, Trichy - 620008.',contact:"see@vsgroupsindia.com"},
            { name: 'SRI SARAVANA AGENCIES', city: ' Salem, TamilNadu', address: ' No : 284, 1st Street, Muruga Gounder Kadu, Kalarampatty Main Road, Salem - 636015.',contact:"slmssa@vsgroupsindia.com"},
     /*     { name: 'SRISHTI SALES & SERVICES', city: 'Ludhiana, Punjab', address: '131, Mahavir Jain dyeing complex, Tajbu road, Ludhiana',contact:"purchase@srishtisales.com , info@stishtisales.com"}, */
            { name: 'STAR DELTA ENGINEERS', city: ' Bangalore, Karnataka', address: ' 2655-A, A Block, AECS Layout, Old Indl. Town,Singsandra, Hosur Rd, Bangalore-560 068',contact:"stardelta@rediffmail.com"},
            { name: 'TIRUPATI ELECTROTECH', city: 'Jaipur, Rajastan', address: ' 446, Unnati Tower, Vidhyadhar Nagar-11, \n Central Spine, Jaipur-302023',contact:"tirupati_electrotech@yahoo.co.in"},
       /*   { name: 'TODI ASSOCIATES', city: 'Chennai, Tamil Nadu', address: ' 14/2, Krishna Iyer Street, Sowcarpet, \n Chennai - 600079.',contact:"todi_associates@yahoo.co.in"}, 
            { name: 'VIJETHA EARTHING SYSTEMS', city: 'Secunderabad, Telangana ', address: '4-3-303/1, Old Bhoiguda, R.P.Road,\n Secunderabad - 500003.',contact:"vijethaearthing@yahoo.co.in"}, */
            { name: 'AMEC GLOBAL', city: 'Gwalior, Madhya Pradesh', address: 'B-17, Sector-3, Vinay Nagar, Gwalior(M.P)-474012.',contact:"amec1010@gmail.com"},
            { name: 'ALPHA ASSOCIATES', city: 'Chennai, Tamilnadu', address: 'No:1/59, 69th Street, RV Nagar, Ashok Nagar,\n Chennai - 600083.',contact:"alphaassociates30@gmail.com"},
     /*       { name: 'SHRI VENKATESHWAR POWER SERVICE', city: 'Belagavi, Karnataka', address: 'S.No.109, Plot No-2A, Near Gulmor Colony, Belagavi, Karnataka - 590011.',contact:"kartikvgh@gmail.com"},
            { name: 'GREEN POWER SOLUTION', city: 'Tirunelveli, Tamilnadu', address: '13/5 Main Road, Near Eskaline Kalyana Mandapam \n T Kallikulam, Tirunelveli, Tamilnadu - 627113.',contact:"greenpowersoln20@gmail.com"},  */
            { name: 'R G ENTERPRISES', city: 'Kota, Rajasthan', address: 'No.164, Sunder Nagar Kanel Road, Kota Junction, Rajasthan - 324002',contact:"rajesh_7529@yahoo.com"},
            // Add more data as needed
          ];
        
          const preprocessData = (data) => {
            return data.map(item => {
              // Replace '\n' with '<br>' in the relevant field
              item.address = item.address.replace(/\n/g, '<br>').replace(/&lt;br&gt;/g, '<br>');
             // console.log(item.address);
              item.address = item.address
              //console.log(item.address);
              return item;
            });
          };
          
          const preprocessedData = preprocessData(data);
      return (
        <div>
            
            <HelmetProvider>
            <title>Join Ashlok's Network - Become a Dealer for Leading Earthing & Lightning Protection Solutions in India</title>
            <meta name="description" content="Ashlok invites you to join our expansive network of dealers across India, bringing top-quality Earthing and Lightning Protection systems to diverse sectors. Explore the opportunity to collaborate with us, representing a brand synonymous with safety, innovation, and reliability. Learn more about becoming a valued Ashlok dealer."/>
          </HelmetProvider>
        <PreHeader/>
        <Header isSticky={isSticky}/>
    <Menu isHeaderVisible={isSticky}/>
        <InstallationBanner page="contact" banalt="contact " apostropheBefore="" apostropheAfter="" ban1x={banner1} ban2x={banner1_2x}/>
        <div className='w-full my-3 flex justify-center items-center'>
            <div className=' container max-w-screen-xl flex flex-col justify-center'>

           <p>Our Authorized Dealers form an integral part of our commitment to ensuring widespread access to top-tier earthing solutions. With a network of trusted partners, we extend our reach to customers across diverse industries, offering them unparalleled quality and service. Our Authorized Dealers are meticulously selected and trained to uphold our standards of excellence, providing expert guidance and support to clients seeking the best in electrical safety. Together, we strive to empower businesses and individuals alike with the assurance of robust earthing systems that prioritize safety above all else. Explore our Authorized Dealers network and discover the peace of mind that comes with choosing the <span className='vollkorn-sc-semibold'>Ashlok</span> Way</p>
            {/*<DataTable data={preprocessedData} />*/}
                  <div className='w-full'>
                      <img src={dealer_gif} alt="dealers list"/>
                  </div>
            </div>  
        </div>
        <Footer linedisp={displline}/>
        </div>
      )
    }
    
    export default Dealers
    