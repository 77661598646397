import React,{useContext} from 'react'
import { ProductContext } from './context/Productcontext';
import { Link,useNavigate } from 'react-router-dom';
import eplogo from './assets/images/earthingcategory/earthing_logo.png';
import eplogo2x from './assets/images/earthingcategory/earthing_logo@2x.png';

import hotdip2x from './assets/images/earthingcategory/hotdip@2x.png';
import zinc2x from './assets/images/earthingcategory/zinc@2x.png';
import pvd2x from './assets/images/earthingcategory/pvd@2x.png';



import alloy2x from './assets/images/earthingcategory/alloy_coated@2x.png';
import copper2x from './assets/images/earthingcategory/copper_plated@2x.png';
import cbr2x from './assets/images/earthingcategory/copper_bonded_rod@2x.png';



import coat2x from './assets/images/earthingcategory/carbon_coating@2x.png';

import ccost2x from './assets/images/earthingcategory/conducto_cast@2x.png';

import acc2x from './assets/images/earthingcategory/earthing_accessories@2x.png';




    const EarthinCategoryContent = () => {
        let navigate = useNavigate();
        const {selectedCategory,setCategory,selectedProduct, setProduct} = useContext(ProductContext);
        const roundedbottom={
            borderBottomLeft:10,
            borderBottomRight:10
  
          }
          const handleItemClick = (item) => {
           setCategory('ele');
           setProduct(item);
           console.log(item);
           navigate('/eedetails');
             /*if(item=='hd_aseel')
             navigate('');*/
          };

        return (
          <div className=' w-full flex-col mx-auto flex justify-center items-center my-3'>
              <img src={eplogo}/>
              <div className='container w-max-xl flex flex-row justify-center items-center m-5 mt-10 space-x-16 cursor-pointer' onClick={() => handleItemClick('hd_aseel')}>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer' onClick={() => handleItemClick('hd_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={hotdip2x} alt="thor img"
                          srcSet={`${hotdip2x} 1x, ${hotdip2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold ">ASEEL HD | Galvanized</p>
                          <p className="text-xl font-bold  text-ashblack  ">ASEEL HD | Galvanized</p>
                          <p className="text-base">Ashlok’s hotdip earthing electrode(s) : superior grounding.</p>
                              
                          
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                          
                      </div>
                  </div>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer' onClick={() => handleItemClick('z_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={zinc2x} alt="thor img"
                          srcSet={`${zinc2x} 1x, ${zinc2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold">ASEEL Z | Zinc Coated</p>
                          <p className="text-xl font-bold  text-ashblack ">ASEEL HD | Galvanized</p>
                          <p className="text-base">Ashlok zinc coated earthing electrodes : premium protection.</p>
                           <Link to="/zincearthdetails">
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                              </Link>
                      </div>
                  </div>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer' onClick={() => handleItemClick('pvd_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={pvd2x} alt="thor img"
                          srcSet={`${pvd2x} 1x, ${pvd2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold">ASEEL PVD | Zinc 150+ Microns</p>
                          <p className="text-base">Ashlok pvd earthing electrodes : precision grounding</p>
                              <Link to="/pvdearthdetails">
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                              </Link>
                      </div>
                  </div>
  
              </div>
              {/* second row */}
              <div className='container w-max-xl flex flex-row justify-center  m-5 mt-10 space-x-16'>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer' onClick={() => handleItemClick('a_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={alloy2x} alt="thor img"
                          srcSet={`${alloy2x} 1x, ${alloy2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold">ASEEL A | Alloy Coated</p>
                          <p className="text-xl font-bold  text-ashblack ">ASEEL HD | Galvanized</p>
                          <p className="text-base">Ashlok alloy coated earthing electrodes : ultimate protection.</p>
                              <Link to="/alloyearthdetails">
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                              </Link>
                      </div>
                  </div>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer' onClick={() => handleItemClick('cp_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={copper2x} alt="thor img"
                          srcSet={`${copper2x} 1x, ${copper2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold">ASEEL CP | Copper Plated</p>
                          <p className="text-xl font-bold  text-ashblack ">ASEEL HD | Galvanized</p>
                          <p className="text-base">Ashlok copper plated earthing electrodes : reliable grounding for safety and efficiency.</p>
                              <Link to="/copperplateearthdetails">
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                              </Link>
                      </div>
                  </div>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer' onClick={() => handleItemClick('cbr_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={cbr2x} alt="thor img"
                          srcSet={`${cbr2x} 1x, ${cbr2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold">ASEEL CBR | Copper Bonded Rod</p>
                          <p className="text-base">Ashlok copper bonded rods : reliable grounding solutions for enhanced safety.</p>
                              <Link to="/cbrearthdetails">
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                              </Link>
                      </div>
                  </div>
  
              </div>

              {/* Third row */}
              <div className='container w-max-xl flex flex-row justify-center  m-5 mt-10 space-x-16'>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer' onClick={() => handleItemClick('cc_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={coat2x} alt="thor img"
                          srcSet={`${coat2x} 1x, ${coat2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold">ASEEL CC | Composite Carbon Coating Technology</p>
                          <p className="text-base">Ashlok’s composite carbon coating : advanced technology for superior grounding.</p>
                              <Link to="/carboncoateearthdetails">
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                              </Link>
                      </div>
                  </div>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer' onClick={() => handleItemClick('cfc_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={ccost2x} alt="thor img"
                          srcSet={`${ccost2x} 1x, ${ccost2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold">ASEEL CFC | CarboForm ConductoCasting</p>
                          <p className="text-base">Ashlok singleton earthing ecosystem : seamless integration for optimal grounding.</p>
                          <p className="text-base text-ashblack"> efficiency.</p>
                              <Link to="/carbonformearthdetails">
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                              </Link>
                      </div>
                  </div>
                  <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer' onClick={() => handleItemClick('earth_aseel')}>
                      <div className=' flex justify-center items-center'>
                          <img src={acc2x} alt="thor img"
                          srcSet={`${acc2x} 1x, ${acc2x}2x`}/>
                      </div>
                      <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                          <p className="text-xl font-bold">Earthing Accessories</p>
                          <p className="text-xl font-bold  text-ashblack ">Earthing Accessories</p>
                          <p className="text-base">Ashlok earthing accessories : enhancing safety and efficiency.</p>
                          <p className="text-base text-ashblack"> efficiency.</p>
                              <Link to="/eartaccdetails">
                              <div className='flex justify-end items-end'>
                                  <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                      </svg>
                                  </div>
                              </div>
                              </Link>
                      </div>
                  </div>
  
              </div>
  
          </div>
        )
    }
    
    export default EarthinCategoryContent
    