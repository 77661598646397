import React,{useContext} from 'react'
import { ProductContext } from './context/Productcontext';
import { Link,useNavigate } from 'react-router-dom';

import eplogo from './assets/images/earthingcategory/earthing_logo.png';

//import hotdip from './assets/images/earthingcategory/hotdip.png';
import hotdip2x from './assets/images/earthingcategory/hotdip@2x.png';
// import zinc from './assets/images/earthingcategory/zinc.png';
import zinc2x from './assets/images/earthingcategory/zinc@2x.png';
// import pvd from './assets/images/earthingcategory/pvd.png';
import pvd2x from './assets/images/earthingcategory/pvd@2x.png';


// import alloy from './assets/images/earthingcategory/alloy_coated.png';
import alloy2x from './assets/images/earthingcategory/alloy_coated@2x.png';
// import copper from './assets/images/earthingcategory/copper_plated.png';
import copper2x from './assets/images/earthingcategory/copper_plated@2x.png';
// import cbr from './assets/images/earthingcategory/copper_bonded_rod.png';
import cbr2x from './assets/images/earthingcategory/copper_bonded_rod@2x.png';


// import coat from './assets/images/earthingcategory/carbon_coating.png';
import coat2x from './assets/images/earthingcategory/carbon_coating@2x.png';
// import ccost from './assets/images/earthingcategory/conducto_cast.png';
import ccost2x from './assets/images/earthingcategory/conducto_cast@2x.png';
// import acc from './assets/images/earthingcategory/earthing_accessories.png';
import acc2x from './assets/images/earthingcategory/earthing_accessories@2x.png';

const customStyles = `
.max-height-percentage {
  max-height: 80%; /* Adjust the percentage as needed */
}
`;

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  };
    const SafeearthingelectrodeContent = () => {
        let navigate = useNavigate();
        const {selectedCategory,setCategory,selectedProduct, setProduct} = useContext(ProductContext);
        const roundedbottom={
            borderBottomLeft:10,
            borderBottomRight:10
  
          }
          const handleItemClick = (item) => {
           setCategory('ele');
           setProduct(item);
           console.log(item);
           navigate('/eedetails');
           scrollToTop();
             /*if(item=='hd_aseel')
             navigate('');*/
          };
    return (

        <div className=' w-full flex-col mx-auto flex justify-center items-center my-10'>
            <style>{customStyles}</style>
        <img src={eplogo}/>
        {/* First row */}
        <div className='container w-max-xl flex flex-row justify-center  m-5 mt-10 space-x-16'>
            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer justify-end' onClick={() => handleItemClick('cc_aseel')}>
                <div className=' flex justify-center items-center '>
                    <img src={coat2x} alt="thor img"
                    srcSet={`${coat2x} 1x, ${coat2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
              
                    <p className="text-l font-bold line-clamp-2 text-center"><span className='vollkorn-sc-semibold'>ASEEL CC | Composite Carbon Coating Technology</span></p>
                   {/*
                    <p className="text-base line-clamp-3 indent-10">Ashlok’s composite carbon coating : advanced technology for superior grounding.</p>
                    <p className=" text-ashblack text-base">Ashlok’s composite carbon coating</p>
                 
                        <Link to="/carboncoateearthdetails">
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                        </div>
                    </Link>*/}
                </div>
            </div>
            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer justify-end' onClick={() => handleItemClick('cfc_aseel')}>
                <div className=' flex justify-center items-center'>
                    <img src={ccost2x} alt="thor img"
                    srcSet={`${ccost2x} 1x, ${ccost2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                   
                    <p className="text-l font-bold line-clamp-2 text-center"><span className='vollkorn-sc-semibold'>ASEEL CFC | CarboForm ConductoCasting</span></p>
                        {/*
                    <p className="text-base line-clamp-3 indent-10">Ashlok singleton earthing ecosystem : seamless integration for optimal grounding.</p>
                    <p className="text-base text-ashblack"> Ashlok singleton earthing ecosystem.</p>
                     
                        <Link to="/carbonformearthdetails">
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                        </div>
                    </Link>*/}
                </div>
            </div>

            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer justify-end' onClick={() => handleItemClick('cp_aseel')}>
                <div className=' flex justify-center items-center'>
                    <img src={copper2x} alt="thor img"
                    srcSet={`${copper2x} 1x, ${copper2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                    <div className="text-l font-bold line-clamp-2 text-center"><span className='vollkorn-sc-semibold'>ASEEL CP | Copper Plated</span> <p className=' text-ashblack'>ASEEL CP | Copper Plated</p></div>
                   {/*
                    <p className="text-xl font-bold  text-ashblack ">ASEEL HD | Galvanized</p>
                    <p className="text-base line-clamp-3 indent-10">Ashlok copper plated earthing electrodes : reliable grounding for safety and efficiency.</p>
                        <Link to="/copperplateearthdetails">
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                        </div>
                    </Link>*/}
                </div>
            </div>
            
            
        </div>
        {/* second row */}
        <div className='container w-max-xl flex flex-row justify-center items-center m-5 mt-10 space-x-16 cursor-pointer' >
            
            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer justify-end' onClick={() => handleItemClick('a_aseel')}>
                <div className=' flex justify-center items-center'>
                    <img src={alloy2x} alt="thor img"
                    srcSet={`${alloy2x} 1x, ${alloy2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                    <p className="text-l font-bold line-clamp-2 text-center"><span className='vollkorn-sc-semibold'>ASEEL A | Alloy Coated</span></p>
                    {/*
                    <p className="text-xl font-bold  text-ashblack ">ASEEL HD | Galvanized</p>
                    <p className="text-base line-clamp-2 indent-10">Ashlok alloy coated earthing electrodes : ultimate protection.</p>
                    <p className=" text-ashblack text-base">Ashlok alloy coated earthing</p>
                        <Link to="/alloyearthdetails">
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                        </div>
                    </Link>*/}
                </div>
            </div>
            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer justify-end' onClick={() => handleItemClick('pvd_aseel')}>
                <div className=' flex justify-center items-center'>
                    <img src={pvd2x} alt="thor img"
                    srcSet={`${pvd2x} 1x, ${pvd2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm ">
                    <p className="text-l font-bold line-clamp-1 text-center"><span className='vollkorn-sc-semibold'>ASEEL PVD | Zinc 150+</span></p>
                    {/*
                    <p className=" text-ashblack text-xl font-bold">ASEEL PVD</p>
                    <p className="text-base line-clamp-2 indent-10">Ashlok pvd earthing electrodes : precision grounding</p>
                  
                        <Link to="/pvdearthdetails">
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                        </div>
                    </Link>*/}
                </div>
            </div>
            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer justify-end' onClick={() => handleItemClick('z_aseel')}>
                <div className=' flex justify-center items-center'>
                    <img src={zinc2x} alt="thor img"
                    srcSet={`${zinc2x} 1x, ${zinc2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                    <p className="text-l font-bold line-clamp-2 text-center"><span className='vollkorn-sc-semibold'>ASEEL Z | Zinc Coated</span></p>
                   {/*
                    <p className="text-xl font-bold  text-ashblack ">ASEEL HD | Galvanized</p>
                    <p className="text-base line-clamp-2 indent-10">Ashlok zinc coated earthing electrodes : premium protection.</p>
                     <Link to="/zincearthdetails">
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                        </div>
                        </Link>*/}
                </div>
            </div>
            

        </div>
        {/* Third row */}
        <div className='container w-max-xl flex flex-row justify-center  m-5 mt-10 space-x-16'>
           
            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer justify-end' onClick={() => handleItemClick('hd_aseel')}>
                <div className=' flex justify-center items-center'>
                    <img src={hotdip2x} alt="thor img"
                    srcSet={`${hotdip2x} 1x, ${hotdip2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                    <p className="text-l font-bold line-clamp-2 text-center" ><span className='vollkorn-sc-semibold'>ASEEL HD | Galvanized</span></p>
                    {/*                    <p className="text-xl font-bold  text-ashblack ">ASEEL HD | Galvanized</p>
                    <p className="text-base line-clamp-2 indent-10">Ashlok’s hotdip earthing electrode(s) : superior grounding.</p>
                        
                    
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                            </div>*/}
                    
                </div>
            </div>
    


            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg cursor-pointer justify-end' onClick={() => handleItemClick('cbr_aseel')}>
                <div className=' flex justify-center items-center'>
                    <img src={cbr2x} alt="thor img"
                    srcSet={`${cbr2x} 1x, ${cbr2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                    <p className="text-l font-bold line-clamp-2 text-center"><span className='vollkorn-sc-semibold'>ASEEL CBR | Copper Bonded Rod</span></p>
                   {/*
                    <p className="text-base line-clamp-3 indent-10">Ashlok copper bonded rods : reliable grounding solutions for enhanced safety.</p>
                        <Link to="/cbrearthdetails">
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                        </div>
                    </Link>*/}
                </div>
            </div>

            <div className='flex flex-col w-1/5 border bg-ashgray1 rounded-lg  cursor-pointer justify-end' onClick={() => handleItemClick('earth_acc')}>
                <div className=' flex justify-center items-center'>
                    <img src={acc2x} alt="thor img"
                    srcSet={`${acc2x} 1x, ${acc2x}2x`}/>
                </div>
                <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                    
                    <p className="text-l font-bold line-clamp-2 text-center"><span className='vollkorn-sc-semibold text-center'>Earthing Accessories</span> </p>
                    {/*
                    <p className="text-xl font-bold text-ashblack">Earthing Accessories</p>
                    <p className="text-base line-clamp-2 indent-10">Ashlok earthing accessories : enhancing safety and efficiency.</p>
                    <p className="text-base text-ashblack"> Ashlok singleton earthing ecosystem. eco system232 Ashlok singleton</p>
                     </div>
                        <Link to="/carbonformearthdetails">
                        <div className='flex justify-end items-end'>
                            <div className='p-2   h-8  bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                            </div>
                        </div>
                    </Link>*/}
                </div>
            </div>

        </div>

     
       

        

    </div>
    )
    }
    
    export default SafeearthingelectrodeContent
    