import React from 'react'
import cmdphoto from './assets/images/heritage/cmd_photo.jpg';    
import cmdphoto2x from './assets/images/heritage/cmd_photo@2x.jpg';  

    const HeritageDecades = () => {
      return (
        <>
        <div className=' container mx-auto max-w-screen-xl'>
          <div className='flex items-center justify-center'>
            <p className=' text-ashred text-2xl font-extrabold my-3'>In Retrospect : The Inception of <span className='vollkorn-sc-semibold'>Ashlok</span></p>
          </div>
          <div className=' flex flex-col'>
              <p className=' text-justify text-base mb-3'>Founded in 1999 by the visionary, late <span className='vollkorn-sc-semibold text-ashred'>Ashok Tripathy,</span> <span className='vollkorn-sc-semibold'>Ashlok</span> Safe Earthing Electrode Limited embarked on a transformative journey in the field of electrical safety. From its inception, the company set out to address prevalent electrical challenges with innovative solutions. Under Mr. Tripathy’s leadership, <span className='vollkorn-sc-semibold'>Ashlok</span> introduced Maintenance-Free Earthing Technology in 1999, revolutionizing conventional earthing methods in India and establishing the company as a trailblazer in the industry.</p>
              <p className=' text-justify text-base mb-3'>Over the years, <span className='vollkorn-sc-semibold'>Ashlok’s</span> commitment to quality and innovation propelled its growth, expanding its product offerings to a comprehensive range of Earthing & Lightning Protection solutions. The company’s diverse portfolio includes cutting-edge technologies designed to safeguard structures from lightning and ensure effective earthing, reflecting its dedication to staying ahead of industry needs.</p>
              <p className=' text-justify text-base mb-3'>As <span className='vollkorn-sc-semibold'>Ashlok</span> Safe Earthing Electrode Limited steps into its 25th year, the journey is marked by milestones of success and a legacy of reliability. The company’s continuous evolution and adaptability have enabled it to become a trusted name in the electrical safety sector. This milestone year is a testament to <span className='vollkorn-sc-semibold'>Ashlok’s</span> resilience and commitment to excellence, paving the way for even greater achievements in the years to come. As it celebrates a quarter-century of innovation, <span className='vollkorn-sc-semibold'>Ashlok</span> remains dedicated to providing state-of-the-art solutions that redefine standards and contribute to the safety and reliability of electrical systems nationwide.</p>
          </div>
          <div className=' flex '>
            <div className='w-1/2 flex flex-col justify-start items-center pb-3'>
                <div className='flex justify-start items-center pt-2'>
                <img className=' object-cover' src={cmdphoto} alt="Founder" 
                   srcSet={`${cmdphoto} 1x, ${cmdphoto2x} 2x`}
                />
                </div>
                <p className=' text-ashred text-2xl mt-3'>Late <span className='vollkorn-sc-semibold'>Ashok Tripathy</span></p>
             
                <p className=''>Founder</p>
            </div>
            <div className='w-1/2 flex flex-col justify-start items-center pl-5'>
            <p className=' text-justify text-base mb-3'>In 1999, late <span className='vollkorn-sc-semibold text-ashred'>Ashok Tripathy</span> sowed the seeds of <span className='vollkorn-sc-semibold'>Ashlok</span> Safe Earthing Electrode Limited, leaving behind a profound legacy in the realm of electrical safety. His vision and pioneering spirit laid the foundation for a company that continues to thrive on his principles of excellence, innovation, and unwavering commitment.</p>
            <p className=' text-justify text-base my-3'><span className='vollkorn-sc-semibold text-ashred'>Mr. Ashok Tripathy’s</span> journey began with a deep understanding of the pressing electrical challenges faced by industries and individuals alike. Motivated by a passion to make a lasting impact, he founded <span className='vollkorn-sc-semibold'>Ashlok</span> Safe Earthing Electrode Limited to introduce revolutionary solutions that would redefine the standards of electrical safety</p>
            <p className=' text-justify text-base my-3'>Under his leadership, the company flourished, propelled by the introduction of Maintenance-Free Earthing Technology in 1999. This groundbreaking innovation not only addressed prevalent electrical challenges but also became a hallmark of the company’s commitment to providing advanced and reliable earthing solutions</p>
            <p className=' text-justify text-base my-3'>Although the visionary founder, <span className='vollkorn-sc-semibold text-ashred'>Mr. Ashok Tripathy,</span> is no longer with us, his legacy lives on through the enduring values and principles instilled in the fabric of <span className='vollkorn-sc-semibold'>Ashlok</span> Safe Earthing Electrode Limited. The company continues to honour his memory by upholding the highest standards of quality, innovation, and customer satisfaction</p>
            <p className=' text-justify text-base my-3'>As we reflect on the journey that began in 1999, we pay tribute to the indomitable spirit of our late founder, whose vision and dedication remain the guiding light for <span className='vollkorn-sc-semibold'>Ashlok</span>.</p>
      
            </div>
          </div>
         


        </div>
        <div className=' w-full   bg-ashred flex flex-col justify-center items-center'>
        <div className=' text-3xl  text-white p-2 underline-offset-auto font-semibold' >Chronicles of Excellence</div>
        </div>
        {/*
        style={{ fontFamily: 'cinzel, sans-serif' }}
        <div className=' bg-white m-5 mb-14'>
            <div className=' container mx-auto max-w-screen-xl flex  flex-col  mb-14  justify-center items-center'> 
                <p className=' text-ashred text-3xl m-3'>2 Decades down… Infinity to go…</p>
                <p className=' text-justify m-3'>We were convinced that a superior solution existed, one that didn’t rely on the outdated and inefficient earthing system. In a bold departure from tradition, we pioneered the earthing revolution in India in 1999. Today, we lead the way in every earthing project undertaken in the nation</p>
                <p className=' text-justify' > We thank you for understanding that there is a better way in the <span className='vollkorn-sc-semibold'>Ashlok</span> way.</p>
                <p className=' mt-10 text-ashred text-3xl font-semibold tracking-wider' style={{ fontFamily: 'cinzel, sans-serif' }}>A S H I S H T R I P A T H Y</p>
                <p className=' text-justify font-semibold tracking-wider    ' style={{ fontFamily: 'Rajdhani, sans-serif' }}> Chairman | Managing Director of <span className='vollkorn-sc-semibold'>Ashlok</span></p>
                
            </div>
           
        </div>
         <div className=' flex bg-ashblack  text-white justify-center items-center'>
                <p className=' text-2xl m-2'>OVER TWO DECADES</p>

         </div>
         <div className=' flex bg-ashred text-white justify-center items-center pb-3'>
                <p className=' text-2xl m-2'>In Retrospect : The Inception of <span className='vollkorn-sc-semibold'>Ashlok</span></p>

         </div>*/}
     </>
      )
    }
    
    export default HeritageDecades
