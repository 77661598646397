    import React,{forwardRef} from 'react'
    import design from './assets/images/designcommission/ese_image.jpg';
    import design2 from './assets/images/designcommission/ese_image@2x.jpg';
    
    import comm from './assets/images/designcommission/commission_image.jpg';
    import comm2 from './assets/images/designcommission/commission_image@2x.jpg';
    
    import test from './assets/images/designcommission/test_image.jpg';
    import test2 from './assets/images/designcommission/test_image@2x.jpg';
    
    import risk from './assets/images/designcommission/risk_image.jpg';
    import risk2 from './assets/images/designcommission/risk_image@2x.jpg';
    import { Link } from 'react-router-dom';
    
    import know from './assets/images/designcommission/know_more_button.svg';
    const DesignCommissionContent = forwardRef((props,ref) => {
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth' // Smooth scrolling animation
            });
          };
         
      return (
        <>
        <div className=' bg-ashred flex items-center justify-center flex-col p-2'>
            <p className='  text-white text-3xl'>DESIGN & COMMISSIONING SERVICES</p>
        </div>
        <div className='w-full bg-white'>
        <div className=' container flex flex-col mx-auto mt-3'>
            <div className=' mx-auto  max-w-screen-xl'>
            <p className=' text-base text-black text-justify'><span className='vollkorn-sc-semibold'>Ashlok’s</span> design and commissioning service epitomizes excellence, blending innovation with precision. Meticulously crafted solutions cater to diverse needs, ensuring seamless integration and optimal performance. Our team’s expertise shines in every detail, from conceptualization to execution, setting industry standards and delivering unparalleled satisfaction. Trust <span className='vollkorn-sc-semibold'>Ashlok</span> for visionary design and flawless commissioning experiences.</p>
            </div>  
            {/* first block start  max-w-6xl*/}
            <div className='mx-auto max-w-screen-xl mt-3 mb-3'>
                <div className='flex flex-row space-x-20'>
                    {/*
                    <div className=' flex basis-2/4  flex-row'>
                        <div className='flex justify-start'>
                        <img src={design} 
                         srcSet={`${design} 1x, ${design2} 2x`}
                         className='object-cover'
                        />
                        </div>
                    </div>
                    */}
                    <div className=' flex basis-1/2 flex-col '>

                        <p className=' bg-ashblack text-white p-3 items-center justify-center font-semibold text-2xl'>
                        E.S.E Conventional Lightning Protection Design

                        </p>
                        <p className=' text-justify pt-2 '>
                                At <span className='vollkorn-sc-semibold'>Ashlok</span>, our E.S.E Conventional Lightning Protection Design service combines cutting-edge technology with expert knowledge to create comprehensive lightning protection solutions. Our designs are tailored to your specific requirements, ensuring the utmost safety for your property and assets.
                        </p>
                        {/*<div className="h-4"></div>*/}
                        <div className=' ml-auto mt-15 pr-2 cursor-pointer' onClick={() => scrollToTop()}>
                           {/*
                            <Link to="/contact">
                            <img src={know} className='h-8'/>
                        </Link>*/}
                        </div>
                    </div>
                    <div className=' flex basis-1/2 flex-col'>

                        <p className=' bg-ashblack text-white p-3 items-center justify-center font-semibold text-2xl'>
                        
                        Risk Assessment of Lightning Protection Design
                        </p>
                        <p className=' text-justify pt-2 '>
                        With our Risk Assessment of Lightning Protection System service, we meticulously analyze potential vulnerabilities in your lightning protection setup. Our thorough assessment helps identify areas of improvement, enabling us to fortify your system and enhance its overall effectiveness.
                        </p>
                        {/*<div className="h-4"></div>*/}
                        <div className=' ml-auto mt-15 pr-2 cursor-pointer' onClick={() => scrollToTop()}>
                        {/*
                          <Link to="/contact">
                            <img src={know} className='h-8'/>
                            </Link>
                            */}
                        </div>
                    </div>


                </div>
            </div>
            {/* second block start */}
            <div className='mx-auto max-w-screen-xl  mb-3'>
                <div className='flex flex-row space-x-20'>
                    {/*
                    <div className=' flex basis-2/4  flex-row'>
                        <div className='flex justify-start'>
                        <img src={risk} 
                         srcSet={`${risk} 1x, ${risk2} 2x`}
                         className='object-cover'
                        />
                        </div>
                    </div>
                    */}

                        <div className=' flex basis-3/4 flex-col'>

                                <p className=' bg-ashblack text-white p-3 items-center justify-center font-semibold text-2xl'>
                                Commissioning of Lightning Protection Design

                                </p>
                                <p className=' text-justify pt-2 '>
                                <span className='vollkorn-sc-semibold'>Ashlok’s</span> Commissioning of Lightning Protection System service ensures that your lightning protection system is installed and integrated seamlessly. Our expert team carefully oversees the commissioning process, guaranteeing that your system operates at peak performance and provides the highest level of safety.
                                </p>
                            {/*<div className="h-8"></div>*/}
                            <div className=' ml-auto mt-15 pr-2 cursor-pointer' onClick={() => scrollToTop()}>
                            {/*
                            <Link to="/contact">
                                <img src={know} className='h-8'/>
                            </Link>*/}
                            </div>
                        </div>

                            <div className=' flex basis-3/4 flex-col '>

                                <p className=' bg-ashblack text-white p-3 items-center justify-center font-semibold text-2xl'>

                                Testing of Existing Lightning Protection System and Earthing System
                                </p>
                                <p className=' text-justify pt-2 '>
                                Our Testing of Existing Lightning Protection System and Earthing System service offers comprehensive evaluations of your current setup. Through meticulous testing and analysis, we identify any potential issues or weaknesses, allowing us to recommend and implement necessary improvements to ensure optimal system functionality and safety.     
                                </p>
                               {/* <div className="h-8"></div>*/}
                                <div className=' ml-auto mt-15 pr-2 cursor-pointer' onClick={() => scrollToTop()}>
                                {/*
                                <Link to="/contact">
                                    <img src={know} className='h-8'/>
                                </Link>*/}
                                </div>
                            </div>
                    
                </div>
            </div>
            {/* Third block start 
            <div className='mx-auto max-w-screen-xl mt-5 mb-5'>
                <div className='flex flex-row space-x-3'>
                   
                    <div className=' flex basis-2/4  flex-row'>
                        <div className='flex justify-start'>
                        <img src={comm} 
                         srcSet={`${comm} 1x, ${comm2} 2x`}
                         className='object-cover'
                        />
                        </div>
                    </div>
                   
                    
                </div>
            </div>
            
            <div className='mx-auto  max-w-screen-xl my-3'>
                <div className='flex flex-row space-x-3'>
                   
                    <div className=' flex basis-2/4  flex-row'>
                        <div className='flex justify-start'>
                        <img src={test} 
                         srcSet={`${test} 1x, ${test2} 2x`}
                         className='object-cover'
                        />
                        </div>
                    </div>
                  
                   
                </div>
            </div> */}
        </div>
      
    </div>
    </>
      )
    });
    
    export default DesignCommissionContent
    