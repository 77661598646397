import React,{useState,useContext} from 'react'
import { Link,useNavigate } from 'react-router-dom';
import { ProductContext } from './context/Productcontext';

import alice_small from './assets/images/alicepage/alice_small.png';
import alice_small2x from './assets/images/alicepage/alice_small@2x.png';

import eart_test_link_small from './assets/images/earthingtestlink/earthing_testlink_small.png';
import eart_test_link_small2x from './assets/images/earthingtestlink/earthing_testlink_small@2x.png';

import equibusbar from './assets/images/equipotential/equipotential_busbar_small.png';
import equibusbar2x from './assets/images/equipotential/equipotential_busbar_small@2x.png';

import slprmast from './assets/images/slprmast/mast_small.png';
import slprmast2x from './assets/images/slprmast/mast_small@2x.png';
    
import slpracc from './assets/images/earthingtestlink/slpr_accessories_kit.png';
import slpracc2x from './assets/images/earthingtestlink/slpr_accessories_kit2x.png';
    



    const LightningAccessoriesSubMenu = () => {
        let navigate = useNavigate();
        //const [selectedItem, setSelectedItem] = useState(null);
        const { selectedLightacc, setLightacc,setCategory, selectedProduct  } = useContext(ProductContext);

        const handleItemClick = (item) => {
          //  setSelectedItem(item);
          setCategory('slpr');
            setLightacc(item);
            //console.log("selected product is="+ selectedProduct);
            //console.log("selected lacc product is="+selectedLightacc);
            //onImageClick(item); // Call the function passed as prop with the clicked item
          };
      return (
       <div className=' w-full flex '>
            <div className=' container mx-auto flex justify-center items-center'>
                <div className={`w-1/5 h-18 flex flex-col justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer `} onClick={() => handleItemClick('alice_digital')}>
                    <div  className='m-2'><img src={alice_small} alt="thor ico"/></div>
                    <div  className=''><p className={` text-base  font-semibold ${selectedLightacc === 'alice_digital' ?  ' text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }}><span className='vollkorn-sc-semibold'>A.L.I.C.E Digital</span></p></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`} onClick={() => handleItemClick('earth_test_link')}>
                    <div  className='m-1'><img src={eart_test_link_small} alt="zeud ico"/></div>
                    <div  className=''><p className={` text-base  font-semibold ${selectedLightacc === 'earth_test_link' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }}><span className='vollkorn-sc-semibold'>Earthing Test Link</span></p></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`}  onClick={() => handleItemClick('equi_busbar')}>
                    <div  className='m-2'><img src={equibusbar} alt="ele ico"/></div>
                    <div  className=''><p className={` text-base  font-semibold ${selectedLightacc === 'equi_busbar' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }}><span className='vollkorn-sc-semibold'>Equipotential Busbar</span></p></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`}  onClick={() => handleItemClick('slpr_mast')}>
                    <div  className='m-4'><img src={slprmast} alt="bfc ico"/></div>
                    <div  className=''><p className={` text-base  font-semibold ${selectedLightacc === 'slpr_mast' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }}><span className='vollkorn-sc-semibold'>Ashlok’s SLPR Mast</span></p></div>
                    
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`} onClick={() => handleItemClick('slpr_acc')}>
                    <div  className='m-5'><img src={slpracc} alt="epcc ico"/></div>
                    <div  className=''><p className={` text-base  font-semibold ${selectedLightacc === 'slpr_acc' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }}><span className='vollkorn-sc-semibold'>SLPR Accessories Kit</span></p></div>
                </div>
                

            </div>
          
        </div>
      )
    }
    
    export default LightningAccessoriesSubMenu
    