    import React,{useState,useEffect} from 'react'
    
    const InstallationBanner = ({page,bannertext,ban1x,ban2x,banalt,apostropheBefore, apostropheAfter, ashlokBefore, ashlokAfter}) => {
        const [pageData, setPageData] = useState({});
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch('/data/data.json');
              const data = await response.json();
              setPageData(data);
                  //  console.log(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
        
        const makeSmallS = (text) => {
          return text.replace(/\((.*?)\)/g, (match, content) => {

            if (content.toLowerCase().includes("ashlok\'s")) {
              //console.log("matched at first if")
              //return `(<span className='vollkorn-sc-semibold'>${content}</span>)`;
              const highlightedContent = content.toLowerCase().replace(/ashlok's/gi, "<span class='vollkorn-sc-semibold'>$&</span>");
              return `(${highlightedContent})`;
            }
            // Check if the content contains an "s"
            else if (content.includes('s')) {
              // If it does, wrap it in a span with smaller font size
              return `(<span style="font-size: 70%">${content}</span>)`;
            } else {
              // Otherwise, return the content as is
              return `(${content})`;
            }
          });
        };

        const renderBannerText = () => {
          if (bannertext) {
            return <span>{bannertext}</span>;
          }
      
          if (ashlokBefore || ashlokAfter) {
            // Handle Ashlok content
            return (
              <span>
                {ashlokBefore && <span>{ashlokBefore}</span>}
                <span className='pl-2' style={{fontFamily: 'vollkorn-sc-regular' ,fontSize:'120%'}}>A</span><span style={{fontFamily: 'vollkorn-sc-regular'}} className=' pr-2 '>shlok{ashlokAfter ? "'s" : ''}</span>
                {ashlokAfter && <span>{ashlokAfter}</span>}
              </span>
            );
          }
      
          if (apostropheBefore || apostropheAfter) {
            // Handle apostrophe content
            return (
              <span>
                {apostropheBefore && <span>{apostropheBefore}</span>}
                <span style={{ fontSize: '70%' }}>s</span>
                {apostropheAfter && <span>{apostropheAfter}</span>}
              </span>
            );
          }
      
          // Default fallback if no text is provided
          return <span></span>;
        };


        // Accessing the 'home_page_title' for the selected language
        const ban1_alt = pageData[page]?.ban1_alt || '';
      
        return (
          <div className='relative w-full flex flex-col justify-center items-center '>
           
           
                    <img  src={ban1x} alt={banalt} 
                   srcSet={`${ban1x} 1x, ${ban2x} 2x`}
                    className='block w-full h-auto'
                    />
                     <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-3xl  text-white uppercase font-semibold' style={{ fontFamily: 'cinzel, sans-serif' }}>
                    {/*
                     <div dangerouslySetInnerHTML={{ __html: makeSmallS(bannertext) }} />
                      <div dangerouslySetInnerHTML={{ __html: bannertext }} />*/}
                      {renderBannerText()}
                </div>
               
          </div>
        )
    }
    
    export default InstallationBanner
    