import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);
/*
document.addEventListener('DOMContentLoaded', function() {
  const rootElement = document.getElementById('root');
  if (root) {
    root.style.position = 'absolute';
    root.style.top = '0';
    // Other styles or actions
  }
});*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
