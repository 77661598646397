import React, { useRef, useEffect, useState } from "react";
import "./faqstyles.css";
import bolticon from './assets/images/bolt.svg';

const renderBullets = (bullets) => {
  return (
    <ul className="list-disc ml-3  faq-answer">
      {bullets.map((bullet, index) => (
        <li key={index} className="flex items-start">
          {typeof bullet === 'string' ? (
             <>
            <img className="mr-2 pt-1.5" src={bolticon} />
             {parseBoldText(bullet)}
            </>
          ) : (
            renderBullets(bullet)
          )}
        </li>
      ))}
    </ul>
  );
};

const parseBoldText = (text) => {
  const parts = text.split(/(\*\*.*?\*\*)/g);

  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <span  style={{fontSize:'110%',whiteSpace: 'nowrap', 'marginRight':'4px'}} key={index}>{part.slice(2, -2)}</span>;
    }
    return part;
  });
};


const parseContent = (content) => {
  const parts = content.split(/(bh1#.*?#bh1|bin#.*?#bin)/g).filter(part => part.trim() !== '');

  return parts.map((part, index) => {
    if (part.startsWith('bh1#') && part.endsWith('#bh1')) {
      return (
        <li key={index} className="flex items-start ml-3">
          <img className="mr-2 pt-1.5" src={bolticon} />
          <span className="font-bold text-lg">{part.slice(4, -4)}</span>
        </li>
      );
    }
    if (part.startsWith('bin#') && part.endsWith('#bin')) {
      return (
        <div key={index} className="ml-8">
          <span className="font-base">{part.slice(4, -4)}</span>
        </div>
      );
    }
    return (
      <p key={index} className="">
        {part}
      </p>
    );
  });
};


// https://codesandbox.io/p/sandbox/react-js-accordion-faq-page-jimx2?file=%2Fsrc%2Fstyles.css%3A1%2C1-80%2C1
const FAQtoggle = ({ faq, index, toggleFAQ,bgColor,textColor, faqicons }) => {
 // console.log('Icons:', faqicons); // Check if icons array is correctly passed
  //console.log('Icons Length:', faqicons.length); // Check the length of icons array
  const answerRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");
  return (
    <div 
      className={`faq ${faq.open ? "open" : ""}`}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      {/*<div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>*/}
      {faq.title && <div className={`flex justify-start items-center faq-question  ${bgColor} ${textColor}`}>
        <img src={faqicons[index % faqicons.length]} alt={`icon ${index}`} style={{width:'1.9%'}} className="mr-1" />
        <p className="mt-2 text-xl ">{faq.title}</p></div>}
        
      {faq.heading1 && <div className='flex justify-center items-center faq-answer'><p className="text-xl ">{faq.heading1}</p></div>}
                {faq.content && <div className="text-justify faq-answer">{parseContent(faq.content)}</div>}
                {faq.bullet1 && (
                        <ul className="list-disc ml-6 ml-40 faq-answer">
                            {faq.bullet1.map((bullet, index) => (
                                <li  key={index} className=" flex items-start"><img className='mr-2 pt-1.5' src={bolticon}/>{  bullet}</li>
                            ))}
                        </ul>
                    )}
                {faq.content2 && <div className="text-justify faq-answer">{parseContent(faq.content2)}</div>}
                {faq.bullet2 && renderBullets(faq.bullet2)}
                {faq.content3 && <div className="text-justify faq-answer ">{parseContent(faq.content3)}</div>}
                {faq.content4 && <div className="text-justify faq-answer ">{parseContent(faq.content4)}</div>}
                {faq.content5 && <div className="text-justify faq-answer ">{parseContent(faq.content5)}</div>}
                {faq.content6 && <div className="text-justify faq-answer ">{parseContent(faq.content6)}</div>}
                {faq.content7 && <div className="text-justify faq-answer ">{parseContent(faq.content7)}</div>}
       
                     
    </div>
  );
};

export default FAQtoggle;
