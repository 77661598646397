import React,{useState} from 'react'
import lplogo from './assets/images/airterminal/air_terminal_big.png';
import lplogo2x from './assets/images/airterminal/air_terminal_big@2x.png';

// import lprotection from './assets/images/thordetails/level_of_protection_image.svg';

// import boltico from './assets/images/thordetails/bolt_icon.svg';
// import LightningProductsSubMenu from './LightningProductsSubMenu';


    const Airterminaldetailscontent = ({ onImageClick }) => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
           
            <div className='w-full flex justify-center items-center my-3'>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={lplogo}  
                                                srcSet={`${lplogo} 1x,${lplogo2x} 2x`} alt=" lpr logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start '>
                        <p className=' text-2xl text-ashred font-bold stitle'><span className='vollkorn-sc-semibold'>Air Terminal</span></p>
                        <p className=' text-base text-justify p-common'>Also known as Single Spike, this component constitutes the uppermost segment of a lightning protection system, aiming to intercept the advancing lightning leader. Crafted from a dense, remarkably conductive substance, the air terminal is available in diverse base materials and surface coatings.</p>
                       
                    </div>
                    
                    
                </div>
            </div>
            {/*
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <p className=' text-2xl'></p>
                    <div className='flex  '>
                        
                        <div className='w-1/2'>
                            <ul className="list-none ">
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 pt-1.5"/>The Air terminal is made of highly solid conductive materials like Galvanized Iron, Copper and Stainless Steel to withstand the strikes.</li>
                                             </ul>
                        </div>
                        <div className='w-1/2 ml-5'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pr-2 pt-1"/>Corrosion Resistant</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pr-2 pt-1.5"/>Polished.</li>
                                 </ul>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className='w-full flex justify-center items-center '>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center mb-3 mx-5'>
                <p className='text-xl'>Coverage of Conventional Type</p>
                    
                        <div className='w-full  mr-5'>
                                    <table className=" w-full divide-y divide-gray-200 mt-2 p-common">
                                        <thead className='w-full'>
                                            <tr className="bg-ashred">
                                            <th className=" px-4 py-2 text-white" rowSpan={2}>Protection Level</th>
                                            <th className=" px-4 py-2 text-white" rowSpan={2}><div>Protection Efficiency</div></th>
                                            <th className=" px-4 py-2 text-white" colSpan={4}><div>Protection Angle Method (Height-Metres)</div></th>
                                            </tr>
                                            <tr className="bg-ashred">

                                            <th className=" px-4 py-2 text-white">20</th>
                                            <th className=" px-4 py-2 text-white">30</th>
                                            <th className="  px-4 py-2 text-white">45</th>
                                            <th className="  px-4 py-2 text-white">60</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">I</td>
                                            <td className=" px-4 py-2 text-center">0.98</td>
                                            <td className=" px-4 py-2 text-center">20˚</td>
                                            <td className=" px-4 py-2 text-center">N/A </td>
                                            <td className=" px-4 py-2 text-center">N/A </td>
                                            <td className=" px-4 py-2 text-center">N/A</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">II</td>
                                            <td className=" px-4 py-2 text-center">0.95</td>
                                            <td className=" px-4 py-2 text-center">35˚</td>
                                            <td className=" px-4 py-2 text-center">25˚</td>
                                            <td className=" px-4 py-2 text-center">N/A </td>
                                            <td className=" px-4 py-2 text-center">N/A</td>
                                            </tr>
    
                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">III</td>
                                            <td className=" px-4 py-2 text-center">0.90</td>
                                            <td className=" px-4 py-2 text-center">45˚ </td>
                                            <td className=" px-4 py-2 text-center">35˚</td>
                                            <td className=" px-4 py-2 text-center">25˚</td>
                                            <td className=" px-4 py-2 text-center">N/A</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">IV</td>
                                            <td className=" px-4 py-2 text-center">0.80</td>
                                            <td className=" px-4 py-2 text-center">55˚</td>
                                            <td className=" px-4 py-2 text-center">45˚</td>
                                            <td className=" px-4 py-2 text-center">35˚</td>
                                            <td className=" px-4 py-2 text-center">25˚</td>
                                            </tr>
    
                                            
                                        
                                        </tbody>
                                    </table>
                        </div>
                        <div>
                            <p className='text-xl mt-3'>Offered in :</p>
                            <p className='text-base'>Spike Material : Copper or Stainless Steel (SS) </p>
                            <p className='text-base'>Base Plate : Copper or Stainless Steel (SS) </p>
                        </div>
                    
                                {/*
                                <p className='text-xl mt-3 '><span className='vollkorn-sc-semibold'>Air Teminal</span> Variants</p>


                                    <table className=" w-full divide-y divide-gray-200   p-common">
                                        <thead className='w-full'>
                                            <tr className="bg-ashred">
                                            <th className=" px-4 py-2 text-white">Type</th>
                                            <th className=" px-4 py-2 text-white"><div>AT GI 300 | 1000 S</div></th>
                                            <th className=" px-4 py-2 text-white">AT SS 300 | 1000 S</th>
                                            <th className="  px-4 py-2 text-white">AT GI 300 | 1000 L</th>
                                            <th className="  px-4 py-2 text-white">AT CU 300 | 1000 L</th>
                                            <th className="  px-4 py-2 text-white">AT AL 1000 | 2000 L</th>
                                            
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">Applicable Standard</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Material Of Construction</td>
                                            <td className=" px-4 py-2 text-center">GI</td>
                                            <td className=" px-4 py-2 text-center">SS</td>
                                            <td className=" px-4 py-2 text-center">GI</td>
                                            <td className=" px-4 py-2 text-center">CU</td>
                                            <td className=" px-4 py-2 text-center">AL</td>
                                            </tr>
                                           
                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Total Length</td>
                                            <td className=" px-4 py-2 text-center">3/10</td>
                                            <td className=" px-4 py-2 text-center">3/10</td>
                                            <td className=" px-4 py-2 text-center">3/10</td>
                                            <td className=" px-4 py-2 text-center">3/10</td>
                                            <td className=" px-4 py-2 text-center">10/20</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Diameter of Main Rod</td>
                                            <td className=" px-4 py-2 text-center">15/16 mm</td>
                                            <td className=" px-4 py-2 text-center">15/16 mm</td>
                                            <td className=" px-4 py-2 text-center">15/16 mm</td>
                                            <td className=" px-4 py-2 text-center">15/16 mm</td>
                                            <td className=" px-4 py-2 text-center">15/16 mm</td>
                                            </tr>
    
                                           
                                            
                                        
                                        </tbody>
                                    </table>

                                    */}
                       
                       
                    
                </div>
                
            </div>
            </>
          )
    }
    
    export default Airterminaldetailscontent
    