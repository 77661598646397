import React from 'react';
import HomePage from "./HomePage";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import EarthingPage from "./EarthingPage";
import WhowearePage from "./WhowearePage";
import HeritagePage from "./HeritagePage";

import AshlokwayPage from "./AshlokwayPage";

import CareerPage from "./CareerPage";
import DesignCommissionPage from "./DesignCommissionPage";
import FaqPage from "./FaqPage";
import InstallationContent from "./InstallationContent";
import InstallationBlock from "./InstallationBlock";
import InstallationPage from "./InstallationPage";
import SoiltestPage from "./SoiltestPage";
import ContactPage from "./ContactPage";
import ProducthomePage from "./ProducthomePage";


import LightningCategoryContent from "./LightningCategoryContent";
import ClpdetailsContent from "./ClpdetailsContent";
import SafeearthingelectrodeContent from "./SafeearthingelectrodeContent";
import Bfcdetailscontent from "./Bfcdetailscontent";
import Epccdetailscontent from "./Epccdetailscontent";
import Thordetailscontent from "./Thordetailscontent";
import LightningCategoryPage from "./LightningCategoryPage";
import FounderPage from "./FounderPage";
import Zeusdetailscontent from "./Zeusdetailscontent";
import Airterminaldetailscontent from "./Airterminaldetailscontent";
import Franklynpentacontent from "./Franklynpentacontent";
import Exotermicweldingkitdetails from "./Exotermicweldingkitdetails";
import Lightningaccessoriesdetails from "./Lightningaccessoriesdetails";
import Earthingtestlinkdetails from "./Earthingtestlinkdetails";
import Equipotentialbusbardetails from "./Equipotentialbusbardetails";
import SlprMastdetails from "./SlprMastdetails";
import SlprAccessories from "./SlprAccessories";
import Alicedigitaldetails from "./Alicedigitaldetails";
import Spddetailscontent from "./Spddetailscontent";
import EarthingCategoryPage from "./EarthingCategoryPage";
import AseelHdearthing from "./AseelHdearthing";
import Aseel_z_earthing from "./Aseel_z_earthing";
import Aseel_pvd_earthing from "./Aseel_pvd_earthing";
import Aseel_alloy_earthing from "./Aseel_alloy_earthing";
import Aseel_cp_earthing from "./Aseel_cp_earthing";
import Aseel_cc_earthing from "./Aseel_cc_earthing";
import Aseel_cfc_earthing from "./Aseel_cfc_earthing";
import Earthing_accessories_earthing from "./Earthing_accessories_earthing";
import Aseel_cbr_earthing from "./Aseel_cbr_earthing";
import Lightningprotectionsystems from "./Lightningprotectionsystems";

import { ProductProvider } from "./context/Productcontext";
import EarthingElementsPage from "./EarthingElementsPage";
import { FontLoaderProvider } from "./context/FontLoaderContext";
import Dealers from "./Dealers";
import Ourservices from "./Ourservices";
import CLPPagecontent from "./CLPPagecontent";
import CLPPage from "./CLPPage";
import WhyPage from "./WhyPage";
import HowPage from "./HowPage";
import Timelinepage from "./Timelinepage";
import LandingPage from "./LandingPage";
import GalleryPage from "./GalleryPage";
import CareerMap from "./CareerMap";
import { BannerProvider } from './BannerContext';

function App() {
  return (
    <FontLoaderProvider>
      <ProductProvider>
        <BannerProvider>
        {/* basename="/ashlokweb2" */}
      <BrowserRouter>
         <Routes>̉
          <Route exact path="/" element={<LandingPage/>} />
          <Route exact path="/home" element={<HomePage/>} />
          <Route path="/earthing" element={<EarthingPage/>} />
          <Route path="/whoweare" element={<WhowearePage/>} />
          <Route path="/ourheritage" element={<HeritagePage/>} />
          <Route path="/ashlokway" element={<AshlokwayPage/>} />
          <Route path="/founder" element={<FounderPage/>} />
          <Route path="/career" element={<CareerPage/>} />
          <Route path="/design" element={<DesignCommissionPage/>} />
          <Route path="/faq" element={<FaqPage/>} />
          <Route path="/installation" element={<InstallationPage/>} />
          <Route path="/soiltest" element={<SoiltestPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/product" element={<ProducthomePage/>} />
          <Route path="/lpcat" element={<LightningCategoryPage/>} />
          <Route path="/epcat" element={<EarthingCategoryPage/>} />


          <Route path="/lps" element={<Lightningprotectionsystems/>}/>
          <Route path="/clp" element={<ClpdetailsContent/>}/>
          <Route path="/bfc" element={<Bfcdetailscontent/>}/>
          <Route path="/epcc" element={<Epccdetailscontent/>}/>

          <Route path="/thordetails" element={<Thordetailscontent/>}/>
          <Route path="/zeusdetails" element={<Zeusdetailscontent/>}/>
          <Route path="/airterminaldetails" element={<Airterminaldetailscontent/>}/>
          <Route path="/franklynpentadetails" element={<Franklynpentacontent/>}/>
          <Route path="/exotermicdetails" element={<Exotermicweldingkitdetails/>}/>
          <Route path="/spddetails" element={<Spddetailscontent/>}/>
          <Route path="/lightaccdetails" element={<Lightningaccessoriesdetails/>}/>
          <Route path="/alicedigitaldetails" element={<Alicedigitaldetails/>}/>
          <Route path="/earthlinkdetails" element={<Earthingtestlinkdetails/>}/>
          <Route path="/equipotentialdetails" element={<Equipotentialbusbardetails/>}/>
          <Route path="/slprmastdetails" element={<SlprMastdetails/>}/>
          <Route path="/slpraccessdetails" element={<SlprAccessories/>}/>

          <Route path="eedetails" element={<EarthingElementsPage/>}/>
          <Route path="/hdearthdetails" element={<AseelHdearthing/>}/>
          <Route path="/zincearthdetails" element={<Aseel_z_earthing/>}/>
          <Route path="/pvdearthdetails" element={<Aseel_pvd_earthing/>}/>
          <Route path="/alloyearthdetails" element={<Aseel_alloy_earthing/>}/>
          <Route path="/cbrearthdetails" element={<Aseel_cbr_earthing/>}/>
          <Route path="/copperplateearthdetails" element={<Aseel_cp_earthing/>}/>
          <Route path="/carboncoateearthdetails" element={<Aseel_cc_earthing/>}/>
          <Route path="/carbonformearthdetails" element={<Aseel_cfc_earthing/>}/>
          <Route path="/eartaccdetails" element={<Earthing_accessories_earthing/>}/>
          <Route path="/dealers" element={<Dealers/>}/>
          <Route path="/ourservices" element={<Ourservices/>}/> 
          <Route path="/clppage" element={<CLPPage/>}/> 
          <Route path="/why" element={<WhyPage/>}/>
          <Route path="/how" element={<HowPage/>}/> 
          <Route path="/timeline" element={<Timelinepage/>}/>
          <Route path="/gallery" element={<GalleryPage/>}/>
          <Route path="/cmap" element={<CareerMap/>}/>


      
        </Routes>
      </BrowserRouter> 
      </BannerProvider>
      </ProductProvider>
      </FontLoaderProvider>
  );
}
export default App;
/*

https://www.fullstacklabs.co/blog/improving-seo-in-react-apps-with-react-helmet-async
 <BrowserRouter basename="/ashlokweb"> 
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/earthing" element={<EarthingPage/>} />
        </Routes>
      </BrowserRouter>   

<div>
    <MetaTags page="home"/>
    <PreHeader/>
    <Header/>
    <Menu/>
    <HomeBanner/>
    <HomeUltimateSolution/>
    <Ourproducts/>
    <Deliversafety/>
    <SafetyReliable/>
    <Whychooseus/>
    <Homecontactus/>
    <Footer/>
 react-app-rewired
    </div>

    
    */