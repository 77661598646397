import React,{useState} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';

import zinc from './assets/images/earthing/zinc_z/zinc_big.png';
import zinc2x from './assets/images/earthing/zinc_z/zinc_big@2x.png';
    
    const Aseel_z_earthing = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
            <EarthingelectrodeSubMenu onImageClick={handleImageClick}/>
            <div className='w-full flex justify-center items-center my-3'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center'>
                        <img src={zinc}  
                                                srcSet={`${zinc} 1x,${zinc2x} 2x`} alt=" ASEEl Z logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred tracking-wider font-semibold' ><span className='vollkorn-sc-semibold'>ASEEL Z <span className=' text-4xl px-1'>|</span> Zinc Coated Electrode(<span style={{fontSize:'80%'}}>s</span>) </span></p>
                        <p className=' text-base text-justify p-common'>With curated <span className='vollkorn-sc-semibold'>Ashlok’s</span> Design Process and the solitary compound formula developed by <span className='vollkorn-sc-semibold'>Ashlok</span>, the zinc coated electrodes are designed by pipe-in-pipe technology filled with Crystalline Conductive Compound(CCC) CompactFill for current dissipation and anti corrosive properties.</p>
                       
                    </div>
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                
                    <div className='flex   space-x-10'>
                    

                        <div className='w-1/2'>
                        <p className=' text-1xl font-extrabold text-ashred font-semibold'>Features & Benefits</p>
                            <ul className="list-none">
                            <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Zinc coated earthing electrode</li>
                            <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Coating thickness exceeding 100 microns </li>
                            <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1 "/>Controlled coating quality</li>
                            <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1 "/>Customisation is available as per customers requirement</li>
                                             </ul>
                        </div>
                       
                        <div className='w-1/2'>
                            <ul className="list-none ml-2">
                            <li className="flex items-center p-common text-ashgray1 text-sm">Improved resistance against corrosion</li>
                            <li className="flex items-center p-common py-1"><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Improved resistance against corrosion</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-1 pr-1 my-1.5"/>Granular surface texture for comprehensive BFC distribution and enhanced current dispersion</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Cathodic corrosion protection</li>
                                 </ul>
                        </div>
                        <div className='w-1/4'></div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <p className='text-xl'>Zinc Coated Earthing Electrode Variant</p>
                    <div className='flex'>
                        <div className='container w-full '>
                                    <table className=" w-full divide-y divide-gray-200 p-common">
                                        <thead className='w-full mb-2'>
                                            
                                            <tr className="bg-ashred">

                                            <th className=" px-4 py-2 text-white">Model</th>
                                            <th className="  px-4 py-2 text-white">Length (Mtr)</th>
                                            <th className="  px-4 py-2 text-white">Inner Pipe Dia  (mm)</th>
                                            <th className="  px-4 py-2 text-white">Outer Pipe Dia  (mm)</th>
                                           
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 192 Z</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48 </td>
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 193 Z</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48 </td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 392 Z</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">76</td>
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 393 Z</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">76</td>
                                            </tr>
    
                                           
                                        
                                        </tbody>
                                    </table>
                        </div>
                        
                    </div>
                
                
                       
                       
                    
                </div>
                
            </div>
            </>
          )
    }
    
    export default Aseel_z_earthing
    