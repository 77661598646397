import React,{useState} from 'react'

import euuipot from './assets/images/equipotential/equipotential_busbar_big.png';
import euuipot2x from './assets/images/equipotential/equipotential_busbar_big@2x.png';
import boltico from './assets/images/thordetails/bolt_icon.svg';
import LightningProductsSubMenu from './LightningProductsSubMenu';
import "./parafont.css"

    const Equipotentialbusbardetails = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
            
            <div className='w-full my-3 flex justify-center items-center'>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={euuipot}  
                                                srcSet={`${euuipot} 1x,${euuipot2x} 2x`} alt=" earthtestlink logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                    <p className=' text-2xl text-ashred font-bold '><span className='vollkorn-sc-semibold'>Equipotential Busbar</span></p>
                     <p className=' p-common text-base '>We at ASHLOK recognize the crucial role of proper bonding in establishing an equipotential plane between service grounds and equipment, particularly in instances of fault conditions. This equipotential plane effectively minimizes voltage differences, safeguarding both individuals and equipment during such occurrences. Presently, the equipotential busbar stands as the most user-friendly bonding solution available.</p>
            
                    </div>
                    {/*
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred fon font-bold stitle'><span className='vollkorn-sc-semibold'>Equipotential Busbar</span></p>
                        <p className=' p-common text-base text-justify'>At Ashlok, we understand that a robust lightning protection system is built on more than just lightning rods and grounding electrodes. That’s why we offer a comprehensive range of Lightning Protection Accessories designed to enhance the efficiency and effectiveness of your lightning protection system. Our accessories are meticulously crafted to ensure the utmost safety and security for your property, assets, and people.</p>
                        <p className=' text-xl font-bold mt-2'>Explore our Lightning Protection Accessories</p>
                        <p className=' text-base text-ashred  font-bold'>Ashlok’s SLPR Mast | A.L.I.C.E Digital | Earthing Test Link | Equipotential Busbar | SLPR Accessories Kit</p>
                        <p className=' p-common text-base text-justify'>Trust Ashlok for all your lightning protection accessory needs. With our commitment to quality and safety, you can count on our accessories to bolster the performance of your lightning protection system. Your safety is our priority, and Ashlok Lightning Protection Accessories are here to secure your world.</p>
                    </div>
                    */}
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                 <p className=' text-1xl font-bold text-ashred'>Attributes</p>
                    <div className='flex '>
                        
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Offers a single-point grounding and bonding site, facilitating convenient installation.</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Conductors can be connected through exothermic welding or bolted using lugs. </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Constructed using GI or Copper materials.</li>

                                             </ul>
                        </div>
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Busbar Dimensions: 25 x 3 | 50 x 6 | 40 x 6</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Connecting Links:  5 | 7. </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Tailored bars can be designed to suit specific customer requirements. </li>
                           
                                 </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            </>
          )
    }
    
    export default Equipotentialbusbardetails
    