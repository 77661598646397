import React from 'react'
import GalleryPageContent from './GalleryPageContent'

const  GalleryPage = () => {
  return (
    <div>
       { <GalleryPageContent/>}
    </div>
  )
}
export default GalleryPage
