import React from 'react'
// import epcclogo from './assets/images/epcc/eppcc_logo.png';
// import epcclogo2x from './assets/images/epcc/eppcc_logo@2x.png';

// import epccinstall from './assets/images/epcc/epcc_install_image.png';
// import epccinstall2x from './assets/images/epcc/epcc_install_image@2x.png';
// import exstr from './assets/images/epcc/excellent_strength.svg';
import epccimg from './assets/images/epcc/epcc_image_big.png';
import remarkico from './assets/images/epcc/ready_to_use.svg';
import lgt from './assets/images/epcc/lightweight.svg';
import zerosc from './assets/images/epcc/zero_scrap_value.svg';
import pplay from './assets/images/epcc/plug_and_play.svg';
import env from './assets/images/epcc/environmentally.svg';
import exp from './assets/images/epcc/exceptional.svg';
import ruse from './assets/images/epcc/ready_to_use.svg';
import hfree from './assets/images/epcc/hassle_free.svg';
import extlife from './assets/images/epcc/extended_lifespan.svg';
import nomain from './assets/images/epcc/no_maintenance.svg';
import uv from './assets/images/epcc/uv_stabilized.svg';

const Epccdetailscontent = () => {
    const eppc = "https://raw.githubusercontent.com/gobisn/Images/main/epcc_install_image.png";
    const eppc2x = "https://raw.githubusercontent.com/gobisn/Images/main/epcc_install_image%402x.png";
    return (
        <>
            <div className=' w-full flex-col mx-auto flex mt-3 justify-center items-center'>
                <div className='container max-w-screen-xl mx-auto flex flex-col justify-center items-center '>
                    <div className='flex items-center justify-center flex-col mt-5'>
                        {/*
                    <img className='m-2' src={epcclogo}
                        srcSet={` ${epcclogo} 1x, ${epcclogo2x} 2x`}
      alt="epclogo"/> */}
                        <img src={epccimg}

                            alt="epcimg" />

                    </div>

                    <div className='mt-5'>
                        <p className=' text-2xl  text-ashred'><span className='vollkorn-sc-semibold'> EarthPit Chamber Cover(<span style={{ fontSize: '80%' }}>s</span>)</span></p>
                        <p className=' text-base text-justify'> An initiative under the aegis of <span className='vollkorn-sc-semibold'>Ashlok</span>, the assortment of Earth Pit Chamber Covers is meticulously crafted with the user’s convenience in mind. Simultaneously, great consideration is given to the contemporary impact on the environment, either directly or indirectly, by adopting raw materials that not only enhance the value of the EPCC series but also ensure ecological well-being, harmoniously coexisting. The fundamental constituents comprise a precise blend of High-Density PolyEthylene (HDPE) compounds, fortified with glass fibers, along with additives like antioxidants and proprietary elements. These elements synergistically yield a high-tensile, quality product endowed with numerous attributes. This renders the Earth Pit Chamber Cover an auxiliary guardian to the overall Lightning and Earthing Solution implemented in your premises, providing the ultimate protection.</p>
                        <p className=' text-base text-justify'> The fundamental constituents comprise a precise blend of High-Density PolyEthylene (HDPE) compounds, fortified with glass fibers, along with additives like antioxidants and proprietary elements. These elements synergistically yield a high-tensile, quality product endowed with numerous attributes. This renders the Earth Pit Chamber Cover an auxiliary guardian to the overall Lightning and Earthing Solution implemented in your premises, providing the ultimate protection.
                        </p>

                    </div>
                </div>


           


                <div className='w-full flex justify-center items-center mt-3 bg-ashgray1  '>
                    <div className='container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                        <div className='flex   space-x-10'>
                            <div className='w-1/2'>
                                <p className=' text-1xl font-extrabold text-ashred font-semibold'>Features & Benefits</p>
                                <ul className="list-none">
                                    <li className="flex items-center "><img src={remarkico} className="mr-2 pr-2 my-2" />Remarkable tensile strength</li>
                                    <li className="flex items-center"><img src={lgt} className="mr-1.5 pr-1.5 my-2" />Lightweight - Facilitates easy handling and transportation</li>
                                    <li className="flex items-center"><img src={extlife} className="mr-2 pr-2 my-2" />Excellent strength-to-weight ratio</li>
                                    <li className="flex items-center"><img src={zerosc} className="mr-2 pr-2 my-2" />Zero Scrap Value - Acts as a theft deterrent</li>
                                    <li className="flex items-center"><img src={pplay} className="mr-2 pr-2 my-2" />Plug and Play configuration - Install and forget</li>
                                    <li className="flex items-center"><img src={env} className="mr-2 pr-2 my-2" />Environmentally friendly</li>
                                </ul>
                            </div>
                            <div className='w-1/2'>

                                <ul className="list-none">
                                    <li className="flex items-center"></li>
                                    <li className="flex items-center text-ashgray1"><p src={exp} className="mr-2 pr-2 m-3  text-ashgray1" />Exceptional compressive strength</li>
                                    <li className="flex items-center"><img src={exp} className="mr-2 pr-2 m-3" />Exceptional compressive strength</li>
                                    <li className="flex items-center"><img src={ruse} className="mr-2 pr-2 mx-3" />Ready to use - No curing time required</li>
                                    <li className="flex items-center"><img src={hfree} className="mr-2 pr-2 mx-3 my-3" />Hassle-Free Installation</li>
                                    <li className="flex items-center"><img src={extlife} className="mr-2 pr-2 mx-3 my-2" />Extended lifespan</li>
                                    <li className="flex items-center"><img src={nomain} className="mr-2 pr-2 mx-3 my-2" />No maintenance required - Install and forget</li>
                                    <li className="flex items-center"><img src={uv} className="mr-2 pr-2 mx-3 my-3" />UV Stabilized</li>
                                </ul>
                            </div>
                            <div className='w-1/4'></div>

                        </div>
                    </div>
                </div>
                {/*
            <div className='container w-max-xl mx-auto flex flex-col justify-center items-center my-3'>
                <div className='flex justify-center items-center'>
                    <img src={eppc}
                    srcSet={`${eppc} 1x, ${eppc2x} 2x `}
                    alt="epcc install"/>
                </div>
                </div>
            */}

            </div>
        </>
    )
}

export default Epccdetailscontent
