import React from 'react'
import clp from './assets/images/our_services/CLP.jpg';
import clp2x from './assets/images/our_services/CLP2x.jpg';
const  CLPInstall =()=> {
  return (
    <div>
      <img src={clp} alt="thor img"
                        srcSet={`${clp} 1x, ${clp2x}2x`}/>
    </div>
  )
}
export default  CLPInstall;
