import React,{useState} from 'react'
import earthtestlink from './assets/images/earthingtestlink/earthing_testlink.png';
import earthtestlink2x from './assets/images/earthingtestlink/earthing_testlink@2x.png';

import boltico from './assets/images/thordetails/bolt_icon.svg';
import LightningProductsSubMenu from './LightningProductsSubMenu';
import "./parafont.css"
    const Earthingtestlinkdetails = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
           
            <div className='w-full my-3 flex justify-center items-center'>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={earthtestlink}  
                                                srcSet={`${earthtestlink} 1x,${earthtestlink2x} 2x`} alt=" earthtestlink logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred stitle mt-3'><span className='vollkorn-sc-semibold'>Earthing Test Link</span></p>
                        <p className=' text-1xl font-bold  '>The Earthing Test Link enables swift and effortless separation of distinct segments within the earthing system, streamlining inspection procedures.</p>
                        {/*<p className=' p-common text-base text-justify '>At Ashlok, we understand that a robust lightning protection system is built on more than just lightning rods and grounding electrodes. That’s why we offer a comprehensive range of Lightning Protection Accessories designed to enhance the efficiency and effectiveness of your lightning protection system. Our accessories are meticulously crafted to ensure the utmost safety and security for your property, assets, and people.</p>
                        <p className=' text-xl font-bold mt-2'>Explore our Lightning Protection Accessories</p>
                        <p className=' text-base text-ashred font-bold'>Ashlok’s SLPR Mast | A.L.I.C.E Digital | Earthing Test Link | Equipotential Busbar | SLPR Accessories Kit</p>
                        <p className=' p-common text-base text-justify'>Trust Ashlok for all your lightning protection accessory needs. With our commitment to quality and safety, you can count on our accessories to bolster the performance of your lightning protection system. Your safety is our priority, and Ashlok Lightning Protection Accessories are here to secure your world.</p>*/}
                    </div>
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                {/* <p className=' text-2xl font-bold '><span className='vollkorn-sc-semibold'>Earthing Test Link</span></p> */}
                
                <p className=' text-1xl font-bold text-ashred'>Attributes</p>
                    <div className='flex'>
                        
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Establishes a convenient, centralized earthing and bonding point.</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5"/> Comprises Copper busbar, galvanized steel brackets for corrosion resistance, halogen-free polyamide insulators, and zinc-plated steel hardware</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5"/> Constructed using GI or Copper materials</li>

                                             </ul>
                        </div>
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5 ml-5"/>Busbar Dimensions: 25 x 3 | 50 x 6 | 40 x 6</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5 ml-5"/>Connecting Links: 1 | 3 | 5 | 7. </li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5 ml-5"/>Customized bars can be tailored to customer specifications </li>
                           
                                 </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            </>
          )
    }
    
    export default Earthingtestlinkdetails
    