import React, { useState, useEffect,useContext } from 'react';
import { ProductContext } from './context/Productcontext';
import { Link,useNavigate } from 'react-router-dom';
import gicon from './assets/images/Group 7013.svg'
import fb from './assets/images/facebook.svg'
import wa from './assets/images/whatsapp.svg'
import tr from './assets/images/twitter-sign.svg'
import ln from './assets/images/linked_in.svg';
import ins from './assets/images/instagram.svg';
import grass from './assets/images/grass-1x.jpg';
import grass2x from './assets/images/grass-2x.jpg';

const Footer = ({ backgroundColor = 'white', linedisp }) => {
  let navigate = useNavigate();
  const [footerItems, setFooterItems] = useState([]);
  const {setCategory, setProduct} = useContext(ProductContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.PUBLIC_URL +'/data/footerItems.json');
        const data = await response.json();
        setFooterItems(data);
      } catch (error) {
        console.error('Error fetching footer items:', error);
      }
    };

    fetchData();
  }, []);

  const handleItemClick = (item) => {
    
    if(item==='slpr')
    {
      setCategory('slpr');

        navigate('/lpcat');
    }
    else if(item=='clp')
    {
      setCategory('clp');
          navigate('/lpcat');

    }
    else if(item=='ele')
    {
      setCategory('ele');
        navigate('/lpcat');
    }
    else if(item=='bfc')
    {
      setCategory('bfc');
        navigate('/lpcat');
    }
    else if(item=='epcc')
    {
      setCategory('epcc');
        navigate('/lpcat');
    }
    else if(item=='spd')
    {
      setCategory('slpr');
      setProduct('spd');
        navigate('/lps');
    }
    else if(item=='exo_kit')
    {
      setCategory('slpr');
      setProduct('exo_kit');
        navigate('/lps');
    }
    scrollToTop();

   };

  return (
    <div>
      <footer>
        {linedisp &&
          <div className='w-full'>
            <img className='mt-[-1px]' src={grass2x} alt="grass"/>
            {/* <img className='mt-[-1px]' src={grass2x} alt="grass"
              srcSet={`${grass2x} 1x, ${grass2x} 2x`}
              
            /> */}
          </div>
        }
        <div className='w-full bg-ashred text-white flex items-center h-1'>
        </div>
        <div className={`flex  bg-${backgroundColor}  text-ashblack pt-10`}>
          {/*
      <section className='container mx-auto max-w-screen-xl flex flex-row'>
          {footerItems.map((column, index) => (
            <div
              key={column.id}
              className={`flex flex-col ${index === 1 || index === 2 ? 'w-1/2' : 'w-1/4'}`}
            >
              <h2 className={`font-bold mb-2 flex ${index === 2 ? 'text-lg justify-start' : 'text-lg justify-start'}`}>{column.title}</h2>
               
               <ul className={index === 2 ? 'justify-start' : 'justify-start'}>
               
                {column.items.map((item) => ( 
                  <li className={`leading-9`} key={item.id}>
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                ))}
              </ul>

              
            </div>
          ))}
        </section>
                */}
          <section className='container mx-auto max-w-screen-xl flex flex-row'>
            {/*
            <div className={`flex flex-col w-1/4 `} >
              <h2 className={`mb-2 flex text-2xl justify-start text-ashred`}> ABOUT US</h2>
               
               <ul className={'justify-start'}>
               
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/ashlokway" onClick={scrollToTop}><span className="text-xl">A</span>SHLOK WAY</Link></span></div> </li>
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/earthing" onClick={scrollToTop}><span className="text-xl">E</span>ARTHING</Link></span></div> </li>
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/whoweare" onClick={scrollToTop}><span className="text-xl">W</span>HO <span className="text-xl">W</span>E <span className="text-xl">A</span>RE</Link></span></div> </li>
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/ourheritage" onClick={scrollToTop}><span className="text-xl">O</span>UR HERITAGE</Link></span></div> </li>
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/faq" onClick={scrollToTop}><span className="text-xl">F</span>AQ</Link></span></div> </li>
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/career" onClick={scrollToTop}><span className="text-xl">C</span>AREER</Link> </span></div></li>                 
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/founder" onClick={scrollToTop}><span className="text-xl">F</span>OUNDER</Link></span></div> </li>
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/dealers" onClick={scrollToTop}><span className="text-xl">D</span>EALER(<span style={{fontSize:'90%'}}>s</span>)</Link></span></div> </li>
                  <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/contact" onClick={scrollToTop}><span className="text-xl">C</span>ONTACT</Link></span></div> </li>
                 
                
              </ul>

              
              </div>*/}

            <div className={`flex flex-col  w-1/2 `} >
              <h2 className={` mb-2 flex text-2xl justify-start  text-ashblack font-extrabold`}>PRODUCT(<span style={{ fontSize: '80%' }}>s</span>)</h2>
              <div className="flex flex-wrap">
                <div className="w-1/2">
                  <ul className={'justify-start flex flex-col flex-wrap'}>

                    <li className={`leading-9 flex-grow`} ><span className='cursor-pointer' onClick={() => handleItemClick('slpr')}><span className="text-xl">S</span>AFE <span className="text-xl">L</span>IGHTNING <span className="text-xl">P</span>ROTECTION <span className="text-xl">R</span>OD(<span style={{ fontSize: '90%' }}>s</span>) </span></li>
                    <li className={`leading-9 flex-grow`} ><span className='cursor-pointer' onClick={() => handleItemClick('clp')}><span className="text-xl">C</span>ONVENTIONAL <span className="text-xl">L</span>IGHTNING <span className="text-xl">P</span>ROTECTION(<span style={{ fontSize: '90%' }}>s</span>)</span></li>
                    <li className={`leading-9 flex-grow`} ><span className='cursor-pointer' onClick={() => handleItemClick('ele')}><span className="text-xl">S</span>AFE <span className="text-xl">E</span>ARTHING <span className="text-xl">E</span>LECTRODE(<span style={{ fontSize: '90%' }}>s</span>)</span></li>


                  </ul>
                </div>

                <div className="w-1/2">
                  <ul className={'justify-start flex flex-col flex-wrap'}>
                    <li className={`leading-9 flex-grow`} ><span className='cursor-pointer' onClick={() => handleItemClick('bfc')}><span className="text-xl">B</span>ACKFILL <span className="text-xl">C</span>OMPOUND(<span style={{ fontSize: '90%' }}>s</span>)</span></li>
                    <li className={`leading-9 flex-grow`} ><span className='cursor-pointer' onClick={() => handleItemClick('epcc')}><span className="text-xl">E</span>ARTHPIT <span className="text-xl">C</span>HAMBER <span className="text-xl">C</span>OVER(<span style={{ fontSize: '90%' }}>s</span>)</span> </li>



                  </ul>
                </div>
              </div>

            </div>
            <div className={`flex flex-col w-1/4 `} >
              <h2 className={` mb-2 flex text-2xl justify-start  text-white font-extrabold`}>PRODUCT(<span style={{ fontSize: '80%' }}>s</span>)</h2>
              <div className="">
                <ul className={'justify-start flex flex-col flex-wrap'}>
                  <li className={`leading-9 flex-grow`} ><span className='cursor-pointer' onClick={() => handleItemClick('exo_kit')}><span className="text-xl">E</span>XOTHERMIC <span className="text-xl">W</span>ELDING <span className="text-xl">K</span>IT</span> </li>
                  <li className={`leading-9 flex-grow`} ><span className='cursor-pointer' onClick={() => handleItemClick('spd')}><span className="text-xl">S</span>URGE <span className="text-xl">P</span>ROTECTION <span className="text-xl">D</span>EVICE(<span style={{ fontSize: '90%' }}>s</span>)</span> </li>
                </ul>
              </div>
            </div>
            <div className={`flex flex-col w-1/4 `} >
              <h2 className={` mb-2 flex text-2xl justify-start  text-ashblack font-extrabold`}> SERVICE(<span style={{ fontSize: '80%' }}>s</span>)</h2>

              <ul className={'justify-start'}>

                <li className={`leading-9`} ><div><span className='cursor-pointer'><Link to="/ourservices#install" onClick={scrollToTop}><span className="text-xl">I</span>NSTALLATION</Link></span></div> </li>
                <li className={`leading-9 `} ><div><span className='cursor-pointer'><Link to="/ourservices#soil" onClick={scrollToTop}><span className="text-xl">S</span>OIL-<span className="text-xl">T</span>ESTING</Link></span> </div></li>
                <li className={`leading-9 `} ><div><span className='cursor-pointer'><Link to="/ourservices#design" onClick={scrollToTop}><span className="text-xl">D</span>ESIGN & <span className="text-xl">C</span>OMMISSIONING</Link></span></div> </li>



              </ul>


            </div>

            {/*
            <div className={`flex flex-col w-1/4`} >
              <h2 className={`mb-2 flex text-2xl justify-start text-ashred font-extrabold`}> BLOG</h2>
               
               <ul className={'justify-start'}>
               
                  <li className={`leading-9`} ><div ><Link to="/earthing" onClick={scrollToTop}><span className='cursor-pointer'><span className="text-xl">W</span>HAT <span className="text-xl">I</span>S <span className="text-xl">E</span>ARTHING ?</span></Link></div>  </li>
                  <li className={`leading-9`} ><div ><Link to="/lpcat" onClick={scrollToTop}><span className='cursor-pointer'><span className="text-xl">W</span>HAT <span className="text-xl">I</span>S <span className="text-xl">L</span>IGHTNING ?</span></Link> </div> </li>
                  <li className={`leading-9`} ><div ><Link to="/ourheritage" onClick={scrollToTop}><span className='cursor-pointer'><span className="text-xl">W</span>HY <span className="text-xl">A</span>SHLOK ?</span></Link></div>  </li>
                 
                 
                
              </ul>

              
            </div>*/}

          </section>
        </div>
        <div className={`w-full  bg-${backgroundColor}  text-ashred flex items-center pb-3`}>
          <section className='container mx-auto max-w-screen-xl flex flex-row justify-end'>
            <img src={fb} className='mx-2' alt="m3" />
            <img src={ln} className='mx-2' alt="m4" />
            <img src={ins} className='mx-2' alt="m4" />
            <img src={wa} className='mx-2' alt="m5" />
            {/*
          <img src={tr} className='m-3' alt="m6"/>
          <img src={gicon} className='m-3' alt=""/>
              */}
          </section>
        </div>
        <div className='w-full bg-ashred text-white flex items-center'>
          <section className='container mx-auto max-w-screen-xl '>
             <div className='flex flex-row'>
              <div className='flex basis-1/2 justify-start align-middle'>
                  <div className='text-white pt-2 pb-2'>© COPYRIGHT 2024 ASHLOK</div>
                </div>
                <div className='flex basis-1/2 justify-end flex-end pt-2 pb-2'>
                 
                <a href="https://salasarsolutions.com">DESIGNED BY   SALASAR TECHNOLOGIES</a>
                  </div>
            </div>
          </section>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
