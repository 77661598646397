import React from 'react'
import Timeline from './Timeline';

const Timelinepage = ()=> {
  return (
    <div>
      <Timeline/>
    </div>
  )
}

export default Timelinepage;