    import React,{useState,useContext,useEffect} from 'react'
    import { ProductContext } from './context/Productcontext';
    import thor_ico from './assets/images/lightningcategory/thor_icon.svg';

    import clp_ico from './assets/images/lightningcategory/clp_icon.svg';
    import ele_ico from './assets/images/lightningcategory/electrode_icon.svg';
    import bfc_ico from './assets/images/lightningcategory/bfc_icon.svg';
    import epcc_ico from './assets/images/lightningcategory/epcc_icon.svg';
    import {useNavigate } from 'react-router-dom';

    const ProductsubMenu = () => {
        let navigate = useNavigate();
        const { selectedCategory,setCategory } = useContext(ProductContext);
        const [selectedItem, setSelectedItem] = useState(selectedCategory);

        const handleItemClick = (item) => {
            setSelectedItem(item);
            //onImageClick(item); // Call the function passed as prop with the clicked item
            setCategory(item);
            console.log(" catgory setedd" +selectedCategory);
             navigate('/lpcat')
             console.log(" catgory setedd" + item)
            /* if(item==='slpr')
                navigate('/lps')
            else if(item==='clp')
                navigate('/clp')
            else if(item==='bfc')
                navigate('/bfc')
            else if(item==='epcc')
                navigate('/epcc')
           else if(item==='ele')
                navigate('/clp')*/
          };
        
          useEffect(() => {
            setSelectedItem(selectedCategory);
            }, [selectedCategory]);
        return (
        <div className=' w-full bg-ashblack flex '>
            <div className=' container mx-auto flex justify-center items-center'>
                <div className={`w-1/5 h-18 flex flex-row justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'slpr' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('slpr')}>
                    <div  className='h-20 flex items-center px-4'><img src={thor_ico} alt="thor ico"/></div>
                    <div  className=''><p className=' text-base text-white'>Safe Lightning Protection Rod(s)</p></div>
                </div>
                <div className={`w-1/5 h-18 px-1 flex flex-row justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'clp' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('clp')}>
                    <div  className='h-20 flex items-center px-4'><img src={clp_ico} alt="clp ico"/></div>
                    <div  className=''><p className=' text-base text-white'>Conventional Lightning Protection</p></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-row justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'ele' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('ele')}>
                    <div  className='h-20 flex items-center px-4'><img src={ele_ico} alt="ele ico"/></div>
                    <div  className=''><p className=' text-base text-white'>Safe Earthing Electrode(s)</p></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-row justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'bfc' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('bfc')}>
                    <div  className='h-20 flex items-center px-4'><img src={bfc_ico} alt="bfc ico"/></div>
                    <div  className=''><p className=' text-base text-white'>BackFil Compound(s)</p></div>
                    
                </div>
                <div className={`w-1/5 h-18 flex flex-row justify-center items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'epcc' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('epcc')}>
                    <div  className='h-20 flex items-center px-4'><img src={epcc_ico} className=' ' alt="epcc ico"/></div>
                    <div  className=''><p className=' text-base text-white'>EarthPit Chamber Cover(s)</p></div>
                </div>

            </div>
          
        </div>
      )
    }
    
    export default ProductsubMenu
   