import React,{useState,useRef,useEffect} from 'react'
import logo from './assets/images/ashlok logo.svg'
import since from './assets/images/since_1999_icon.svg'
import sos from './assets/images/sos.svg';
import { debounce } from 'lodash';
import './stickystyle.css';
// import since from './assets/images/since 1999 new.svg
//import sos2x from './assets/images/sos@2x.png';
//import mobile from './assets/images/mobile icon.svg'
//import email from './assets/images/mail icon.svg'


const Header = () => {
  const [isSticky, setSticky] = useState(true);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('0px');

 
  /*const debouncedHandleScroll = debounce(() => {
    const offset = window.scrollY;
    setSticky(offset <= 0);
  }, 3000); // Adjust the debounce delay as needed*/

  const handleScroll = () => {
    const offset = window.scrollY;
    setSticky(offset <= 0);
   /* if (offset > 0) {
      setSticky(false);
      
    } else {
      setSticky(true);
    }*/
  };

  const debouncedHandleScroll = debounce(handleScroll, 3000);

  useEffect(() => {
    //window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
     // window.removeEventListener('scroll', handleScroll);
     // sticky hidden       opacity-100 top-0                
     window.removeEventListener('scroll',debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  useEffect(() => {
    if (contentRef.current) {
      //setContentHeight(isSticky ? `${contentRef.current.scrollHeight}px` : '0px');
      let calcheigt =contentRef.current.scrollHeight;
      if (calcheigt<150)
            calcheigt =150
      else
        calcheigt = contentRef.current.scrollHeight;

      setContentHeight(isSticky ? `${calcheigt}px` : '0px');
    }
  }, [isSticky]);

  return (
    <div ref={contentRef} 
    className={`w-full  flex flex-row  bg-white   text-white transition-all duration-300 ease-in-out  transition-height`}
    style={{ height: contentHeight }}
    >
      <section className=' container mx-auto max-w-screen-xl'>
        <section className=' flex justify-center align-middle pt-5 pb-5'>
      <div className='flex basis-1/4'>  
                <img  src={sos} alt="sosalt" />
      </div>
      <div className='flex basis-1/2 justify-center align-middle'>
            <div className=" w-[200px]  flex align-middle justify-center">
            <img  className='max-w-1/4' src={since} alt='since'/> 
            </div>
            
        </div>
        {/*
        <div className='flex basis-1/6'>
            <div className=" flex items-center justify-center">
            <img  className='max-w-1/4' src={mobile} alt='mobile'/>
            <span className=' pl-2'>+91 94440 87356</span>
            </div>
        </div>
        <div className='flex basis-1/6'>
            <div className="flex items-center justify-center">
            <img  className='max-w-1/4' src={email} alt='email'/>
            <span className=' pl-2'>earthing@ashlok.com</span>
            </div>
        </div>*/}
        <div className='flex basis-1/4 items-end justify-end'>
            <div className="flex items-end justify-end">
            <img  className='max-w-1/4' src={logo} alt='logo'/>
            
            </div>
        </div>

      </section>
      </section>
    </div>
  )
}

export default Header
