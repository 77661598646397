import React from 'react'
import HowpageContent from './HowpageContent';
import Footer from './Footer';
import banner1  from './assets/images/whypage/how_banner.jpg';
import banner1_2x  from './assets/images/whypage/how_banner@2x.jpg';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import EarthingBanner from './EarthingBanner';
import ScrollToTopButton from './ScrollToTopButton';
const HowPage = ()=> {
  return (
    <div>
  
         <PreHeader/>
    
    <Header/>
    <Menu/>

    <EarthingBanner page="Why" banalt="Why ban1" bannertext="" ban1x={banner1} ban2x={banner1_2x}/>
      <HowpageContent/>
      <ScrollToTopButton/>
      <Footer linedisp="true"/>
    </div>
  )
}

export default HowPage;