import React,{useState} from 'react'
import ExapndabelMenu from './ExapndabelMenu'
import contactus from './assets/images/faq/contact_us_button.png'
import contactus2 from './assets/images/faq/contact_us_button@2x.png'
import { Link } from 'react-router-dom'
import FAQtoggle from './FAQtoggle'

import faq1 from './assets/images/faq/icons/faq-icon1.svg';
import faq2 from './assets/images/faq/icons/faq-icon2.svg';
import faq3 from './assets/images/faq/icons/faq-icon3.svg';
import faq4 from './assets/images/faq/icons/faq-icon4.svg';
import faq5 from './assets/images/faq/icons/faq-icon5.svg';
import faq6 from './assets/images/faq/icons/faq-icon6.svg';
import faq7 from './assets/images/faq/icons/faq-icon7.svg';
import faq8 from './assets/images/faq/icons/faq-icon8.svg';
import faq9 from './assets/images/faq/icons/faq-icon9.svg';
import faq10 from './assets/images/faq/icons/faq-icon10.svg';
import faq11 from './assets/images/faq/icons/faq-icon11.svg';
import faq12 from './assets/images/faq/icons/faq-icon12.svg';


const icons = [
  faq1,
  faq2,
  faq3,
  faq4,
  faq5,
  faq6,
  faq7,
  faq8,
  faq9,
  faq10,
  faq11,
  faq12

];

    
    const FaqContentnewv2 = () => {
        const [openMenuIndex, setOpenMenuIndex] = useState(null);
        const [selectedSection, setSelectedSection] = useState('earthing');

        const [faqs, setFaqs] = useState([
            {
              title: "How does a lightning protection system work?",
              content:
                "A lightning protection system consists of a network of conductors installed on the roof and sides of a building, connected to earth electrode buried in the earth. When lightning strikes, the system provides a path for the electrical energy to safely dissipate into the ground, preventing damage to the structure.",
              open: true
            },
            {
              title: "What are the components of a lightning protection system?",
              content: "Key components of a lightning protection system include air terminals (lightning rods) that attract the lightning strike, down conductors to carry the electrical energy to the ground, and earth electrode to disperse the energy safely. Surge protection devices may also be installed to protect electrical and electronic equipment inside the building ",
              open: false
            },
            {
              title:
                "Why is a lightning protection system important?",
              content:"Lightning can cause significant damage to buildings, electrical systems, and electronic equipment. A properly installed lightning protection system helps dissipate the lightning strike's energy and prevent it from causing harm. ",
              open: false
            },
            {
                title:
                  "How does a lightning protection system work?",
                content: "The air terminals are the highest points on the structure and attract the lightning strike.The down conductors then provide a direct path for the lightning current to flow into the ground electrodes, bypassing the building's structure.",
               open: false
              },
              {
                title:
                  "What kind of building needs a lightning protection system?",
                content: "Any tall or isolated structure, such as a residential home, commercial building, factory, or telecommunication tower, should have a lightning protection system installed. ",
               
                open: false
              },
              {
                title:
                  "How is a lightning protection system installed?",
                content: "The installation process involves carefully planning the placement of air terminals, down conductors, and ground electrodes to provide comprehensive protection for the entire structure. ",
               
                open: false
              },
              {
                title:
                  "How often should a lightning protection system be inspected?",
                content: "Regular inspections, typically annual or bi-annual, are recommended to ensure the system is functioning correctly and to identify any damage or deterioration. ",
               
                open: false
              },
              {
                title:
                  "What are the risks of not having a lightning protection system?",
                content: "Without a lightning protection system, a building is at risk of direct lightning strikes, which can cause fires, structural damage, and the destruction of electrical and electronic equipment.",
                open: false
              },
              {
                title:
                  "Can a lightning protection system prevent all lightning damage?",
                content: "No, a lightning protection system is designed to reduce the risk of damage, but it cannot completely eliminate the possibility of lightning-related incidents. ",
                open: false
              },
              {
                title:
                  "How much does a lightning protection system cost?",
                content: "The cost of a lightning protection system can vary widely depending on the size and complexity of the building, but it is generally considered a worthwhile investment to safeguard against the potentially catastrophic effects of lightning strike. ",
                open: false
              },
              {
                title:
                  "What is the difference between Conventional and ESE Lightning Arresters?",
                content: "Conventional lightning rods and ESE have one major aspect in common: they attract lightning. ESE terminals arguably differ in effectiveness—an ESE terminal is equipped with a device that increases the probability that an initiated upward streamer will connect with a downward leader. Increasing this probability means that lightning is more likely to strike the terminal rather than unwanted areas. ",
                open: false
              }
          ]);
        
          const [eqfaqs, eqsetFaqs] = useState([
            {
              title: "What is an electrical earthing system?",
              content:
                "An electrical earthing system is a network of conductors buried in the ground to provide a safe path for the dissipation of fault currents from electrical systems.",
              open: true
            },
            {
                title: "Why is it important to have an earthing system in place?",
              content:
                "Earthing is primarily done to ensure safety by providing a low resistance path for fault current to flow In the event of a fault, the current flows through the earthing system instead of passing through a person or equipment, thereby minimising the risk of electric shock and damage to equipment ",
              content2:"bh1#Electrical Safety :#bh1 bin# The primary goal of earthing is to prevent electrical shocks and protect people and equipment from the harmful effects of electric currents. By establishing a low-resistance path to the ground, earthing ensures that fault currents are safely diverted away from conductive parts of equipment or structures, minimising the risk of electric shock. #bin",
              content3:"bh1#Equipment Protection :#bh1 bin# Earthing safeguards electrical equipment and appliances from damage due to electrical faults. When a fault occurs, excessive current flows through the grounding system and safely dissipates into the earth. Without proper earthing, this current could cause overheating, electrical fires, or irreversible damage to equipment. #bin",
              content4:"bh1#Lightning Protection :#bh1 bin# Lightning strikes can cause catastrophic damage to buildings and structures. A well-designed earthing system provides a path for lightning-induced currents to dissipate safely into the ground, reducing the risk of lightning-related fires and structural damage. #bin",
              content5:"bh1#Power Quality :#bh1 bin# Earthing helps maintain stable power quality by providing a reference point for voltage levels. It ensures that equipment operates within safe voltage limits and prevents voltage fluctuations that could disrupt sensitive electronics. #bin",
              open: false
            },
            {
                title: "What are the different types of earthing systems available?",
              content:
                "The main types of electrical earthing systems include the TN system, the TT system, and the IT system, each with its specific applications and requirements.",
              open: false
            },
            {
                title: "How does an earthing system protect against electric shock and equipment damage?",
              content:
                "Earthing is primarily done to ensure safety by providing a low resistance path for fault current to flow In the event of a fault, the current flows through the earthing system instead of passing through a person or equipment, thereby minimising the risk of electric shock and damage to equipment ",
               open: false
            },
            {
                title: "What are the key elements of an earthing system?",
              content:
                "The elements that are used in an earthing system, will vary slightly depending of the resistivity of the land.",
              content2:"bh1# Earthing Electrodes :#bh1 bin# An earth electrode may take form a rod, strip, tube or plate which is buried in the ground and which serves to conduct current from the earthing system to the general mass of the earth.#bin ",
              content3:"bh1# Arquetas (Earth Pit Chamber Cover) :#bh1 bin# Earth pits are usually situated towards the outside of buildings. Their function is to provide an accessible manhole for any tests and inspections.#bin",
              content4:"bin#The connection to the earth pit is made at the bottom of the excavation, through a device that allows the disconnection of the earth conductors and which must carry an earth symbol.#bin",
              content5:"bh1# Earth Conductor :#bh1 bin# The earthing conductor is commonly called the earthing lead. It joins the installation earthing terminal to the earth electrode or to the earth terminal provided by the Electricity Supply Company. It is a vital link in the protective system. These are generally made with copper, galvanised steel, aluminium, copper clad steel and copper clad aluminium in form of strip, tape, wire and cable.#bin",
              content6:"bh1# Earthing bonds :#bh1 bin# Bonding refers to the process by which a low impedance path for the flow of an electric current is established between two metallic objects. Bonding is concerned with those techniques and procedures necessary to achieve a mechanically strong, low impedance interconnection between metal objects and to prevent the path thus established from subsequent deterioration through corrosion or mechanical looseness. Some bonding techniques that are used in earthing system is given below-Exothermic Welding, Brazing, Soft Solder, Bolt & Nuts, Screws, Rivets, Conductive Adhesive and Compression techniques.#bin",
              content7:"bh1# Conductive Enhancer :#bh1 bin# Conductivity enhancers are used in high resistivity terrains, where obtaining a low resistivity ground value could be impossible, even with the installation of multiple electrodes. Conductivity enhancers achieve a moisture retention in the soil and provide ions that significantly reduce the resistance in a long lasting manner and without any corrosion #bin", 
              open: false
            },
            {
              title: "How is an earthing system tested and maintained?",
              content:"Regular maintenance, such as periodic testing for resistance and integrity, is essential to ensure the effectiveness of the electrical earthing system. Measured with an earth tester also called a \"merger\” which can test the resistance across range of current and distance It consists of a voltage source, ohm-meter to measure resistance and spikes that are staked into the ground for measuring. During dry periods, dry soil can increase resistance and impact the effectiveness of the earthing system. Water the area around the earth pit during dry period."
            },
            {
              title: "What is data is required to make an earthing project?",
              content:"In order to make a basic general earthing project, the following data is required:",
              content2:"For the estimate of the material required:",
              bullet2:["Dimensions of the site on which the grounding can be carried out.","Geo-electrical study with the resistivity of the ground/terrain by strata/layers.","Desired earthing resistance value."],
              content3:"For the number of earth pits required:",
              bullet3:["Maximum short-circuit current.","Maximum duration of the short-circuit current or how long it takes for the protections to operate.","Type of ground surface layer and thickness."]
            },
            {
              title: "Should the general earth connection be connected to the lightning rod earth connection?",
              bullet1:["An earth connection shall be made for each down conductor with at least two electrodes for each earth connection.","The earthing resistance of the lightning conductor must be less than 10 ohms, measured independently of any element of a conductive nature.","Avoid long earthing points (>20m) in order to ensure the lowest possible impedance.","They should always be oriented towards the outside of the building."],
            },

           
          ]);
  
          const [soilfaqs, soilsetFaqs] = useState([
            {
                title: "What is Soil Resistivity?",
                content:
                "Soil resistivity, in simple terms, is a measure of how much the soil in a specific location resists the flow of electrical current. It's similar to how different materials like metals or rubber have varying levels of resistance to electricity. In the context of electrical grounding and earthing systems, soil resistivity indicates how easily electrical current can be dissipated into the ground. ",
              open: true
              },
            {
              title: "What are the factors that influence Soil Resistivity?",
              content:
              "Several factors affect soil resistivity, making it a dynamic property that varies from place to place.",
              content2:"bh1#Moisture Content:#bh1 bin# Moisture in the soil significantly affects its resistivity. Wet or highly saturated soil has lower resistivity because water is a good conductor of electricity.Conversely, dry soil has higher resistivity as it offers more resistance to electrical flow.#bin",
              content3:"",
              content4:"bh1#Soil Type :#bh1 bin# Different soil types have different inherent resistivity values. For instance, clay-rich soils typically have higher resistivity, while sandy or rough soils have lower resistivity. The mineral composition and particle size distribution of the soil play a crucial role in determining its resistivity.#bin",
              content5:"bh1#Temperature :#bh1 bin# Soil temperature can impact resistivity to some extent. Colder soil tends to have slightly higher resistivity than warmer soil. However, this effect is usually not as significant as moisture and soil type.#bin",
              content6:"bh1#Salinity and Contaminants :#bh1 bin# The presence of salts or contaminants in the soil can alter its resistivity. Salty or contaminated soils may have lower resistivity due to the enhanced conductivity of the added substances.#bin",
              open: false
            },
            {
              title: "How does Soil Resistivity vary with depth?",
              content:"Soil resistivity isn't constant throughout the ground; it typically changes with depth. As you go deeper into the ground, resistivity often increases. This is because the moisture content tends to decrease, and soil becomes more compact, which restricts the flow of electrical current. ",
              open: false
            },
            {
              title: "Why is Soil Resistivity important in Earthing?",
              content:"Soil resistivity is of paramount importance in earthing systems because it directly influences the effectiveness and safety of electrical installations ",
              open: false
            },
            {
            title: "Why is Low Soil Resistivity Important?",
            content:"bh1#Effective Dissipation of Fault Currents :#bh1 bin# Low-resistivity soil allows fault currents to disperse quickly into the ground. This rapid dissipation ensures that fault currents do not linger in the electrical system, reducing the risk of damage or fire.#bin",
            content2:"bh1#Reduced Voltage Rise :#bh1 bin# During a fault, low-resistivity soil limits the rise in voltage along the grounding path. High resistivity can result in a significant voltage rise, potentially causing damage to nearby equipment and posing a danger to personnel.#bin ",
            open: false
           },
           {
           title: "What are the risks associated with High Soil Resistivity?",
           content:"bh1#Reduced Effectiveness :#bh1 bin# High soil resistivity limits the ability of the earthing system to conduct fault currents efficiently. This can lead to extended fault durations and increased risks to personnel and equipment#bin ",
           content2:"bh1#Increased Touch and Step Voltages :#bh1 bin#High-resistivity soil can result in elevated touch and step voltages during a fault. Touching an energised structure or the ground in such conditions can be dangerous.#bin ",
           content3:"bh1#Ineffective Lightning Protection :#bh1  bin#Lightning-induced currents may not dissipate effectively in high-resistivity soil, increasing the likelihood of lightning-related damage and fires.#bin ",
           open: false
            },
            {
            title: "What are the essential equipment and steps involved in the soil resistivity testing process?",
            content:"Soil resistivity testing is a crucial step in the design and maintenance of earthing systems. It involves measuring the electrical resistance of the soil at various depths to determine its resistivity. Accurate soil resistivity measurements are essential for designing effective grounding systems and ensuring their continued performance over time. ",
            content2:"Here's an overview of the soil resistivity testing process.",
            bullet2:["Equipment and Tools",["Soil Resistivity Meter: This specialised instrument, often called a soil resistivity tester or meter, is used to measure the electrical resistance of the soil. It typically includes a four-terminal measurement method to ensure accuracy. ","**Electrodes :** To make measurements, you'll need a set of current and potential electrodes. These are typically metal rods that are inserted into the ground at specific distances and configurations. "]],
            content3:"bh1#Test Locations and Grid Layout:#bh1 bin#Choose test locations that represent the areas where the earthing system will be installed or is already in place. The number of test locations and their arrangement should be selected to provide a comprehensive view of the site's soil resistivity.Create a grid or pattern of test points, usually in a triangular, square, or rectangular layout. The specific layout depends on the site's characteristics and the standards being followed.t#bin",
            content5:"bh1#Data Analysis#bh1 bin#After collecting data from all the test points, analyse the results to determine the soil resistivity at different depths and locations. #bin",
            open: false
          },
            {
            title: "What are Earth Enhancing Compounds?",
            content:"Back Fill Compound(s)® are earth enhancement compounds, each possessing distinct attributes that can be opted for based on the soil type and its inherent characteristics. In essence, an ideal Back Fill Compound(s)® (BFC) should exhibit qualities such as low resistance, exceptional moisture absorption and retention capabilities, and thermal stability. ",
            contnet2:"Although earthing can be established without using BFC, it is recommended to use it for improved performance of the earthing system.",
            content3:"BARON has developed a range of five distinct variations of Back Fill Compound(s)® intended to contribute to the three-dimensional augmentation of electrical earthing systems. This approach aims to achieve low group resistance values, which has consistently posed challenges in regions with inadequate soil conductivity. ",
            open: false
            }
            
        ])

        

          const toggleFAQ = index => {
            setFaqs(
              faqs.map((faq, i) => {
                if (i === index) {
                  faq.open = !faq.open;
                } else {
                  faq.open = false;
                }
        
                return faq;
              })
            );
          };

          const toggleFAQeq = index => {
            eqsetFaqs(
              eqfaqs.map((faq, i) => {
                if (i === index) {
                  faq.open = !faq.open;
                } else {
                  faq.open = false;
                }
        
                return faq;
              })
            );
          };
          const toggleFAQsoil = index => {
            soilsetFaqs(
              soilfaqs.map((faq, i) => {
                if (i === index) {
                  faq.open = !faq.open;
                } else {
                  faq.open = false;
                }
        
                return faq;
              })
            );
          };
        

      return (
        <div className=' w-full flex flex-col bg-white'>
          
              <div className='container mx-auto max-w-screen-xl space-x-4 my-3 '>
                    {/* Second colum */}
                    <div className=' flex  flex-col justify-start'> 
                        <div className=' flex items-center justify-center space-x-5 ' style={{zIndex:'1'}}>
                          {/*}  <p className='text-3xl text-ashred  '>Earthing System </p> */}
                            <button
                            onClick={() => setSelectedSection('earthing')}
                            className={`px-4 py-2  bg-ashred2 text-white rounded-full hover:bg-ashred      ${selectedSection === 'earthing' ? 'focus:outline-none focus:ring-2 focus:ring-red-500' : 'bg-gray-500'}`}>
                                 Earthing System
                            </button>
                            <button 
                            onClick={() => setSelectedSection('lightning')}
                            className={`px-4 py-2 bg-ashred2 text-white rounded-full hover:bg-ashred  ${selectedSection === 'lightning' ? 'focus:outline-none focus:ring-2 focus:ring-red-500' : 'bg-gray-500'}`}>
                            Lightning System
                            </button>
                            <button 
                            onClick={() => setSelectedSection('soil')}
                            className={`px-4 py-2 bg-ashred2 text-white rounded-full hover:bg-ashred ${selectedSection === 'soil' ? 'focus:outline-none focus:ring-2 focus:ring-red-500':'bg-gray-500'} `}>
                            Soil Test
                            </button>

                        </div>
                        <div className='flex flex-col justify-start faqs'>
                            {selectedSection === 'earthing' && (
                                <>
                                {eqfaqs.map((eqfaq, index) => (
                                        <FAQtoggle faq={eqfaq} index={index} key={index} toggleFAQ={toggleFAQeq}  bgColor="border border-b-1 border-t-0 border-l-0 border-r-0 border-red-500 my-2"   textColor="text-black" faqicons={icons}/>
                                ))}
                                </>
                            )}
                                     {/* "border border-b-1 border-t-0 border-l-0 border-r-0 border-red-500  */}
                                {selectedSection === 'lightning' && (
                                    <>
                                             {faqs.map((faq, index) => (
                                                <FAQtoggle faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}  bgColor="border border-b-1 border-t-0 border-l-0 border-r-0 border-red-500 my-2"   textColor="text-black" faqicons={icons}/>
                                    ))}
                                    </>          

                                )}
                                {selectedSection === 'soil' && (
                                    <>
                                      {soilfaqs.map((soilfaq, index) => (
                                            <FAQtoggle faq={soilfaq} index={index} key={index} toggleFAQ={toggleFAQsoil}   bgColor="border border-b-1 border-t-0 border-l-0 border-r-0 border-red-500 my-2"   textColor="text-black" faqicons={icons}/>
                                        ))}      
                                    </>          

                                )}


                        </div>
                        
                    </div>
                  
              </div>
          {/*
          <div className='w-full flex justify-center items-center  bg-ashgray1 '>
            <div className='container mx-auto max-w-screen-xl  my-3'>
                <div className=' flex  flex-col justify-start'> 
                    <div className=' flex items-center justify-center'>
                        <p className=' text-3xl text-ashred '>Lightning System </p>
                    </div>
                    <div className='flex flex-col justify-start faqs'>
                        
                    {faqs.map((faq, index) => (
                            <FAQtoggle faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}  bgColor="bg-ashred2 my-1"   textColor="text-white"/>
                    ))}
                    </div>
                    
                </div>
            </div>
            </div>
            <div className='container mx-auto max-w-screen-xl   my-3'>
            <div className=' flex flex-col justify-start'>
                    <div className=' flex items-center justify-center'>
                        <p className=' text-3xl text-ashred '>Soil Test </p>
                    </div>
                    <div className='flex flex-col justify-start faqs'>
                    {soilfaqs.map((soilfaq, index) => (
                            <FAQtoggle faq={soilfaq} index={index} key={index} toggleFAQ={toggleFAQsoil}  bgColor="bg-ashred2"   textColor="text-white"/>
                    ))}
                    </div>
                        
                    
            </div> 
            
         </div> */}
            
            <div className=' container mx-auto flex flex-col justify-center my-3 items-center cursor-pointer'>
                <p className=' text-ashred text-3xl'>Didn't find your answer? </p>
                {/*<p  className=' text-xl my-1'>Don't hesitate to contact us</p>*/}

                <Link to="/contact">
                <img className=' h-10 my-3' src={contactus}
                srcSet={`${contactus} 1x, ${contactus2} 2x`}
                alte="conta"/>
                </Link>
            </div>
        </div>
      )
    }
    
    export default FaqContentnewv2
    