import React,{useState} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';
import accimg from './assets/images/earthing/accessories/earthing_accessories_big.png';
import accimg2x from './assets/images/earthing/accessories/earthing_accessories_big@2x.png';

const Earthing_accessories_earthing = () => {
    const [selectedSubmenu, setSelectedSubmenu] = useState('');

    const handleImageClick = (system) => {
        setSelectedSubmenu(system);
        console.log("selcet system is"+system);
    };

    return (
        <>
        <EarthingelectrodeSubMenu onImageClick={handleImageClick}/>
        <div className='w-full my-3 flex justify-center items-center'>
            <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                <div className='flex justify-center'>
                    
                    <div className='flex justify-center items-center'>
                    <img src={accimg}  
                                            srcSet={`${accimg} 1x,${accimg2x} 2x`} alt=" ASEEl Earthing accesssories logo"
                                            /> 
                    </div>
                   
                </div>
                <div className='flex flex-col items-start mt-4'>
                    <p className=' text-2xl text-ashred font-bold'><span className='vollkorn-sc-semibold'>Ashlok’s Safe Earthing Accessorie(<span style={{fontSize:'80%'}}>s</span>)</span></p>
                    <p className=' text-base text-justify p-common my-2'>Earthing is a critical aspect of electrical safety, and our range of earthing accessories is designed to optimize grounding efficiency and reliability. These accessories include earth clamps, earth pits, earth bars, conductors, connectors, Exothermic Welding kit and test equipment. Each component is carefully engineered to facilitate proper grounding connections, ensure low resistance paths, and enable effective fault current dissipation. With our earthing accessories, you can establish a robust grounding system that enhances electrical safety and system performance.</p>
                   
                   
                </div>
               
                
            </div>
        </div>
        
            
        
        </>
      )
}

export default Earthing_accessories_earthing
