import React from 'react'

import einstall from './assets/images/our_services/earthing_install.png';
import einstall2x from './assets/images/our_services/earthing_install2x.png';

const EarthingInstall =() => {
  return (
    <div className="flex">
        <div className="text-left text-base">
            <div className='flex flex-col'>
                        <div className='text-justify'>
                        Proper installation of <span className='vollkorn-sc-semibold'>Ashlok</span> Earthing Systems is of utmost importance to ensure the safe and effective operation of the system, as well as to achieve the maximum benefits. When installed correctly, the <span className='vollkorn-sc-semibold'>Ashlok</span> Earthing System displays a low earth resistance value in comparison to traditional earthing systems, particularly under specific soil conditions. It’s worth noting that once it is installed, it demands minimal maintenance. However, in cases of inadequate soil conditions, it’s recommended to pour a few buckets of water inside and around the earth pits.
                        </div>
                        <div className=' h-2'></div>
                        <div className="  text-justify text-base">
                        It’s important to recognize that the earth resistance value of any earthing system varies depending on the location due to the inherent non-uniformity of soil composition and its varying resistivity even in nearby areas. In such scenarios, the utilization of multiple earth pits arranged in a grid pattern is advised to attain the desired earth resistance value.
                        </div>
                        <div className=' h-2'></div>
                        <div className=" text-justify text-base">
                            Please note : The optimal resistance values for an earthing system differ due to the diverse types of soil and environmental conditions present worldwide. 

                        </div>
                        <div className=' h-4'></div>
                        <h1>Normal Soil</h1>
                        <ul className="list-disc pl-5 text-justify">
                            <li>Create a borehole with a diameter of 8” to 10” and a depth  matching the electrode length of 2 or 3 meters.</li>
                            <li>Fill the lower 4” of the borehole with the supplied BFC</li>
                            <li>Place the electrode vertically at the centre of the pit.</li>
                            <li>Fill the area around the electrode with the BFC.</li>
                            <li>Ensure that no pockets of air are present in the filled BFC  area.</li>
                            <li>Gradually add water into the earth pit until the BFC  transforms into a paste-like or muddy consistency. Allow the  pit to absorb the water and settle naturally. </li>
                            <li>Conduct an assessment of the earth pit and establish  necessary connections to the electrical service box.</li>
                        </ul>
            </div>
            <div className=' h-4'></div>
            <div className='flex'>
                    
                    <div className='w-1/2 justify-center items-center'>
                                        <img src={einstall}  
                                                srcSet={`${einstall} 1x,${einstall2x} 2x`} alt="Earting installation"
                                                /> 
                    </div>
                    <div className=' flex  flex-col w-1/2 justify-center '>
                        <div className=' text-base font-extrabold text-justify'>
                            Warning : Refrain from overwatering and using excessive force while installing the electrode.
                        </div>
                        <div className=' h-4'></div>
                        <div className=' text-base text-justify'>
                                For guidance on installation procedures tailored to different soil compositions and their respective FCs, please get in touch with us at</div>
                        <div className=' h-4'></div>
                        <div className=' text-ashred'>earthing@ashlok.com or call +91 94440 87356.
                        </div>

                    </div>
            </div>

        </div>
        
    </div>
  )
}

export default EarthingInstall;
