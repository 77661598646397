import React,{useState} from 'react'

import spd from './assets/images/spd/spd_big.png';
import sp2x from './assets/images/spd/spd_big@2x.png';
import boltico from './assets/images/thordetails/bolt_icon.svg';
import LightningProductsSubMenu from './LightningProductsSubMenu';


    const Spddetailscontent = ({ onImageClick }) => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
            
            <div className='w-full my-3 flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={spd}  
                                                srcSet={`${spd} 1x,${sp2x} 2x`} alt=" spd logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred stitle'><span className='vollkorn-sc-semibold'>Surge Protection Device (SPD)</span></p>
                        <p className=' text-base text-justify p-common'>Is your electrical and electronic equipment shielded from lightning and transient surges? As per a study, around 65% of electronic equipment degradation is attributed to transient surges, indirect lightning, and faulty wiring. These factors contribute to premature aging, data loss, and sometimes complete equipment failure</p>
                        <p className=' text-base text-justify p-common mt-2'>ASHLOK’S lineup of Surge Protection Devices, when integrated into panels and distribution boards, guard against internally generated surges and the impact of indirect lightning strikes. This safeguards the durability of electrical and electronic appliances, shielding them from deterioration caused by surge-related consequences.</p>
                    </div>
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                    <div className='flex  py-1'>
                        <div className='w-1/2'>
                            <p className=' text-2xl '>Class B Surge Protection Device (3+1)</p>
                        </div>
                        <div className='w-1/2'>
                            <p className=' text-2xl '>Class C Surge Protection Device (3+1)</p>
                        </div>
                    </div>
                    <div className='flex  pt-2 pb-2'>
                        
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pt-1.5"/>Devices for AC Surge Protection</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Meets Type 1 (T1), Class I, and Class B standards</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> SPD compliant with EN 61643-11 and IEC 61643-11</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Voltage capability from 275 V to 600 V AC</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Available in 1P, 1P+N, 2P, 2P+N, 3P, 3P+N, and 4P configurations</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Equipped with Gas Discharge Tube (GDT)</li>
                                             </ul>
                        </div>
                        <div className='w-1/2'>
                        
                            <ul className="list-none ">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>AC Surge Protection Devices as per EN 61643-11 and IEC 61643-11 </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Type 2 (T2), Class II, and Class C protection</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Voltage tolerance from 275 V to 600 V AC</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Designed with a plug-in module concept</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Features a device status indicator and optional remote indication contact</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Suitable for 1P, 1P+N, 2P, 2P+N, 3P, 3P+N, and 4P connections</li>
                                 </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center '>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                    <div className='flex  py-1'>
                        <div className='w-1/2'>
                            <p className=' text-2xl text-ashred'>Solar PV Surge Protection Device</p>
                        </div>
                        <div className='w-1/2'>
                            <p className=' text-2xl text-ashred'>Street Light Surge Protection Device</p>
                        </div>
                    </div>
                    <div className='flex  py-1'>
                        
                        <div className='w-1/2'>
                            <ul className="list-none ml2">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pt-1.5"/>Rapid self-protection via swift thermal response</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pt-1.5"/>Available with or without remote indication contact</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Incorporates a plug-in module configuration</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Offers IP 20 protection level </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Designed for DC Surge Protection</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Compliant with Type 2 (T2), Class II, and Class C standards)</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Variants for Ucpv 600Vdc, 1000Vdc, and 1500Vdc</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>SPD designed as per EN 50539-11 and IEC 61643-31</li>
                                             </ul>
                        </div>
                        <div className='w-1/2'>
                        
                            <ul className="list-none ml2">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pt-1.5"/>Includes built-in LED indicator for simplified maintenance and replacement identification  </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pt-1.5"/>Thermal protection feature</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pt-1.5"/>Maximum discharge current of 20kA </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Offers robust line-to-ground/earth resistance</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Rated IP 66 for resistance against dust and water</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pt-1.5"/>Meets IEC 61643-11 standards </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Suitable for use in luminaires with Class I or Class II insulation</li>
                                 </ul>
                        </div>
                    </div>
                   

                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <div className='flex  py-1 '>
                        <div className='w-1/2'>
                            <p className=' text-2xl text-ashred'>DCDB</p>
                        </div>
                    </div>
                    <div className='flex'>
                        
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Polymer housing with UV resistance | IP Class - 54</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Non-combustible polymer construction</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>streamlined cable management, simple and evident</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Withstands varied weather conditions and ensures extended life </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Available for 500V and 1000V DC systems</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>I/O configurations range from 1 in 1 out to 12 in 12 out</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Constructed from Poly-Carbonate material</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Installation and upkeep made convenient</li>
                                             </ul>
                        </div>
                    </div>
              
              </div>
            </div>
            
            </>
          )

    }
    
    export default Spddetailscontent
    