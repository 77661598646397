import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import "./infinitescroll.css";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

//import { useGSAP } from '@gsap/react';

//gsap.registerPlugin(useGSAP);

const InfiniteScrollCarousel = ({ images }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    const imageElements = carousel.querySelectorAll('.ashclient-image');

    /*
    Array.from(imageElements):

imageElements is a NodeList, which is similar to an array but doesn't have array methods like reduce or map.
Array.from(imageElements) converts the NodeList into a true array so that array methods can be used on it.


    */
   
    const totalWidth = Array.from(imageElements).reduce((acc, img) => acc + img.offsetWidth, 0);
   
    //const startAnimation = () => {
      let ctx = gsap.context(() => {
      const tween =gsap.to(carousel, {
      x: `-${totalWidth}px`,
      ease: 'linear',
      duration: 200,
      //delay: 1,
      repeat: -1,
      immediateRender:false,
      //paused: true,
     /// autokill: false,
      modifiers: {
        x: gsap.utils.unitize(x => parseFloat(x) % totalWidth),
      },
      scrollTrigger: {
        trigger: carousel,
       // start: "top bottom", // this means the animation will start when the top of the trigger element reaches the bottom of the viewport 
        //end: "bottom top", // 
       // scrub: true, // this will make the animation smooth and not jumpy when scrolling up and down the page
        onUpdate: (e) => {
          //setY(e.progress * y);
        //  console.log(e.y);
        },
      //  start: "top 65px",
      }
    });
    
      
    // ScrollTrigger.create({
    //   trigger: carousel,
    //  // start: '.ashclientimage-containers', // Adjust this based on when you want the animation to start
    //  // end: 'bottom top',
    //  // onEnter: () => tween.play(), // Start the animation when the carousel comes into view
    //  // onLeave: () => tween.pause(), // Pause the animation when it's out of view
    //  // onEnterBack: () => tween.play(), // Restart the animation when scrolling back into view
    //   //onLeaveBack: () => tween.pause(), // Pause the animation when scrolling back out
    // });
    // Force 3D rendering
    gsap.set(carousel, { willChange: 'transform' });
  //Note: without below line safari browser not working
  //  return () => {
  //     //gsap.killTweensOf(carousel);
  //     tween.kill();
  //   };
  return () => ctx.revert();


  //};
  // Call startAnimation immediately
  //const cleanup = startAnimation();

  // // Also ensure it starts when window is fully loaded
  // if (document.readyState !== 'complete') {
  //   //window.addEventListener('load', startAnimation);
  //   return () => {
  //    //window.removeEventListener('load', startAnimation);
  //     //cleanup();
  //   };
  // }
  //return cleanup;

})

  }, [images]);

  return (
    <div className="ashclientimage-container" style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      <div className="ashclient_list" ref={carouselRef}>
        {images.concat(images).map((image, index) => (
          <img
            key={index}
            src={image.url}
            className="ashclient-image"
            style={{ display: 'inline-block', marginRight: '10px' }}
            alt={`carousel ${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default InfiniteScrollCarousel;