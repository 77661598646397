    import React,{useEffect,useState} from 'react'
    
    const HeritageBanner = ({page,bannertext,ban1x,ban2x,banalt}) => {
        const [pageData, setPageData] = useState({});
        // useEffect(() => {
        //   const fetchData = async () => {
        //     try {
        //       const response = await fetch('/data/data.json');
        //       const data = await response.json();
        //       setPageData(data);
        //             console.log(data);
        //     } catch (error) {
        //       console.error('Error fetching data:', error);
        //     }
        //   };
      
        //   fetchData();
        // }, []);
      
        // Accessing the 'home_page_title' for the selected language
        const ban1_alt = pageData[page]?.ban1_alt || '';
      
        return (
          <div className='relative w-full flex flex-col justify-center items-center '>
           
           
                    <img  src={ban1x} alt={banalt} 
                   srcSet={`${ban1x} 1x, ${ban2x} 2x`}
                    className='block w-full h-auto'
                    />
                     <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-3xl  text-white uppercase font-semibold' style={{ fontFamily: 'cinzel, sans-serif' }}>
                            {bannertext}
                </div>
               
          </div>
        )
    }
    
    export default HeritageBanner
    