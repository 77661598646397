import React,{useEffect} from 'react'
import tecgline from './assets/images/whypage/tech_line.svg';
import techdesign from './assets/images/whypage/tech_design.svg';
import desifndevelop from './assets/images/whypage/deign_developed.svg';
import productionprocess from './assets/images/whypage/production_process.svg';

import crossec from './assets/images/whypage/cross_section_view.svg';

import earthsol from './assets/images/whypage/earthing_solution.jpg';
import earthsol2x from './assets/images/whypage/earthing_solution@2x.jpg';

import shbanner from './assets/images/whypage/shield_banner.png';
import shbanner2x from './assets/images/whypage/shield_banner2x.png';

import grass from './assets/images/grass-1x.jpg';
import grass2x from './assets/images/grass-2x.jpg';

import inner_ele from './assets/images/whypage/Inner_Electrode.svg';
import outer_ele from './assets/images/whypage/Outer_Electrode.svg';
import strip from './assets/images/whypage/strip.svg';


import light_safety from './assets/images/whypage/lightning_safety.jpg';
import light_safety2x from './assets/images/whypage/lightning_safety@2x.jpg';

import thor_slpr from './assets/images/whypage/thor_slpr.jpg';
import thor_slpr2x from './assets/images/whypage/thor_slpr@2x.jpg';

const HowpageContent = ()=> {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scrolling animation
        });
      };
      useEffect(() => {
        scrollToTop();
      }, []);
  return (
    <div>
    <div className='w-full flex flex-col items-center   '>
        
            <div className='w-full'>
                <div className='   flex justify-center items-center w-full'>
                    <p className='  text-2xl text-ashred py-2'>First Time "Pipe-in-Pipe" technology concept of earthing system</p>
                </div>
                <div className='flex'>
                    <div className='container mx-auto max-w-screen-xl flex' > 
                        <div className='w-1/2 flex  items-center' >
                           <img className=' object-cover'
                            src={earthsol} alt="earthing solution" 
                            srcSet={`${earthsol} 1x, ${earthsol2x} 2x`}

                           />
                        </div>
                        <div className='w-1/2 flex flex-col justify-center my-3'>
                           
                                <p className='pl-5 text-justify'>Since <span className='vollkorn-sc-semibold'>Ashlok’s</span> inception, we’ve consistently led the charge in introducing novel technologies and groundbreaking earthing designs to the field. We were the pioneers in securing government test approvals, setting the standard for advanced earthing designs that define the current market landscape.</p>
                                <p className='py-1 pl-5 text-justify'><span className='vollkorn-sc-semibold'>Ashlok’s</span> earthing system designs operate effectively throughout the globe, spanning residential, commercial, and industrial domains within both the private and public sectors.</p>
                                <p className='py-1 pl-5 text-justify'>Our comprehensive product portfolio, streamlined manufacturing processes, extensive supply network, and dedicated customer support have propelled us to the forefront of the industry. The roster of clients we serve is a testament to our unrivaled position in every earthing project undertaken today.</p>
                                <p className='py-1 pl-5 text-justify'><span className='vollkorn-sc-semibold'>Ashlok’s</span> presence extends across India, ensuring we can address earthing requirements wherever they emerge. With a robust Research & Development and Service team, we’re well-equipped to tackle any earthing challenges that arise.</p>
                                <p className='pl-5 text-justify'>Irrespective of your location, rest assured that we possess an earthing solution tailored to your needs.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className=' flex justify-center items-center w-full h-10 bg-ashred'>
            <p className='py-3 text-2xl text-white'><span className='vollkorn-sc-semibold'>Ashlok</span> DESIGNED PRODUCTION PROCESS</p>
            </div>
            {/*
             <div className='max-w-screen-xl '>
                <img src={tecgline}/>
            </div>*/}
            <div className='container mx-auto max-w-screen-xl '>

                    <div className='flex my-3'>
                        <div className=' w-2/5'>
                            <div className=' flex flex-col'>
                            <p className=' text-ashred text-xl   '>Pipe-In-Pipe Technology</p>
                            <p className=' text-justify   '>First Introduced by <span className='vollkorn-sc-semibold'>Ashlok</span> in 1999, the Pipe-In-Pipe design involves employing two pipes with coaxial dimensions that are interconnected to improve the overall durability and effectiveness of the earthing system. The annular space between the electrodes is filled with Crystalline Conductive Compound(s) like lonFill or CompactFill, known for their ability to dissipate current and prevent corrosion. The electrode’s cross-sectional shape needs to be circular to ensure consistent dispersion of fault current in all directions from the electrode to the ground.</p>
                            </div>
                            <p className=' pt-3 text-ashred cursor-not-allowed' style={{ userSelect: 'none' }}>Cross Section View</p>
                                <div className='flex justify-center items-center cursor-not-allowed'>
                                    <img src={crossec}/>
                                </div>
                            <div className='   '>
                                <p>CCC Crystalline Conductive Compound</p>
                                <p>………… Fault Current Dissipation</p>
                                <div className="flex items-center mb-2">
                                <img   src={inner_ele} className="mr-2" />
        <p className="text-base text-black">Inner Electrode</p>
      </div>
      <div className="flex items-center mb-2">
      <img   src={outer_ele}  className="mr-2" />
        <p className="text-base text-black">Outer Electrode</p>
      </div>
                                <p className='h-3'></p>
                                <p>Description : <span className='vollkorn-sc-semibold'>Ashlok’s</span> Pipe-in-Pipe Design</p>
                                <p>Design Year : 1999</p>
                            </div>
                            <div className=' flex flex-col   '>
                                <p className=' text-xl'>Available in the following sizes (may vary with product)</p>
                                <table className=" w-full divide-y divide-gray-200   text-base">
                                        <thead className='w-full'>
                                            
                                            <tr className="bg-ashred">
    
                                            <th className=" px-2 py-2 text-white">Model</th>
                                            <th className="  px-2 py-2 text-white">Length (Mtr)</th>
                                            <th className="  px-2 py-2 text-white">Inner Pipe Dia (mm)</th>
                                            <th className="  px-2 py-2 text-white">Outer Pipe Dia (mm)</th>
                                            
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-2 py-2 text-center">ASEEL 19</td>
                                            <td className=" px-2 py-2 text-center">2 & 3</td>
                                            <td className=" px-2 py-2 text-center">26 - 27</td>
                                            <td className=" px-2 py-2 text-center">48 - 50 </td>
                                          
    
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-2 py-2 text-center">ASEEL 39</td>
                                            <td className=" px-2 py-2 text-center">2 & 3</td>
                                            <td className=" px-2 py-2 text-center">38 - 42</td>
                                            <td className=" px-2 py-2 text-center">75- 76</td>
                                            
                                           
                                            </tr>
    
                                           
                                        
                                        </tbody>
                                    </table>

                               <p>Custom designed Safe Earthing Electrodes can be produced to align with the Bespoke specifications of individual clients.</p>
                               <p className=' py-3 text-ashred text-xl text-justify'>CompactFill</p>
                               <p className=' pb-3 text-justify'>The space between the inner and outer pipes of the electrode is filled with a Crystalline Conductive Compound (CCC) called CompactFill, a proprietary compound developed by <span className='vollkorn-sc-semibold'>Ashlok</span>. This compound contributes to enhancing the electrical conductivity, lifespan, and ability to withstand current of the earthing electrode. It consists of natural elements and also possesses anti-corrosive properties.</p>
                               <p className=' text-ashred text-xl text-justify'>lonFill</p>
                                <p className=' pt-3 text-justify'>This is a Conductive Crystalline Compound (CCC) formulated for the IC99 series of earthing electrodes. The interior space of the electrode is filled with lonFill, containing elements that contribute to the electrode’s breathing action. These elements play a vital role in the electrode’s operation by capturing moisture from the environment and improving electrical conductivity. Through this respiratory mechanism, the electrode establishes conductive pathways throughout the surrounding earthing system.</p>
                                
                            </div>

                        </div>
                        <div className=' w-1/5 flex justify-center  items-center   '>
                            <img src={techdesign} className='h-[55%]'/>
                        </div>
                        <div className=' w-2/5   '>
                            <div className=' flex flex-col '>
                                
                                <div className='flex'>
                                    <p className=' text-ashred text-xl'><span className='vollkorn-sc-semibold'>Ashlok</span> Designed Production Process</p>
                                    
                                </div>
                                <p className=' pb-3 text-justify'>Due to the diverse range of soil types, climates, and electrical conditions encountered globally by earthing systems, it is of utmost significance that they are crafted with meticulous attention, precision and a strong commitment to quality.</p>
                                <p className=' py-3 text-justify'>Deficiencies such as inconsistent coating levels on the electrode surface can detrimentally impact the lifespan of the system. Incorrect proportions of the composition of Crystalline Conductive Compound(s) can result in varying resistance results. </p>
                                <p className=' py-3 text-justify'>At <span className='vollkorn-sc-semibold'>Ashlok</span>, we embraced the challenge of conceptualising, developing and designing our own production procedures tailored for distinct metallic coatings, fillings for internal cavities and packaging methods. As a result, we have successfully established our own production protocols that consistently align with the top-tier ‘A’ grade quality standards we have set for ourselves. This is a key factor in our dedication to improving the service we offer you, everyday.</p>
                                <div className='flex flex-col justify-start items-start mb-3'>
                                   <img  src={desifndevelop} className='flex justify-center w-[50%] my-3'/>
                                    <img src={productionprocess} className='mt-3'/>
                                </div>
                                <div className='mt-3'>
                                    <p className=' text-ashred text-xl text-justify '>Composite Carbon Coating Technology</p>
                                    <p className=' py-3 text-justify'>The R&D team of <span className='vollkorn-sc-semibold'>Ashlok</span> has developed a proprietary coating for the Safe Earthing Electrode(s) that precludes the possibility of corrosion. It is the first Safe Earthing Electrode to incorporate the latest Proprietary, “Composite Carbon Coating Technology” which is Conceptualised, Developed and Designed for highly corrosive earth environment.</p>
                                    <p className=' pb-3 text-justify'>With that in mind, we humbly present to you the crown jewel..</p>
                                    <p className=' pb-3 text-justify'><span className='vollkorn-sc-semibold'>Ashlok’s</span> Sentinel Range of Safe Earthing Electrode(s)</p>
                                    
                                    <p className=' text-ashred text-xl text-justify pb-3'>CarboForm ConductoCasting</p>
                                    <p className=' pb-3 text-justify'><span className='vollkorn-sc-semibold'>Ashlok's</span> Singleton Earthing EcoSystem based ConductoCast Electrodes has been conceptualised & designed such that the primary conductor comes encased within a tightly formed conductive matrix of CarboForm Compound. It is a blend of different environmentally-friendly elements, carefully designed for this purpose.</p>
                                    <p className=' pb-3 text-justify'>In regions characterised by excessively loose soil conditions that lead to intermittent contact between the primary electrode and the surrounding earth’s surface area, <span className='vollkorn-sc-semibold'>Ashlok’s</span> Singleton Earthing EcoSystem based ConductoCast Electrodes offers a suitable solution. </p>
                                    <p className='text-justify'> <span className='vollkorn-sc-semibold'>Ashlok’s</span> Singleton Earthing EcoSystem ConductoCast Electrode(s)</p>
                                </div>
                             </div>
                        </div>

                    </div>

            </div>
           
            
                <div className=' w-full   bg-ashred flex flex-col justify-center items-center   '>
                        <p className='text-2xl text-white py-2'><span className='vollkorn-sc-semibold'>Ashlok's</span> Unveils THOR® SLPR: First Indigenous Safe Lightning Protection Rod</p>
                        </div>
                <div className='max-w-screen-xl  flex  py-3 justify-start   ' >
                        <div className='w-1/2 flex justify-center items-center'>
                            <img src={light_safety}/>
                        </div>
                        <div className='w-1/2'>
                            <p className='pb-3 pl-5 text-justify'>Since 1999, <span className='vollkorn-sc-semibold'>Ashlok</span>. has led the way in the innovation of Earthing & Lightning Protection Systems, establishing itself as a highly dependable entity within the Asian market due to its unwavering commitment to quality products. The components for <span className='vollkorn-sc-semibold'>Ashlok’s</span> earthing and lightning protection systems are meticulously crafted to endure maximum fault and lightning currents, aligning with British, European, Indian and various international standards.</p>
                            <p className='pb-3 pl-5 text-justify'>Throughout its history, <span className='vollkorn-sc-semibold'>Ashlok</span> has strived to introduce innovative offerings to its diverse customer base, leading to a strong sense of brand loyalty as a result of its persistent efforts. Over time, <span className='vollkorn-sc-semibold'>Ashlok</span> has solidified its status as a household name in the Indian market, with installations in over million(s) locations across India, Nepal, Sri Lanka, and African nations, safeguarding both lives and valuable equipment.</p>
                            <p className='text-justify pl-5'><span className='vollkorn-sc-semibold'>Ashlok</span> with its R&D team is proud to present an Indigenous Safe Lightning Protection Rod, conceptualized, designed and developed for the Indian & International market ….. THOR® SLPR, which works on the principle of ESE type lightning protection system.</p>
                        </div>
                        
                                       
                
            

                </div>
                <div className=' w-full   bg-ashred flex flex-col justify-center items-center   '>
                        <p className='text-2xl text-white py-2'>Working Concept of THOR® SLPR</p>
                        </div>
                 <div className='max-w-screen-xl  flex  py-3 justify-start   '>
                         <div className='w-1/2'>
                            <p className='text-justify pr-5'>THOR® SLPR is ingeniously constructed to ensure its components synergize, effectively attracting and intercepting lightning strikes. As the downward leader approaches, inducing an electric field for charge movement, THOR® SLPR’s internal structure enhances charge emission by curbing corona during the initial lightning stages. Charge conservation is ensured, as THOR® SLPR’s design eliminates corona opportunities, preventing charge wastage during lightning development.</p>
                            <p className='py-3 text-justify pr-5'>Upon full charge, THOR® SLPR emits a potent upward streamer with heightened charge density and extended length. This streamer couples with the lightning’s downward leader, at a significant distance from the safeguarded structure, effectively enlarging the protected area.</p>
                            <p className='pb-3 text-justify pr-5'>Recent studies reveal that lightning generates secondary flashes upon striking objects. THOR® SLPR’s design adeptly captures these secondary flashes, design adeptly captures these secondary flashes by incorporating Side Flash Capture Head (SFCH) in its design thus averting potential for harm.</p>
                            <p className='text-justify pr-5'>Engineered for minimal maintenance, THOR® SLPR functions in diverse atmospheric conditions, exemplifying versatility.</p>
                        </div>
                       <div className='w-1/2 flex justify-center items-center'>
                            <img src={thor_slpr}/>
                        </div>
                   
                   </div>
           
                




    {/*</div>
    <div className='w-full flex flex-col items-center'>*/}
        
        

    </div> 

        {/*
    <div className='w-full flex flex-col items-center '  style={{ backgroundImage: `url(${shbanner})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
        
        <div className='max-w-screen-xl  flex pt-10 h-[700px]' >
            <div className='flex'>
            </div>
            

        </div>


    </div>   */}  

    </div>
  )
}
export default HowpageContent;