import React,{useEffect} from 'react'
import rectimg from './assets/images/whypage/Rectangle.png';
import rectimg2x from './assets/images/whypage/Rectangle@2x.png';

import prshield from './assets/images/whypage/product_shield.png';
import prshield2x from './assets/images/whypage/product_shield@2x.png';

import rcolimg from './assets/images/whypage/eco_skyline.jpg';
import rcolimg2x from './assets/images/whypage/eco_skyline@2x.jpg';


const WhypageContent = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scrolling animation
        });
      };
      useEffect(() => {
        scrollToTop();
      }, []);

  return (
    <div className='w-full flex flex-col items-center'>
        <div className='w-full bg-ashred h-10 p-2 flex justify-center items-center'>
            <div className='container mx-auto max-w-screen-xl flex'>
             
                  <div className=' w-1/2 text-white text-xl font-bold'>
                      VISION
                  </div>
                  <div className=' w-1/2 text-white text-xl font-bold pl-5'>
                  MISSION
                  </div>
            
            </div>
            
        </div>
        <div className='w-full' style={{ backgroundImage: `url(${rectimg})` }}>
            <div className='container mx-auto max-w-screen-xl'>
              <div className='flex  py-5'>
                  <div className=' w-1/2 pr-5 text-justify'>
                  Empowering safety and reliability in every connection. <span className='vollkorn-sc-semibold'>Ashlok</span> envisions a world where every individual, every infrastructure and every community is safe guarded against electrical hazards. With a commitment to technological advancement, sustainability and unparalleled service, we strive to be the global leader in Earthing and Lightning Protection Solutions, ensuring the safety and well being of generations to come.


                 </div>
                 <div className=' w-1/2 pl-5 text-justify'>
                 Our mission is to empower our customers with the knowledge and tools necessary to effectively mitigate electrical hazards, ensuring a safer world for future generations. We are dedicated to safeguarding lives, property, and infrastructure from electrical dangers through continuous research, development, and delivery of superior products and solutions that exceed industry standards and meet our customers’ evolving needs. Committed to integrity, reliability, and excellence in all aspects of our operations, we aim to foster enduring relationships with our clients, partners, and communities, contributing to a safer and more sustainable world.
                 </div>

              </div>
            </div>

        </div>
        <div className='w-full'>
            <div className='container mx-auto max-w-screen-xl '>
                <div className=' flex items-center justify-center my-3'>
                  <p className=' text-ashred text-4xl'>Why <span className='vollkorn-sc-semibold'>Ashlok ?</span> </p>
                </div>
                <div className='grid grid-cols-3 gap-5'>
                    <div className=' flex flex-col  justify-center items-center'>
                      <p className=' text-justify'>
                        <span className=' text-ashred pr-1 text-xl text-left' style={{ display: 'inline-block' }}>Experience & Expertise : </span>
                          With over 2 decades of industry experience, <span className='vollkorn-sc-semibold'>Ashlok</span> has established itself as a trusted leader in providing the Ultimate Earthing & Lightning Solutions. Our team of professionals bring extensive expertise in electrical safety, grounding technology, and innovative product development. We pride ourselves on our deep understanding of industry standards and the evolving needs of our customers.
                      </p>
                      <p className=' pb-2 text-justify my-3'>
                        <span className=' text-ashred pr-1 text-xl text-left' style={{ display: 'inline-block' }}>Tailored Solutions  : </span>
                        Whether it’s for residential, commercial, or industrial applications, <span className='vollkorn-sc-semibold'>Ashlok</span> leverages its extensive expertise and innovative technologies to deliver solutions that fit perfectly with our clients’ specifications. Our personalised approach ensures that each solution not only meets but exceeds industry standards, providing unparalleled protection and reliability.
                      </p>
                      <div className=' pb-2 text-justify'>
                      <p className='text-justify'>
                        <span className=' text-ashred pr-1 text-xl text-left' style={{ display: 'inline-block' }}>Quality, Compliance & Safety  : </span>
                        Quality, Compliance, and Safety are the cornerstones of our operations. We are committed to delivering products that meet the highest standards of quality, ensuring durability and reliability in every application. Compliance with international and local regulations is a fundamental aspect of our business. We adhere strictly to industry standards and certifications, providing our customers with peace of mind knowing that our products meet all necessary legal and safety requirements
                        </p>
                      </div>
                    </div>
                    {/* second column */}
                    <div className='flex justify-center items-center'>
                        <img  src={prshield} alt="product shild"
                          srcSet={`${prshield} 1x, ${prshield2x} 2x`}
                            className='block w-full h-auto'
                            />
                    </div>
                    {/* Third column */}
                    <div className=' flex flex-col justify-start'>
                      <div className="flex items-center justify-center">
                        <img  src={rcolimg} alt="Ashlok features"
                          srcSet={`${rcolimg} 1x, ${rcolimg2x} 2x`}
                            className='w-[65%]'
                            />
                      </div>
                      <div className=''>
                     
                        <div className='mt-3'>
                          <p className='text-justify'><span className=' text-ashred pr-1 text-xl text-left' style={{ display: 'inline-block' }}>Comprehensive Services: </span>
                         <span className='vollkorn-sc-semibold text-left'>Ashlok</span> offers a comprehensive range of services designed to meet the diverse needs of our clients. Our expert team provides end-to-end solutions, from initial consultation and site assessment to customised design, installation, and maintenance of earthing systems.</p>
                        </div>
                        <div className='  text-justify my-3'>
                        <p className='text-justify-inter-word '>
                          <span className=' text-ashred pr-1 text-xl text-left' style={{ display: 'inline-block' }}>Reputation & Trust  :</span>
                          Our reputation is built on a foundation of trust, reliability, and excellence. Clients across various sectors rely on <span className='vollkorn-sc-semibold'>Ashlok</span> for our unwavering commitment to integrity and professionalism. We believe that trust is earned through transparency, open communication, and a relentless pursuit of excellence. Our proven track record of successful projects and long-standing relationships with our customers is a testament to our dependable performance and ethical practices
                          </p>
                        </div>
                      </div>
                    </div>
                </div>
           </div>
        </div>
        
        
    </div>
  )
}

export default WhypageContent;

{/*
  
old contnet 


import prshield_wt from './assets/images/whypage/product_shield.png';
import prshield2x_wt from './assets/images/whypage/product_shield@2x.png';


 <div className='w-full'>
            <div className='container mx-auto max-w-screen-xl flex justify-start '>
                <div className=' w-1/3 py-4 flex flex-col  justify-center items-center'>
                  <p className=' pb-2  pt-[10%] text-justify'>
                    <span className=' text-ashred pr-1'>Experience & Expertise :</span>
                      With over 2 decades of industry experience, <span className='vollkorn-sc-semibold'>Ashlok</span> has established itself as a trusted leader in providing the Ultimate Earthing & Lightning Solutions. Our team of professionals bring extensive expertise in electrical safety, grounding technology, and innovative product development. We pride ourselves on our deep understanding of industry standards and the evolving needs of our customers.
                  </p>
                  <p className=' pb-2 text-justify'>
                    <span className=' text-ashred pr-1'>Tailored Solutions  :</span>
                    Whether it’s for residential, commercial, or industrial applications, <span className='vollkorn-sc-semibold'>Ashlok</span> leverages its extensive expertise and innovative technologies to deliver solutions that fit perfectly with our clients’ specifications. Our personalised approach ensures that each solution not only meets but exceeds industry standards, providing unparalleled protection and reliability.
                  </p>
                  <p className=' pb-2 text-justify'>
                    <span className=' text-ashred pr-1'>Quality, Compliance & Safety  :</span>
                    Quality, Compliance, and Safety are the cornerstones of our operations. We are committed to delivering products that meet the highest standards of quality, ensuring durability and reliability in every application. Compliance with international and local regulations is a fundamental aspect of our business. We adhere strictly to industry standards and certifications, providing our customers with peace of mind knowing that our products meet all necessary legal and safety requirements
                  </p>
                </div>
                <div className=' w-1/3 py-6 '>
                <img  src={prshield} alt="product shild"
                   srcSet={`${prshield} 1x, ${prshield2x} 2x`}
                    className='block w-full h-auto'
                    />
                </div>
                <div className=' w-1/3 flex flex-col  justify-center'>
                <p className=' pb-2  text-justify'>
                    <span className=' text-ashred pr-1'>Comprehensive Services   :</span>
                    <span className='vollkorn-sc-semibold'>Ashlok</span> offers a comprehensive range of services designed to meet the diverse needs of our clients. Our expert team provides end-to-end solutions, from initial consultation and site assessment to customised design, installation, and maintenance of earthing systems.
                  </p>
                  <p className=' pb-2 text-justify'>
                    <span className=' text-ashred pr-1'>Reputation & Trust  :</span>
                    Our reputation is built on a foundation of trust, reliability, and excellence. Clients across various sectors rely on <span className='vollkorn-sc-semibold'>Ashlok</span> for our unwavering commitment to integrity and professionalism. We believe that trust is earned through transparency, open communication, and a relentless pursuit of excellence. Our proven track record of successful projects and long-standing relationships with our customers is a testament to our dependable performance and ethical practices


                  </p>
                </div>
           </div>
        </div>

  */
  }