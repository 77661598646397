    import React from 'react'
    import locicon from './assets/images/contact/location_icon.svg';
    import phone from './assets/images/contact/phone_icon.svg';
    import mail from './assets/images/contact/mail_icon.svg';

    import facebook from './assets/images/contact/facebook_icon.svg';
    import twitter from './assets/images/contact/twitter_icon.svg';
    import linkin from './assets/images/contact/linkedin_icon.svg';
    import whatsapp from './assets/images/contact/whatsapp_icon.svg';
    import insta from './assets/images/contact/instagram_contact.svg';

    import map from './assets/images/contact/map.jpg';


    
    const Contactcontet = () => {
      return (
        <div className=' w-full mx-auto'>
            <div className=' bg-ashgray1'>
                <div className=' container mx-auto max-w-screen-xl justify-center flex flex-row'>
                    <div className='flex basis-1/2 justify-start items-start flex-col  py-5'>
                        <p className=' text-ashred text-2xl'>
                        Head Office
                        </p>
                        <div className='h-2'></div>
                        <ul className="list-none">
                        <li className="flex items-center"> <img src={locicon} alt="Your Image" className="w-6 mr-2" /><div className=' text-2xl font-semibold'><span className='vollkorn-sc-semibold'>Ashlok</span> Safe Earthing Electrode Limited</div></li>
                        <li className="flex items-center"><div className='pl-8'>No. 58 | 3rd Street | North Phase | SIDCO Industrial Estate</div></li>
                        <li className="flex items-center"><div className='pl-8'> Ambattur | Chennai - 600 098 | Tamil Nadu | India.</div></li>
                        </ul>
                        <div className='h-5'></div>
                        <ul className="list-none">
                            <li className="flex items-center"> <img src={phone} alt="Your Image" className="w-6 mr-2" /><div>+91 - 44 2625 4757</div></li>
                            <li className="flex items-center"><div className='pl-8'>+91 - 94440 87356</div></li>
                        </ul>
                        <div className='h-5'></div>
                        <ul className="list-none">
                            <li className="flex items-center"> <img src={mail} alt="Your Image" className="w-6 mr-2" /><div>earthing@ashlok.com</div></li>
                           
                        </ul>
                        <div className='h-5'></div>
                        {/*
                        <p className=' text-ashred text-xl ml-8'>
                        Other Location Manufacturing Unit(s)
                        </p>
                        <div className='h-2'></div>
                        <ul className="list-none ml-2">
                        <li className="flex items-center"> <img src={locicon} alt="Your Image" className="mr-2 pl-5" /><div className=' text-2xl font-semibold'>Ashlok Safe Earthing Electrode Limited</div></li>
                        <li className="flex items-center"><div className='pl-12'>Plot No.27, Narnarayanan Industrial Park, Kubadthal Kuha Road,</div></li>
                        <li className="flex items-center"><div className='pl-12'> Near Shivbhumi Estate, Kujad, Kubadthal, Ahmedabad - 382430, Gujarat.</div></li>
                        </ul>*/}
                         <ul className="list-none">
                         <li className="flex items-center"> <img src={locicon} alt="Your Image" className="w-6 mr-2" /><div className=' text-l font-semibold'>Manufacturing Unit(<span style={{fontSize:'90%'}}>s</span>) : Chennai | Ahmedabad</div></li>
                            </ul>
                        <div className='h-5'></div>
                        <ul className="list-none flex items-center   justify-center">
                            <li className="flex items-center justify-center"> <img src={facebook} alt="Your Image" className="mr-2" />  <img src={linkin} alt="Your Image" className="mr-2 pl-5" /> <img src={insta} alt="Your Image" className="mr-2 pl-5" /> <img src={whatsapp} alt="Your Image" className="mr-2 pl-5" /></li>
                           
                        </ul>
                    </div>
                    <div className='flex basis-1/2 ml-6 justify-end  items-start'>
                        <img className=" max-w-xl my-5 border-2 border-gray-500" src={map}/>
                    </div>
                </div>
            </div>


            <div className=' flex justify-center max-w-4xl'>

               
            </div>
          
        </div>
      )
    }
export default Contactcontet
    