import React,{ createContext, useState } from 'react';

const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedLightacc, setSelectedlightacc] = useState(null);


  const setCategory = (category) => {
    setSelectedCategory(category);
  };

  const setProduct = (product) => {
    setSelectedProduct(product);
  };

  const setLightacc = (product) => {
    setSelectedlightacc(product);
  };

  return (
    <ProductContext.Provider value={{ selectedCategory, selectedProduct, setCategory, setProduct, selectedLightacc,setLightacc}}>
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider };