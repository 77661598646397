import React from 'react'
import cleint from './assets/images/ashlok_way/cleint_logo.png';
import cleint2x from './assets/images/ashlok_way/cleint_logo@2x.png';

import reliance from './assets/images/ashlok_way/clients/reliance.png';
import reliance2x from './assets/images/ashlok_way/clients/reliance@2x.png';

import aarti from './assets/images/ashlok_way/clients/aarti.png';
import aarti2x from './assets/images/ashlok_way/clients/aarti@2x.png';
import barc from './assets/images/ashlok_way/clients/barc.png';
import barc2x from './assets/images/ashlok_way/clients/barc@2x.png';
import fine_organics from './assets/images/ashlok_way/clients/fine-organics.png';
import fine_organics2x from './assets/images/ashlok_way/clients/fine-organics@2x.png';
import gits from './assets/images/ashlok_way/clients/gits.png';
import gits2x from './assets/images/ashlok_way/clients/gits@2x.png';
import henkel from './assets/images/ashlok_way/clients/henkel.png';
import henkel2x from './assets/images/ashlok_way/clients/henkel@2x.png';
import indian_oil from './assets/images/ashlok_way/clients/indian-oil.png';
import indian_oil2x from './assets/images/ashlok_way/clients/indian-oil@2x.png';
import indospace from './assets/images/ashlok_way/clients/indospace.png';
import indospace2x from './assets/images/ashlok_way/clients/indospace@2x.png';
import jsw from './assets/images/ashlok_way/clients/jsw.png';
import jsw2x from './assets/images/ashlok_way/clients/jsw@2x.png';
import lodha from './assets/images/ashlok_way/clients/lodha.png';
import lodha2x from './assets/images/ashlok_way/clients/lodha@2x.png';
import lubrizol from './assets/images/ashlok_way/clients/lubrizol.png';
import lubrizol2x from './assets/images/ashlok_way/clients/lubrizol@2x.png';
import mahanagar_gas from './assets/images/ashlok_way/clients/mahanagar-gas.png';
import mahanagar_gas2x from './assets/images/ashlok_way/clients/mahanagar-gas@2x.png';
import mahindra from './assets/images/ashlok_way/clients/mahindra.png';
import mahindra2x from './assets/images/ashlok_way/clients/mahindra@2x.png';
import mastek from './assets/images/ashlok_way/clients/mastek.png';
import mastek2x  from './assets/images/ashlok_way/clients/mastek@2x.png';
import military from './assets/images/ashlok_way/clients/military.png';
import military2x from './assets/images/ashlok_way/clients/military@2x.png';
import piramal from './assets/images/ashlok_way/clients/piramal.png';
import piramal2x from './assets/images/ashlok_way/clients/piramal@2x.png';
import precision from './assets/images/ashlok_way/clients/precision.png';
import precision2x from './assets/images/ashlok_way/clients/precision@2x.png';
import smart_point from './assets/images/ashlok_way/clients/smart-point.png';
import smart_point2x from './assets/images/ashlok_way/clients/smart-point@2x.png';
import tata_housing from './assets/images/ashlok_way/clients/tata-housing.png';
import tata_housing2x from './assets/images/ashlok_way/clients/tata-housing@2x.png';
import tata_motors from './assets/images/ashlok_way/clients/tata-motors.png';
import tata_motors2x from './assets/images/ashlok_way/clients/tata-motors@2x.png';
import unity from './assets/images/ashlok_way/clients/unity.png';
import unity2x from './assets/images/ashlok_way/clients/unity@2x.png';
import vikram_solar from './assets/images/ashlok_way/clients/vikram-solar.png';
import vikram_solar2x from './assets/images/ashlok_way/clients/vikram-solar@2x.png';


import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './hcarsol.css';
import InfiniteScrollCarousel from './InfiniteScrollCarousel';
import Infinitescrollnew from './Infinitescrollnew';
import Infinitescroll from './Infinitescroll';
import Scrollhorizontal from './Scrollhorizontal';



const clientImageUrl = [
  //First image url
   {
    url:reliance,
    url2x:reliance2x
  },
   {
    url:aarti,
    url2x:aarti2x
  },
   {
    url:barc,
    url2x:barc2x
  },
   {
    url:fine_organics,
    url2x:fine_organics2x
  },
   {
    url:gits,
    url2x:gits2x
  },
   {
    url:henkel,
    url2x:henkel2x
  },
   {
    url:indian_oil,
    url2x:indian_oil2x
  },
  {
    url:indospace,
    url2x:indospace2x
  },
  {
    url:jsw,
    url2x:jsw2x
  },
  {
    url:lodha,
    url2x:lodha2x
  },
  {
    url:lubrizol,
    url2x:lubrizol2x
  },
  {
    url:mahanagar_gas,
    url2x:mahanagar_gas2x
  },
  {
    url:mahindra,
    url2x:mahindra2x
  },
  {
    url:mastek,
    url2x:mastek2x
  },
  {
    url:military,
    url2x:military2x
  },
  {
    url:piramal,
    url2x:piramal2x
  },
  {
    url:precision,
    url2x:precision2x
  },
  {
    url:smart_point,
    url2x:smart_point2x
  },
  {
    url:tata_housing,
    url2x:tata_housing2x
  },
  {
    url:tata_motors,
    url2x:tata_motors2x
  },
  {
    url:unity,
    url2x:unity2x
  },
  {
    url:vikram_solar,
    url2x:vikram_solar2x
  },
]
const Carouselcon = ({ children, className }) => {
  return (
    <div className={`carousel ${className}`}>
      <div className="slider-track">
        {children}
       
      </div>
    </div>
  );
};


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const AshlokClient = () => {
  return (
    <>
    <div className=' bg-ashred flex items-center justify-center flex-col p-2'>
    <p className='  text-white text-3xl'>A few who chose the <span className='vollkorn-sc-semibold'>Ashlok</span> Way…....</p>
    
</div>
<div className=' '>
    
  
            {/* .  bg-white flex items-center justify-center flex-col  py-3}
            <img src={cleint} style={{width:'90%'}}
                    srcSet={`${cleint} 1x, ${cleint2x} 2x`}
                    alt="client"/> */}

                  
                
                  {/*  <div className="parent">
                  <Carousel
                  className="custom-carousel slider-track"
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={false}
                    draggable={true}
                    showDots={false}
                    infinite={true}
                    partialVisible={false}
                    dotListClass="custom-dot-list-style"
                    
                  >
                    {clientImageUrl.map((imageUrl, index) => {
                      return (
                        <div className="slider" key={index}>
                          <img src={imageUrl.url2x} 
                          alt="logos" 
                          />
                        {}

                        </div>
                      );
                    })}
                  </Carousel>
                    </div>
               
                  {/*
                   <InfiniteScrollCarousel images={clientImageUrl}/> 
                 
                 
                  <Infinitescroll images={clientImageUrl}/> 
                  <Scrollhorizontal/>*/}
                  <Infinitescrollnew images={clientImageUrl}/> 
                 
                 
                  
                  
        
</div>
</>
  )
}

export default AshlokClient