import React,{useState,useContext} from 'react'
import { useParams } from 'react-router-dom';
import { ProductContext } from './context/Productcontext';

import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu';
import Footer from './Footer'
import InstallationBanner from './InstallationBanner';
import Thordetailscontent from './Thordetailscontent';
import MetaTags from './MetaTags';

import Zeusdetailscontent from './Zeusdetailscontent';
import Airterminaldetailscontent from './Airterminaldetailscontent';
import Franklynpentacontent from './Franklynpentacontent';
import Spddetailscontent from './Spddetailscontent';
import Lightningaccessoriesdetails from './Lightningaccessoriesdetails';
import ProductsubMenu from './ProductsubMenu';
import LightningProductsSubMenu from './LightningProductsSubMenu';

import banner1 from './assets/images/thordetails/thor_page_banner.jpg';
import banner1_2x from  './assets/images/thordetails/thor_page_banner@2x.jpg';

import zeusban from './assets/images/zeusdetails/zeus_page_banner.jpg';
import zeusban2x from  './assets/images/zeusdetails/zeus_page_banner.jpg';

import airterm from './assets/images/airterminal/air_terminal_page_banner.jpg';
import airterm2x from  './assets/images/airterminal/air_terminal_page_banner@2x.jpg';

import frank from './assets/images/franklynpenta/franklyn_page_banner.jpg';
import frank2x from  './assets/images/franklynpenta/franklyn_page_banner@2x.jpg';
import exo from './assets/images/exothermic/exothermic_welding_kit_page_banner.jpg';
import exo2x from './assets/images/exothermic/exothermic_welding_kit_page_banner@2x.jpg';


import spdban from './assets/images/spd/spd_page_banner.jpg';
import spdban2x from  './assets/images/spd/spd_page_banner@2x.jpg';

import accban from './assets/images/alicepage/alice_page_banner.jpg';
import accban2x from  './assets/images/alicepage/alice_page_banner@2x.jpg';

import etest_ban from './assets/images/earthingtestlink/earthing-test-link_banner.jpg';
import etest_ban2x from  './assets/images/earthingtestlink/earthing-test-link_banner@2x.jpg';

import eqpot_busbar_ban from './assets/images/alicepage/equipotential-busbar.jpg';
import eqpot_busbar_ban2x from  './assets/images/alicepage/equipotential-busbar@2x.jpg';

import slprmast_ban from './assets/images/alicepage/slpr-mast.jpg';
import slprmast_ban2x from  './assets/images/alicepage/slpr-mast@2x.jpg';

import slpr_kit_ban from './assets/images/alicepage/SLPR-Accessories-Kit.jpg';
import slpr_kit_ban2x from  './assets/images/alicepage/SLPR-Accessories-Kit@2x.jpg';


import LightningAccessoriesSubMenu from './LightningAccessoriesSubMenu';
import Alicedigitaldetails from './Alicedigitaldetails';
import Earthingtestlinkdetails from './Earthingtestlinkdetails';
import Equipotentialbusbardetails from './Equipotentialbusbardetails';
import SlprMastdetails from './SlprMastdetails';
import SlprAccessories from './SlprAccessories';
import Exotermicweldingkitdetails from './Exotermicweldingkitdetails';








    
    const Lightningprotectionsystems = () => {
        //const { ipage } = useParams();
        const [selectedItem, setSelectedItem] = useState(null);
       // const [ipageValue, setIpageValue] = useState(ipage);
        const { selectedProduct,selectedLightacc } = useContext(ProductContext);

        const handleItemClick = (item) => {
            setSelectedItem(item);
            //setIpageValue(item);
            console.log("set new ipaf vale "+item);
            //onImageClick(item); // Call the function passed as prop with the clicked item
          };

          const t1=``

      return (
        <div>
         
            <PreHeader/>
            <Header/>            
            <Menu/>
            
            {selectedProduct=='thor'  ? (
                <>
            <InstallationBanner page="installation" banalt="earthing ban1" ashlokAfter={t1} ban1x={banner1} ban2x={banner1_2x}/>
            <ProductsubMenu/>
            <LightningProductsSubMenu onImageClick={handleItemClick }/>
            <Thordetailscontent/>
            </>
            ):(selectedProduct== 'zeus' )? (
                <>

            <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore=""  apostropheAfter="" ban1x={zeusban} ban2x={zeusban2x}/>
            <ProductsubMenu/>
            <LightningProductsSubMenu onImageClick={handleItemClick}/>
            <Zeusdetailscontent/>
            </>
            ):(selectedProduct== 'air' ) ? (
                <>
            <InstallationBanner page="installation" banalt="earthing ban1" ashlokAfter="" ban1x={airterm} ban2x={airterm2x}/>
            <ProductsubMenu/>
            <LightningProductsSubMenu onImageClick={handleItemClick}/>
            <Airterminaldetailscontent/>
            </>
            ): (selectedProduct== 'frank' )? (
                <>
            <InstallationBanner page="installation" banalt="earthing ban1" ashlokAfter="" ban1x={frank} ban2x={frank2x}/>
            <ProductsubMenu/>
            <LightningProductsSubMenu onImageClick={handleItemClick }/>
            <Franklynpentacontent/>
            </>
            ):(selectedProduct== 'exo_kit' )? (
                <>
            <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={exo} ban2x={exo2x}/>
            <ProductsubMenu/>
            <LightningProductsSubMenu onImageClick={handleItemClick }/>
            <Exotermicweldingkitdetails/>
            </>
            ):  (selectedProduct== 'access' )? (
                <> {selectedLightacc=='alice_digital' ? (
                    <>
                    <InstallationBanner page="installation" banalt="earthing ban1" ashlokBefore="" ashlokAfter="" ban1x={accban} ban2x={accban2x}/>
                    <ProductsubMenu/>
          
                     <LightningAccessoriesSubMenu onImageClick={handleItemClick }/>
                    <Alicedigitaldetails/>
                    </> ):selectedLightacc=='earth_test_link' ? (
                    <>
                    <InstallationBanner page="installation" banalt="earthing ban1" ashlokBefore="" ashlokAfter="" ban1x={etest_ban} ban2x={etest_ban2x}/>
                    <ProductsubMenu/>
          
                     <LightningAccessoriesSubMenu onImageClick={handleItemClick }/>
                        <Earthingtestlinkdetails/>
                    </> ):selectedLightacc=='equi_busbar' ? (
                    <>
                    <InstallationBanner page="installation" banalt="earthing ban1" ashlokBefore="" ashlokAfter="" ban1x={eqpot_busbar_ban} ban2x={eqpot_busbar_ban2x}/>
                    <ProductsubMenu/>
          
                     <LightningAccessoriesSubMenu onImageClick={handleItemClick }/>
                        <Equipotentialbusbardetails/>
                    </> ):selectedLightacc=='slpr_mast' ? (
                    <>
                    <InstallationBanner page="installation" banalt="earthing ban1" ashlokBefore="" ashlokAfter="" ban1x={slprmast_ban} ban2x={slprmast_ban2x}/>
                    <ProductsubMenu/>
          
                     <LightningAccessoriesSubMenu onImageClick={handleItemClick }/>
                        <SlprMastdetails/>
                    </> ):selectedLightacc=='slpr_acc' ? (
                    <>
                    <InstallationBanner page="installation" banalt="earthing ban1" ashlokBefore="" ashlokAfter="" ban1x={slpr_kit_ban} ban2x={slpr_kit_ban2x}/>
                    <ProductsubMenu/>
          
                     <LightningAccessoriesSubMenu onImageClick={handleItemClick }/>
                        <SlprAccessories/>
                    </> ):
                    (
                         <>
                         <InstallationBanner page="installation" banalt="earthing ban1" ashlokBefore="" ashlokAfter="" ban1x={accban} ban2x={accban2x}/>
                         <ProductsubMenu/>
               
                          <LightningAccessoriesSubMenu onImageClick={handleItemClick }/>
                         <Alicedigitaldetails/>
                         </> 
                    )}
            </>
            ): (selectedProduct== 'spd' )? (
                <>
            <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={spdban} ban2x={spdban2x}/>
            <ProductsubMenu/>
            <LightningProductsSubMenu onImageClick={handleItemClick}/>
            <Spddetailscontent/>
            </>
            ):(<>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="LIGHTNING PROTECTION SYSTEM" ban1x={banner1} ban2x={banner1_2x}/>
                <ProductsubMenu/>
                <LightningProductsSubMenu onImageClick={handleItemClick}/>
            <Thordetailscontent/>
            </>
            )}
            <div>
            </div>
            <Footer/>
        </div>
      )
    }
    
    export default Lightningprotectionsystems
    