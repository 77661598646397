import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Smooth scrolling animation
  });
};

const MenuItem = ({ item }) => {
  const [submenuWidth, setSubmenuWidth] = useState(null);


  const handleMouseEnter = () => {
    const menuItemWidth = document.querySelector('.menu-item').offsetWidth;
    setSubmenuWidth(menuItemWidth);
  };
  const handleClick = () => {
    scrollToTop();
  };
return (
  <li className="group relative menu-item "  onMouseEnter={handleMouseEnter}>
    {item.subMenu ? (
      <div className="sub-menu-item" >
        <div className="flex items-center px-5 py-2 block  hover:bg-ashred2 hover:text-white"  style={{ width: item.subMenu ? 'auto' : item.width }}>
          <span className="hover:text-white flex items-center">{item.text}</span>
          {/* item.icon && <img  src={process.env.PUBLIC_URL + item.icon} alt={`${item.text} icon`} className="ml-2" /> */}
        </div>
      </div>
    ) : (
      <Link to={item.link} className="menu-item-link" onClick={handleClick}>
        <div className="mx-auto flex items-center justify-start px-5 py-2 hover:bg-ashred2 hover:text-white"  style={{ width: item.subMenu ? 'auto' : item.width }}>
          <span className="hover:text-white">{item.text}</span>
          {/*item.icon && <img src={process.env.PUBLIC_URL +item.icon} alt={`${item.text} icon`} className="ml-2" />*/}
        </div>
      </Link>
    )}  
    {item.subMenu && <SubMenu items={item.subMenu} />}
  </li>
);

    };

const SubMenu = ({ items,item }) => (
  <ul className="absolute hidden sub-menu group-hover:block  bg-white text-black z-50 text-sm">
    {items.map((subItem, index) => (
      <MenuItem key={index} item={subItem}/>
    ))}
  </ul>
);

const Menu = ({ isHeaderVisible }) => {
  const [menuData, setMenuData] = useState({});
  const [isSticky, setSticky] = useState(false);
  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch(process.env.PUBLIC_URL +'/data/menu.json')
        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenuData();
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 0) {
      setSticky(true);
    } else {
      setSticky(true);
    }
   // scrollToTop();

  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    {/*<div className={`bg-ashred min-h-3.5 ${isSticky ? 'sticky top-44 z-50' : ''}`}>*/}
    <div className={` bg-ashred min-h-3.5 ${isHeaderVisible ? 'sticky top-8 z-50' : 'mt-1'}`}>
      <nav className="container mx-auto max-w-screen-xl bg-ashred text-white menu-hover:bg-menu-hover">
        <ul className="flex justify-center items-center">
          {menuData.menuItems &&
            menuData.menuItems.map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
        </ul>
      </nav>
    </div>
    </>
  );
};

export default Menu;
{/*}
const MenuItem = ({ item }) => (
  <li className="group relative hover:bg-ashred">
    {item.subMenu ? (
      <div className="group-hover:bg-ashgray2 block ">
      <div className="flex items-center px-4 py-2 block hover:bg-ashred" style={{ width: item.width }}>
        <span>{item.text}</span>
        {item.icon && <img src={item.icon} alt={`${item.text} icon`} className="ml-2" />}
      </div>
      </div>
    ) : (
      <a href={item.link} className="group-hover:bg-ashgray2 block ">
        <div className="flex items-center px-4 py-2" style={{ width: item.width }}>
          <span>{item.text}</span>
          {item.icon && <img src={item.icon} alt={`${item.text} icon`} className="ml-2" />}
        </div>
      </a>
    )}  
    {item.subMenu && <SubMenu items={item.subMenu} />}
  </li>
);

const SubMenu = ({ items }) => (
  <ul className="absolute hidden group-hover:block ml-4">
    {items.map((subItem, index) => (
      <MenuItem key={index} item={subItem} />
    ))}
  </ul>
);

const Menu = () => {
  const [menuData, setMenuData] = useState({});

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch('/menu.json')
        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenuData();
  }, []);

  return (
    <div className=' bg-ashred min-h-3.5'>
    <nav className=" container  mx-auto max-w-screen-lg bg-ashred text-white">
      <ul className="flex" >
        {menuData.menuItems &&
          menuData.menuItems.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
      </ul>
    </nav>
    </div>
  );
};
export default Menu */}
