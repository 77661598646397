import React,{useEffect,useState} from 'react'

import linewarrow from './assets/images/heritage/line_with_arrow_icon.svg';

const HeritageLine = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Fetch the JSON file from the public/data folder
    fetch('/data/heritage_old.json')
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array ensures this effect runs only once

  const formatWord = (text, wordToFormat, className) => {
    const regex = new RegExp(`(${wordToFormat})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? <span key={index} className={className}>{part}</span> : part
    );
  };

  if (!data) {
    return <div>Loading...</div>; // Render a loading state while data is being fetched
  }
  return (
    <div className=' bg-ashgray1 pt-10'>
    <div className=' container mx-auto max-w-screen-xl flex  flex-col  pb-14  justify-center items-center'> 
        
    {data.map((item, index) => (
          <div key={index} className='flex  flex-row'>
            <div className='flex basis-1/4 m-3 items-center'>
                <div>
                  {item.image2x ? (
                      <img src={item.image} 
                      srcSet={`${item.image} 1x, ${item.image2x} 2x`}
                      alt={`Image ${item.imgalt}`}/>
                      ) : (
                        <img src={item.image} 
                      alt={`Image ${index + 1}`}/>
                      )}
                </div>
                <div className='relative'>
                    <img src={linewarrow} alt="25img"/>
                    {item.year &&  <p className='absolute top-0 left-10 text-xl font-bold'> {item.year}</p>}
                </div>
                
            </div>
            <div className='flex basis-3/4 flex-col justify-center'>
               <div className='flex basis-5/6 flex-col'>
                  {item.title && <p className='text-xl text-ashred  font-bold mt-5 mb-5' style={{ fontFamily: 'cinzel, sans-serif' }}>{formatWord(item.title,'ashlok','vollkorn-sc-semibold')}</p>}
                  {item.content && <p className=' text-justify'>
                  {formatWord(item.content,'ashlok','vollkorn-sc-semibold')}
                  </p>}
                </div>
            </div>
          </div>
         ))}
    </div>
    </div>
  )
}

export default HeritageLine
