import React,{useState,useContext} from 'react'
import { ProductContext } from './context/Productcontext';
import { useNavigate } from 'react-router-dom';
import thor_small from './assets/images/thordetails/thor_small.png';
import thor_small2x from './assets/images/thordetails/thor_small@2x.png';

import zeus_small from './assets/images/thordetails/zeus_small.png';
import zeus_small2x from './assets/images/thordetails/zeus_small@2x.png';

import airterm_small from './assets/images/thordetails/air_terminal_small.png';
import airterm_small2x from './assets/images/thordetails/air_terminal_small@2x.png';

import frank_small from './assets/images/thordetails/frankyln_small.png';
import frank_small2x from './assets/images/thordetails/frankyln_small@2x.png';

import exo_small from './assets/images/thordetails/exothermic_small.png';
import exo_small2x from './assets/images/thordetails/exothermic_small@2x.png';

import spd_small from './assets/images/thordetails/spd_small.png';
import spd_small2x from './assets/images/thordetails/spd_small@2x.png';

import light_acc_small from './assets/images/thordetails/lightning_accessories_small.png';
import light_acc_small2x from './assets/images/thordetails/lightning_accessories_small@2x.png';










    const LightningProductsSubMenu = ({ onImageClick }) => {
        let navigate = useNavigate();
        const { selectedProduct, setProduct } = useContext(ProductContext);
        const [selectedItem, setSelectedItem] = useState(selectedProduct);

        const handleItemClick = (item) => {
            setSelectedItem(item);
            setProduct(item);
            navigate('/lps');
           // onImageClick(item); // Call the function passed as prop with the clicked item
          };
      return (
       <div className=' w-full flex '>
            <div className=' container mx-auto flex justify-center items-end'>
                <div className={`w-1/5 h-18 flex flex-col justify-end  items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer `} onClick={() => handleItemClick('thor')}>
              
                    <div  className='m-4 h-15 flex items-center'><img src={thor_small} alt="thor ico"/></div>
                    <div  className=''><p className={` text-base font-semibold ${selectedItem === 'thor' ?  ' text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }} ><span className='vollkorn-sc-semibold'>Thor® SLPR</span></p><div className='vollkorn-sc-semibold text-ashgray2 h-6'></div></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`} onClick={() => handleItemClick('zeus')}>
                    <div  className='m-4 h-15 flex items-center'><img src={zeus_small} alt="zeud ico"/></div>
                    <div  className=''><p className={` text-base  font-semibold ${selectedItem === 'zeus' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }} ><span className='vollkorn-sc-semibold'>Zeus® SLPR</span></p><div className='vollkorn-sc-semibold text-ashgray2 h-6'></div></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`} onClick={() => handleItemClick('air')}>
                    <div  className='m-4 h-15 flex items-center'><img src={airterm_small} alt="ele ico"/></div>
                    <div  className=''><p className={` text-base  font-semibold ${selectedItem === 'air' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }} ><span className='vollkorn-sc-semibold'>Air Terminal</span></p><div className='vollkorn-sc-semibold text-white h-6 '></div></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`} onClick={() => handleItemClick('frank')}>
                    <div  className='m-4 h-15 flex items-center'><img src={frank_small} alt="bfc ico"/></div>
                    <div  className=''><div className={` text-base  font-semibold ${selectedItem === 'frank' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }} ><div className='vollkorn-sc-semibold'>Franklyn</div><div className='vollkorn-sc-semibold'> Penta</div></div></div>
                    
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`} onClick={() => handleItemClick('exo_kit')}>
                    <div  className='m-4 h-15 flex items-center'><img src={exo_small} alt="epcc ico"/></div>
                    <div  className=''><div className={` text-base font-semibold ${selectedItem === 'exo_kit' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }} ><div className='vollkorn-sc-semibold'>Exothermic </div><div className='vollkorn-sc-semibold'>Welding KIT</div></div></div>
                </div>
                
                <div className={`w-1/5 h-18 flex flex-col items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointe`} onClick={() => handleItemClick('spd')}>
                    <div  className='m-4 h-15 flex  items-start justify-start'><img src={spd_small} alt="epcc ico"/></div>
                    <div  className=' justify-center items-center'><p className={`   justify-center items-center text-base font-semibold ${selectedItem === 'spd' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }} ><span className='  text-ashgray2'></span><span className='vollkorn-sc-semibold items-center'>SPD</span></p><div className='vollkorn-sc-semibold h-6'></div></div>
                </div>
                <div className={`w-1/5 h-18 flex flex-col justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`} onClick={() => handleItemClick('access')}>
                    <div  className='m-4 h-15 flex items-center'><img src={light_acc_small} alt="epcc ico"/></div>
                    <div  className=''><div className={` text-base font-semibold ${selectedItem === 'access' ? 'text-ashred' : ''}`} style={{ fontFamily: 'Rajdhani, sans-serif' }} ><div className='vollkorn-sc-semibold'>Lightning</div> <div className='vollkorn-sc-semibold'>Accessories</div></div></div>
                </div>

            </div>
          
        </div>
      )
    }
    
    export default LightningProductsSubMenu
    