    import React,{useState} from 'react'
   
import DesignCommissionContent from './DesignCommissionContent';
import DesignCommissionBanner from './DesignCommissionBanner';
import banner1 from './assets/images/designcommission/design_commission_banner.jpg';
import banner1_2x from  './assets/images/designcommission/design_commission_banner@2x.jpg';
import Footer from './Footer';
import MetaTags from './MetaTags';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import { Helmet  } from 'react-helmet';

    const DesignCommissionPage = () => {
      const [isSticky, setSticky] = useState(true);
      const displline=true;
      return (
       <div>
          <Helmet>
        <title>Expert Design & Commissioning Services by Ashlok for Optimal Safety</title>
        <meta name="description" content="At Ashlok, we specialize in the meticulous design and commissioning of Earthing and Lightning Protection systems across India. Our services ensure tailored solutions that meet the specific needs of diverse sectors, providing maximum safety and efficiency. Discover how our expertise in design and commissioning sets new standards in electrical protection." />

      </Helmet>
            <PreHeader/>
            <Header isSticky={isSticky}/>
            <Menu isHeaderVisible={isSticky}/>
            <DesignCommissionBanner page="Design commision" banalt="Design commision ban1" bannertext="DESIGN & COMMISSIONING SERVICES" ban1x={banner1} ban2x={banner1_2x}/>
            <DesignCommissionContent/> 
            <Footer linedisp={displline}/>
       </div>
      )
    }
    
    export default DesignCommissionPage
    