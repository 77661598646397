import React from 'react'
import limg from './assets/images/our_services/Lightning_install_image.png';
import limg2x from './assets/images/our_services/Lightning_install_image2x.png';

import bp from './assets/images/our_services/base_plate.png';
import bp2x from './assets/images/our_services/base_plate2x.png';
 const ESEinstall = ()=> {
  return (
    <div className="flex">
        <div className="text-left text-base ">
            <h1 className=' text-ashred font-bold'>Mast for Level Concrete Surface with Additional Options of Plus, Square, and Circular Base Plates | Installation Guidelines:</h1>
            <div className='flex justify-start  items-start'>
                <div className=' w-4/5 justify-start'>
                    <ul className="list-disc pl-5 text-base text-justify">
                        <li > Choose the installation point according to the lightning protection system’s design</li>
                        <li>Fasten the level base plate of the mast onto the chosen flat cement surface using the expansion bolts supplied in the accessory package.</li>
                        <li>Hammer the expansion bolts directly onto the flat cement surface.</li>
                        <li>Assemble the THOR® SLPR before inserting the mast pipe into the secure flat base plate. This assembly involves attaching the THOR® SLPR atop the mast using the Mast coupler. This is accomplished by joining the provided external and internal threading</li>
                        <li>THOR® SLPR should be connected to the down-conductor through copper lugs and the LN Bolt included in the accessories package. The down-conductor should be fastened every 1 meter along the length of the mast pipe using cable ties</li>
                        <li>If the provided base plate is a Plus Base plate or Square Base plate, insert the mast pipe into the secured flat base plate.</li>
                        <li>If the provided base plate is a welded Circular Base plate or Square Base plate, secure the mast onto the chosen flat cement surface using the expansion bolts provided in the accessories package</li>
                    </ul>
                </div>
                <div className='w-1/5'>
                    <div className=' flex justify-end items-end'>
                        <img className='  ' src={bp}  srcSet={`${bp} 1x,${bp2x} 2x`} alt="Base plate installation"
                                                /> 
                    </div>
           
                </div>
            </div>
            <div className=' h-4'></div>
            <div className='flex '>
                <div className=' w-2/5'>
                <img src={limg}  
                                                srcSet={`${limg} 1x,${limg2x} 2x`} alt="Lightning installation"
                                                /> 
                </div>
                <div className=' w-3/5'>
                    <h1 className=' text-ashred font-bold'>Mast with Side Clamping Base Plate | Installation Guidelines:</h1>
                    <ul className="list-disc pl-5 text-base">
                        <li className=' text-justify'>Choose the installation location based on the lightning protection system’s design</li>
                        <li className=' text-justify'>Affix the side clamping base plate of the mast onto the designated side surface using the expansion bolts provided in the accessory kit</li>
                        <li className=' text-justify'>Prior to inserting the mast pipe into the secured side clamping base plate, pre-assemble the THOR® SLPR. This involves connecting the THOR® SLPR to the top of the mast using the Mast coupler.</li>
                        <li className=' text-justify'>Connect the THOR® SLPR to the down-conductor using copper lugs and the LN Bolt supplied in the accessories kit. The down-conductor should be securely fastened every meter along the pipe’s length using cable ties.</li>
                        <li className=' text-justify'>Once the pre-assembly is complete, insert the mast pipe into the firmly secured side clamping base plate</li>
                        <li className=' text-justify'>Additionally, two clamps are included with the side clamping base plate. These clamps need to be installed above the side clamping base plate using the expansion bolts provided in the accessories kit for additional support</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}
export default ESEinstall;
