import React,{useState} from 'react'

import mast from './assets/images/slprmast/mast_big.png';
import mast2x from './assets/images/slprmast/mast_big@2x.png';
import boltico from './assets/images/thordetails/bolt_icon.svg';
import LightningProductsSubMenu from './LightningProductsSubMenu';
import "./parafont.css"
    

    const SlprMastdetails = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
           
            <div className='w-full my-3 flex justify-center items-center'>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={mast}  
                                                srcSet={`${mast} 1x,${mast2x} 2x`} alt=" mast logo"
                                                /> 
                        </div>
                       
                    </div>
                    {/*                     
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred fon stitle'><span className='vollkorn-sc-semibold'>Ashlok’s SLPR Mast</span></p>
                        <p className=' p-common text-base text-justify '>At Ashlok, we understand that a robust lightning protection system is built on more than just lightning rods and grounding electrodes. That’s why we offer a comprehensive range of Lightning Protection Accessories designed to enhance the efficiency and effectiveness of your lightning protection system. Our accessories are meticulously crafted to ensure the utmost safety and security for your property, assets, and people.</p>
                        <p className=' text-xl font-bold mt-2'>Explore our Lightning Protection Accessories</p>
                        <p className=' text-base text-ashred my-1 font-bold'>Ashlok’s SLPR Mast | A.L.I.C.E Digital | Earthing Test Link | Equipotential Busbar | SLPR Accessories Kit</p>
                        <p className=' p-common text-base text-justify'>Trust Ashlok for all your lightning protection accessory needs. With our commitment to quality and safety, you can count on our accessories to bolster the performance of your lightning protection system. Your safety is our priority, and Ashlok Lightning Protection Accessories are here to secure your world.</p>
                    </div>
                     */}
                      <div className='flex flex-col items-start'>
                      <p className=' text-2xl font-bold text-ashred'><span className='vollkorn-sc-semibold'>Ashlok’s SLPR Mast</span></p>
                            <p className=' p-common text-base  '>Engineered for the installation of ASHLOK’S THOR® Safe Lightning Protection Rod, this design is tailored to endure all weather situations. It is envisioned as a self-supporting mast, eliminating the necessity for supplementary guy wires.</p>
             
                      </div>
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                  <p className=' text-1xl font-bold text-ashred'></p>
                    <div className='flex py-1'>
                        
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Available in 4.5m or 6m lengths</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Includes Mast Coupler, Mast, and Base Plate. </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> The Mast Coupler, composed of high-quality dielectric material, is UV-resistant.</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>The Base Plate can be anchored using either foundation bolts or expansion bolts</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>The Mast bracket is subjected to hot dip galvanization for durability. </li>

                                             </ul>
                                             <p className=' text-1xl font-bold text-ashred my-1'>DOWN CONDUCTOR</p>
                            <ul className="list-none">
                            
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>70 sq mm Single Core Copper Flexible Cable with PVC Insulation (or) </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Copper or Galvanized Iron (GI) Strip.</li>
                           
                            </ul>           
                        </div>
                        <div className='w-1/2'>
                            {/*
                        <p className=' text-1xl font-bold text-ashred mt-2 mb-2'>DOWN CONDUCTOR</p>
                            <ul className="list-none">
                            
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pr-2 my-2"/>70 sq mm Single Core Copper Flexible Cable with PVC Insulation (or) </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pr-2 my-2"/>Copper </li>
                           
        </ul>                   */}
                        </div>
                    </div>
                </div>
            </div>
            
            </>
          )
    }
    
    export default SlprMastdetails
    