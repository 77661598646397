import React,{useState,forwardRef} from 'react'
import SoiltestContent from './SoiltestContent'
import banner1 from './assets/images/soiltest/soil_testing_banner.jpg';
import banner1_2x from  './assets/images/soiltest/soil_testing_banner@2x.jpg';
import MetaTags from './MetaTags';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import SoiltestBanner from './SoiltestBanner';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
const SoiltestPage = forwardRef((props, ref) => {
  const [isSticky, setSticky] = useState(true);
  const displline=true;
  return (
    <>
    {/*
     <div className=' bg-ashred flex items-center justify-center flex-col p-2'>
    <p className='  text-white text-3xl'>SOIL TESTING SERVICES</p>
    
    </div>*/}
    <div>
      {/*
      <Helmet>
        <title>Comprehensive Soil Testing Services by Ashlok for Optimal Earthing Solutions</title>
        <meta name="description" content="Rely on Ashlok's expert Soil Testing Services to ensure the effectiveness of your Earthing and Lightning Protection Systems across India. Our detailed soil analysis is crucial for designing systems that meet specific environmental requirements, providing safety and efficiency in any sector. Learn how our soil testing forms the foundation of reliable electrical safety solutions." />

      </Helmet>
       <PreHeader/>
       <Header isSticky={isSticky}/>
    <Menu isHeaderVisible={isSticky}/>
    <SoiltestBanner page="soiltest" banalt="earthing ban1" bannertext="SOIL TESTING SERVICES" ban1x={banner1} ban2x={banner1_2x}/>
    */} 
    <SoiltestContent/>
    {/*
      <Footer linedisp={displline}/> */}
    </div>
    </>
  )
});

export default SoiltestPage
