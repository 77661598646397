import React,{useState,forwardRef} from 'react'
import appimg from './assets/images/our_services/application_image.png';
import appimg2x from './assets/images/our_services/application_image2x.png';
import CLPInstall from './CLPInstall';
import EarthingInstall from './EarthingInstall';
import ESEinstall from './ESEinstall';
import SoiltestPage from './SoiltestPage';



const Installation = forwardRef((props, ref)=> {
    const [selectedContent, setSelectedContent] = useState('Content 1'); // Default content
    const [activeButton, setActiveButton] = useState('Button 1');
  const contentData = {
    'Content 1': <ESEinstall/>,
    'Content 2': <EarthingInstall/>,
    'Content 3': <CLPInstall/>,
    'Content 4': <SoiltestPage/>
  };

  const handleButtonClick = (content, button) => {
    setSelectedContent(content);
    setActiveButton(button);
  };

  const getParentDivClassName = (button) => {
    return activeButton === button ? 'bg-ashblack p-5 text-white flex text-2xl' : 'p-5 text-white flex text-2xl';
  };

  return (
    <div className="container mx-auto flex max-w-screen-xl justify-center">
    <div className="left-column w-2/5 mb-4 border-r bg-ashred">
      <div className="top-section mb-4">
        <div className={getParentDivClassName('Button 1')}>
            <button onClick={() => handleButtonClick('Content 1', 'Button 1')}  className={` text-white py-2 px-4 rounded`}>E.S.E INSTALLATION</button>
        </div>
        <div className={getParentDivClassName('Button 2')}>
        <button onClick={() => handleButtonClick('Content 2', 'Button 2')}  className={` text-white py-2 px-4 rounded`}>EARTHING INSTALLATION</button>
        </div>
        <div className={getParentDivClassName('Button 3')}>
        <button onClick={() => handleButtonClick('Content 3', 'Button 3')}  className={` text-white py-2 px-4 rounded`}>CLP INSTALLATION</button>
        </div>
        <div className={getParentDivClassName('Button 4')}>
        <button onClick={() => handleButtonClick('Content 4', 'Button 4')}  className={` text-white py-2 px-4 rounded`}>Soil Testing Services</button>
        </div>
      </div>
      <div className='h-40'></div>
      <div className="bottom-section mt-4 p-4">
        <img   src={appimg} alt="thor img"
                        srcSet={`${appimg} 1x, ${appimg2x}2x`} className="w-full" />
      </div>
    </div>

    <div className="right-column w-3/5 pl-4 pb-4 pt-4">
      {contentData[selectedContent]}
    </div>
  </div>
  )
});
export default Installation