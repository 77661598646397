import React,{useEffect} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import selfstand from './assets/images/clppage/self_standing.png';
import selfstand2x from './assets/images/clppage/self_standing@2x.png';
import selfclamp from './assets/images/clppage/self_clamping.png';
import selfclamp2x from './assets/images/clppage/self_clamping@2x.png';

import con_base from './assets/images/clppage/concrete_base.png';
import con_base2x from './assets/images/clppage/concrete_base@2x.png';

import con_base_spoke_bracket from './assets/images/clppage/concrete_base_spoke_bracket.png';
import con_base_spoke_bracket2x from './assets/images/clppage/concrete_base_spoke_bracket@2x.png';
import con_base_bracket from './assets/images/clppage/concrete_base_bracket.png';
import con_base_bracket2x from './assets/images/clppage/concrete_base_bracket@2x.png';

import full_bracket from './assets/images/clppage/full_bracket.png';
import full_bracket2x from './assets/images/clppage/full_bracket@2x.png';

import light_rod from './assets/images/clppage/lightning_rod_base_plate.png';
import light_rod2x from './assets/images/clppage/lightning_rod_base_plate@2x.png';

import con_al from './assets/images/clppage/conductor_al.png';
import con_al2x from './assets/images/clppage/conductor_al@2x.png';
import mul_str from './assets/images/clppage/multi_strand_sonductor.png';
import mul_str2x from './assets/images/clppage/multi_strand_sonductor@2x.png';


import roof from './assets/images/clppage/roof_conductor_holder.png';
import roof2x from './assets/images/clppage/roof_conductor_holder@2x.png';
import para from './assets/images/clppage/parapet_conductor_holder.png';
import para2x from './assets/images/clppage/parapet_conductor_holder@2x.png';

import edgehol from './assets/images/clppage/edge_conductor_holder.png';
import edgehol2x from './assets/images/clppage/edge_conductor_holder@2x.png';
import rodcross_ss from './assets/images/clppage/rod_cross_connector_ss.png';
import rodcross_ss2x from './assets/images/clppage/rod_cross_connector_ss@2x.png';


import rodstrip from './assets/images/clppage/rod_to_strip_cross.png';
import rodstrip2x from './assets/images/clppage/rod_to_strip_cross@2x.png';
import rodcross from './assets/images/clppage/rod_cross_connector.png';
import rodcross2x from './assets/images/clppage/rod_cross_connector@2x.png';

import rodparaconn from './assets/images/clppage/rod_parallerl_connector.png';
import rodparaconn2x from './assets/images/clppage/rod_parallerl_connector@2x.png';
import linsleeve from './assets/images/clppage/linear_sleeve.png';
import linsleeve2x from './assets/images/clppage/linear_sleeve@2x.png';

import exp_pece from './assets/images/clppage/expansion_piece.png';
import exp_pece2x from './assets/images/clppage/expansion_piece@2x.png';

import bridgcab from './assets/images/clppage/briding_cable.png';
import bridgcab2x from './assets/images/clppage/briding_cable@2x.png';
import clampshoe from './assets/images/clppage/clamping_shoe.png';
import clampshoe2x from './assets/images/clppage/clamping_shoe@2x.png';


import test_jo from './assets/images/clppage/test_joint.png';
import test_jo2x from './assets/images/clppage/test_joint@2x.png';
import t_strip from './assets/images/clppage/t_strip_holder.png';
import t_strip2x from './assets/images/clppage/t_strip_holder@2x.png';

import trod_hold from './assets/images/clppage/t_rod_holder.png';
import trod_hold2x from './assets/images/clppage/t_rod_holder@2x.png';
import eart_conn from './assets/images/clppage/copper_bonded_earth_conductor.png';
import eart_conn2x from './assets/images/clppage/copper_bonded_earth_conductor@2x.png';

import strip_cond from './assets/images/clppage/strip_conductor.png';
import strip_cond2x from './assets/images/clppage/strip_conductor@2x.png';
import strip_cross from './assets/images/clppage/strip_cross_connector.png';
import strip_cross2x from './assets/images/clppage/strip_cross_connector@2x.png';

import pr_tape from './assets/images/clppage/corrosion_tape.png';
import pr_tape2x from './assets/images/clppage/corrosion_tape@2x.png';
import pvc_sleeve from './assets/images/clppage/pvc_sleeve.png';
import pvc_sleeve2x from './assets/images/clppage/pvc_sleeve@2x.png';
import ubolt_clamp from './assets/images/clppage/u_bolt_clamp.png';
import ubolt_clamp2x from './assets/images/clppage/u_bolt_clamp@2x.png';
import ear_clamp from './assets/images/clppage/flat_clamp.png';
import ear_clamp2x from './assets/images/clppage/flat_clamp@2x.png';

import eq_bond from './assets/images/clppage/equipotential_bonding_link.png';
import eq_bond2x from './assets/images/clppage/equipotential_bonding_link@2x.png';
import test_link from './assets/images/clppage/test_link.png';
import test_link2x from './assets/images/clppage/test_link@2x.png';

import zbase_hold from './assets/images/clppage/holder_z_base.png';
import zbase_hold2x from './assets/images/clppage/holder_z_base@2x.png';
import flatbase_hold from './assets/images/clppage/holder_flat_base.png';
import flatbase_hold2x from './assets/images/clppage/holder_flat_base@2x.png';
import metcond_hold from './assets/images/clppage/metal_conductor_holder.png';
import metcond_hold2x from './assets/images/clppage/metal_conductor_holder@2x.png';

import strip from './assets/images/clppage/strip.svg';

{/*
    https://xd.adobe.com/view/20caf0de-d842-4896-862f-07b1c0dba6dd-7599/specs/ */}

const CLPPagecontent=()=> {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scrolling animation
        });
      };
      useEffect(() => {
        scrollToTop();
      }, []);
    

    return (
        <div className='w-full flex items-center'>
        <div className='container mx-auto max-w-screen-xl flex flex-col  items-center my-5'>
            <div className='flex flex-col'>
                <p className='flex text-ashred text-4xl justify-center vollkorn-sc-semibold'>Conventional Lightning Protection</p>
                <p className=' items-start'><span className='vollkorn-sc-semibold'>Ashlok’s</span> Conventional Lightning Protection (CLP) System employs two key approaches to comprehensively safeguard structures. The internal segment of the CLP System typically encompasses an array of Surge Protective Devices.</p>
                <div className=' h-4'></div>
                <p className=' items-start'>External Conventional Lightning Protection Systems serve as the primary interception points for direct lightning strikes. These systems are based on a blend of methods including the Rolling Sphere, Angular Method, and Faraday Cage. This intricate amalgamation facilitates the secure interception, conduction, and swift dissipation of lightning current into the ground during thunderstorms.</p>
                <div className=' h-4'></div>
                <p className=' items-start'><span className='vollkorn-sc-semibold'>Ashlok’s</span> CLP Systems are meticulously crafted to ensure the synergy of their components, which consist of Lightning Rods, Conductors, Fasteners, Earthing, and BackFill Compounds. This synergy works harmoniously to guarantee the safety of the structure</p>
                <div className=' h-4'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                                <p className=' text-white text-xl justify-start w-full pl-2'>Lightning Rod | Self Standing</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Intercepting direct lightning strikes</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Built with exceptional stability and robustness, featuring a self-supporting base plate</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Comprising a vertical lightning rod, clamp, frost-resistant 16 kg concrete block, and bracket</li>
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Tested and approved by CPRI</li>
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Material - AL</li>
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Product code : ASEEL CLP LR | C | CB | FB</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                    <img className=' h-[85%]' src={selfstand}  
                                                srcSet={`${selfstand} 1x,${selfstand2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-start'>SELF STANDING</p>        
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                    <p className='  text-white text-xl justify-start pl-2'>Lightning Rod | Side Clamping</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Intercepting direct lightning strikes</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Featuring a robust and durable design with a side-clamping base.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Comprising a vertical lightning rod with a side base, clamp, and fasteners</li>
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 "/>The system can alternatively be affixed to a metal surface using Industrial Adhesive</li>
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Tested and approved by CPRI.</li>
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Material - AL.</li>
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Product code : ASEEL CLP LR | SB.</li>
                                    </ul>
                                </div>
                                <div className='flex  flex-col w-2/6 items-center'>
                                    <img className=' h-[85%]' src={selfclamp}  
                                                srcSet={`${selfclamp} 1x,${selfclamp2x} 2x`} alt=" self clamp logo"
                                                /> 
                                    <p className=' text-xs justify-start items-center'>SIDE CLAMPING</p>

                                </div>
                        </div>
                    
                    
                    </div>

                

                </div>
                {/* second row */}

               
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                <p className=' text-white text-xl justify-start w-full pl-2'>Concrete Base</p>
                                </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Firm foundation for lightning conductor.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Constructed using concrete that withstands freezing conditions</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Total diameter measures 360 mm.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP CB</li>

                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='' src={con_base}  
                                                srcSet={`${con_base} 1x,${con_base2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>CONCRETE BASE</p>  
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                         
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                <p className=' text-white text-xl justify-start pl-2'>Concrete Base Bracket</p>
                                </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Bracket for safeguarding the edges of a 16 kg concrete base.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Crafted from PP substance.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Resistant to UV rays. | Material - PP</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP CBSB | CBFB</li>
                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                    <img className='items-start' src={con_base_spoke_bracket}  
                                                srcSet={`${con_base_spoke_bracket} 1x,${con_base_spoke_bracket2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>CONCRETE BASE SPOKE BRACKET</p>  
                                        <img className='items-start' src={full_bracket}  
                                                srcSet={`${full_bracket} 1x,${full_bracket2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>CONCRETE BASE FULL BRACKET</p>  
                                </div>
                        </div>
                    </div>
                </div>
               
                {/* Third row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white text-xl justify-start w-full pl-2'>Lightning Rod to Base Plate Clamp</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Link between lightning rod and conductor to base plate</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Conductor connection range: 8 - 10 mm in diameter</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Equipped with three stainless steel bolts.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - SS.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP LR2BPC SS</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={light_rod}  
                                                srcSet={`${light_rod} 1x,${light_rod2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <div className=' text-xs justify-start items-center'>LIGHTNING ROD TO <div>BASE PLATE CLAMP </div> </div>
                        
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                <p className=' text-white text-xl justify-start pl-2'>Conductor</p>
                                </div>
                                <p className='text-s justify-start pl-2'>The conductor system plays a crucial role in effectively transmitting lightning current from the point of impact to thegrounding system</p>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Dispersing lightning current.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Cross-sectional area of at least 50 sq. Mm</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Conductor dimensions: 8 mm and 10 mm.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Offered in either coil or linear configurations</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - AL | SS | GI</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP CR | ASEEL CLP MSC</li>
                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6  items-center'>
                                <img className='items-start justify-start' src={con_al}  
                                                srcSet={`${con_al2x} 1x,${con_al2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>CONDUCTOR </p>  
                                <img className='items-start' src={ mul_str}  
                                                srcSet={`${ mul_str2x} 1x,${ mul_str2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>Multi Strand Conductor</p>  
                        
                                </div>
                        </div>
                    </div>
                </div>
                {/* addeld last one */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start w-full'>Roof Conductor Holder</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Anchoring conductor onto a roof with corrugated metal.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Base crafted from stainless steel. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Installation options include hook attachment or industrial adhesive.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>UV resistant. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - PA+SS | SS</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP RCH ZB | ASEEL CLP RCH FB ASEEL CLP MCH SSS</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                    <img className='items-start' src={zbase_hold}  
                                                srcSet={`${zbase_hold} 1x,${zbase_hold2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center px-10'>ROOF CONDUCTOR HOLDER Z BASE</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex  w-1/2">
                                    <div className="flex flex-col w-1/6">
                                    </div>
                                <div className="flex flex-col  w-2/6">
                                <img className='items-start' src={flatbase_hold}  
                                                srcSet={`${flatbase_hold} 1x,${flatbase_hold2x} 2x`} alt=" self stand logo"
                                                /> 
                                <p className=' text-xs justify-start items-center px-10'>ROOF CONDUCTOR HOLDER FLAT BASE</p>  
                                </div>
                                <div className="flex flex-col w-1/6">
                                </div>
                                <div className="flex flex-col w-2/6">
                                    <img className='items-start' src={metcond_hold}  
                                                srcSet={`${metcond_hold} 1x,${metcond_hold2x} 2x`} alt=" self stand logo"
                                                /> 

                                    <p className=' text-xs justify-start items-center px-10'>METAL CONDUCTOR HOLDER</p>  
                                    </div> 
                         
                    </div>
                </div>

                {/* Fourth row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                <p className='  text-white pl-2 text-xl justify-start w-full'>Roof Conductor Holder with Concrete</p>
                                </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Anchoring conductor onto a level horizontal surface</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Exterior casing constructed from polyamide and packed with 1 kg of frost resistant concrete</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Accommodates conductor within the 8 - 10 mm range.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>UV resistant.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP RCH CPA</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={roof}  
                                                srcSet={`${roof} 1x,${roof2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>ROOF CONDUCTOR HOLDER WITH CONCRETE</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                <p className=' text-white pl-2 text-xl justify-start'>Parapet Conductor Holder</p>
                                </div>
                               
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Fastening the conductor onto a structural surface.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Equipped with an M6 female thread at the base for secure attachment.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Made of PA | UV resistant.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - PA</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP PCH</li>

                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={para}  
                                                srcSet={`${para} 1x,${para2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center px-10'>PARAPET CONDUCTOR HOLDER</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>

                {/* Fifth row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                     <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                            <p className=' text-white pl-2 text-xl justify-start w-full'>Edge Conductor Holder</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Anchoring conductor on vertical metal seam profile.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Ensures resilience against rust and offers robust contact.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Capable of securing materials with a thickness of up to 10 mm</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP ECH</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={edgehol}  
                                                srcSet={`${edgehol} 1x,${edgehol2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center pr-5'>ROOF CONDUCTOR HOLDER WITH CONCRETE</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start'>Rod Cross Connector</p>
                                    </div>
                                    <ul className="list-none pl-2 ">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Connector options including T, cross, and series configurations for linking rods.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Supplied with four stainless steel bolts.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Designed for connections with a diameter of 8 - 10 mm.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS | CU</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP RTR SQ</li>

                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={rodcross}  
                                                srcSet={`${rodcross_ss} 1x,${rodcross_ss2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>ROD CROSS CONNECTOR</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>
               
                {/* Sixth row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                         <p className=' text-white pl-2 text-xl justify-start w-full'>Rod To Strip Connector</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Connectors in T, cross, and series formats for attaching a rod to a strip.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Supplied with four stainless steel bolts.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Suitable for linking rods with a diameter of 8 - 10 mm to strips with a cross section of up to 30 x 6 mm.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS | CU</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP RTS SQ</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={rodstrip}  
                                                srcSet={`${rodstrip} 1x,${rodstrip2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center pr-5'>ROD TO STRIP CROSS CONNECTOR</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                     <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                            <p className=' text-white pl-2 text-xl justify-start'>Rod Cross Connector With Plate</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Connectors in T, cross, and series configurations for joining rods or strips to rods or strips. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Supplied with four stainless steel bolts.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Designed for connecting rods with a diameter of 8-10 mm to strips with a cross section of up to 30 x 6 mm</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS | CU</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP RTR SQP</li>

                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={rodcross}  
                                                srcSet={`${rodcross} 1x,${rodcross2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>ROD CROSS CONNECTOR WITH PLATE</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>

                {/* Seventh row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">

                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start w-full'>Rod Parallel Connector</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>T-shaped connection, along with parallel connection option.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Designed for connections with a diameter of 8 - 10 mm</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Connector of choice for expansion piece</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP RTR CT</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={rodparaconn}  
                                                srcSet={`${rodparaconn} 1x,${rodparaconn2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>ROD PARALLEL CONNECTOR</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                        <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                            <p className=' text-white pl-2 text-xl justify-start'>Linear Sleeve</p>
                                        </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Direct rod-to-rod linkage in a linear arrangement </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Supplied with four stainless steel bolts</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS | CU | AL</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP LS</li>


                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={linsleeve}  
                                                srcSet={`${linsleeve} 1x,${linsleeve2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>LINEAR SLEEVE</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>

                {/* Eight row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start w-full'>Expansion Piece</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Addressing the expansion and contraction of the conductor caused by temperature variations.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Utilizing a semi-rigid material.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Offered in both 8 mm and 10 mm diameter sizes</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Connection with the conductor can be achieved using either a swift connector or a linear sleeve.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - AL</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP EP</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={exp_pece}  
                                                srcSet={`${exp_pece} 1x,${exp_pece2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>EXPANSION PIECE</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                     <p className=' text-white pl-2 text-xl justify-start'>Bridging Cable With Clamping Shoe</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Establishing a bridging link between the conductor and adjacent metal. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>  Insulated cable with a cross-sectional area of 16 square millimeters, featuring copper lugs at both ends.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Utilization of a clamping shoe for the connection of the rod to the bridging cable.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | Insulated Copper Cable </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP BGC | ASEEL CLP CS </li>


                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                            <img className='items-start' src={bridgcab}  
                                                srcSet={`${bridgcab} 1x,${bridgcab2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>BRIDGING CABLE</p>  
                                        <img className='items-start' src={clampshoe}  
                                                srcSet={`${clampshoe} 1x,${clampshoe2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>CLAMPING SHOE</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>
             
                {/* Ninth row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start w-full'>Test Joint</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Testing junction clamp linking lightning and grounding conductors.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Connects a rod with a diameter of 8 - 10 mm to a strip with a cross section of up to 30 x 6 mm dia.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Equipped with four stainless steel bolts</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Designed for simple and rapid installation</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS | ABS</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP RTSR | ASEEL CLP TJ E101070</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                        <img className='items-start' src={test_jo}  
                                                srcSet={`${test_jo} 1x,${test_jo2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>ROD TO STRIP | ROD CONNECTOR</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                     <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start'>T-Strip Holder</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Anchoring a strip on either a vertical or horizontal surface.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>  Supplied with two stainless steel bolts.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Capable of securing strips with a cross section of up to 30 x 6 mm.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Includes a wall flake to facilitate swift and straightforward installation</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP TSH </li>


                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                        <img className='items-start' src={t_strip}  
                                                srcSet={`${t_strip} 1x,${t_strip2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>T-STRIP HOLDER</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>

           
                {/* Tenth row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                    <p className=' text-white pl-2 text-xl justify-start w-full'>T-Rod Holder</p>
                                </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Anchoring a conductor rod on a vertical or horizontal surface.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Supplied with two stainless steel bolts.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Capable of securing rods within the 8 - 10 mm diameter range.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Includes a wall flake to facilitate convenient and rapid installation.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP TRH</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                        <img className='items-start' src={trod_hold}  
                                                srcSet={`${trod_hold} 1x,${trod_hold2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>T-ROD HOLDER</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start'>Copper Bonded Earth Conductor</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Significant capacity for effective dissipation.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>  Exhibiting strong resistance to corrosion. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Offered in both coil and linear formats.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Flexibility for customization to align with project specifications.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - Copper Bonded Steel </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP CBC</li>


                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                        <img className='items-start' src={eart_conn}  
                                                srcSet={`${eart_conn} 1x,${eart_conn2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>COPPER BONDED EARTH CONDUCTOR</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>

                {/* Eleventh row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start w-full'>Strip Conductor</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Excellent capacity for efficient dissipation</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Exceptionally resistant to corrosion</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Customization is also available to match project specifications.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL STP</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                    <img className='items-start' src={strip_cond}  
                                                srcSet={`${strip_cond} 1x,${strip_cond2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>STRIP CONDUCTOR</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start'>Strip Cross Connector</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Strip connections available in T, Cross, and Series configurations.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>  Supplied with four stainless steel bolts. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP STS SQ</li>


                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                        <img className='items-start' src={strip_cross}  
                                                srcSet={`${strip_cross} 1x,${strip_cross2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>STRIP CROSS CONNECTOR</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>

                {/* Twelth row */}
                <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                     <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start w-full'>Protection Tape</p>
                                    </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Supplying resistance to corrosion and guarding against moisture</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Intended for use in buried connections.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>PVC sleeve designed to insulate the strip above ground level.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - PVC | Petrolatum*</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP PVC | ASEEL CLP CP</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>

                                            <img className='items-start' src={pr_tape}  
                                                srcSet={`${pr_tape} 1x,${pr_tape2x} 2x`} alt=" self stand logo"
                                                /> 
                                         <p className=' text-xs justify-start items-center'>CORROSION PROTECTION TAPE</p>  
                                         <img className='items-start' src={pvc_sleeve}  
                                                srcSet={`${pvc_sleeve} 1x,${pvc_sleeve2x} 2x`} alt=" self stand logo"
                                                /> 
                                         <p className=' text-xs justify-start items-center'>PVC SLEEVE</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                    <p className=' text-white pl-2 text-xl justify-start'>Earth Clamp</p>
                                </div>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Concluding the connection of the earth conductor to a copper bonded rod. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Equipped with mechanisms to firmly secure the connection to the conductor. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Exhibiting excellent conductivity and extended lifespan. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - Brass + Gun metal | SS</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL CLP CBR FC | U BOLT CLAMP</li>


                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                <img className='items-start' src={ubolt_clamp}  
                                                srcSet={`${ubolt_clamp} 1x,${ubolt_clamp2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>U BOLT CLAMP</p>  
                                        <img className='items-start' src={ear_clamp}  
                                                srcSet={`${ear_clamp} 1x,${ear_clamp2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>ASEEL FLAT CLAMP</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>

                 {/* Thirtenth row */}
                 <div className=' h-8'></div>
                <div className='flex w-full'>
                
                    <div className="flex flex-col w-1/2">
                      
                        
                        <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                        <p className=' text-white pl-2 text-xl justify-start w-full'>Equipotential Bonding Link</p>
                                    </div>
                                <p className=' text-base justify-start w-full pl-2'>Strips offer an effective and practical method for establishing equipotential bonding among metallic elements. A disconnecting link is included to enable the opening and closing of the connection.</p>
                                    <ul className="list-none pl-2">
                                        <li className="flex items-center p-common "><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Establishing equipotential bonding</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Rapid and simple installation process. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Extensive array of choices available.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Enclosure options tailored to meet project specifications.</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS | CU</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL EB</li>
                                    </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                        <img className='items-start' src={eq_bond}  
                                                srcSet={`${eq_bond} 1x,${eq_bond2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>EQUIPOTENTIAL BONDING LINK</p>  
                         
                                </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        
                         <div className="flex w-full">
                                <div className='flex flex-col w-4/6'>
                                    <div className="bg-cover bg-center"
                                                style={{ backgroundImage: `url(${strip})` }}>
                                    <p className=' text-white pl-2 text-xl justify-start'>Test Link</p>
                                    </div>
                               
                                    <ul className="list-none pl-2">
                                        <li className="flex items-start p-common "><img src={boltico} className="mr-1 pr-1 my-1.5"/>Employed to facilitate the opening and closing of connections between conductors and metal structures.  </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>  Installation is straightforward and rapid. </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/> Offers a broad spectrum of choices.  </li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Material - GI | SS | CU</li>
                                        <li className="flex items-start "><img src={boltico} className="mr-1 pr-1 my-1 pt-[0.16em]"/>Product code : ASEEL TL</li>


                                                                            </ul>
                                </div>
                                <div className='flex flex-col w-2/6 items-center'>
                                        <img className='items-start' src={test_link}  
                                                srcSet={`${test_link} 1x,${test_link2x} 2x`} alt=" self stand logo"
                                                /> 
                                        <p className=' text-xs justify-start items-center'>TEST LINK</p>  
                         
                                </div>
                        </div>
                    </div>
                </div>


                {/* Fourteenth row */}
                




            
            </div>
        </div>
        </div>
      )
 }

 export default CLPPagecontent;
    