import React from 'react'
import telecom from './assets/images/ashlok_way/telecom_image.png';
import telecom2x from './assets/images/ashlok_way/telecom_image@2x.png';
import solar from './assets/images/ashlok_way/solar_&_renewable_energy_image.png';
import solar2x from './assets/images/ashlok_way/solar_&_renewable_energy_image@2x.png';
import oil from './assets/images/ashlok_way/oil_&_gas_image.png';
import oil2x from './assets/images/ashlok_way/oil_&_gas_image@2x.png';
import heavy from './assets/images/ashlok_way/heavy_industries_image.png';
import heavy2x from './assets/images/ashlok_way/heavy_industries_image@2x.png';
import mining from './assets/images/ashlok_way/mining_image.png';
import mining2x from './assets/images/ashlok_way/mining_image@2x.png';
import infrastructure from './assets/images/ashlok_way/infrastructure_image.png';
import infrastructure2x from './assets/images/ashlok_way/infrastructure_image@2x.png';
import cleint from './assets/images/ashlok_way/cleint_logo.png';
import cleint2x from './assets/images/ashlok_way/cleint_logo@2x.png';
import skyline from './assets/images/Skyline1x.jpg';
import skyline2x from './assets/images/Skyline2x.jpg';
import grass from './assets/images/grass-1x.jpg';
import grass2x from './assets/images/grass-2x.jpg';
import GifLoader from './GifLoader';




const AshlokwayContent = () => {
  return (
    <>
    <div className=' bg-white py-3'>
        <div className=' container mx-auto max-w-screen-xl flex flex-col items-center justify-center'>
            <p className=' text-3xl text-ashred items-center justify-center'> <span className=' font-bold' style={{ fontFamily: 'cinzel, sans-serif' }}>Ashlok’s </span> Lightning Protection System Safeguarding All Sectors.</p>
                {/* row 1 */}
              <div className='flex fle-row py-3 justify-center'>
                    <div className='flex basis-1/4 flex-col'>
                            <img src={telecom} 
                            srcSet={`${telecom} 1x, ${telecom2x} 2x`}
                            alt="telecom1"/>                                                
                            <p className=' text-ashred text-lg mt-2 mb-4' style={{ fontFamily: 'cinzel, sans-serif' }}>Telecom</p>
                            <p className='text-base text-justify'>
                            Ensuring the utmost protection for telecom towers against lightning, Ashlok’s Lightning Protection System (LPS) offers unparalleled safety measures.
                            </p>
                    </div>
                    <div className='flex basis-1/12 flex-col'>

                    </div>
                    <div className='flex basis-1/4 flex-col'>
                            <img src={solar} 
                            srcSet={`${solar} 1x, ${solar2x} 2x`}
                            alt="telecom1"/>                                                
                            <p className=' text-ashred text-lg mt-2 mb-4' style={{ fontFamily: 'cinzel, sans-serif' }}>Solar & Renewable Energy</p>
                            <p className='text-base text-justify'>
                            Ashlok’s Lightning Protection System (LPS) range shields solar farms from the adverse effects of lightning, providing a protective barrier against potential harm.
                            </p>
                    </div>
                    <div className='flex basis-1/12 flex-col'>

                    </div>  
                    <div className='flex basis-1/4 flex-col'>
                            <img src={oil} 
                            srcSet={`${oil} 1x, ${oil2x} 2x`}
                            alt="telecom1"/>                                                
                            <p className=' text-ashred text-lg mt-2 mb-4' style={{ fontFamily: 'cinzel, sans-serif' }}>Oil & Gas</p>
                            <p className='text-base text-justify'>
                            The Ashlok Lightning Protection System (LPS) series ensures the protection of solar farms from the destructive consequences of lightning, offering a reliable defence against potential damage.
                            </p>
                    </div>
              </div>

               {/* row 2 */}
               <div className='flex fle-row p-3 justify-center'>
                    <div className='flex basis-1/4 flex-col'>
                            <img src={heavy} 
                            srcSet={`${heavy} 1x, ${heavy2x} 2x`}
                            alt="telecom1"/>                                                
                            <p className=' text-ashred text-lg mt-2 mb-4' style={{ fontFamily: 'cinzel, sans-serif' }}>Heavy Industries</p>
                            <p className='text-base text-justify'>
                            Ashlok’s Lightning Protection System (LPS) allows for the controlled dissipation of lightning energy, simultaneously offering structural protection.
                            </p>
                    </div>
                    <div className='flex basis-1/12 flex-col'>

                    </div>
                    <div className='flex basis-1/4 flex-col'>
                            <img src={mining} 
                            srcSet={`${mining} 1x, ${mining2x} 2x`}
                            alt="telecom1"/>                                                
                            <p className=' text-ashred text-lg mt-2 mb-4' style={{ fontFamily: 'cinzel, sans-serif' }}>Mining & Minerals</p>
                            <p className='text-base text-justify'>
                            Ashlok’s selection of air terminals is strategically placed along the roof’s perimeter and elevated points, delivering unparalleled protection.
                            </p>
                    </div>
                    <div className='flex basis-1/12 flex-col'>

                    </div>  
                    <div className='flex basis-1/4 flex-col'>
                            <img src={infrastructure} 
                            srcSet={`${infrastructure} 1x, ${infrastructure2x} 2x`}
                            alt="telecom1"/>                                                
                            <p className=' text-ashred text-lg mt-2 mb-4' style={{ fontFamily: 'cinzel, sans-serif' }}>Infrastructure</p>
                            <p className='text-base text-justify'>
                            Ashlok’s Lightning Protection System (LPS) aims to prevent direct contact between the structure and the lightning current.
                            </p>
                    </div>
              </div>

        </div>
       
    </div>  {/*
    <div className=' bg-ashgray1 flex items-center justify-center flex-col p-2'>
            <p className=' text-ashred text-3xl'>A few who chose the <span className='first-letter'>A</span>SHLOK Way…</p>
            
    </div>
    <div className=' bg-white flex items-center justify-center flex-col  py-3'>
            <div className=' container mx-auto max-w-screen-xl flex flex-col items-center'>
                    <img src={cleint} style={{width:'90%'}}
                            srcSet={`${cleint} 1x, ${cleint2x} 2x`}
                            alt="client"/> 
                </div>
    </div>
    <GifLoader/>
  
    <div className='  w-full flex flex-col bg-white  items-center'>
 
            <img  src={skyline} alt="skyline"
               srcSet={`${skyline} 1x, ${skyline2x} 2x`}
            />
      
        <img  src={grass} alt="grass" 
           srcSet={`${grass} 1x, ${grass2x} 2x`}
          />
  </div>*/}
    </>
  )
}

export default AshlokwayContent
