import React,{useContext} from 'react'
import { Link,useNavigate } from 'react-router-dom';
import lplogo from './assets/images/lightningcategory/lightning_protection_logo.svg';
import thorimg from './assets/images/lightningcategory/thor_image.png';
import thorimg2x from './assets/images/lightningcategory/thor_image@2x.png';

import zeus from './assets/images/lightningcategory/zeus_image.png';
import zeus2x from './assets/images/lightningcategory/zeus_image@2x.png';

import air from './assets/images/lightningcategory/air_terminal_image.png';
import air2x from './assets/images/lightningcategory/air_terminal_image@2x.png';

import frank from './assets/images/lightningcategory/franklyn.png';
import frank2x from './assets/images/lightningcategory/franklyn@2x.png';

import ltacc from './assets/images/lightningcategory/lightning_accessories.png';
import ltacc2x from './assets/images/lightningcategory/lightning_accessories@2x.png';

import spd from './assets/images/lightningcategory/spd.png';
import spd2x from './assets/images/lightningcategory/spd@2x.png';

import wkit from './assets/images/lightningcategory/welding_kit.png';
import wkit2x from './assets/images/lightningcategory/welding_kit2x.png';


import Thordetailscontent from './Thordetailscontent';
import { ProductContext } from './context/Productcontext';

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  };
    const LightningCategoryContent = () => {
        let navigate = useNavigate();
        const { selectedProduct, setProduct ,setCategory } = useContext(ProductContext);
        const roundedbottom={
          borderBottomLeft:10,
          borderBottomRight:10

        }
        const handleProductSelect = (product) => {
            setProduct(product);
            setCategory('slpr');
            console.log("clieck product using context="+product);
            navigate('/lps');
            scrollToTop();
                /*
            if(product==="thor")
                navigate('/thordetails');
            else if(product==="zeus")
                navigate('/zeusdetails');
            else if(product==="air")
                navigate('/airterminaldetails');
            else if(product==="frank")
                navigate('/franklynpentadetails');
            else if(product==="access")
                navigate('/lightaccdetails');
            else if(product==="spd")
                navigate('/spddetails');
            
             <Route path="/lps/:ipage" element={<Lightningprotectionsystems/>}/>
          <Route path="/thordetails" element={<Thordetailscontent/>}/>
          <Route path="/zeusdetails" element={<Zeusdetailscontent/>}/>
          <Route path="/airterminaldetails" element={<Airterminaldetailscontent/>}/>
          <Route path="/franklynpentadetails" element={<Franklynpentacontent/>}/>
          <Route path="/exotermicdetails" element={<Exotermicweldingkitdetails/>}/>
          <Route path="/spddetails" element={<Spddetailscontent/>}/>
          <Route path="/lightaccdetails" element={<Lightningaccessoriesdetails/>}/>

            */

            
        }

      return (
        <div className=' w-full flex-col mx-auto flex justify-center items-center m-5'>
            <img src={lplogo}/>
            <div className='container w-max-xl flex flex-row justify-center items-center m-5 mt-10 space-x-16' >
                <div className='flex flex-col w-1/5   bg-ashgray1 rounded-lg cursor-pointer justify-end' style={{border:'black 1px solid',borderRadius:4}} onClick={() =>handleProductSelect('thor')}>
                    <div className=' flex justify-center items-center'>
                        <img src={thorimg2x} alt="thor img"
                        />
                    </div>
                    <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm" >
                        <p className="text-l font-bold text-center"><span className='vollkorn-sc-semibold'>Thor® SLPR</span></p>
                       
              
                        {/* <p className="text-base line-clamp-3">Ashlok’s range of lps safeguards the solar farms from the harmful impact of lightning.</p>
                         
                        
                            <div className='flex justify-end items-end' >
                                <div className='p-2   h-6  bg-ashred text-xs text-white flex items-center justify-center rounded-sm'>Explore
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                    </svg>
                                </div>
                            </div> */}
                        
                    </div>
                </div>
                <div className='flex flex-col w-1/5 bg-ashgray1 rounded-lg cursor-pointer justify-end' style={{border:'black 1px solid',borderRadius:4}}  onClick={() =>handleProductSelect('zeus')}>
                    <div className=' flex justify-center items-center'>
                        <img src={zeus2x} alt="thor img"/>
                       {/* srcSet={`${zeus} 1x, ${zeus2x} 2x`}/> */}
                    </div>
                    <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                        <p className="text-l font-bold text-center"><span className='vollkorn-sc-semibold'>Zeus® SLPR</span></p>
                        {/* <p className="text-base line-clamp-3">Safeguarding the telecom towers from lightning ashlok’s lps provides the ultimate safety.</p>
                         
                            <div className='flex justify-end items-end'>
                                <div className='p-2   h-6  bg-ashred text-xs text-white flex items-center justify-center rounded-sm'>Explore
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                    </svg>
                                </div>
                            </div> */}
                          
                    </div>
                </div>

                <div className='flex flex-col w-1/5      bg-ashgray1 rounded-lg cursor-pointer justify-end' style={{border:'black 1px solid',borderRadius:4}}  onClick={() =>handleProductSelect('frank')}>
                    <div className=' flex justify-center items-center'>
                        <img src={frank2x} alt="thor img"/>
                        {/*  srcSet={`${frank} 1x, ${frank2x} 2x`}/> */}
                    </div>
                    <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                        <p className="text-l font-bold line-clamp-1 text-center"><span className='vollkorn-sc-semibold'>Franklyn Penta</span></p>
                        {/* <p className="text-base line-clamp-3">Lps of ashlok enables the lightning to be dissipated whilst providing protection to the structure.</p>
                       
                            <div className='flex justify-end items-end'>
                                <div className='p-2   h-6  bg-ashred text-xs text-white flex items-center justify-center rounded-sm'>Explore
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                    </svg>
                                </div>
                            </div> */}
                            
                    </div>
                </div>
                

            </div>
            {/* second row */}
            <div className='container w-max-xl flex flex-row justify-center  m-5 mt-10 space-x-16'>
                
                <div className='flex flex-col w-1/5  bg-ashgray1 rounded-lg cursor-pointer justify-end' style={{border:'black 1px solid',borderRadius:4}}  onClick={() =>handleProductSelect('air')}>
                    <div className=' flex justify-center items-center'>
                        <img src={air2x} alt="thor img"/>
                        {/*  srcSet={`${air} 1x, ${air2x} 2x`}/> */}
                    </div>
                    <div className="p-4  text-center sm:text-left bg-ashblack text-white rounded-sm">
                        <p className="text-l font-bold text-center"><span className='vollkorn-sc-semibold'>Air Terminal</span></p>
                        {/*<p className="text-base line-clamp-3">Giving a high range of protection against lightning, our products has benchmarked in oil & gas sector.</p>
                         <p className=' text-ashblack'>Franklyn Penta  </p>
                            <div className='flex justify-end items-end'>
                                <div className='p-2   h-6  bg-ashred text-xs text-white flex items-center justify-center rounded-sm'>Explore
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                    </svg>
                                </div>
                            </div> */}
                           
                    </div>
                </div>

                <div className='flex flex-col w-1/5  bg-ashgray1 rounded-lg cursor-pointer justify-end' style={{border:'black 1px solid',borderRadius:4}}  onClick={() =>handleProductSelect('spd')}>
                    <div className=' flex justify-center items-center'>
                        <img src={spd2x} alt="thor img" />
                        {/*  srcSet={`${spd} 1x, ${spd2x} 2x`}/> */}
                    </div>
                    <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                        <p className="text-l font-bold line-clamp-1 text-center"><span className='vollkorn-sc-semibold'>SPD</span></p>
                        {/* <p className="text-base line-clamp-3 ">Is your electrical and electronic equipment shielded from lightning and transient surges?</p>
                        <p className="text-base text-ashblack">Ashlok safe earthing</p>
                           
                            <div className='flex justify-end items-end'>
                                <div className='p-2   h-6  bg-ashred text-xs text-white flex items-center justify-center rounded-sm'>Explore
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                    </svg>
                                </div>
                            </div> */}
                           
                    </div>
                </div>
                <div className='flex flex-col w-1/5  bg-ashgray1 rounded-lg cursor-pointer justify-end' style={{border:'black 1px solid',borderRadius:4}}  onClick={() =>handleProductSelect('exo_kit')}>
                    <div className=' flex justify-center items-center'>
                        <img src={wkit2x} alt="thor img" />
                       {/*   srcSet={`${wkit} 1x, ${wkit2x} 2x`}/> */}
                    </div>
                    <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                        <p className="text-l font-bold line-clamp-1 text-center"><span className='vollkorn-sc-semibold'>Exothermic Welding KIT                     </span></p>
                        {/* <p className="text-base line-clamp-4"> <span className='vollkorn-sc-semibold'>Ashlok's</span> ExoThermic Welding Kit offers superior thermal performance and safety.</p>
                        <p className="text-base text-ashblack">Ashlok safe earthing</p>
                            <div className='flex justify-end items-end'>
                                <div className='p-2   h-6  bg-ashred text-xs text-white flex items-center justify-center rounded-sm'>Explore
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                    </svg>
                                </div>
                            </div> */}
                           
                    </div>
                </div>
                

            </div>
            {/* Third Row */}
            <div className='container w-max-xl flex flex-row justify-center  m-5 mt-10 space-x-16'>
                <div className='flex flex-col w-1/5  bg-ashgray1 rounded-lg cursor-pointer justify-end' style={{border:'black 1px solid',borderRadius:4}}  onClick={() =>handleProductSelect('access')}>
                    <div className=' flex justify-center items-center'>
                        <img src={ltacc2x} alt="thor img" />
                        {/*  srcSet={`${ltacc} 1x, ${ltacc2x} 2x`}/> */}
                    </div>
                    <div className="p-4 text-center sm:text-left bg-ashblack text-white rounded-sm">
                        <p className="text-l font-bold line-clamp-1 text-center"><span className='vollkorn-sc-semibold'>Accessories</span></p>
                        {/* <p className="text-base line-clamp-4">Our range of air terminals are positioned around the edge of the roof and on high points providing the ultimate protection.</p>
                          
                            <div className='flex justify-end items-end'>
                                <div className='p-2   h-6  bg-ashred text-xs text-white flex items-center justify-center rounded-sm'>Explore
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                    </svg>
                                </div>
                            </div> */}
                           
                    </div>
                </div>
                <div className='flex flex-col w-1/5   rounded-lg cursor-pointer justify-end'>
                </div>
                <div className='flex flex-col w-1/5  rounded-lg cursor-pointer justify-end'>
                </div>

            </div>



        </div>
      )
    }
    
    export default LightningCategoryContent
    