import React from 'react'
import Footer from './Footer'
import Menu from './Menu'
import Header from './Header'
import PreHeader from './PreHeader'
import MetaTags from './MetaTags'
import CLPPagecontent from './CLPPagecontent'
import InstallationBanner from './InstallationBanner'
import clp_ban from './assets/images/clppage/clp_banner.jpg';
import clp_ban2x from './assets/images/clppage/clp_banner2x.jpg';
import GifLoader from './GifLoader'

const CLPPage = () => {
  return (
    <div>
             
    <PreHeader/>
    <Header/>    
     <Menu/>
  
     <InstallationBanner page="" banalt="CLP Page"  ban1x={clp_ban} ban2x={clp_ban2x}/>
    <CLPPagecontent/>
  
    <GifLoader/>
     <Footer/>
</div>
  )
}

export default CLPPage
