import React,{useState,useContext} from 'react'
import { ProductContext } from './context/Productcontext';
import { useNavigate } from 'react-router-dom';

import aseel_hd from './assets/images/earthing/aseel_hd_small.png';
import aseel_z from './assets/images/earthing/aseel_z_small.png';
import aseel_pvd from './assets/images/earthing/aseel_pvd_small.png';
import aseel_a from './assets/images/earthing/aseel_a_small.png';
import aseel_cp from './assets/images/earthing/aseel_cp_small.png';
import aseel_cbr from './assets/images/earthing/aseel_cbr_small.png';
import aseel_cc from './assets/images/earthing/aseel_cc_small.png';
import aseel_cfc from './assets/images/earthing/aseel_cfc_small.png';
import earthing from './assets/images/earthing/earthing_accessories_small.png';
import styles from  './EarthingelectrodeSubMenu.module.css';

    const EarthingelectrodeSubMenu = () => {
        let navigate = useNavigate();
        const [selectedItem, setSelectedItem] = useState(null);
        const {selectedCategory,selectedProduct,setProduct} = useContext(ProductContext);


        const handleItemClick = (item) => {
            setSelectedItem(item);
            setProduct(item);
            //onImageClick(item); // Call the function passed as prop with the clicked item
          };
      return (
       <div className=' w-full flex  bg-ashgray1'>
            <div className='my-1 container mx-auto flex justify-center items-center'>
                <div className={`w-1/6 h-18 flex justify-end  items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'exo_kit' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('cc_aseel')}>
                    <div  className='h-18 pb-1'><img src={aseel_cc} alt="epcc ico"/></div>
                    <div  className='w-3/4'><div className={`  px-4 text-base  font-semibold ${selectedProduct === 'cc_aseel' ? 'text-ashred' : ''}`} style={{ fontFamily: 'vollkorn-sc-semibold' }} ><span className='vollkorn-sc-semibold'><div>Composite</div> <div>Carbon</div> Coating </span></div></div>
                </div>
                <div className={` w-1/6 h-18 flex justify-end  items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'exo_kit' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('cfc_aseel')}>
                    <div  className={`flex items-center py-1 ${styles.noMaxWidth}`}><img src={aseel_cfc} alt="epcc ico"/></div>
                    <div  className='w-3/4'><div className={` px-4 text-base  font-semibold ${selectedProduct === 'cfc_aseel' ? 'text-ashred' : ''}`} style={{ fontFamily: 'vollkorn-sc-semibold' }} ><span className='vollkorn-sc-semibold'><div>CarboForm</div> <div>Conducto</div><div>Casting</div></span></div></div>
                </div>
                <div className={`w-1/6 h-18 flex justify-end items-center ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'exo_kit' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('cp_aseel')}>
                    <div  className={`h-18 pb-1 flex items-center py-1 ${styles.noMaxWidth}`}><img  className='' src={aseel_cp} alt="epcc ico"/></div>
                    <div  className='w-3/4'><div className={` px-4 text-base  font-semibold ${selectedProduct === 'cp_aseel' ? 'text-ashred' : ''}`} style={{ fontFamily: 'vollkorn-sc-semibold' }} ><span className='vollkorn-sc-semibold'><div>Copper</div> <div>Plated</div> <div>Electrodes</div></span></div></div>
                </div>
                <div className={` w-1/6 h-18 flex  justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'fr_penta' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('a_aseel')}>
                    <div  className={`h-18 pb-1 flex items-center py-1 ${styles.noMaxWidth}`}><img src={aseel_a} alt="bfc ico"/></div>
                    <div  className='w-3/4'><div className={` px-4 text-base  font-semibold ${selectedProduct === 'a_aseel' ? 'text-ashred' : ''}`} style={{ fontFamily: 'vollkorn-sc-semibold' }} ><span className='vollkorn-sc-semibold'><div>Alloy</div> <div>Coated</div> <div>Electrodes</div></span></div></div>
                    
                </div>
                <div className={`w-1/6 h-18 flex  justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'air_term' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('pvd_aseel')}>
                    <div  className='h-18 pb-1'><img src={aseel_pvd} alt="ele ico"/></div>
                    <div  className='w-3/4'><div className={` px-4 text-base  font-semibold ${selectedProduct === 'pvd_aseel' ? 'text-ashred' : ''}`} style={{ fontFamily: 'vollkorn-sc-semibold' }} ><span className='vollkorn-sc-semibold'><div>PVD</div> <div>150 +</div> <div>Coated</div></span></div></div>
                </div>
                
                <div className={`w-1/6 h-18 flex  justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer`} onClick={() => handleItemClick('z_aseel')}>
                    <div  className={`h-18 pb-1 flex items-center py-1 ${styles.noMaxWidth}`}><img src={aseel_z} alt="zeud ico"/></div>
                    <div  className='w-3/4'><div className={` px-4 text-base  font-semibold ${selectedProduct === 'z_aseel' ? 'text-ashred' : ''}`} style={{ fontFamily: 'vollkorn-sc-semibold' }} ><span className='vollkorn-sc-semibold'><div>Zinc</div><div> Coated</div><div> Electrodes</div></span></div></div>
                </div>
               
                <div className={` w-1/6 h-18 flex  justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer `} onClick={() => handleItemClick('hd_aseel')}>
                    <div  className={`h-18 pb-1 flex items-center py-1 ${styles.noMaxWidth}`}><img src={aseel_hd} alt="thor ico"/></div>
                    <div  className='w-3/4' style={{ fontFamily: 'vollkorn-sc-semibold' }}><div className={` px-4 text-base  ${selectedProduct === 'hd_aseel' ?  ' text-ashred' : ''}`}  ><span className='vollkorn-sc-semibold'><div>Galvanised</div> <div>Electrodes</div><div className='  text-ashgray1'>aseel</div></span></div></div>
                </div>
              
                <div className={`w-1/6 h-18 flex  justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'exo_kit' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('cbr_aseel')}>
                    <div  className='h-18 pb-1'><img src={aseel_cbr} alt="epcc ico"/></div>
                    <div  className='w-3/4'><div className={` px-4 text-base font-semibold ${selectedProduct === 'cbr_aseel' ? 'text-ashred' : ''}`} style={{ fontFamily: 'vollkorn-sc-semibold' }} ><span className='vollkorn-sc-semibold'><div>Copper</div> <div>Bonded</div> <div>Rods</div></span></div></div>
                </div>
               
                <div className={` w-1/6 h-28 flex  justify-end items-center  ml-1 mr-1 cursor-pointer hover:cursor-pointer ${selectedItem === 'exo_kit' ? 'bg-ashred' : ''}`} onClick={() => handleItemClick('earth_acc')}>
                    <div  className={`h-18 pb-1 flex items-center py-1 ${styles.noMaxWidth}`}><img src={earthing} className=' ' alt="epcc ico"/></div>
                    <div  className='mt-2 w-3/4'><div className={` px-4 text-base  font-semibold ${selectedProduct === 'earth_acc' ? 'text-ashred' : ''}`} style={{ fontFamily: 'vollkorn-sc-semibold' }} ><div className='vollkorn-sc-semibold'>Earthing</div> <div className='vollkorn-sc-semibold'>Accessories</div></div></div>
                </div>
                
                
                

            </div>
          
        </div>
      )
    }
    
    export default EarthingelectrodeSubMenu
    