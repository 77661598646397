import React,{useEffect} from 'react'
import { Link,useNavigate } from 'react-router-dom';
import lpage from './assets/images/Landing/Entry_Page.jpg';
import lpage_2x from './assets/images/Landing/Entry_Page@2x.jpg';


const LandingPage =() => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the home page after 7 seconds
    const timer = setTimeout(() => {
      navigate('/home');
    }, 7000);

    // Add keydown event listener for ESC key
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {  // ESC key is pressed
        navigate('/home');
      }
    };

    // Attach event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup both the timeout and event listener on component unmount
    return () => {
      clearTimeout(timer);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  return (
    <div className='no-scroll h-screen overflow-hidden w-full flex flex-col relative'>
         <Link to="/home">
        <img src={lpage_2x} className='w-fit'/>
        <Link to="/home">
        <p  className=' text-white  text-3xl' style={{position:'absolute',left:'80%',top:'80%'}}>Click Here to Find Out</p>
        </Link>
        </Link>
    </div>
  )
}
export default  LandingPage