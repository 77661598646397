import React from 'react'

import baronlogo from './assets/images/whoweare/baron_logo.svg';
import whtmanlogo from './assets/images/whoweare/whytman_logo.svg';
import salasarlogo from './assets/images/whoweare/salasar_logo.svg';
import larrow from './assets/images/whoweare/arrow-left.svg';
import rarrow from './assets/images/whoweare/arrow-right.svg';

import epoint from './assets/images/whoweare/earth_point_logo.svg';

import alloy_coated from './assets/images/whoweare/banner_logos/alloy-coated.png';
import alloy_coated2x from './assets/images/whoweare/banner_logos/alloy-coated@2x.png';

import back_fill from './assets/images/whoweare/banner_logos/backfill-compunds.png';
import back_fill2x from './assets/images/whoweare/banner_logos/backfill-compunds@2x.png';

import baron from './assets/images/whoweare/banner_logos/baron.png';
import baron2x from './assets/images/whoweare/banner_logos/baron@2x.png';

import conv from './assets/images/whoweare/banner_logos/conventional-lightning-protection.png';
import conv2x from './assets/images/whoweare/banner_logos/conventional-lightning-protection@2x.png';
import coppbond from './assets/images/whoweare/banner_logos/copper-bonded-rod.png';
import coppbond2x from './assets/images/whoweare/banner_logos/copper-bonded-rod@2x.png';

import copperplate from './assets/images/whoweare/banner_logos/copper-plated.png';
import coppperplate2x from './assets/images/whoweare/banner_logos/copper-plated@2x.png';

import cpri from './assets/images/whoweare/banner_logos/cpri.png';
import cpri2x from './assets/images/whoweare/banner_logos/cpri@2x.png';
import earthing_solution from './assets/images/whoweare/banner_logos/earthing-solution.png';
import earthing_solution2x from './assets/images/whoweare/banner_logos/earthing-solution@2x.png';
import earthpoint from './assets/images/whoweare/banner_logos/earthpoint.png';
import earthpoint2x from './assets/images/whoweare/banner_logos/earthpoint@2x.png';
import epcc from './assets/images/whoweare/banner_logos/epcc.png';
import epcc2x from './assets/images/whoweare/banner_logos/epcc@2x.png';
import galvanized from './assets/images/whoweare/banner_logos/galvanized.png';
import galvanized2x from './assets/images/whoweare/banner_logos/galvanized@2x.png';
import hizal from './assets/images/whoweare/banner_logos/hizal.png';
import hizal2x from './assets/images/whoweare/banner_logos/hizal@2x.png';
import icmet from './assets/images/whoweare/banner_logos/icmet.png';
import icmet2x from './assets/images/whoweare/banner_logos/icmet@2x.png';
import iika from './assets/images/whoweare/banner_logos/iika.png';
import iika2x from './assets/images/whoweare/banner_logos/iika@2x.png';
import ite from './assets/images/whoweare/banner_logos/ite.png';
import ite2x from './assets/images/whoweare/banner_logos/ite@2x.png';
import lightning_protection from './assets/images/whoweare/banner_logos/lightning-protection.png';
import lightning_protection2x from './assets/images/whoweare/banner_logos/lightning-protection@2x.png';
import nfc from './assets/images/whoweare/banner_logos/nfc.png';
import nfc2x from './assets/images/whoweare/banner_logos/nfc@2x.png';
import rohs from './assets/images/whoweare/banner_logos/rohs.png';
import rohs2x from './assets/images/whoweare/banner_logos/rohs@2x.png';
import salasar from './assets/images/whoweare/banner_logos/salasar.png';
import salasar2x from './assets/images/whoweare/banner_logos/salasar@2x.png';
import sentinel_earthing from './assets/images/whoweare/banner_logos/sentinel-earthing.png';
import sentinel_earthing2x from './assets/images/whoweare/banner_logos/sentinel-earthing@2x.png';
import singleton_earthing from './assets/images/whoweare/banner_logos/singleton-earthing.png';
import singleton_earthing2x from './assets/images/whoweare/banner_logos/singleton-earthing@2x.png';
import ul from './assets/images/whoweare/banner_logos/ul.png';
import ul2x from './assets/images/whoweare/banner_logos/ul@2x.png';
import whytman from './assets/images/whoweare/banner_logos/whytman.png';
import whytman2x from './assets/images/whoweare/banner_logos/whytman@2x.png';
import zinc_coated from './assets/images/whoweare/banner_logos/zinc-coated.png';
import zinc_coated2x from './assets/images/whoweare/banner_logos/zinc-coated@2x.png';

import iec from './assets/images/whoweare/banner_logos/iec.png';
import iec2x from './assets/images/whoweare/banner_logos/iec@2x.png';

import iso from './assets/images/whoweare/banner_logos/iso.png';
import iso2x from './assets/images/whoweare/banner_logos/iso@2x.png';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './hcarsol.css';
import InfiniteScrollCarousel from './InfiniteScrollCarousel';
import Infinitescrollnew from './Infinitescrollnew';

//import './carsoulheight.css';


//import {Carousel} from 'react-responsive-carousel';
//https://www.youtube.com/watch?v=VFHWuy2olto
//import banner1 from './assets/images/Banner1.jpg'
//import "react-responsive-carousel/lib/styles/carousel.min.css"; 
//import banner1 from './assets/images/whoweare/logo_1.png';
//import banner1_2x from './assets/images/whoweare/logo_1_2x.png';
//import banner2 from './assets/images/whoweare/logo_2.png';
//import banner2_2x from './assets/images/whoweare/logo_2_2x.png';



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const sliderImageUrl = [
  //First image url
   {
    url:alloy_coated,
    url2x:alloy_coated2x
  },
  {
    url:back_fill,
    url2x:back_fill2x
  },
  {
    url:baron,
    url2x:baron2x
  },
  {
    url:conv,
    url2x:conv2x
  },
  {
    url:coppbond,
    url2x:coppbond2x
  },
 {
    url:copperplate,
    url2x:coppperplate2x
  },
 {
    url:cpri,
    url2x:cpri2x
  },
 {
    url:earthing_solution,
    url2x:earthing_solution2x
  },
 {
    url:earthpoint,
    url2x:earthpoint2x
  },
 {
    url:epcc,
    url2x:epcc2x
  },
 {
    url:galvanized,
    url2x:galvanized2x
  },
 {
    url:hizal,
    url2x:hizal2x
  },
 {
    url:icmet,
    url2x:icmet2x
  },
//  {
//     url:iika,
//     url2x:iika2x
//   },
 {
    url:ite,
    url2x:ite2x
    
},
{
    url:lightning_protection,
    url2x:lightning_protection2x
  },
{
    url:nfc,
    url2x:nfc2x
  },
{
    url:rohs,
    url2x:rohs2x
  },
{
    url:salasar,
    url2x:salasar2x
  },
{
    url:sentinel_earthing,
    url2x:sentinel_earthing2x
  },
{
    url:singleton_earthing,
    url2x:singleton_earthing2x
  },
{
    url:ul,
    url2x:ul2x
  },
{
    url:whytman,
    url2x:whytman2x
  },
{
    url:zinc_coated,
    url2x:zinc_coated2x
  },

  {
    url:iec,
    url2x:iec2x
  },

  {
    url:iso,
    url2x:iso2x
  }

 
];






const WhoweareContent = () => {
  return (
    <>
   
    <div className=' w-full   bg-ashred flex flex-col justify-center items-center'>
        <div className=' text-3xl  text-white p-2 underline-offset-auto font-semibold' style={{ fontFamily: 'cinzel, sans-serif' }}><span className='vollkorn-sc-semibold'>Ashlok</span> Family</div>
    </div>
    <div className=' w-full  flex flex-col justify-center items-center my-3'>
        {/*<div className=' text-3xl text-ashred'>Group Of Companies</div>*/}
        <div className='container mx-auto max-w-screen-xl flex  flex-row  '>
            <div className='flex  basis-1/3 items-end justify-start'>
                <img  src={baronlogo} alt="baron logo"/>
            </div>
            <div className='flex  basis-1/3 items-end justify-center'>
            <img src={whtmanlogo} alt="whytman logo" />
        

            </div>
            <div className='flex  basis-1/3 items-end justify-end'>
            <img  src={salasarlogo} alt="salasar lgo "/>
            </div>
        </div>

        
    </div>
    <div className=' w-full  flex flex-col justify-center items-center'>
        <div className=' text-3xl  text-ashred'>Certification(<span style={{fontSize:'80%'}}>s</span>) <span className=' text-4xl px-1'>|</span> Trademark(<span style={{fontSize:'80%'}}>s</span>) <span className=' text-4xl px-1'>|</span> Brand(<span style={{fontSize:'80%'}}>s</span>) <span className=' text-4xl px-1'>|</span> Product(<span style={{fontSize:'80%'}}>s</span>)</div>
    </div>
    {/*
    <div className=' w-full  bg-ashred flex flex-col justify-center items-center'>
         <div  className='h-1'></div>
    </div>*/}
    <div className=' w-full  bg-white flex flex-col justify-center items-center'>
        <div className='container mx-auto max-w-screen-xl pt-1 pb-2' id="who">
                {/*<HorizontalScrollBanner/>*/}
               {/*<Carousel showArrows={false} 
      showThumbs={false} 
      showIndicators={false}
      axis='horizontal' 
      autoPlay={true} 
      interval={3000}
      showStatus={false}
      infiniteLoop={true}
      //showIndicators={false}
      >
        <div>
          <img src={banner1} 
          srcSet={`${banner1} 1x,${banner1_2x} 2x `}/>
        </div>
        <div>
          <img src={banner2} 
          srcSet={`${banner2} 1x,${banner2_2x} 2x `}/>
        </div>
        
  </Carousel> 

<div className="parent">

      <Carousel
       className="custom-carousel"
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {sliderImageUrl.map((imageUrl, index) => {
          return (
            <div className="slider" key={index}>
              <img src={imageUrl.url2x} 
              alt="logos" 
              />
            

            </div>
          );
        })}
      </Carousel>
    </div>
        
        <InfiniteScrollCarousel images={sliderImageUrl}/>*/}
        <Infinitescrollnew images={sliderImageUrl}/>

         </div>
    </div>
    </>
  )
}

export default WhoweareContent
