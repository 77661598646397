import React,{useEffect,useRef} from 'react'
import { HelmetProvider } from 'react-helmet-async'
import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import Installation from './Installation'
import SoiltestPage from './SoiltestPage'
import DesignCommissionPage from './DesignCommissionPage'
import DesignCommissionContent from './DesignCommissionContent'
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from './ScrollToTopButton'

 const Ourservices = ()=> {
    const installRef = useRef(null);
    const soilRef = useRef(null);
    const designRef = useRef(null);
    const location = useLocation();
    const displline=true;
    const helmetContext = {};
    useEffect(() => {
       
    //     console.log('Current hash:', location.hash);
    // console.log('Install Ref:', installRef.current);
    // console.log('Soil Ref:', soilRef.current);
    // console.log('Design Ref:', designRef.current);

    if (installRef.current && location.hash === '#install') {
      console.log("Matched #install");
      installRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (soilRef.current && location.hash === '#soil') {
      console.log("Matched #soil");
      soilRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (designRef.current && location.hash === '#design') {
      console.log("Matched #design");
      designRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log("No match found for hash");
    }

      }, [location]);

  return (
    <div>
        <HelmetProvider context={helmetContext}>
            <title>Ashlok's Advanced Lightning Protection Products - Safeguarding India</title>‍
            <meta name="description" content="Explore Ashlok's range of state-of-the-art Lightning Protection products, designed to offer unparalleled safety across India's diverse sectors. Our innovative solutions are tailored to meet the unique challenges of protecting against lightning hazards. Discover how our products ensure reliability and compliance with the highest safety standards." /> 
            <meta property="og:url" content="Ashlok.abc" />
            </HelmetProvider>
        <PreHeader/>
            <Header/>
           <Menu/>
           <div id="install" ref={installRef}>
                <Installation id="install" ref={installRef}/>
            </div>
            {/*
            <div id="soil" ref={soilRef}>
                <SoiltestPage/> 
            </div>*/}
            <div id="design" ref={designRef}>
                <DesignCommissionContent />
            </div>
            <ScrollToTopButton/>
            <Footer linedisp={displline}/>
    </div>
  )
}

export default Ourservices
