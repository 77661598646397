    import React,{useState} from 'react'
import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu'
import Foundercontent from './Foundercontent'
import banner1 from './assets/images/founder/founder_page_banner.jpg';
import banner1_2x from './assets/images/founder/founder_page_banner@2x.jpg';
import shield from './assets/images/shield.svg';
import FaqBanner from './FaqBanner'
import Footer from './Footer'
import { Helmet } from 'react-helmet'
const bannertext="A LEGACY OF INNOVATION AND COMMITMENT";
const banalt=" Founder banner alt";
    const FounderPage = () => {
      const [isSticky, setSticky] = useState(true);
      return (
        <div>
          <Helmet>
            <title>Meet the Founder of Ashlok - Visionary in Earthing & Lightning Protection</title>
            <meta name="description" content="Explore the inspiring story of Ashlok's founder, the driving force behind our PAN India success in Earthing & Lightning Protection. Discover how visionary leadership and a commitment to innovation have established Ashlok as a trusted name in electrical safety solutions, serving diverse needs with excellence and expertise."/>
          </Helmet>
         
            <PreHeader/>
             <Header isSticky={isSticky}/>
            <Menu isHeaderVisible={isSticky}/>
            {/*
            <FaqBanner page="Founder" img1={shield} banalt="founder ban1" bannertext="  A LEGACY OF INNOVATION AND COMMITMENT" ban1x={banner1} ban2x={banner1_2x}/>
            */}
             <div className='relative w-full flex flex-col justify-center items-center '>
           
           
                <img  src={banner1} alt={banalt} 
                srcSet={`${banner1} 1x, ${banner1_2x} 2x`}
                className='block w-full h-auto'
                />
                  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-3xl  text-white uppercase font-semibold' style={{ fontFamily: 'cinzel, sans-serif' }}>
                  <img  style={{maxWidth:'4%'}} src={shield} className="  mr-2 inline-block  justify-start items-start pb-2"/>     
                  <span className="inline-block">{bannertext}</span>
                </div>
      
            </div>
            <Foundercontent/>
            <Footer/>
        </div>
      )
    }
    
    export default FounderPage
    