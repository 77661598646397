import React from 'react'
import conticon from './assets/images/Contact us icon.svg'
import contbut from './assets/images/contact us button.png'
import contbut2x from './assets/images/contact us button 2x.png'
import skyline from './assets/images/Skyline1x.jpg';
import skyline2x from './assets/images/Skyline2x.jpg';
import grass from './assets/images/grass-1x.jpg';
import grass2x from './assets/images/grass-2x.jpg';

import { Link} from 'react-router-dom';
import GifLoader from './GifLoader';


const Homecontactus = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  };

  return (
    <>
    {/*
    <div className=' w-full flex flex-col pt-15 pb-15 bg-ashred text-white  items-center pt-10 pb-10'>
    <section className=' container mx-auto max-w-screen-xl flex  flex-col items-center justify-center '>
        <div>
            
        </div>
        <div className='m-1 text-3xl mb-10'>Safeguarding Life by Shielding Against Lightning, Paving the Way for a Safer Tomorrow...</div>
        <div className='m-1 cursor-pointer' onClick={scrollToTop}>
          <Link to="/contact">
            <img src={contbut} alt="contbut" className=' h-10'
            srcSet={`${contbut} 1x, ${contbut2x} 2x`}
            /></Link>

           
        </div>
    </section>
  </div> 
  <div className='  w-full flex flex-col mt-10 bg-white  items-center' >
 
            <img  src={skyline} alt="skyline"
               srcSet={`${skyline} 1x, ${skyline2x} 2x`}
            />
      
        <img  src={grass} alt="grass" 
           srcSet={`${grass} 1x, ${grass2x} 2x`}
          />
  </div>
  <GifLoader/>*/}
  

  </>
  )
}

export default Homecontactus
