import React,{useState} from 'react'
import exotermic from './assets/images/exothermic/exothermic_big.png';
import exotermic2x from './assets/images/exothermic/exothermic_big@2x.png';

import boltico from './assets/images/thordetails/bolt_icon.svg';
import LightningProductsSubMenu from './LightningProductsSubMenu';
import "./parafont.css"
    
    const Exotermicweldingkitdetails = ({ onImageClick }) => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
            
            <div className='w-full my-3 flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={exotermic}  
                                                srcSet={`${exotermic} 1x,${exotermic2x} 2x`} alt=" franl=klyn logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred stitle'><span className='vollkorn-sc-semibold'>Exothermic Welding Kit</span></p>
                        <p className=' p-common text-base text-justify'>Exothermic welding employs a combination of a mold, welding powder, starting powder, and additional accessories for cleaning and safety precautions. Various molds are chosen based on the conductor’s size.</p>
                    </div>
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <p className=' text-1xl font-bold text-ashred font-extrabold'>Features & Benefits</p>
                    <div className='flex'>
                        
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Connectors in T, Cross, and Series configurations are used for strips</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Uniting conductors within a grid.</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Available for strips, cables, stranded wires, and round conductors.</li>
                                             </ul>
                        </div>
                        <div className='w-1/2'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Demonstrating exceptional electrical conductivity and a lengthy operational life. </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Resistant to bimetallic corrosion. </li>
                           
                                 </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
               
                
                
                    
                   
                                    <table className=" w-full divide-y divide-gray-200  p-common">
                                        <thead className='w-full'>
                                            <tr className="bg-ashred">
                                            <th className=" px-4 py-2 text-white">Joint Category</th>
                                            <th className=" px-4 py-2 text-white"><div>Product Code</div></th>
                                            <th className=" px-4 py-2 text-white">Type of Joint</th>
                                                                                        </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">Cable to Cable</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ECC S</td>
                                            <td className=" px-4 py-2 text-center">Series</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Cable to Cable</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ECC T</td>
                                            <td className=" px-4 py-2 text-center">T</td>
                                            </tr>

                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Cable to Cable</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ECC L</td>
                                            <td className=" px-4 py-2 text-center">L</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Cable to Cable</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ECC X</td>
                                            <td className=" px-4 py-2 text-center">X</td>
                                            </tr>     

                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Cable to Cable</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ECC P</td>
                                            <td className=" px-4 py-2 text-center">Parallel</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Strip to Strip</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESS S</td>
                                            <td className=" px-4 py-2 text-center">Series</td>
                                            </tr>     

                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Strip to Strip</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESS T</td>
                                            <td className=" px-4 py-2 text-center">T</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Strip to Strip</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESS L</td>
                                            <td className=" px-4 py-2 text-center">L</td>
                                            </tr>     

                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Strip to Strip</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESS X</td>
                                            <td className=" px-4 py-2 text-center">X</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Strip to Strip</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESS P</td>
                                            <td className=" px-4 py-2 text-center">Parallel</td>
                                            </tr>     

                                            {/* <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Strip to Strip</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ECC T</td>
                                            <td className=" px-4 py-2 text-center">T</td>
                                            </tr> */}
                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Strip to Rod</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESR S</td>
                                            <td className=" px-4 py-2 text-center">Series</td>
                                            </tr>     

                                            {/* <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Strip to Rod</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ECC T</td>
                                            <td className=" px-4 py-2 text-center">T</td>
                                            </tr> */}
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Strip to Rod</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESR T</td>
                                            <td className=" px-4 py-2 text-center">T</td>
                                            </tr>     

                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Strip to Rod</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESR L</td>
                                            <td className=" px-4 py-2 text-center">L</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Strip to Rod</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESR X</td>
                                            <td className=" px-4 py-2 text-center">X</td>
                                            </tr>   
                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Strip to Rod</td>
                                            <td className=" px-4 py-2 text-center">ASEEL ESR P</td>
                                            <td className=" px-4 py-2 text-center">Parallel</td>
                                            </tr>  
                                          
                                        
                                        </tbody>
                                    </table>
                       
                       
                    
                </div>
                
            </div>
            </>
          )
    }
    
    export default Exotermicweldingkitdetails
    