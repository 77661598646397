import React,{useContext} from 'react'
import earthing from './assets/images/earthing/Earthing_install_image.png';
import earthing2x from './assets/images/earthing/Earthing_install_image2x.png';
import eleicon from './assets/images/earthing/Electrode icon.svg';
import bfcico from './assets/images/earthing/bfc icon.svg';
import thorico from './assets/images/earthing/thor icon.svg';
import { ProductContext } from './context/Productcontext';
import { Link,useNavigate } from 'react-router-dom';

const EathingNecessary = ({page}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  };
  let navigate = useNavigate();
  const {setCategory, setProduct} = useContext(ProductContext);
  const handleItemClick = (item) => {

    if(item=="thor")
    {
      setCategory("slpr")
      setProduct("thor");
      navigate('/lps');

    }
    else if(item=="bfc")
    {
      setCategory("bfc")
      setProduct("");
      navigate('/lpcat');

    }
    else if(item=="ele")
    {
      setCategory("ele")
      setProduct("");
      navigate('/lpcat');

    }
    scrollToTop();
    // setCategory('ele');
    // setProduct(item);
    // console.log(item);
    // navigate('/eedetails');
    //   /*if(item=='hd_aseel')
    //   navigate('');*/
   };


  return (
    <div className=' bg-white' >
        <div className=' container mx-auto max-w-screen-xl flex  flex-col  pb-14  justify-center items-center'> 

              <span className=' text-4xl text-ashred m-5 font-semibold'>Why is Earthing  Necessary ?</span>
            <div className='flex flex-row'>
                <div className='flex basis-1/2'>
                <img src={earthing}
                    srcSet={`${earthing} 1x, ${earthing2x} 2x`}
                         alt="eartnecess"/>
                </div>
                <div className='flex basis-1/2 ml-5'>
                  <div className='flex flex-col'>
                    <p className='text-justify text-base'>
                    Earthing is the process of creating an alternative path for the flow of fault/excessive currents safely into the ground in the presence of minimal resistance or impedance.
                    </p>
                    <p className='mt-2 text-justify text-base'>
                    The primary purpose of earthing is to reduce the risk of serious electric shock from current leaking into uninsulated metal parts of an appliance, power tool, or other electrical devices. In a properly earthed system, such leaking/fault current is carried away harmlessly while tripping the fuse. Earthing also provides protection from large electrical disturbances like lightning strikes and power surges. It also aids in the dissipation of hazardous static electrical charges. 
                    </p>
                    <p className='mt-2 text-justify text-base'>
                    Although most electrical systems have fuses or circuit breakers for protection against a fault current, the human body may be fatally electrocuted by a current of less than one ampere which is well below the point at which a fuse or breaker will operate. Earthing helps minimize such hazards from occurring.
                    </p>
                    {/*  First Block*/}
                    <div className='flex flex-row mt-2 mb-2'>
                      
                        <div className='flex basis-3/4 text-justify'>
                              <div className='flex flex-col'> 
                                  <span className=' text-2xl text-ashred mt-2 mb-2'><span className="text-3xl">S</span>AFE <span className="text-3xl">E</span>ARTHING <span className="text-3xl">E</span>LECTRODE(<span style={{fontSize:'90%'}}>s</span>)</span>
                                    <p className='text-justify text-base'>This is a highly conductive metallic electrode which is inserted into the ground close to the structure. Its purpose is to facilitate the effective discharge of all fault currents or surge currents within the electrical framework. Additionally, it aids in dispersing the elevated voltages conveyed through lightning arrestors situated atop buildings.</p>
                              </div>
                          </div>
                          <div className='flex basis-1/4 flex-col justify-center items-center'> 
                            <img className="flex " src={eleicon} alt="eleicon"></img>
                              <div className="flex flex-col justify-end items-end m-1 cursor-pointer" onClick={() => handleItemClick('ele')}>
                   
                                <div className='p-2   h-8 bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                                </div>
                              </div>
                          </div>
                    </div>

                    {/*  Second Block*/}
                    <div className='flex flex-row m-2'>
                      
                        <div className='flex basis-3/4 text-justify'>
                              <div className='flex flex-col'> 
                                  <span className=' text-2xl text-ashred mt-2 mb-2'><span className="text-3xl">B</span>ACK <span className="text-3xl">F</span>ILL <span className="text-3xl">C</span>OMPOUND(<span style={{fontSize:'90%'}}>s</span>)®</span>
                                    <p className='text-justify text-base'>These compounds serve to enhance the earth and come with diverse attributes that can be opted for based on soil characteristics and other factors. Ideally, a superior Back Fill Compound® (BFC) should possess properties like low resistance, adeptness at absorbing and retaining moisture, as well as thermal stability. While it is feasible to create an earthing system without using a Back Fill Compound®, it is highly recommended to employ one for better results when setting up the earthing system.


                                </p>
                              </div>
                          </div>
                          <div className='flex basis-1/4 flex-col justify-end items-center'> 
                            <img className="flex h-3/5" src={bfcico} alt="eleicon"></img>
                              <div className="flex flex-col justify-end items-end mt-2 cursor-pointer" onClick={() => handleItemClick('bfc')}>
                   
                                <div className='p-2   h-8 bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                                </div>
                              </div>
                          </div>
                    </div>

                    {/*  Third Block*/}
                    <div className='flex flex-row m-2'>
                      
                        <div className='flex basis-3/4 text-justify'>
                              <div className='flex flex-col'> 
                                  <span className=' text-2xl text-ashred mt-2 mb-2'> <span className="first-letter">S</span>AFE  <span className="text-3xl">L</span>IGHTNING  <span className="text-3xl">P</span>ROTECTION  <span className="text-3xl">R</span>OD(<span className='last-bracket-s'>s</span>)</span>
                                    <p className='text-justify text-base'>It is a highly engineered, robust product, typically positioned at the highest point of a structure, designed to intercept lightning strikes and guide them through a safe path to the ground. By doing so, it prevents the lightning from coursing through the building’s electrical system. In the absence of a lightning arrestor, a lightning strike could result in the devastation of electrical equipment and pose risks to human safety due to potential electrocutions.
                                </p>
                              </div>
                          </div>
                          <div className='flex basis-1/4 flex-col justify-end items-center'> 
                            <img className="flex " src={thorico} alt="eleicon"></img>
                              <div className="flex flex-col justify-center items-end mt-2 cursor-pointer" onClick={() => handleItemClick('thor')}>
                   
                                <div className='p-2   h-8 bg-ashred text-xs text-white flex items-center justify-center rounded-md'>Explore
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                </svg>
                                </div>
                              </div>
                          </div>
                    </div>




                  </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default EathingNecessary
