    import React from 'react'
import EarthinCategoryContent from './EarthinCategoryContent'
import Footer from './Footer'
import Menu from './Menu'
import Header from './Header'
import PreHeader from './PreHeader'
import MetaTags from './MetaTags'
    
    const EarthingCategoryPage = () => {
      return (
        <div>
             
            <PreHeader/>
            <Header/>    
             <Menu/>
            <div>
            <EarthinCategoryContent/>
             </div>
             <Footer/>
        </div>
      )
    }
    
    export default EarthingCategoryPage
   