import React from 'react';
import skyline from './assets/images/Skyline.gif';
import grass from './assets/images/grass-1x.jpg';
import grass2x from './assets/images/grass-2x.jpg';
const GifLoader = () => {
  return (
    <div>
       <img src={skyline}  alt="giufalter" />
       <img className='mt-[-1px]' src={grass2x} alt="grass"/>
        {/* <img className='mt-[-1px]' src={grass} alt="grass" 
           srcSet={`${grass} 1x, ${grass2x} 2x`}
          /> */}
    </div>
  );
};
{/* <GifLoader src1x="path/to/your/gif_1x.gif" src2x="path/to/your/gif_2x.gif" alt="Loading GIF" /> */}
export default GifLoader;