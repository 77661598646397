import React,{useState} from 'react'
import lplogo from './assets/images/thordetails/thor_big.png';
import lplogo2x from './assets/images/thordetails/thor_big@2x.png';

import lplogoWebp1x from './assets/images/thordetails/thor_big.webp';
import lplogoWebp2x from './assets/images/thordetails/thor_big@2x.webp';

// import lprologo from './assets/images/thordetails/lightning_protection_logo.png';
// import lprologo2x from './assets/images/thordetails/lightning_protection_logo@2x.png';

// import cplogo from './assets/images/thordetails/cdd_logo.png';
// import cplogo2x from './assets/images/thordetails/cdd_logo@2x.png';

import lprotection from './assets/images/thordetails/level_of_protection_image.svg';

import boltico from './assets/images/thordetails/bolt_icon.svg';










    const Thordetailscontent = ({ onImageClick }) => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

      return (
        <>
        
        <div className='w-full  flex justify-center items-center my-3'>
            <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                <div className='flex justify-center'>
                    
                    <div className='flex justify-center items-center  my-5'>
                    {/* <img src={lplogo}  
                                            srcSet={`${lplogo} 1x,${lplogo2x} 2x`} alt=" lpr logo"
                                            />  */}

                        <picture>
                        <source 
                            srcSet={`${lplogoWebp1x} 1x, ${lplogoWebp2x} 2x`} 
                            type="image/webp" 
                        />
                            <img 
                                decoding="async" 
                                loading="lazy" 
                                src={lplogo} 
                                srcSet={`${lplogo} 1x, ${lplogo2x} 2x`} 
                                alt="lpr logo" 
                            
                            />
                        </picture>
                    </div>
                   
                </div>
                {/*
                <div className='flex flex-col items-start'>
                    <div className=' text-2xl text-ashred  vollkorn-sc-semibold'>Thor® SLPR</div>
                    <p className=' text-base text-justify p-common'><span className='vollkorn-sc-semibold'>Thor® SLPR</span> is designed in such a way that the sum of its parts works in tandem to attract and capture lightning strikes in an efficient way</p>
                    <p className=' text-base text-justify mt-2 mb-2 p-common'>When the downward leader approaches the structure and creates an electric field to enable free movement of charges, the internal design of Thor® SLPR helps to increase the quantity of emitted charges by reducing and limiting corona in the initial stages of lightning. The charges stored in Thor® SLPR will not be wasted in the developing stages of lightning as it is designed to provide zero surface area for corona to occur. Once Thor® SLPR gets fully charged, a very powerful upward streamer with high charge density of longer length is released from THOR® SLPR which couples with the downward leader from lightning, at a very high distance from the structure, which it is protecting. Hence Thor® SLPR is able to protect a larger area.</p>
                </div>
                */}
                
            </div>
        </div>
        <div className='flex flex-col items-start'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center '>
                    <div className=' text-2xl text-ashred  vollkorn-sc-semibold'>Thor® SLPR</div>
                </div>
        </div>
        <div className='w-full flex justify-center items-center bg-ashgray1 '>
            <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3 '>
            <p className='text-2xl '> Characteristics & Benefits</p>
                <div className='flex py-1'>
                    
                    <div className='w-1/2'>
                        <ul className="list-none ">
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5"/><p><span className=' font-semibold'>Country Of Origin</span> | First Indian made Advanced Safe Lightning Protection Rod</p></li>
                        <li className="flex  items-start p-common"><img src={boltico} className="mr-2  py-1.5  leading-4"/><p><span className=' font-semibold'>Technological Basis </span> | Rooted in a Hybrid Model conforming to the latest ESE standards like NFC & Corona Inhibiting Tech.</p></li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2  py-2 leading-4"/><p><span className=' font-semibold'>Central Main Rod </span>| Recent research has determined the Blunt Tip’s enhanced efficiency in real-world conditions.</p></li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2  py-1.5 leading-4"/><p><span className=' font-semibold'>Upward Streamer </span>| Blunt tip increases the length of upward streamer as the construction of the system allows it to store the charge with in it so as to release it in the most optimum possible way.</p></li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2 pt-1.5"/><p><span className=' font-semibold'>Power Source </span>| THOR® SLPR operates autonomously without external input.
</p></li>
                        </ul>
                    </div>
                    <div className='w-1/2'>
                        <ul className="list-none ml-5">
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5"/><p><span className=' font-semibold'>Coverage Area </span>| Encompasses a broader region for lightning interception.</p></li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5 leading-4"/><p><span className=' font-semibold'>Construction Material (SS 304) </span>| Crafted from premium stainless steel for all-weather efficiency and corrosion resistance.</p></li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5"/><p><span className=' font-semibold'>Installation & Support </span>| Direct Manufacturer Service for Desgin Installation and Testing.</p></li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2  pt-1.5"/><p><span className=' font-semibold'>Certifications </span>| CPRI | ITE | CE | NFC tested & approved for both polarities of impulse.</p></li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-2  py-1.5"/><p><span className=' font-semibold'>Design & Intellectual Patent </span>| Sole Indian brand in this category patented for distinctive technology and design blend.</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-full flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>      
                <p className='text-xl'>Applications</p>
               <p> Power plants & Transmission towers | Solar farms | Office | buildings | Residential complexes | Commercial structures | Telecommunication towers | Wind farms | Power stations | Mines | Any kind of installation</p>
            </div>
        </div>
        
        <div className='w-full flex justify-center items-center'>
         
            <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center '>
            <p className='text-xl'>Level of Protection</p>
                <div className='flex'>
                    <div className='w-3/4  mr-5'>
                                <table className=" w-full divide-y divide-gray-200 mt-2 mx-15 p-common">
                                    <thead className='w-full'>
                                        <tr className="bg-ashred">
                                        <th className="px-4 py-2 text-white">Model</th>
                                        <th className="px-4 py-2 text-white"><div className='vollkorn-sc-semibold'>Thor® 1.0</div><div  className='vollkorn-sc-semibold font-extrabold'> Thor® Helios</div></th>
                                        <th className="px-4 py-2 text-white "><span className='vollkorn-sc-semibold'>Thor® </span> 1.1</th>
                                        <th className="px-4 py-2 text-white"><span className='vollkorn-sc-semibold'>Thor® Helios Bolt</span></th>
                                       
                                        </tr>
                                    </thead>
                                    <tbody className="bg-gray-100">
                                        <tr className="bg-white">
                                        <td className="px-4 py-2 text-center">Δ</td>
                                        <td className="px-4 py-2 text-center">60 μs</td>
                                        <td className="px-4 py-2 text-center">45 μs</td>
                                        <td className="px-4 py-2 text-center">30 μs</td>
                                     
                                        </tr>
                                        <tr className="bg-gray-200">
                                        <td className="px-4 py-2 text-center">Level 1</td>
                                        <td className="px-4 py-2 text-center">79 M</td>
                                        <td className="px-4 py-2 text-center">63 M</td>
                                        <td className="px-4 py-2 text-center">48 M</td>
                                      
                                        </tr>

                                        <tr className="bg-gray-100">
                                        <td className="px-4 py-2 text-center">Level 2</td>
                                        <td className="px-4 py-2 text-center">87 M</td>
                                        <td className="px-4 py-2 text-center">71 M</td>
                                        <td className="px-4 py-2 text-center">54 M</td>
                                       
                                        </tr>
                                        <tr className="bg-gray-200">
                                        <td className="px-4 py-2 text-center">Level 3</td>
                                        <td className="px-4 py-2 text-center">97 M</td>
                                        <td className="px-4 py-2 text-center">81 M</td>
                                        <td className="px-4 py-2 text-center">63 M</td>
                                       
                                        </tr>

                                        <tr className="bg-gray-100">
                                        <td className="px-4 py-2 text-center">Level 4</td>
                                        <td className="px-4 py-2 text-center">107 M</td>
                                        <td className="px-4 py-2 text-center">89 M</td>
                                        <td className="px-4 py-2 text-center">71 M</td>
                                        
                                        </tr>
                                        
                                    
                                    </tbody>
                                </table>
                    </div>
                    <div className='flex flex-col  w-1/4 '><img className=' w-3/4 items-start justify-end mt-2' src={lprotection} />
                        <div className=' items-end'>
                        <div className='mt-4  p-common'>Level I - Level with maximum security </div>
                        <div className='p-common'>Level II- Level with high security</div>
                        <div className='p-common'>Level III - Level with standard security </div>
                              <div className='p-common'>Level IV - Level with Moderate Security </div>
                        </div>
                    </div>
                </div>
            {/*
            <p className='my-3 text-xl my-3'>THOR® SLPR Variants</p>
                
               
                                <table className=" w-full divide-y divide-gray-200  my-3  *:p-common ">
                                    <thead className='w-full'>
                                        <tr className="bg-ashred">
                                        <th className=" px-4 py-2 text-white">Type</th>
                                        <th className=" px-4 py-2 text-white"><div>Thor® SLPR 1.0</div><div> Thor® Helios SLPR</div></th>
                                        <th className=" px-4 py-2 text-white">Thor® SLPR 1.1</th>
                                        <th className="  px-4 py-2 text-white">Thor® SLPR 1.2</th>
                                        <th className="  px-4 py-2 text-white">Thor® Helios</th>
                                        <th className="  px-4 py-2 text-white">Thor® Helios Bolt</th>
                                        
                                        </tr>
                                    </thead>
                                    <tbody className="bg-gray-100">
                                        <tr className="bg-white">
                                        <td className=" px-4 py-2 text-left">Applicable Standard</td>
                                        <td className=" px-4 py-2 text-center">NFC 17 - 102</td>
                                        <td className=" px-4 py-2 text-center">NFC 17 - 102</td>
                                        <td className=" px-4 py-2 text-center">NFC 17 - 102</td>
                                        <td className=" px-4 py-2 text-center">NFC 17 - 102</td>
                                        <td className=" px-4 py-2 text-center">NFC 17 - 102</td>
                                        </tr>
                                        <tr className="bg-gray-200">
                                        <td className=" px-4 py-2 text-left">Material Of Construction</td>
                                        <td className=" px-4 py-2 text-center">SS 304 L</td>
                                        <td className=" px-4 py-2 text-center">SS 304 L</td>
                                        <td className=" px-4 py-2 text-center">SS 304 L</td>
                                        <td className=" px-4 py-2 text-center">SS 304 L</td>
                                        <td className=" px-4 py-2 text-center">SS 304 L</td>
                                        </tr>
                                        <tr className="bg-gray-100">
                                        <td className=" px-4 py-2 text-left">Triggering Time ( ∆ T)</td>
                                        <td className=" px-4 py-2 text-center">60”μsec</td>
                                        <td className=" px-4 py-2 text-center">45”μsec</td>
                                        <td className=" px-4 py-2 text-center">25”μsec</td>
                                        <td className=" px-4 py-2 text-center">60”μsec</td>
                                        <td className=" px-4 py-2 text-center">60”μsec</td>
                                        </tr>
                                        <tr className="bg-gray-200">
                                        <td className=" px-4 py-2 text-left">Total Length</td>
                                        <td className=" px-4 py-2 text-center">660 mm</td>
                                        <td className=" px-4 py-2 text-center">590 mm</td>
                                        <td className=" px-4 py-2 text-center">510 mm</td>
                                        <td className=" px-4 py-2 text-center">510 mm</td>
                                        <td className=" px-4 py-2 text-center">252 mm</td>
                                        </tr>
                                        <tr className="bg-gray-100">
                                        <td className=" px-4 py-2 text-left">Diameter of Main Rod</td>
                                        <td className=" px-4 py-2 text-center">22 mm</td>
                                        <td className=" px-4 py-2 text-center">20 mm</td>
                                        <td className=" px-4 py-2 text-center">19 mm</td>
                                        <td className=" px-4 py-2 text-center">19 mm</td>
                                        <td className=" px-4 py-2 text-center">22 mm</td>
                                        </tr>

                                        <tr className="bg-gray-200">
                                        <td className=" px-4 py-2 text-left">Diameter of Sphere</td>
                                        <td className=" px-4 py-2 text-center">210 mm</td>
                                        <td className=" px-4 py-2 text-center">150 mm</td>
                                        <td className=" px-4 py-2 text-center">135 mm</td>
                                        <td className=" px-4 py-2 text-center">135 mm</td>
                                        <td className=" px-4 py-2 text-center">-</td>
                                        </tr>
                                        
                                    
                                    </tbody>
                                </table> */}
                   
                   
                
            </div>
            
        </div>
        <div className='w-full flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>      
                <p className='text-xl'>Certifications | Standards | Approvals</p>
                <p>ISO 9001:2008 | OHSAS 18001</p>
                <p>CPRI | CE | HIZAL | ICMET | ITE </p>
                <p>Compliance Standard | NFC 17 - 102</p>

            </div>
        </div>
        
        </>
      )
    }
    
    export default Thordetailscontent
    