import React,{useEffect,useState,useContext} from 'react'
import {Carousel} from 'react-responsive-carousel';
//import banner1 from './assets/images/Banner1.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import banner1 from './assets/images/Home/banner_1.jpg'
import banner1_2x from './assets/images/Home/banner_1@2x.jpg'
import banner2 from './assets/images/Home/banner_2.jpg'
import banner2_2x from './assets/images/Home/banner_2@2x.jpg'
import banner3 from './assets/images/Home/banner_3.jpg'
import banner3_2x from './assets/images/Home/banner_3@2x.jpg'
import banner4 from './assets/images/Home/banner_4.jpg'
import banner4_2x from './assets/images/Home/banner_4@2x.jpg'
import banner5 from './assets/images/Home/banner_5.jpg'
import banner5_2x from './assets/images/Home/banner_5@2x.jpg'
import {useBannerContext } from './BannerContext';

// https://github.com/leandrowd/react-responsive-carousel
const HomeBanner = ({page}) => {
  const { handleBannerLoad } = useBannerContext();

  //const banner1="https://raw.githubusercontent.com/gobisn/Images/main/Banner1.jpg";
  const [pageData, setPageData] = useState({});
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const bannerImages = [
    { src: banner4, srcSet: `${banner4} 1x,${banner4_2x} 2x` },
    { src: banner5, srcSet: `${banner5} 1x,${banner5_2x} 2x` },
    { src: banner3, srcSet: `${banner3} 1x,${banner3_2x} 2x` },
    { src: banner2, srcSet: `${banner2} 1x,${banner2_2x} 2x` },
    { src: banner1, srcSet: `${banner1} 1x,${banner1_2x} 2x` },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/data.json');
        const data = await response.json();
        setPageData(data);
           //   console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Create a new link element
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = banner1;
    link.as = 'image';

    // Append the link element to the document head
    document.head.appendChild(link);

    // Cleanup function to remove the link element when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  // const handleImageLoad = () => {
  //   setImagesLoaded((prev) => {
  //     const newCount = prev + 1;
  //     if (newCount === bannerImages.length) {
  //       handleBannerLoad();
  //     }
  //     return newCount;
  //   });
  // };
  const handleImageLoad = () => {
    setImagesLoaded((prev) => prev + 1);
  };

  useEffect(() => {
    if (imagesLoaded === bannerImages.length) {
     
      handleBannerLoad();
    }
  
  }, [imagesLoaded, bannerImages.length, handleBannerLoad]);

  // Accessing the 'home_page_title' for the selected language
  const ban1_alt = pageData[page]?.ban1_alt || '';

/*
{/*<img data_ash_name="ban1_alt" src={banner1} alt={ban1_alt}/> }
       {/* old order start 
        <div>
          <img src={banner1} 
          srcSet={`${banner1} 1x,${banner1_2x} 2x `}/>
        </div>
        <div>
          <img src={banner2}
          srcSet={`${banner2} 1x,${banner2_2x} 2x `}/>
        </div>
        <div>
          <img src={banner3}
          srcSet={`${banner3} 1x,${banner3_2x} 2x `}/>
        </div>
        <div>
          <img src={banner4}
          srcSet={`${banner4} 1x,${banner4_2x} 2x `}/>
        </div>
        <div>
          <img src={banner5}
          srcSet={`${banner5} 1x,${banner5_2x} 2x `}/>
        </div>
  old order end }
      


*/


  return (
    <div className=' w-full '>
      <Carousel showArrows={false} 
      showThumbs={false} 
      axis='horizontal' 
      autoPlay={true} 
      interval={3000}
      transitionTime={500}
      showStatus={false}
      infiniteLoop={true}
      //showIndicators={false}
      >
        {bannerImages.map((image, index) => (
          <div key={index}>
            <img
              src={image.src}
              srcSet={image.srcSet}
              alt={ban1_alt}
              onLoad={handleImageLoad}
            />
          </div>
        ))}

       {/*<div>
          <img src={banner4}
          srcSet={`${banner4} 1x,${banner4_2x} 2x `}/>
        </div>
        <div>
          <img src={banner5}
          srcSet={`${banner5} 1x,${banner5_2x} 2x `}/>
        </div>
       
          <div>
          <img src={banner3}
          srcSet={`${banner3} 1x,${banner3_2x} 2x `}/>
        </div>
          
        <div>
          <img src={banner2}
          srcSet={`${banner2} 1x,${banner2_2x} 2x `}/>
        </div>
        <div>
          <img src={banner1} 
          srcSet={`${banner1} 1x,${banner1_2x} 2x `}/>
        </div>*/}


              </Carousel>

    </div>
  )
}

export default HomeBanner
