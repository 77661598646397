import React, { useState } from 'react'
import PreHeader from "./PreHeader";
import Header from "./Header";
import Menu from "./Menu";
import HomeBanner from "./HomeBanner";
import HomeUltimateSolution from "./HomeUltimateSolution";
import Ourproducts from "./Ourproducts";
import Deliversafety from "./Deliversafety";
import Homecontactus from "./Homecontactus";
import Footer from "./Footer";
import SafetyReliable from "./SafetyReliable";
import Whychooseus from "./Whychooseus";
import MetaTags from "./MetaTags";
import { Helmet } from 'react-helmet-async';
import WhoweareContent from './WhoweareContent';
import AshlokClient from './AshlokClient';
import HomeMap from './HomeMap';
const HomePage = () => {
  const [isSticky, setSticky] = useState(true);
  const displline=true;
  return (
    <div className=''>
    
      <title>Ashlok Safe Earthing Electrode Limited: Leading Lightning and Earthing Protection Systems in Chennai</title>
      <meta name="description" content="Discover top-tier lightning and earthing protection solutions with Ashlok Safe Earthing Electrode Limited, based in Chennai. Specializing in innovative and reliable safety systems, we are committed to delivering excellence in every product for residential, commercial, and industrial needs."/>
     
    <PreHeader/>
    <Header isSticky={isSticky}/>
    <Menu isHeaderVisible={isSticky}/>
    <HomeBanner page="home"/>
     <HomeMap/>
    <HomeUltimateSolution page="home"/>
    {/*
    <Ourproducts/>
    <Deliversafety page="home"/>
    <SafetyReliable page="home"/>
    <Whychooseus/>*/}
    <WhoweareContent/>
    <AshlokClient/>
    <Homecontactus/>
    <Footer linedisp={displline}/>
    
    </div>
  )
}

export default HomePage
