import React,{useState} from 'react'

import slpr from './assets/images/slpraccessories/slpr_accessoriez_kit_images_big.png';
import slpr2x from './assets/images/slpraccessories/slpr_accessoriez_kit_images_big@2x.png';
import boltico from './assets/images/thordetails/bolt_icon.svg';
import LightningProductsSubMenu from './LightningProductsSubMenu';
import "./parafont.css"
    
    const SlprAccessories = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
          
            <div className='w-full my-3 flex justify-center items-center'>
                <div className='container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={slpr}  
                                                srcSet={`${slpr} 1x,${slpr2x} 2x`} alt=" slpr logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start mt-2'>
                        <p className=' text-2xl text-ashred fon stitle'><span className='vollkorn-sc-semibold'>SLPR Accessories Kit</span></p>
                        {/* <p className=' p-common text-base text-justify'>At Ashlok, we understand that a robust lightning protection system is built on more than just lightning rods and grounding electrodes. That’s why we offer a comprehensive range of Lightning Protection Accessories designed to enhance the efficiency and effectiveness of your lightning protection system. Our accessories are meticulously crafted to ensure the utmost safety and security for your property, assets, and people.</p>
                        <p className=' text-xl font-bold mt-2'>Explore our Lightning Protection Accessories</p>
                        <p className=' text-base text-ashred font-bold'>Ashlok’s SLPR Mast | A.L.I.C.E Digital | Earthing Test Link | Equipotential Busbar | SLPR Accessories Kit</p>
                        <p className=' p-common text-base text-justify'>Trust Ashlok for all your lightning protection accessory needs. With our commitment to quality and safety, you can count on our accessories to bolster the performance of your lightning protection system. Your safety is our priority, and Ashlok Lightning Protection Accessories are here to secure your world.</p> */}
                    </div>
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <p className=' text-2xl font-bold '>Accessories Kit for SLPR</p>
               
                    <div className='flex'>
                        
                        <div className='w-1/4'>
                            <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>LN SS Bolt | M8</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> LNKey </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Copper Lug</li>
                            </ul>
                        </div>
                        <div className='w-1/4'>
                        <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Cable Tie</li>
                            
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>M16 MS </li>

                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Fasteners</li>
                            </ul>
                        </div>  
                        <div className='w-1/4'>
                        <ul className="list-none">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Wall Flakes</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Wall Screw </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Wall Clip</li>
                            </ul>
                        </div>  
                        <div className='w-1/4'>
                        <ul className="list-none">   
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Insulating Tape</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Guy wire kit </li>

                        </ul>
                        </div>
                        
                    </div>
                    {/*
                    <div className='w-1/2'>
                        <p className=' text-1xl font-bold text-ashred my-1'>Compliance Norms</p>
                            <ul className="list-none">
                            
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pt-1.5 "/>I. IS 2309 - Safeguarding buildings and related structures from lightning. </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>II. BS 6651 - Shielding structures against lightning.</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> III. NFPA 780 - Guidelines for setting up lightning protection systems.  </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> IV. NFC 17 - 102 - ESE Standard according to the National French Code.</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>V. IS/IEC - 62305 - Lightning Protection Standard. </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>VI. UNE 21186 - 96 </li>

                                 </ul>
                    </div>
                    */}

                </div>
            </div>
            
            </>
          )
    }
    
    export default SlprAccessories
    