import React from 'react'
import bfclogo from './assets/images/bfcdetails/bfc_logo.png';
import bfclogo2x  from './assets/images/bfcdetails/bfc_logo@2x.png';

import baron from './assets/images/bfcdetails/baron_logo.png';
import baron2x  from './assets/images/bfcdetails/baron_logo@2x.png';


import bfcbig from './assets/images/bfcdetails/bfc_image_big.png';
import bfcbig2x  from './assets/images/bfcdetails/bfc_image_big@2x.png';

import electrodite from './assets/images/bfcdetails/electrodite.png';
import electrodite2x  from './assets/images/bfcdetails/electrodite@2x.png';

import electrodite_pow from './assets/images/bfcdetails/electrodite-powder.png';
import electrodite_pow_2x  from './assets/images/bfcdetails/electrodite-powder@2x.png';


import conductolite from './assets/images/bfcdetails/conductolite.png';
import conductolite2x  from './assets/images/bfcdetails/conductolite@2x.png';

import conductolite_pow from './assets/images/bfcdetails/conductolite-powder.png';
import conductolite_pow_2x  from './assets/images/bfcdetails/conductolite-powder@2x.png';

import zetalite from './assets/images/bfcdetails/zetalite.png';
import zetalite2x  from './assets/images/bfcdetails/zetalite@2x.png';

import zetalite_pow from './assets/images/bfcdetails/zetalite-powder.png';
import zetalite_pow_2x  from './assets/images/bfcdetails/zetalite-powder@2x.png';

import terraion from './assets/images/bfcdetails/terraion.png';
import terraion2x  from './assets/images/bfcdetails/terraion@2x.png';

import terraion_pow from './assets/images/bfcdetails/terraion-powder.png';
import terraion_pow_2x  from './assets/images/bfcdetails/terraion-powder@2x.png';

import humdite from './assets/images/bfcdetails/humedite.png';
import humdite2x  from './assets/images/bfcdetails/humedite@2x.png';

import humdite_pow from './assets/images/bfcdetails/humedite-powder.png';
import humdite_pow_2x  from './assets/images/bfcdetails/humedite-powder@2x.png';


    const Bfcdetailscontent = () => {
      return (
        <>
        <div className=' w-full flex-col mx-auto flex my-3 justify-center items-center'>
            
                <div className='container flex w-max-xl mx-auto items-center justify-center '>
                   
                      
                        <div className='flex' >
                            <img src={bfcbig} srcSet={` ${bfcbig} 1x, ${bfcbig2x} 2x`} alt="baron logo"/> 
                     </div>
                        
                   
                </div>
                
          
        
        </div>
         <div className='w-full flex-col mx-auto flex my-3 justify-center items-center'>
            <div className='container  mx-auto  max-w-screen-xl flex flex-row  space-x-16'>
                    <div className='items-start'>
                        {/*
                        <p className='text-2xl text-ashred'>BackFill Compound(s)® Series</p>*/}
                        <p className='text-2xl  text-ashred  uppercase' >Earth Resistivity</p>
                        <p className='text-base text-justify '>The resistance to earth of an electrode is directly proportional to soil resistivity & inversely proportional to the total area of contact established with the soil for fixed land areas. Soil resistivity is a function of several factors. These include the type of soil, moisture content, temperature, mineral content, granularity & compactness. Usually moisture & mineral content are the only factors that can be influenced by any practical control concept. Ionisation of soil & moisture retention are required to reduce soil resistivity, but the mineral content has the most dramatic influence. Ionisation is the first step to reduce soil resistivity. </p>
                        <p className='text-base text-justify '>The next step is to increase the moisture retention capacity of the soil. These are the two recommended techniques for reducing earth resistivity</p>
                        <p className='mt-2 text-2xl  text-ashred  vollkorn-sc-semibold'>Baron BackFill Compound(<span style={{fontSize:'80%'}}>s</span>)®</p>
                        <p className='text-base text-justify '>These are earth enhancement compounds, having different properties that can be chosen | selected as per soil & its other properties. Essentially, an ideal BFC should have low resistance, excellent moisture absorption and retention capabilities, and thermally stable. Although, earthing can be provided even without the use of BFC, however, for better performance it is suggested to use the BFC while providing the earthing system.</p>
                        <p className='text-base text-justify '><span className='vollkorn-sc-semibold'>Baron</span> has developed five different types of BackFill Compound(s) for three dimensional augmentation in electrical earthing system. This system should achieve low group resistance value for long periods where obtaining a satisfactory earth resistance has always been a problem in areas of poor soil conductivity.</p>
                    </div>
                </div>
         </div>
         <div className='w-full flex-col mx-auto flex mt-3 justify-center items-center bg-ashgray2'>
            <div className='container  mx-auto flex max-w-screen-xl flex-row  flex-row space-x-16 bg-ashgray2 my-3' >
                    <div className='flex flex-col items-start'>
                        <div className='flex'>
                            <div className='flex flex-col justify-center items-start basis-1/5'>
                            <img className='' src={electrodite} srcSet={` ${electrodite} 1x, ${electrodite2x} 2x`} alt="electrodite logo"/>
                            </div>
                            <div className='flex flex-col basis-4/5'>
                                <p className='text-2xl text-ashred vollkorn-sc-semibold'>Electrodite™</p>

                                <p className='text-base text-justify'>This Back Fill Compound functions as a standard solution, adept at absorbing and retaining moisture. By doing so, it effectively reduces soil resistivity, facilitating the rapid dissipation of fault current. Comprised of natural elements, it plays an active role in facilitating the dispersion of fault current. This compound possesses hygroscopic properties, expanding upon contact with water, thereby ensuring consistent contact between the electrode surface and the soil. This contact guarantees a seamless and efficient discharge of fault current. It is particularly suited for average soil conditions. Under ideal moist conditions, its resistivity is less than 5 ohm-metre, while in dry conditions, it exceeds 5 ohm-metre. The pH value ranges from 7.0 to 8.5. </p>
                                <p className='text-base text-justify text-green-500'>Available in : 15 kg & 25 kg</p>
                            </div>
                            <div className='flex flex-col justify-start basis-1/5'>
                            <img className='px-2' src={electrodite_pow} srcSet={` ${electrodite_pow} 1x, ${electrodite_pow_2x} 2x`} alt="electrodite logo"/>
                            </div>
                        </div>
                        <div className='flex'>
                                <div className='flex flex-col justify-center items-start basis-1/5'>
                                     <img className='' src={conductolite} srcSet={` ${conductolite} 1x, ${conductolite2x} 2x`} alt="conductolite logo"/>
                                </div>
                                <div className='flex flex-col basis-4/5'>
                                    <p className='text-2xl text-ashred mt-2 vollkorn-sc-semibold'>Conductolite®</p>

                                    <p className='text-base text-justify '> This exceptional conductive carbonaceous substance serves as a top-tier option for achieving robust, high-conductivity earthing solutions. Its application leads to a substantial reduction in impedance, consequently elevating the overall effectiveness, dependability, and lifespan of earthing systems. Conductolite® has been meticulously formulated to function seamlessly in soil environments characterized by low electrical conductivity. This material not only enhances soil conductivity, facilitating swifter current dissipation, but also possesses hygroscopic and anti-corrosive attributes. It is particularly recommended for challenging conditions. Under dry conditions, its resistivity is less than 0.5 ohm-metre, and it maintains consistent resistivity irrespective of moisture levels. The pH value ranges from 6.5 to 8.0.</p>
                                    <p className='text-base text-justify text-green-500'>Available in : 25 kg</p>
                                </div>
                                <div className='flex flex-col justify-start  basis-1/5'>
                                     <img className='px-2' src={conductolite_pow} srcSet={` ${conductolite_pow} 1x, ${conductolite_pow_2x} 2x`} alt="conductolite logo"/>
                                </div>
                        </div>
                        <div className='flex'>
                                <div className='flex flex-col justify-center items-start basis-1/5'>
                                     <img className='' src={zetalite} srcSet={` ${zetalite} 1x, ${zetalite2x} 2x`} alt="zetalite logo"/>
                                </div>
                                    <div className='flex flex-col basis-4/5'>
                                        <p className='text-2xl text-ashred mt-2 vollkorn-sc-semibold'>Zetalite™</p>

                                        <p className='text-base text-justify'> Comprising a blend of naturally existing elements, this compound is designed to enhance the soil’s conductivity surrounding the earth electrode. By incorporating Zetalite, the earthing electrode’s resistance can be notably reduced. It boasts exceptional conductivity and possesses the ability to retain significant levels of moisture and minerals without experiencing shrinkage or cracks. This clay-based compound is characterized by its elevated water retention capacity, as well as its pronounced thickening and conductive characteristics. </p>
                                        <p className='text-base text-justify text-green-500'>Available in : 15 kg</p>
                                    </div>
                                <div className='flex flex-col justify-start  basis-1/5'>
                                     <img className='px-2' src={zetalite_pow} srcSet={` ${zetalite_pow} 1x, ${zetalite_pow_2x} 2x`} alt="zetalite logo"/>
                                </div>
                        </div>
                        
                        
                    </div>
                </div>
         </div>
         <div className='w-full flex-col mx-auto flex justify-center items-center bg-ashgray1 pb-3'>
            <div className='container  mx-auto flex max-w-screen-xl flex-row  flex-row space-x-16  bg-ashgray1' >
                    <div className='flex flex-col items-start'>
                    <div className='flex'>
                                <div className='flex flex-col justify-center items-start basis-1/5'>
                                     <img className='' src={terraion} srcSet={` ${terraion} 1x, ${terraion2x} 2x`} alt="terraion logo"/>
                                </div>
                                <div className='flex flex-col basis-4/5'>
                                    <p className='text-2xl text-ashred mt-2 vollkorn-sc-semibold'>Terraion®</p>

                                    <p className='text-base text-justify '>The recognition that soil treatment involving the application of salt can enhance earthing stems from its ability to lower soil resistivity. However, this approach has drawbacks, as salt tends to leach out from treated soil when exposed to water during rainy seasons, resulting in only temporary improvements. Additionally, this method pollutes groundwater, poses environmental concerns, and accelerates electrode corrosion.  </p>
                                    <p className='text-base text-justify '> In response to these challenges, a formulation has been developed with the harshest global earthing system conditions in mind. This formula serves to reduce soil resistivity and restore soil conditions to normal. Upon the addition of water, it generates a voluminous aqueous solution, creating conductive pathways in the vicinity of the earthing system. It acts as a soil ion enhancer and is used in conjunction with Conductolite, Humedite, or Electrodite, making it an optimal choice for recharging earth pits. The pH value ranges from 7.0 to 9.0</p>
                                    <p className='text-base text-justify text-green-500'>Available in : 10 kg</p>
                                </div>
                                <div className='flex flex-col justify-start  basis-1/5'>
                                     <img className='px-2' src={terraion_pow} srcSet={` ${terraion_pow} 1x, ${terraion_pow_2x} 2x`} alt="terraion logo"/>
                                </div>
                        </div>
                        <div className='flex items-start'>
                                <div className='flex flex-col align justify-center items-start basis-1/5  '>
                                     <img className=' ' src={humdite} srcSet={` ${humdite} 1x, ${humdite2x} 2x`} alt="humdite logo"/>
                                </div>
                                <div className='flex flex-col basis-4/5'>
                                    <p className='text-2xl text-ashred mt-2 vollkorn-sc-semibold'>Humdite®</p>

                                    <p className='text-base text-justify '>Moisture holds a critical role within the earthing system, as the effective dispersion of fault current relies significantly on the capillary and electro-osmotic actions of the soil. Tailored specifically for comparatively arid settings, this compound is designed to attract moisture from its surroundings and retain it over extended durations. Possessing an electrical resistivity of roughly 0.5 ohm-meter, it offers minimal resistance to current dissipation. When combined with water, it transforms into a semi-permeable conductive gel encompassing the electrode, significantly reducing the likelihood of shrinkage. It is especially recommended for conditions with a semi-rocky terrain. Under ideal moist circumstances, its resistivity ranges from 1 to 5 ohm-metre, and its pH value lies between 7.0 and 8.0.  </p>
                                    <p className='text-base text-justify  text-green-500'>Available in : 25 kg</p>
                                </div>
                                <div className='flex flex-col align justify-center  basis-1/5  '>
                                     <img className=' px-2' src={humdite_pow} srcSet={` ${humdite_pow} 1x, ${humdite_pow_2x} 2x`} alt="humdite logo"/>
                                </div>
                        </div>
                    </div>
            </div>
        </div>
         </>
      )
    }
    
    export default Bfcdetailscontent
    