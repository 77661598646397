import React from 'react'
import soiltestimg from './assets/images/our_services/soil_test_image.jpg';
import soiltestimg2x from './assets/images/our_services/soil_test_image@2x.jpg';
import ExapndabelMenu from './ExapndabelMenu';
import bulleticon from './assets/images/soiltest/bullet_icon.svg';


    const SoiltestContent = () => {
      return (
        <div className='w-full bg-white'>
            <div className='container max-w-screen-xl  flex flex-col mx-auto mb-3'>
                <p className=' text-ashred text-2xl my-3  flex'>
                Soil Resistivity Test
                </p>
                
                
                <p className='text-base text-justify '>
                The goal of soil resistivity testing is to obtain measurements that help model the electrical performance of the earth for a specific earthing system. Accurate results depend on proper investigation and correct test execution. Follow these guidelines:
                </p>
                <div>
                    <p className=' text-ashred text-3xl justify-center items-center font-semibold my-3'>
              
                    </p>
                    <ul className="list-none ml2 text-justify">
                        <li className="flex items-start"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-2" /><p> <span style={{fontSize:'130%'}}>Initial Research:</span> Gather background data, including information on nearby metallic structures and the area's geological, geographical, and meteorological characteristics. This includes understanding soil strata, water retention properties, and recent rainfall data to assess if results are realistic</p></li>
                        <li className="flex items-start"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-2" /><p><span style={{fontSize:'130%'}}>Factors Influencing Soil Resistivity:</span> Soil resistivity is affected by texture, mineral composition, grain size, compaction, temperature, and moisture content. It's crucial for designing effective earthing systems.
                        </p></li>
                        <li className="flex items-start"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-2" /><p><span style={{fontSize:'130%'}}>Enhancing Earthing Resistance:</span> Use Backfill Compounds to improve earthing resistance in poor soil conditions. Place safe earth electrodes as deeply as possible, preferably in deeper strata.
                        </p></li>
                    </ul>
                </div>
                {/*
                <p className=' text-ashred text-3xl justify-center items-center font-semibold my-3'>
                Soil Resistivity Test
                </p>*/}
                <div className='flex space-x-4'>
                    <div className="flex items-start w-1/2">
                        <div className='flex flex-col justify-start items-start text-justify'>
                           {/*
                            <p>The purpose of resistivity testing is to obtain a set of measurements which may be interpreted to yield an equivalent model for the electrical performance of the earth, as seen by the particular earthing system. However, the results may be incorrect or misleading if adequate investigation is not made prior to the test, or the test is not correctly undertaken. To overcome these problems, the following data gathering and testing guidelines are suggested: An initial research phase is required to provide adequate background, upon which to determine the testing program, and against which the results may be interpreted. Data related to nearby metallic structures, as well as the geological, geographical and meteorological nature of the area is very useful. For instance the geological data regarding strata types and thicknesses will give an indication of the water retention properties of the upper layers and also the variation in resistivity to be expected due to water content. By comparing recent rainfall data, against the seasonal average, maxima and minima for the area it may be ascertained whether the results are realistic or not. </p>
                            <p className='my-3'> Soil resistivity is a crucial factor in designing the earthing system for new installations to ensure they meet your specified earth resistance standards. Various factors, such as soil texture, mineral composition (ion content), grain size, compaction, temperature, and moisture content, influence soil resistivity.</p>
                            <p  className='mb-3'> <span className='vollkorn-sc-semibold'>Ashlok</span> offers a range of Backfill Compounds that can enhance the earthing resistance in cases of poor soil conditions, enabling you to achieve the desired level of grounding. It is advisable to place the safe earth electrodes as deeply as possible into the earth, preferably in deeper strata. </p>
                            */}                   
                            <div className='flex flex-col'> 
                                <p className=' text-base  '>
                                Calculating Soil Resistivity (Wenner Method): ρ = 2πAR

                                </p>
                                {/*
                                <p className=' text-base text-left'>
                                    ρ= 2 π A R 
                                </p>
                                <p className=' text-base text-justify'>
                                    where
                                </p>*/}
                                <ul className="list-none ml2">
                                    <li className="flex items-center"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-1" /> ρ= Average soil resistivity to depth A (ohm-cm) </li>
                                    <li className="flex items-center"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-1" /> π = 3,1416</li>
                                    <li className="flex items-center"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5" /> A = Distance between electrodes (cm)     </li>
                                    <li className="flex items-start"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-1.5" /> R = Measured resistance value (ohm)
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                    <div className=" w-1/2 justify-start items-start">
                         <img className=" pt-1.5" src={soiltestimg}  srcSet={`${soiltestimg} 1x, ${soiltestimg2x} 2x`} />
                    </div>
                </div>
                <div className=' flex text-justify flex-col'>
                
                <p className=' text-ashred text-3xl justify-center items-center font-semibold my-3'>
                Four-Point Method:
                </p>
                <ul className="list-none ml2">
                    <li className="flex items-center"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5" />Four electrodes spaced equidistantly and driven vertically into the ground
                    </li>
                    <li className="flex items-start"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-1" />A test current I passes between the outer electrodes, measuring the potential V between the inner electrodes
                    </li>
                    <li className="flex items-center"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5" />V/I provides resistance R
                    </li>
                    <li className="flex  items-start"> <img  src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-1.5" />The tester uses Ohm’s Law (V = IR) to calculate soil resistance
                    </li>
                </ul>
                </div>
                <div>

                <p className=' text-ashred text-3xl justify-center items-center font-semibold my-3'>
                Benefits of Soil Resistivity Testing:
                </p>
                <ul className="list-none ml2">
                    <li className="flex items-center"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5" />Ensures a well-protected and professional facility
                    </li>
                    <li className="flex items-center"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5" />Helps select the type and material of earth electrode
                    </li>
                    <li className="flex items-center"> <img src={bulleticon} alt="Your Image" className="mr-2 pl-5" />Demonstrates that many short rods are often more effective than a few long ones
                    </li>
                    <li className="flex  items-start"> <img  src={bulleticon} alt="Your Image" className="mr-2 pl-5 pt-1.5" />Emphasizes the importance of the earthing system in site planning, which can be enhanced with conventional or advanced methods using electrodes with conductive backfill.
                    </li>
                </ul>

                </div>
                    {/*
                 <div className='m-10 max-w-7xl'>
                    
                </div>*/}
            </div>
            {/*
            <div className=' bg-ashblack p-5 text-white flex justify-center '>
                FAQ
            </div>
            <div className=' container mx-auto flex flex-col max-w-7xl'>
                    
                        <ExapndabelMenu title="What is the significance of a Soil Resistivity test?" 
                        content={"Soil resistivity measurements serve the following purposes:"   }
                        bullet1={["Determining the earth resistance for a planned Substation or Earthing system installation","Assessing potential gradients, including step and touch voltage."]}
                        bgColor="bg-ashred"  textColor="text-white"
                        className=' text-white bg-ashred'
                        />
                    
                        <ExapndabelMenu title="Varieties of Soil Resistivity Testing :" 
                        content={"Earth resistivity measurements are useful in,"   }
                        bullet1={["Uniformly Distributed or Wenner Configuration","Non-uniformly Distributed or Schlumberger-Palmer Configuration","Single Rod Testing Method"]}
                        bgColor="bg-ashred"  textColor="text-white"
                        className=' text-white bg-ashred'
                        />
                    
        </div> */}
          
        </div>
      )
    }
    
    export default SoiltestContent
    
