import React from 'react'
import clpdesign from './assets/images/clpdesign/clp_design.png';
import clpdesign2x from './assets/images/clpdesign/clp_design@2x.png';

import clp from './assets/images/clpdesign/clp.png';
import clp2x from './assets/images/clpdesign/clp@2x.png';

    const ClpdetailsContent = () => {
      return (
        <div className='w-full my-3 flex justify-center items-center'>
            <div className=' container max-w-screen-xl flex flex-col justify-center'>
                <img src={clpdesign} alt="clp design"
                srcSet={` ${clpdesign} 1x, ${clpdesign2x} 2x`}/>
                <div className='relative flex items-center justify-center m-5'>
                <img className="z-10" src={clp} alt="clp"
                     srcSet={` ${clp} 1x, ${clp2x} 2x`}/>
                     
                </div>
                <div className='container max-w-screen-xl flex flex-col justify-center  items-start'>
                    <p className=' text-ashred text-2xl justify-center'><span className='vollkorn-sc-semibold'>  Conventional Lightning Protection</span></p>
                    <p className=' text-black text-justify '> <span className='vollkorn-sc-semibold'>Ashlok’s Conventional Lightning Protection (CLP)</span> System has two primary methods for the complete protection of any structure. Internal CLP Systems usually comprise of a range of Surge Protective Devices.</p>
                    <p className=' text-black text-justify '>External <span className='vollkorn-sc-semibold'> Conventional Lightning Protection Systems</span> are the primary point of direct lightning strikes which are designed on a combination of Rolling Sphere | Angular Method | Faraday Cage which enables the safe interception, conduction and immediate dissipation of lightning current into the ground during any thunderstorm</p>
                    <p className=' text-black text-justify '><span className='vollkorn-sc-semibold'>Ashlok’s</span> CLP Systems is designed in such a way that the sum of its parts comprised of a combination of Lightning Rods, Conductors, Fasteners, Earthing & BackFill Compound(s) works in tandem to ensure the safety of the structure.</p>
                </div>

            </div>

          
        </div>
      )
    }
    
    export default ClpdetailsContent
    