import React,{useState} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';

import cpimg from './assets/images/earthing/copper_plated_cp/copper_plated_big.png';
import cpimg2x from './assets/images/earthing/copper_plated_cp/copper_plated_big@2x.png';
    
    const Aseel_cp_earthing = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };
    
        return (
            <>
            <EarthingelectrodeSubMenu onImageClick={handleImageClick}/>
            <div className='w-full my-3 flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center '>
                        <img src={cpimg}  
                                                srcSet={`${cpimg} 1x,${cpimg2x} 2x`} alt=" ASEEl CP logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred'><span className='vollkorn-sc-semibold'>ASEEL CP <span className=' text-4xl px-1'>|</span> Copper Plated Electrode (<span style={{fontSize:'80%'}}>s</span>)</span></p>
                        <p className=' text-base text-justify p-common'>Using 99.99% pure electrolytic copper thickness of 250 microns over a low carbon high steel rod Baron Plating assures Corrosion-free property against all assets. Our Platings service are ideal in Industrial requisites & copper plating Indian standard - IS 1772.</p>
                       
                    </div>
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                
                    <div className='flex   space-x-10'>
                 
    
                        <div className='w-1/2'>
                        <p className=' text-1xl text-ashred font-extrabold'>Features & Benefits</p>
                            <ul className="list-none">
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2  my-1.5"/>Copper coating with a thickness ranging from 100 to over 250 microns</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2   my-1.5"/>Exceptionally high electrical conductivity (5.96 x 107 s/m) along with excellent resistance to corrosion</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2   my-1.5"/>Customisation is available as per customers requirement</li>

                                             </ul>
                        </div>
                        <div className='w-1/2'>
                        <p className=' text-1xl'></p>
                            <ul className="list-none ml-2">
                            <li className="flex items-center p-common text-ashgray1 text-xs">Extended operational lifespan</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2   my-1 pt-1"/>Extended operational lifespan</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2   my-2.5 pt-1"/>Ideally suited for harsh surroundings</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2   my-1 pt-0.5"/>Anodic protection</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2  my-1 pt-0.5"/>Purity of copper is more than 99%</li>
                                             </ul>
                        </div>
                       
                        <div className='w-1/4'></div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <p className='text-xl'>ASEEL Copper Plating Earthing Electrode(s) Variant</p>
                    <div className='flex'>
                        <div className='w-full   mb-2'>
                                    <table className=" w-full divide-y divide-gray-200  p-common">
                                        <thead className='w-full'>
                                            
                                            <tr className="bg-ashred">
    
                                            <th className=" px-4 py-2 text-white">Model</th>
                                            <th className="  px-4 py-2 text-white">Length (Mtr)</th>
                                            <th className="  px-4 py-2 text-white">Inner Pipe Dia  (mm)</th>
                                            <th className="  px-4 py-2 text-white">Outer Pipe Dia  (mm)</th>
                                            
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-200">
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 192 CP 100 </td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48-50 </td>
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 193 CP 100</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48-50 </td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 192 CP 250 </td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48-50 </td>
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 193 CP 250 </td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48-50 </td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 392 CP 100 </td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">75-76</td>
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 393 CP 100 </td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">75-76</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 392 CP 250</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">75-76</td>
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 393 CP 250 </td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">75-76</td>
                                            </tr>
    
                                           
                                        
                                        </tbody>
                                    </table>
                                    <p>* Standard Tolerance are applicable </p>
                        </div>
                        
                    </div>
                
                
                       
                       
                    
                </div>
                
            </div>
            </>
          )
    }
    
    export default Aseel_cp_earthing
    