import React,{useState} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';
import ccimg from './assets/images/earthing/carbon_coating_cc/carbon_coating_big.png';
import ccimg2x from './assets/images/earthing/carbon_coating_cc/carbon_coating_big@2x.png';
    const Aseel_cc_earthing = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };
    
        return (
            <>
            <EarthingelectrodeSubMenu onImageClick={handleImageClick}/>
           
            <div className='w-full my-3 flex justify-center items-center'>
                 <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                 <p className='flex  justify-center text-2xl text-ashred my-2'><span className='vollkorn-sc-semibold'>Ashlok’s</span><span className='text-white' style={{fontSize:'40%'}}>s</span>Sentinel Range of Safe Earthing Electrode(<span style={{fontSize:'80%'}}>s</span>)</p>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-3'>
                        <img src={ccimg}  
                                                srcSet={`${ccimg} 1x,${ccimg2x} 2x`} alt=" ASEEl CC logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start '>
                        <p className=' text-2xl text-ashred'><span className='vollkorn-sc-semibold'>ASEEL CC <span className= 'text-4xl px-1'>|</span> Composite Carbon Coating Technology</span></p>
                        <p className=' text-base text-justify p-common '>The biggest challenge faced by an Earthing System has always been the deteriorating effects on the system due to Corrosion & fluctuations in Resistance caused due to the vagaries of Mother Earth. To take on Mother Earth is an impossibility based on the current levels of technology available to Mankind. We went and solved the other challenge that threatens Your Earthing System.. That of Corrosion. With that in mind, we humbly present to you the crown jewel. <span className=' font-extrabold'><span className='vollkorn-sc-semibold'>Ashlok's</span> Sentinel Range of Safe Earthing Electrode(s).</span></p>
                        <p className=' text-base text-justify p-common mt-1'>The R&D team of <span className='vollkorn-sc-semibold'>Ashlok</span> has developed a proprietary coating for the Safe Earthing Electrode(s) that precludes the possibility of Corrosion. Its the first Safe Earthing Electrode to incorporate the latest proprietary Carbon Coating Technology from <span className='vollkorn-sc-semibold'>Ashlok</span></p>
                       
                    </div>
                    {
                    <div className='flex flex-col items-center justify-center  my-3'>
                            <div className='flex justify-center items-center text-xl text-ashred'>With that in mind, we humbly present to you the crown jewel.. </div>
                            <div className='flex justify-center items-center text-2xl'><span className='vollkorn-sc-semibold font-bold text-ashred mr-2'>Ashlok’s  <span className=' text-black'>    Sentinel Range of </span></span></div>
                            <div className='flex justify-center items-center text-2xl vollkorn-sc-semibold'>Safe Earthing Electrode(<span  style={{fontSize:'80%'}}>s</span>)</div>
                    </div>
                    }
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                
                    <div className='flex   space-x-10'>
                
    
                        <div className='w-1/2'>
                        <p className=' text-1xl text-ashred font-extrabold'>Features & Benefits</p>
                            <ul className="list-none">
                            <li className="flex items-start p-common py-1"><img src={boltico} className="mr-2  my-1.5 "/>Zero galvanic corrosion</li>
                            <li className="flex items-start p-common py-1"><img src={boltico} className="mr-2  my-1.5 "/>Proprietary carbon composition </li>
                            <li className="flex items-start p-common py-1"><img src={boltico} className="mr-2  my-1.5 "/>Uniform dissipation of current</li>
                            <li className="flex items-start p-common py-1"><img src={boltico} className="mr-2  my-1.5 "/>Customisation is available as per customers requirement</li>

                                             </ul>
                        </div>
                        <div className='w-1/2'>
                        <p className=' text-1xl'></p>
                            <ul className="list-none ml-2">
                            <li className="flex items-center p-common text-ashgray1">Eco-Friendly | Long Life</li>
                            <li className="flex items-center p-common py-1"><img src={boltico} className="mr-2  my-1 pt-1"/>Eco-Friendly | long life</li>
                            <li className="flex items-center p-common py-1"><img src={boltico} className="mr-2  my-1 pt-1"/>Low resistivity</li>
                            <li className="flex items-center p-common py-1"><img src={boltico} className="mr-2  my-1 pt-1"/>Designed for all highly corrosive terrain</li>
                            
                                             </ul>
                        </div>
                       
                        <div className='w-1/4'></div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                
                    <div className='flex'>
                        <div className='w-full  mt-2 mb-2'>
                                    <table className=" w-full divide-y divide-gray-200  p-common">
                                        <thead className='w-full'>
                                            
                                            <tr className="bg-ashred">
    
                                            <th className=" px-4 py-2 text-white">Model</th>
                                            <th className=" px-4 py-2 text-white">Length (Mtr)</th>
                                            <th className="  px-4 py-2 text-white">Inner Pipe Dia  (mm)</th>
                                            <th className="  px-4 py-2 text-white">Outer Pipe Dia  (mm)</th>

                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 192 CC</td>
                                            <td className=" px-4 py-2 text-center">2 </td>
                                            <td className=" px-4 py-2 text-center">26 - 27</td>
                                            <td className=" px-4 py-2 text-center">48 - 50</td>
 
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 193 CC</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">26 - 27</td>
                                            <td className=" px-4 py-2 text-center">48 - 50</td>
 
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 392 CC</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">38 - 42</td>
                                            <td className=" px-4 py-2 text-center">75 - 76</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 393 CC</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">38 - 42</td>
                                            <td className=" px-4 py-2 text-center">75 - 76</td>
                                            </tr>
    
                                           
                                        
                                        </tbody>
                                    </table>
                        </div>
                        
                    </div>
                
                
                       
                       
                    
                </div>
                
            </div>
            </>
          )
    }
    
    export default Aseel_cc_earthing
    