import React,{useState} from 'react'
import alicebig from './assets/images/alicepage/alice_big.png';
import alicebig2x from './assets/images/alicepage/alice_big@2x.png';

import boltico from './assets/images/thordetails/bolt_icon.svg';
import LightningProductsSubMenu from './LightningProductsSubMenu';
import "./parafont.css"
    const Alicedigitaldetails = ({ onImageClick }) => {

            const [selectedSubmenu, setSelectedSubmenu] = useState('');
    
            const handleImageClick = (system) => {
                setSelectedSubmenu(system);
                console.log("selcet system is"+system);
            };
    
            return (
                <>
               
                <div className='w-full my-3 flex justify-center items-center'>
                    <div className='container mx-auto max-w-screen-xl flex flex-col justify-center'>
                        <div className='flex justify-center'>
                            
                            <div className='flex justify-center items-center my-5'>
                            <img src={alicebig}  
                                                    srcSet={`${alicebig} 1x,${alicebig2x} 2x`} alt=" alice big logo"
                                                    /> 
                            </div>
                           
                        </div>
                        <div className='flex flex-col items-start'>
                            <p className=' text-2xl text-ashred tracking-widest stitle'><span className='vollkorn-sc-semibold'>A.L.I.C.E Digital</span></p>
                           {/* <p className=' p-common text-base text-justify '>At <span className='vollkorn-sc-semibold'>Ashlok</span>, we understand that a robust lightning protection system is built on more than just lightning rods and grounding electrodes. That’s why we offer a comprehensive range of Lightning Protection Accessories designed to enhance the efficiency and effectiveness of your lightning protection system. Our accessories are meticulously crafted to ensure the utmost safety and security for your property, assets, and people.</p>
                            <p className=' text-xl font-bold mt-3'>Explore our Lightning Protection Accessories</p>
                            <p className=' text-base text-ashred'><span className='vollkorn-sc-semibold'>Ashlok’s</span> SLPR Mast | A.L.I.C.E Digital | Earthing Test Link | Equipotential Busbar | SLPR Accessories Kit</p>
                            <p className=' p-common text-base text-justify'>Trust <span className='vollkorn-sc-semibold'>Ashlok</span> for all your lightning protection accessory needs. With our commitment to quality and safety, you can count on our accessories to bolster the performance of your lightning protection system. Your safety is our priority, and <span className='vollkorn-sc-semibold'>Ashlok</span> Lightning Protection Accessories are here to secure your world.</p>*/}
                        </div>
                        
                        
                    </div>
                </div>
                <div className='flex justify-center items-center bg-ashgray1 '>
                    <div className='container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                    <p className=' text-2xl font-bold '>A.L.I.C.E® | <span className='vollkorn-sc-semibold'>Ashlok’s</span> Lightning Impulse Counter Electromechanical</p>
                    <p className=' text-1xl font-bold text-ashred mt-2 mb-2 '>Characteristics</p>
                        <div className='flex'>
                            
                            <div className='w-1/2'>
                                <ul className="list-none">
                                <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5 "/>Dimensions | Box : 80 x 120 x 85 mm</li>
                                <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Maximum Display: 9999 9999, functions without external power</li>
                                <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Minimum Current (I min): 1KA</li>
                                <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/> Lightweight and Simple Installation</li>
                                                 </ul>
                            </div>
                            <div className='w-1/2'>
                                <ul className="list-none">
                                <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Certified & Tested by CPRI Bangalore | Only Lightning Impulse Counter </li>
                                <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Tested for dual Polarities | Positive & Negative </li>
                                <li className="flex items-center p-common"><img src={boltico} className="mr-2  pt-1.5"/>Compatible with both Conventional Lightning Arresters and ESE Type Lightning Arresters </li>
                               
                                     </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center h-0'></div>
                
                </>
              )
    }
    
    export default Alicedigitaldetails
    