import React from 'react'
import ashish from './assets/images/founder/ashish_tripathy.jpg';
import ashish2x from './assets/images/founder/ashish_tripathy@2x.jpg';
import foun from './assets/images/founder/ashok_tripathy.jpg';
import foun2x from './assets/images/founder/ashok_tripathy@2x.jpg';

    const Foundercontent = () => {
      return (
        <>
        <div className='w-full flex flex-col justify-center items-center bg-ashgray2'>
          <div className=' container flex w-max-xl flex-col m-5 '>
            <p className='flex  justify-center items-center'><span className='text-ashred text-2xl '>OUR STORY</span></p>
            <p className='text-base text-justify m-2'>
            Founded in 1999 by the visionary, late Mr. Ashok Tripathy, Ashlok Safe Earthing Electrode Limited embarked on a transformative journey in the field of electrical safety. From its inception, the company set out to address prevalent electrical challenges with innovative solutions. Under Mr. Tripathy’s leadership, Ashlok introduced Maintenance-Free Earthing Technology in 1999, revolutionizing conventional earthing methods in India and establishing the company as a trailblazer in the industry. 
            </p>
            <p className='text-base text-justify m-2'>
            Over the years, Ashlok’s commitment to quality and innovation propelled its growth, expanding its product offerings to a comprehensive range of Earthing & Lightning Protection solutions. The company’s diverse portfolio includes cutting-edge technologies designed to safeguard structures from lightning and ensure effective earthing, reflecting its dedication to staying ahead of industry needs.
            </p>
            <p className='text-base text-justify m-2'>
            As Ashlok Safe Earthing Electrode Limited steps into its 25th year, the journey is marked by milestones of success and a legacy of reliability. The company’s continuous evolution and adaptability have enabled it to become a trusted name in the electrical safety sector. This milestone year is a testament to Ashlok’s resilience and commitment to excellence, paving the way for even greater achievements in the years to come. As it celebrates a quarter-century of innovation, Ashlok remains dedicated to providing state-of-the-art solutions that redefine standards and contribute to the safety and reliability of electrical systems nationwide.
            </p>
          </div>
          </div>
        <div className='w-full flex flex-col justify-center items-center'>
          <div className=' container flex w-max-xl space-x-10 mt-5 mb-10'>
            <div className='w-1/2 flex flex-col  items-center'>
                <img src={foun}
                    srcSet={`${foun} 1x,${foun2x} 2x`}
                    alt="founder"/>
                <p className=' text-3xl text-ashred  m-2 font-semibold' style={{ fontFamily: 'Cinzel, sans-serif' }}>LATE. Mr. ASHOK TRIPATHY</p>
                <p className=' text-ashblack text-2xl' style={{ fontFamily: 'Rajdhani, sans-serif' }}> Founder</p>
                <p className='text-base text-justify m-2 mt-10'>In 1999, late <span className=' text-ashred'>Mr. Ashok Tripathy</span> sowed the seeds of Ashlok Safe Earthing Electrode Limited, leaving behind a profound legacy in the realm of electrical safety. His vision and pioneering spirit laid the foundation for a company that continues to thrive on his principles of excellence, innovation, and unwavering commitment.</p>
                <p className='text-base text-justify m-2'><span className=' text-ashred'>Mr. Ashok Tripathy’s</span> journey began with a deep understanding of the pressing electrical challenges faced by industries and individuals alike. Motivated by a passion to make a lasting impact, he founded Ashlok Safe Earthing Electrode Limited to introduce revolutionary solutions that would redefine the standards of electrical safety.</p>
                <p className='text-base text-justify m-2'>Under his leadership, the company flourished, propelled by the introduction of Maintenance-Free Earthing Technology in 1999. This groundbreaking innovation not only addressed prevalent electrical challenges but also became a hallmark of the company’s commitment to providing advanced and reliable earthing solutions.</p>
                <p className='text-base text-justify m-2'>Although the visionary founder, <span className=' text-ashred'>Mr. Ashok Tripathy,</span> is no longer with us, his legacy lives on through the enduring values and principles instilled in the fabric of Ashlok Safe Earthing Electrode Limited. The company continues to honour his memory by upholding the highest standards of quality, innovation, and customer satisfaction.</p>
                <p className='text-base text-justify m-2'>As we reflect on the journey that began in 1999, we pay tribute to the indomitable spirit of our late founder, whose vision and dedication remain the guiding light for Ashlok.</p>

            </div>
            <div className='w-1/2 flex flex-col  items-center'>
            <img src={ashish}
                    srcSet={`${ashish} 1x,${ashish2x} 2x`}
                    alt="ashish"/>
                <p className=' text-3xl text-ashred m-2 font-semibold' style={{ fontFamily: 'Cinzel, sans-serif' }}>Mr. ASHISH TRIPATHY</p>
                <p className=' text-ashblack text-2xl ' style={{ fontFamily: 'Rajdhani, sans-serif' }}> Continuing the Legacy as Chairman of</p>
                <p className=' text-ashblack text-2xl ' style={{ fontFamily: 'Rajdhani, sans-serif' }}>Ashlok Safe Earthing Electrode Limited</p>
                <p className='text-base text-justify m-2'><span className=' text-ashred'>Ashish Tripathy,</span> the son of the late visionary founder <span className=' text-ashred'>Mr. Ashok Tripathy,</span> steps into the role of Chairman at Ashlok Safe Earthing Electrode Limited. Carrying forward his father’s legacy, <span className=' text-ashred'>Ashish</span> brings a dynamic and forward-thinking approach to lead the company into new realms of success.</p>
                <p className='text-base text-justify m-2'>Having grown up immersed in the ethos of electrical safety and innovation, <span className=' text-ashred'>Ashish</span> possesses a profound understanding of the industry. Under the guidance of his father, he gained invaluable insights into the challenges and opportunities that define the landscape of electrical solutions</p>
                <p className='text-base text-justify m-2'>As Chairman, <span className=' text-ashred'>Ashish</span> is committed to upholding the core values instilled by his father – a dedication to excellence, a passion for innovation, and an unwavering commitment to customer satisfaction. Building on the foundation laid by <span className=' text-ashred'> late Mr. Ashok Tripathy, Ashish</span> is steering Ashlok towards new heights of success and prominence in the electrical safety sector.</p>
                <p className='text-base text-justify m-2'>Inheriting the reins of leadership, <span className=' text-ashred'>Ashish</span> is driving the company to explore new frontiers in technology and service. His vision includes expanding the reach of Ashlok’s cutting-edge products, ensuring they continue to set industry standards and meet the evolving needs of a dynamic market.</p>
                <p className='text-base text-justify m-2'>Under his leadership, Ashlok remains a steadfast beacon of reliability and innovation, staying true to the legacy of its founder. As Chairman, <span className=' text-ashred'>Ashish</span> is not just continuing the family legacy; he is shaping the future of electrical safety solutions with a fresh perspective and a commitment to excellence.</p>
            
            
            </div>

          </div>
        </div>
        </>
      )
    }
    
    export default Foundercontent
    