// BannerContext.js
import React, { createContext, useState, useContext } from 'react';

const BannerContext = createContext();

 const BannerProvider = ({ children }) => {
  const [isBannerLoaded, setBannerLoaded] = useState(false);

  const handleBannerLoad = () => {
    setBannerLoaded(true);
  };

  return (
    <BannerContext.Provider value={{ isBannerLoaded, handleBannerLoad }}>
      {children}
    </BannerContext.Provider>
  );
};

//export const useBannerContext = () => useContext(BannerContext);
// Custom hook to use the BannerContext
const useBannerContext = () => useContext(BannerContext);

export { BannerProvider, useBannerContext };