import React, { useRef, useLayoutEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import "./infinitescroll.css";

gsap.registerPlugin(ScrollTrigger);

const Infinitescrollnew = ({ images }) => {
  const carouselRef = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [totalWidth, setTotalWidth] = useState(0); 
  const fadeDuration = 1.5;
const stayDuration = 5;
const stagger = fadeDuration + stayDuration;

  // Function to handle image load
  const handleImageLoad = () => {
    const imageElements = carouselRef.current.querySelectorAll('.ashclient-image');
    let newTotalWidth = Array.from(imageElements).reduce((acc, img) => acc + img.offsetWidth, 0);
    newTotalWidth = newTotalWidth/2;   /// to avid white space after last images
    setTotalWidth(newTotalWidth); // Set total width after images load
    setImagesLoaded(true);
    //console.log(newTotalWidth);
  };

  useLayoutEffect(() => {
    if (!imagesLoaded || !carouselRef.current) return;
  
    const carousel = carouselRef.current;
    if (!carousel) return;
  
    // Force reflow
    const height = carousel.offsetHeight; // Accessing layout properties like offsetHeight forces the browser to re-calculate the layout, ensuring that dimensions are up-to-date and correct.
   // console.log("Force reflow height:", height);
  
    const imageElements = carousel.querySelectorAll('.ashclient-image');
    imageElements.forEach(img => {
    //  console.log("Image width:", img.offsetWidth);
    });
      // Access the last image
    const lastImage = imageElements[imageElements.length - 1];

      // Get the `x` position of the last image using getBoundingClientRect
    const lastImageX = () => lastImage.getBoundingClientRect().x;
    //console.log("Last image's x position:", lastImageX);
  
  //  const totalWidth = Array.from(imageElements).reduce((acc, img) => acc + img.offsetWidth, 0);
    //console.log("Total Width:", totalWidth);
    const totalImages = imageElements.length;
 // Duplicate the images for seamless loop
    

    gsap.to(carousel, {
      x: `-${totalWidth}px`,
      ease: "linear",
      duration: 50,  // duration: 200,
      repeat: -1,
      modifiers: {
        x: gsap.utils.unitize(x => parseFloat(x) % totalWidth),
      },
      onRepeat: () => {
        console.log("Animation loop restarted");
        gsap.set(carousel, { x: 0 }); // Reset to the beginning on repeat
      },
      onStart: () => {
       // console.log("Animation started");
      },
      // stagger: {
      //   each: stagger,
      //   onStart: function() {
      //     console.log(this);
      //   }
      // }
    });
    
    gsap.set(carousel, { willChange: 'transform' });
  
    return () => {
      gsap.killTweensOf(carousel); // Kill any running animations
    };
  }, [imagesLoaded, images,totalWidth]);

  return (
    <div className="ashclientimage-container">
      <div className="ashclient_list" ref={carouselRef}>
        {images.concat(images).map((image, index) => (
          <img
            key={index}
            src={image.url}
            className="ashclient-image"
            style={{ display: 'inline-block', marginRight: '10px' }}
            alt={`carousel ${index}`}
            onLoad={handleImageLoad} // Handle image load   onLoad handler, you made sure that the images were fully loaded before performing any calculations. This ensures that the dimensions of the images are accurate and reflects in the carousel width calculation.
          />
        ))}
      </div>
    </div>
  );
};

export default Infinitescrollnew;