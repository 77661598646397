import React from 'react'
import worldmap from './assets/images/Home/worldmap_nasa.jpg';
import worldmap2x from './assets/images/Home/worldmap_nasa@2x.jpg';
const HomeMap = () => {
  return (
    <div className='container max-w-screen-xl mx-auto pt-3'>
        <div className=''>
                <img src={worldmap} alt="Home map" 
                         srcSet={`${worldmap} 1x, ${worldmap2x} 2x`}/>
        </div>
        <div className=' text-justify text-xl  italic my-3'>
            The world map shown above is the result of a series of photographs taken by one of NASA's space satellites during the night and was released to the world in the winter of 2000. Around the same time Ashlok had just turned an year old, and the photograph got etched into our memories as an inspiration, depicting the power, reach and beauty of electricity.
        </div>
      
    </div>
  )
}
export default HomeMap