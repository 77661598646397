    import React,{useState} from 'react'
import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu'
import Careercontent from './Careercontent'
import CareerBanner from './CareerBanner'
import banner1 from './assets/images/career/career_page_banner.jpg';
import banner1_2x from './assets/images/career/career_page_banner@2x.jpg';
import Footer from './Footer'
import { HelmetProvider } from 'react-helmet-async'
import InstallationBanner from './InstallationBanner'
    
    const CareerPage = () => {
      const [isSticky, setSticky] = useState(true);
      const displline=true;
      return (
        <div>
          <HelmetProvider>
            <title>Join Our Team - Careers at Ashlok, India's Earthing & Lightning Protection Leader</title>
            <meta name="description" content="Embark on a rewarding career with Ashlok, a frontrunner in Earthing and Lightning Protection services across India. Explore opportunities to be part of a team that's dedicated to safeguarding various sectors with innovative solutions. Join us in our mission to excel in electrical safety and make a real impact nationwide."/>
          </HelmetProvider>
    
        <PreHeader/>
         <Header isSticky={isSticky}/>
    <Menu isHeaderVisible={isSticky}/>
      <CareerBanner page="career" banalt="career ban1" bannertext="" bannertext2=""  ban1x={banner1} ban2x={banner1_2x}/>
       {/* <InstallationBanner page="career" banalt="career ban1" bannertext1="" bannertext2=" " ban1x={banner1} ban2x={banner1_2x}/>*/}
       
        <Careercontent/>
        <Footer linedisp={displline}/>
        </div>
      )
    }
    
    export default CareerPage
    