    import React,{useState} from 'react'
import InstallationBlock from './InstallationBlock'
import InstallationContent from './InstallationContent'
import InstallationBanner from './InstallationBanner'

import banner1 from './assets/images/installation/installtion_banner.jpg';
import banner1_2x from  './assets/images/installation/installtion_banner@2x.jpg';
import Footer from './Footer';
import MetaTags from './MetaTags';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import { Helmet } from 'react-helmet-async';
    const InstallationPage = () => {
      const [isSticky, setSticky] = useState(true);
      return (
        <div>
          <Helmet>
            <title>Professional Installation Services by Ashlok for Earthing & Lightning Protection</title>
            <meta name="description" content="Experience seamless and expert installation of Earthing and Lightning Protection Systems with Ashlok. Our PAN India service ensures safe, efficient, and compliant setup for all sectors. Learn about our meticulous installation process, adherence to safety standards, and commitment to safeguarding your premises against electrical hazards."/>
          </Helmet>
          
            <PreHeader/>
            <Header isSticky={isSticky}/>
            <Menu isHeaderVisible={isSticky}/>
            
            <InstallationBanner page="installtion" banalt="earthing ban1" bannertext="YOUR SAFETY, OUR EXPERTISE <br>ASHLOK’S INSTALLATION SERVICE" ban1x={banner1} ban2x={banner1_2x}/>
            <div className=' w-full bg-white py-3'>
                <div className=' container flex mx-auto justify-center items-center'>
                    <p className='  text-ashred text-3xl font-semibold'>
                    Earthing & Lightning Installation
                    </p>
                </div>
            </div>
          <InstallationBlock/>
          <InstallationContent/>
          <Footer/>

        </div>
      )
    }
    
    export default InstallationPage
    