import React,{useState,useContext} from 'react'
import { Link,useNavigate } from 'react-router-dom';
import { ProductContext } from './context/Productcontext';

import alicebig from './assets/images/alicepage/alice_big.png';
import alicebig2x from './assets/images/alicepage/alice_big@2x.png';

import boltico from './assets/images/thordetails/bolt_icon.svg';

import "./parafont.css"
import MetaTags from './MetaTags';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';
import InstallationBanner from './InstallationBanner';
import LightningAccessoriesSubMenu from './LightningAccessoriesSubMenu';
import Alicedigitaldetails from './Alicedigitaldetails';
import Earthingtestlinkdetails from './Earthingtestlinkdetails';
import Equipotentialbusbardetails from './Equipotentialbusbardetails';
import SlprMastdetails from './SlprMastdetails';
import SlprAccessories from './SlprAccessories';

import alice_ban from './assets/images/alicepage/alice_page_banner.jpg';
import alice_ban2x from './assets/images/alicepage/alice_page_banner@2x.jpg';

import testlink_ban from './assets/images/earthingtestlink/earthing-test-link_banner.jpg';
import testlink_ban2x from './assets/images/earthingtestlink/earthing-test-link_banner@2x.jpg';

import busbar_ban from './assets/images/equipotential/equipotential_busbar_page_banner.jpg';
import busbar_ban2x from './assets/images/equipotential/equipotential_busbar_page_banner@2x.jpg';


import mast_ban from './assets/images/slprmast/ashlok_slpr_mast_page_banner.jpg';
import mast_ban2x from './assets/images/slprmast/ashlok_slpr_mast_page_banner@2x.jpg';


import acc_ban from './assets/images/slpraccessories/slpr_accessories_kit_page_banner.jpg';
import acc_ban2x from './assets/images//slpraccessories/slpr_accessories_kit_page_banner@2x.jpg';



/*

Note create a commn page for this now it had alice digital



*/
    const Lightningaccessoriesdetails = () => {
        let navigate = useNavigate();
        //const [selectedlightaccSubmenu, setSelectedlightaccSubmenu] = useState('');
        const { selectedLightacc, setLightacc,setCategory  } = useContext(ProductContext);
        const handleImageClick = (system) => {
            //setSelectedlightaccSubmenu(system);
            setLightacc(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
           
            <div>
            {selectedLightacc === 'alice_digital' ? (
                <>
            <InstallationBanner page="installation" banalt="earthing ban1" bannertext="STRENGTHEN YOUR LIGHTNING SHIELDING ASHLOK’S ACCESSORY LINEUP" ban1x={alice_ban} ban2x={alice_ban2x}/>
            
            <LightningAccessoriesSubMenu onImageClick={handleImageClick}/>
            <Alicedigitaldetails/>
            </>
            ): selectedLightacc === 'earth_test_link' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="STRENGTHEN YOUR LIGHTNING SHIELDING ASHLOK’S ACCESSORY LINEUP" ban1x={testlink_ban} ban2x={testlink_ban2x}/>
                <LightningAccessoriesSubMenu onImageClick={handleImageClick}/>
             <Earthingtestlinkdetails/>
            </>
            ): selectedLightacc === 'equi_busbar' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="STRENGTHEN YOUR LIGHTNING SHIELDING ASHLOK’S ACCESSORY LINEUP" ban1x={busbar_ban} ban2x={busbar_ban2x}/>
                <LightningAccessoriesSubMenu onImageClick={handleImageClick}/>
               <Equipotentialbusbardetails/>
            </>
            ):selectedLightacc === 'slpr_mast' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="STRENGTHEN YOUR LIGHTNING SHIELDING ASHLOK’S ACCESSORY LINEUP" ban1x={mast_ban} ban2x={mast_ban2x}/>
                <LightningAccessoriesSubMenu onImageClick={handleImageClick}/>
               <SlprMastdetails/>
            </>
            ):selectedLightacc === 'slpr_acc' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="STRENGTHEN YOUR LIGHTNING SHIELDING ASHLOK’S ACCESSORY LINEUP" ban1x={acc_ban} ban2x={acc_ban2x}/>
                <LightningAccessoriesSubMenu onImageClick={handleImageClick}/>
               <SlprAccessories/>
            </>
            ): (
                <>
             
            <Alicedigitaldetails/>
            </>
            )}
            </div>
            
            </>
          )
    }
    
    export default Lightningaccessoriesdetails
    