    import React,{useState} from 'react'
import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu'
import ContactBanner from './ContactBanner'
import banner1 from './assets/images/contact/contact_banner.jpg';
import banner1_2x from './assets/images/contact/contact_banner@2x.jpg';
import Footer from './Footer'
import Contactcontet from './Contactcontet'
import Contactform from './Contactform'
import { Helmet } from 'react-helmet'    
import HomeBanner from './HomeBanner'

    const ContactPage = () => {
      const [isSticky, setSticky] = useState(true);
      const footerBackgroundColor = "gray";
      const displline= true;
      return (
        <div>
          <Helmet>
        <title>Get in Touch with Ashlok - Your Earthing & Lightning Protection Experts in India</title>
        <meta name="description" content="Contact Ashlok for unparalleled Earthing and Lightning Protection solutions across India. Whether you need expert advice, service inquiries, or support, our team is ready to assist you. Reach out to safeguard your sector with Ashlok's comprehensive protection systems, tailored to your specific needs." />

      </Helmet>
            <PreHeader/>
            <Header isSticky={isSticky}/>
    <Menu isHeaderVisible={isSticky}/>
        
       <ContactBanner page="contact" banalt="contact " bannertext="" ban1x={banner1} ban2x={banner1_2x}/>
       <Contactcontet/>
       <Contactform/>
     
        <Footer backgroundColor={footerBackgroundColor} linedisp={displline}/>
        </div>
      )
    }
    
    export default ContactPage
    