import React from 'react'
import partsaftey from './assets/images/whoweare/partner safety logo.svg';
import sos from './assets/images/whoweare/sos logo.svg';
import ashphoto from './assets/images/whoweare/ashlok_photos.jpg';
import ashphoto2x from './assets/images/whoweare/ashlok_photos2x.jpg';

export default function WhoweareInfo() {
  return (
    <div className=' w-full  flex flex-col justify-center items-center my-10'>
    <div className=' text-4xl text-ashred font-semibold'>SEEriously Immortal Since 1999</div>
    <div className='container mx-auto max-w-screen-xl flex  flex-row justify-start mt-5'>
        <div className='flex  basis-2/12 items-start'>
            <img className=' mt-10 ' src={partsaftey} alt="Partners Saftery"/>
        </div>
        <div className='flex  basis-8/12 items-start'>
        <img src={ashphoto} alt="ashlok photo"
          srcSet={`${ashphoto} 1x, ${ashphoto2x} 2x`} />
    

        </div>
        <div className='flex  basis-2/12 items-start'>
        <img className=' mt-10 '  src={sos} alt="sos"/>
        </div>
        
    </div>
    <div className='container mx-auto max-w-screen-xl flex  flex-row justify-start mt-5'>
    <div className=' text-black text-justify text-base' >
        Pioneers in the production of Earthing electrodes and Lightning Protection Systems, Ashlok Safe Earthing Electrode Limited is an ISO 9001:2015 certified business. We at Ashlok strive to offer the best lightning and earthing protection available by developing cutting-edge, intelligent, and distinctive protection technologies. Ashlok’s Earthing & Lightning protection devices, which are trusted for their safety and reliability purposes, have established their brand on significant industries including industrial units, wind farms, solar farms, refineries, mines, telecommunication towers, office buildings, and houses.
        </div>
    </div>

    
</div>
  )
}
