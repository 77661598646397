    import React,{useState} from 'react'
import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import AshlokwayContent from './AshlokwayContent'
import AshlokwayBanner from './AshlokwayBanner'
import banner1 from './assets/images/ashlok_way/ashlok_way_banner.jpg';
import banner1_2x from  './assets/images/ashlok_way/ashlok_way_banner@2x.jpg';
    
    const AshlokwayPage = () => {
      const [isSticky, setSticky] = useState(true);
      return (
        <div>
      
        <PreHeader/>
         <Header isSticky={isSticky}/>
        <Menu isHeaderVisible={isSticky}/>
       
        <AshlokwayBanner page="ashlokway" banalt="ashlokway ban1" bannertext="Ashlok Way" ban1x={banner1} ban2x={banner1_2x}/>
        <AshlokwayContent/>
        <Footer/>
          
        </div>
      )
    }
    
    export default AshlokwayPage
    