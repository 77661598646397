import React,{useContext} from 'react'
import { ProductContext } from './context/Productcontext';
import { Link,useNavigate } from 'react-router-dom';

import thor from './assets/images/installation/THOR_SLPR_image.png';
import thor2x from './assets/images/installation/THOR_SLPR_image@2x.png';
import zeus from './assets/images/installation/Zeus_SLPR_image.png';
import zeus2x from './assets/images/installation/Zeus_SLPR_image@2x.png';


import frank from './assets/images/installation/Frankyln_image.png';
import frank2x from './assets/images/installation/Frankyln_image@2x.png';
import cbr from './assets/images/installation/cbr_image.png';
import cbr2x from './assets/images/installation/cbr_image@2x.png';



import alloy from './assets/images/installation/Alloy_Coated_image.png';
import alloy2x from './assets/images/installation/Alloy_Coated_image@2x.png';
import copper from './assets/images/installation/Copper_Plated_image.png';
import copper2x from './assets/images/installation/Copper_Plated_image@2x.png';

import rockysoil from './assets/images/installation/rocky_soil.png';
import rockysoil2x from './assets/images/installation/rocky_soil@2x.png';
import semirocky from './assets/images/installation/semi_rocky_soil.png';
import semirocky2x from './assets/images/installation/semi_rocky_soil@2x.png';
import norm_soil from './assets/images/installation/normal_soil.png';
import norm_soil2x from './assets/images/installation/normal_soil@2x.png';
import know from './assets/images/installation/know_more_button.svg';

import skyline from './assets/images/Skyline1x.jpg';
import skyline2x from './assets/images/Skyline2x.jpg';
import grass from './assets/images/grass-1x.jpg';
import grass2x from './assets/images/grass-2x.jpg';

import earthing from './assets/images/installation/Lightning_install_image.jpg';
import earthing2x from './assets/images/installation/Lightning_install_image2x.jpg';
import GifLoader from './GifLoader';
   const InstallationContent = () => {
    let navigate = useNavigate();
    const {setCategory, setProduct} = useContext(ProductContext);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scrolling animation
        });
      };
    const handleItemClick = (item) => {
        if(item==='thor')
        {
            setCategory('slpr');
            setProduct('thor');
            navigate('/lps');
        }
        else if(item==='zeus')
        {
            setCategory('slpr');
            setProduct('zeus');
            navigate('/lps');
        }
        else if(item==='frank')
        {
            setCategory('slpr');
            setProduct('frank');
            navigate('/lps');
        }
        else if(item==='ele')
        {
            setCategory('ele');
            navigate('/lpcat');
        }
        else
        {
             setCategory('ele');
            navigate('/lpcat');
        }
        scrollToTop();
       };

      return (
        <>
        <div className='justify-center bg-white py-5'>
            <div className=' max-w-7xl mx-auto justify-center flex-col '>
                <div className=' flex flex-row space-x-10'>
                    <div className=' border flex basis-1/2 flex-col '>
                        <div className='flex flex-row p-4' onClick={() => handleItemClick('thor')}>
                            <div className='flex flex-col basis-3/4' >
                                <p className=' text-ashred text-2xl'>SLPR INSTALLATION - THOR</p>
                                <p className=' text-black text-base text-justify pt-4'>We firmly affix the Safe Lightning Protection Rod to the roof of the building and establish a connection to THOR’s dedicated earthing system. Additionally, we include a testing point located just above A.L.I.C.E for routine maintenance and testing purposes.</p>
                                <div className='h-4'/>
                                {/*
                                <div className='flex justify-end items-end'>
                                    <img  src={know} className='h-8'/>
                                </div>*/}
                            </div>
                            <div className='flex flex-col basis-1/4 ml-5 justify-center items-center'>
                                <img  src={thor}/>
                            </div>
                        </div>
                    </div>
                    <div className=' border flex basis-1/2 flex-col'>
                        <div className='flex flex-row p-4 ' onClick={() => handleItemClick('zeus')}>
                            <div className='flex flex-col basis-3/4'>
                                <p className=' text-ashred text-2xl'>SLPR INSTALLATION - ZEUS</p>
                                <p className=' text-black text-base text-justify pt-4'>The ZEUS Safe Lightning Protection Rod is strategically installed to redirect the high-voltage current generated by direct lightning strikes away from the building, channeling it into the earth through conductors. This installation effectively safeguards the building against the risk of fire damage.</p>
                                <div className='h-4'/>
                                {/*
                                <div className='flex justify-end items-end'>
                                    <img  src={know} className='h-8'/>
                                </div>
                            */}
                            </div>
                            <div className='flex flex-col basis-1/4 ml-5 justify-center items-center'>
                            <img src={zeus}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* second row */}
                <div className=' flex flex-row space-x-10 mt-10 mb-10'>
                    <div className=' border flex basis-1/2 flex-col '>
                        <div className='flex flex-row p-4' onClick={() => handleItemClick('frank')}>
                            <div className='flex flex-col basis-3/4'>
                                <p className=' text-ashred text-2xl'>SLPR INSTALLATION - FRANKLYN</p>
                                <p className=' text-black text-base text-justify pt-4'>At the pinnacle, we securely fasten the Lightning Rod featuring a pure copper sphere, aligning the pointed spikes in opposite directions while leaving a solitary spike oriented upwards to intercept the lightning leader.</p>
                                <div className='h-4'/>
                                {/*
                                <div className='flex justify-end items-end'>
                                    <img  src={know} className='h-8'/>
                            </div>*/}
                                
                            </div>
                            <div className='flex flex-col basis-1/4 ml-5 justify-center items-center'>
                                <img  src={frank}/>
                            </div>
                        </div>
                    </div>
                    <div className=' border flex basis-1/2 flex-col'>
                        <div className='flex flex-row p-4 ' onClick={() => handleItemClick('ele')}>
                            <div className='flex flex-col basis-3/4'>
                                <p className=' text-ashred text-2xl'>S.E.E INSTALLATION - NORMAL SOIL</p>
                                <p className=' text-black text-base text-justify pt-4'>At Ashlok, we ensure the soil’s suitability for electrode placement by conducting a Soil Resistivity test, enabling efficient dissipation of fault current for a swift response.</p>
                                <div className='h-10'/>
                                {/*
                                <div className='flex flex-row space-x-10'>
                                    <div className='flex justify-start items-start'>
                                        <img  src={norm_soil} className='w-50'/>
                                    </div>
                                   
                                    <div className='flex justify-end items-end'>
                                        <img  src={know}/>
                                    </div>
                                </div>
                                */}
                                
                            </div>
                            <div className='flex flex-col basis-1/4 ml-5 justify-center items-center'>
                            <img src={cbr}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* third Row */}
                {/* second row */}
                <div className=' flex flex-row space-x-10 mt-10 mb-10'>
                    <div className=' border flex basis-1/2 flex-col '>
                        <div className='flex flex-row p-4' onClick={() => handleItemClick('ele')}>
                            <div className='flex flex-col basis-3/4'>
                                <p className=' text-ashred text-2xl'>S.E.E INSTALLATION - ROCKY SOIL</p>
                                <p className=' text-black text-base text-justify pt-4'>Our Safe Earthing Electrodes are inserted into the ground using Terraion BFC, a specially designed material crafted to withstand challenging environmental conditions. We verify the soil’s capacity to support the electrode through a soil resistivity test.</p>
                                <div className='h-16'/>
                                {/*
                                <div className='flex flex-row space-x-10'>
                                    <div className='flex justify-start items-start'>
                                        <img  src={rockysoil} className='w-50'/>
                                    </div>
                                   
                                    <div className='flex justify-end items-end'>
                                        <img  src={know}/>
                                    </div>
                                   
                                </div> */}
                            </div>
                            <div className='flex flex-col basis-1/4 ml-5 justify-center items-center'>
                                <img  src={alloy} srcSet={`${alloy} 1x, ${alloy2x} 2x`}/>
                            </div>
                        </div>
                    </div>
                    <div className=' border flex basis-1/2 flex-col'>
                        <div className='flex flex-row p-4 ' onClick={() => handleItemClick('ele')}>
                            <div className='flex flex-col basis-3/4'>
                                <p className=' text-ashred text-2xl'>S.E.E INSTALLATION - SEMI-ROCKY SOIL</p>
                                <p className=' text-black text-base text-justify pt-4'>By focusing on both preserving soil moisture and ensuring minimal resistance for the safe dissipation of fault current into the earth, our engineers meticulously supervise the entire product installation process. They conduct on-site inspections and provide any necessary assistance to guarantee a successful installation.</p>
                                <div className='h-10'/>
                                {/*
                                <div className='flex flex-row space-x-10'>
                                    <div className='flex justify-start items-start'>
                                        <img  src={semirocky} className=' w-50'
                                         alt="img1"
                                        />
                                    </div>
                                   
                                </div> */}
                                
                            </div>
                            <div className='flex flex-col basis-1/4 ml-5 justify-center items-center'>
                            <img src={copper}/>
                            </div>
                        </div>
                    </div>
                </div>


               
            </div>  
            <div className=' bg-ashblack text-white p-2 text-2xl flex justify-center'>
                    THOR® SLPR INSTALLATION PROCEDURE
            </div> 

        </div>
        <div>
           <img className="mx-auto my-auto mt-10" src={earthing}
                        srcSet={`${earthing} 1x,${earthing2x} 2x`}
                         alt="eartnecess"/>
        </div>      
       <GifLoader/>
       {/*
        <div className='  w-full flex flex-col mt-10 bg-white  items-center'>
 
            <img  src={skyline} alt="skyline"
               srcSet={`${skyline} 1x, ${skyline2x} 2x`}
            />
       
            <img  src={grass} alt="grass" 
            srcSet={`${grass} 1x, ${grass2x} 2x`}
            />
                            </div>*/}
        </>
      )
    }
    
    export default InstallationContent
    