import React,{useState} from 'react'
import frimg from './assets/images/franklynpenta/franklyn_big.png';
import frimg2x from './assets/images/franklynpenta/franklyn_big@2x.png';
import LightningProductsSubMenu from './LightningProductsSubMenu';

import boltico from './assets/images/thordetails/bolt_icon.svg';
    
    const Franklynpentacontent = ({ onImageClick }) => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
            
            <div className='w-full my-3 flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={frimg}  
                                                srcSet={`${frimg} 1x,${frimg2x} 2x`} alt=" franl=klyn logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start '>
                        <p className=' text-2xl text-ashred stitle '><span className='vollkorn-sc-semibold'>Franklyn Lightning Rod</span></p>
                        <p className=' text-xl'>Operational Mechanism</p>
                        <p className=' text-base'>It lures and intercepts incoming direct lightning, channeling high voltage lightning currents into the earth via conductors. This effectively averts structural harm and potential fire risks.</p>
                        <p className=' text-xl mt-3'>Product | FRANKLYN Penta 1.0 Cu</p>
                        <p className=' text-base'>ASHLOK presents its innovatively designed FRANKLYN type Lightning Conductor, crafted from Copper material. The air terminal consists of a metallic sphere featuring five vertically arranged spikes oriented at various angles. This sphere is linked to a vertical rod, equipped with an appropriate base plate for secure placement on a flat surface. The protective conductor connects to the base plate on one end and is linked to Earth Rods on the other. The Lightning current is drawn to the air terminal, and security is channeled through predetermined protective conductors, subsequently discharging into the ground through the earthing system.</p>
                        <p className=' text-xl mt-3'>Standard of Compliance for Conventional type</p>
                        <p className=' text-base'>IS | IEC 62561</p>
                        <p></p>
                        {/*
                        <p className=' text-base text-justify  p-common' >How <span className='vollkorn-sc-semibold'>Franklyn Lightning Rod</span> functions - It attracts and receives the direct lightning, approaching the structure and dissipates the high voltage lightning currents into the earth mass through conductors, thus preventing damages to the structure/building and fire hazards .</p>
                        <p className=' text-base text-justify font-bold my-1'>Specification</p>
                        <p className=' text-base text-justify p-common'>Franklyn Penta 1.0 Cu : Ashlok designed Franklyn type Lightning Conductor, Made of Copper material the air terminal consist of metal sphere having 5 spikes fixed vertically at different angles. The sphere is connected with a vertical rod having a suitable base plate to fix it over horizontal plain surface. The protective conductor is connected at base plate at one end and other end is connected with Earth Rods. The Lightning current gets attracted towards air terminal and safety passes through the protective conductors laid in a pre-determined route and dissipates in to the ground | Mother earth through earthing system.</p>
                            */}
                    </div>
                    
                    
                </div>
            </div>
            {/*}
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3 '>
                <p className=' text-2xl'></p>
                    <div className='flex '>
                        
                        <div className='w-1/2'>
                            <ul className="list-none ">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pr-2 pt-1.5 "/>I. IS 2309 - Protection of buildings and allied structures against lightning</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-2 pr-2 pt-1.5 "/> II. BS 6651 - Protection of structures against lightning.</li>
                                             </ul>
                        </div>
                        <div className='w-1/2'>
                            <ul className="list-none ml2">
                            <li className="flex items-center "><img src={boltico} className="mr-2 pr-2 pt-1.5"/>III. NFPA 780 - Standard for installation of lightning protection system.</li>
                           
                                 </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
               
                
                
                    
                   
                                    <table className=" w-full divide-y divide-gray-200   p-common">
                                        <thead className='w-full'>
                                            <tr className="bg-ashred">
                                            <th className=" px-4 py-2 text-white">Type</th>
                                            <th className=" px-4 py-2 text-white"><span className='vollkorn-sc-semibold'>Franklyn Penta 1.0 Cu</span></th>
                                            <th className=" px-4 py-2 text-white"><span className='vollkorn-sc-semibold'>Franklyn Penta 1.1 Cu</span></th>
                                            <th className=" px-4 py-2 text-white"><span className='vollkorn-sc-semibold'>Franklyn Penta 1.2 Cu</span></th>
                                            <th className="  px-4 py-2 text-white"><span className='vollkorn-sc-semibold'>Franklyn Penta 1.1 SS</span></th>
                                            <th className="  px-4 py-2 text-white"><span className='vollkorn-sc-semibold'>Franklyn Penta 1.2 SS</span></th>  
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">Applicable Standard</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Material Of Construction</td>
                                            <td className=" px-4 py-2 text-center">Copper</td>
                                            <td className=" px-4 py-2 text-center">Copper</td>
                                            <td className=" px-4 py-2 text-center">Copper</td>
                                            <td className=" px-4 py-2 text-center">SS 304 L</td>
                                            <td className=" px-4 py-2 text-center">SS 304 L</td>
                                            </tr>
                                           
                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Total Length</td>
                                            <td className=" px-4 py-2 text-center">1590 mm</td>
                                            <td className=" px-4 py-2 text-center">1200 mm</td>
                                            <td className=" px-4 py-2 text-center">505 mm</td>
                                            <td className=" px-4 py-2 text-center">1000 mm</td>
                                            <td className=" px-4 py-2 text-center">505 mm</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Spike Length</td>
                                            <td className=" px-4 py-2 text-center">390 mm</td>
                                            <td className=" px-4 py-2 text-center">390 mm</td>
                                            <td className=" px-4 py-2 text-center">390 mm</td>
                                            <td className=" px-4 py-2 text-center">390 mm</td>
                                            <td className=" px-4 py-2 text-center">390 mm</td>
                                            </tr>
                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Diameter of Main Rod</td>
                                            <td className=" px-4 py-2 text-center">22 mm</td>
                                            <td className=" px-4 py-2 text-center">19 mm</td>
                                            <td className=" px-4 py-2 text-center">19 mm</td>
                                            <td className=" px-4 py-2 text-center">19 mm</td>
                                            <td className=" px-4 py-2 text-center">19 mm</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Diameter of Sphere</td>
                                            <td className=" px-4 py-2 text-center">155 mm</td>
                                            <td className=" px-4 py-2 text-center">150 mm</td>
                                            <td className=" px-4 py-2 text-center">135 mm</td>
                                            <td className=" px-4 py-2 text-center">150 mm</td>
                                            <td className=" px-4 py-2 text-center">135 mm</td>
                                            </tr>
                                           
                                            
                                        
                                        </tbody>
                                    </table>
                       
                       
                    
                </div>
                
            </div>*/}
            </>
          )
    }
    
    export default Franklynpentacontent
    