import React, { useState } from 'react'
import careerbg from './assets/images/career/career_background.jpg';
import careerbg2x from './assets/images/career/career_background@2x.jpg';
import pl from './assets/images/career/plus_icon.svg';
import mcon from './assets/images/career/medical_icon.svg';
import edu from './assets/images/career/education_icon.svg';
import empben from './assets/images/career/employe_benefits.svg';
import eveicon from './assets/images/career/events_icon.svg';
import pbonus from './assets/images/career/performance_bonus_icon.svg';
import wlife from './assets/images/career/work_life_balance_icon.svg';
import ExapndabelMenu from './ExapndabelMenu';
  

    const Careercontent = () => {
        const [isExpandedbyuser, setIsExpandedbyuser] = useState(false);
        const divStyle = {
            backgroundImage: `url(${careerbg})`,
           
          };
          const handleExpandToggle = () => {
            setIsExpandedbyuser(!isExpandedbyuser);
          };
        
      return (
        <div className='w-full flex flex-col bg-white mb-1'>
            <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center  items-center  mb-10    '>
                {/*<p className=' text-ashred text-3xl m-5'>Charting Your Career with <span className=' font-bold' style={{ fontFamily: 'cinzel, sans-serif' }}>Ashlok</span></p>*/}
                <div className='my-3 relative text-2xl font-bold ' style={{ fontFamily: 'cinzel, sans-serif' }}>“Choose a job you love, and you will never have to work a day in your life.” 
                <div className='absolute   left-2/3 translate-x-80 text-base'>—Confucius</div>
                </div>
               
               
            
            </div>
            <div className='container mx-auto max-w-screen-xl flex flex-col'>

                    <p className='text-base text-justify mb-10'>With a dedicated team of experts continuously focused on innovating improved earthing systems, we extend a warm invitation to join <span className='vollkorn-sc-semibold'>Ashlok</span> in shaping a brighter future. Through effective safety measures and protective equipment, we have the capability to mitigate the extent of disaster impact to a certain degree.</p>
                    <div className='  bg-ashblack text-white flex items-center justify-center flex-col'>
                     <p className=' text-3xl  p-2'>Opportunities at  <span className='vollkorn-sc-semibold'>Ashlok</span></p>
            
                    </div>
                    <div className="h-full">
                    <div style={divStyle} className="bg-center h-1/2">
                        <div className="mx-auto max-w-4xl">
                            <div className='flex basis-3/4 justify-center mb-12'>
                                <table className=" w-full divide-y divide-gray-200 mt-10">
                                    <thead className='w-full'>
                                        <tr className=" bg-ashred text-white">
                                        <th className=" px-4 py-2 text-left">Job Title</th>
                                       
                                        <th className=" px-4 py-2 text-left">Type</th>
                                        <th className="  px-4 py-2 text-left">Category</th>
                                        <th className="  px-4 py-2 text-left">Location</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-gray-100">
                                        <tr className="bg-white">
                                        <td className=" px-4 py-2 items-center"><ExapndabelMenu title="Sales Executive" 
                        content={"Sales Executive/Business Development Executive"   }
                        bullet1={["Should have 2-3 years of suitable selling experiences of Software solutions/products in B2B market","Handling direct sales & Follow up with customer","Schedule discussions with prospects to understand their needs and decision making process criteria.","Provide value propositions, handles objections, prepares proposals, Support agreements and closes sales by getting prospects commitments","Supports the development of sales presentations. Make sales presentations to prospects for growing the clients in India.","Strong process orientation and an understanding of sales process and review mechanisms","Should have impeccable communication (verbal, listening, writing) & presentation skills","Good negotiation skills, competitive and confident approach."]}
                        bgColor="bg-ashred"  textColor=" text-white"
                        className=' text-white bg-ashred'>
                            <button className=' bg-ashred p-3  border-gray-100   text-white ml-5'   style={{ borderRadius: '8px' }} subject="Sales Executive Job | Chennai" emailid="ravikumar.k@ashlok.com">Apply Now</button>
                         
                        </ExapndabelMenu>
                        
                        </td>
                                       
                                        <td className={` px-4 py-2 `}>Full Time</td>
                                        <td className=" px-4 py-2 items-center">Sales</td>
                                        <td className=" px-4 py-2 items-center">Chennai</td>
                                        </tr>
                                        <tr className="bg-gray-200">
                                        <td className=" px-4 py-2"><ExapndabelMenu title="Supply Operations" 
                        content={"Supply Operations Job duties and responsibilities"   }
                        bullet1={["Plan and implement the overall supply chain strategy","Collaborate with Sales, Operations, and Customer Service teams","Determine key supply chain KPIs.","Suggest solutions for process improvements","Identify process bottleneck and implement solutions in a timely manner","Train and evaluate others","Provide constructive feedback","Work with finance, sales, and manufacturing team to determine best vendors and distributors.","Build and maintain good relationships with vendors"]}
                        bgColor="bg-ashred"  textColor="text-white"
                        className=' text-white'>
                            <button className=' bg-ashred p-3  border-gray-100   text-white ml-5'   style={{ borderRadius: '8px' }} subject="Supply Operations Job | Chennai" emailid="ravikumar.k@ashlok.com">Apply Now</button>
                        </ExapndabelMenu></td>
                                        {/*
                                        <td className=" px-4 py-2"><img src={pl} alt="plus icon"/></td>*/}
                                        <td className=" px-4 py-2">Full Time</td>
                                        <td className=" px-4 py-2">Sales Sales and Support</td>
                                        <td className=" px-4 py-2">Chennai</td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            <div className=' w-max-xl flex flex-col justify-center items-center border-2 border-ashgray2 p-10 bg-white bg-opacity-50'> 
                                    <p className=' text-3xl text-ashred  relative'>The <span className='vollkorn-sc-semibold'>Ashlok</span> Experience: A Smart Career Move</p>
                                    <p className=' text-3xl  text-white border-t-2 border-ashgray2'>The <span className='vollkorn-sc-semibold'>Ashlok</span> Experience: A Smart Career Move</p>
                                    
                                    <p className=' text-3xl  text-black mb-3'>We’ve got you covered. We are a family!</p>
                                    <p className=' text-justify text-black text-base'>Each person’s unique skillset plays a vital role in shaping a brighter future. At <span className='vollkorn-sc-semibold'>Ashlok</span>, we function as a collaborative team, welcoming innovative ideas, offering fresh perspectives, and fostering your career development alongside ours. <span className='vollkorn-sc-semibold'>Ashlok</span> has a history of establishing enduring relationships and serving a distinguished clientele across various industries with a focus on safety. We eagerly anticipate the opportunity to connect with you and work together in creating a secure future.</p>
                                    
                                
                                
                                
                               
                                        <div className='flex flex-row m-8 w-full'>
                                                <div className='flex basis-2/4 flex-col  items-center'>
                                                    <div className='flex basis-1/4 justify-center  items-center'>
                                                        <img src={mcon}alt="telecom1"/>
                                                    </div>
                                                    <p className=' text-ashred mt-2 mb-4 text-black' >Medical Insurance</p>
                                                    
                                                </div>
                                                <div className='flex basis-2/4 flex-col'>
                                                
                                                    
                                                </div>
                                                <div className='flex basis-2/4 flex-col items-center'>
                                                        <div className='flex basis-1/4 justify-center'>
                                                            <img src={edu} alt="telecom1"/>    
                                                        </div>                                        
                                                    <p className=' text-ashred mt-2 mb-4 text-black' >Education Sponsorships</p>
                                                    
                                                </div>
                                                <div className='flex basis-2/4 flex-col'>
                                                
                                                    
                                                </div>
                                                <div className='flex basis-2/4 flex-col  items-center'>
                                                    <div className='flex  justify-center items-center'>
                                                        <img src={empben}  alt="telecom1"/>  
                                                    </div>    
                                                                                
                                                        <p className=' text-ashred mt-2 text-black items-center justify-center' >All Manufacturing Industry</p>
                                                        <p className=' text-ashred mb-4 text-black items-center justify-center' > Employee Benefits</p>
                                                           
                                                </div>

                                        </div>
                                
                                        <div className='relative flex flex-row m-8 w-full'>
                                                <div className='flex basis-2/4 flex-col items-center '>
                                                    <div className='flex basis-1/4 justify-center'>
                                                        <img src={wlife}alt="telecom1"/>
                                                    </div>
                                                    <p className=' text-ashred mt-2 mb-4 text-black justify-center' >Work-Life Balance</p>
                                                    
                                                </div>
                                                <div className='flex basis-2/4 flex-col'>
                                                
                                                    
                                                </div>
                                                <div className='flex basis-2/4 flex-col items-center justify-center'>
                                                        <div className='flex basis-1/4 justify-center'>
                                                            <img src={pbonus} alt="telecom1"/>    
                                                        </div>                                        
                                                    <p className=' text-ashred mt-2 mb-4 text-black' >Performance bonus</p>
                                                    
                                                </div>
                                                <div className='flex basis-2/4 flex-col'>
                                                
                                                    
                                                </div>
                                                <div className='flex basis-2/4 flex-col items-center justify-center'>
                                                    <div className='flex basis-1/4 justify-center'>
                                                        <img src={eveicon}  alt="telecom1"/>  
                                                    </div>                                             
                                                    <p className=' text-ashred mt-2 mb-4 text-black' >Events and Celebrations</p>
                                                    
                                                </div>

                                        </div>
                                
                            </div> 
                        
                    </div>
                    </div>
                            
                           
                  

            </div>
        </div>
      )
    }
    
    export default Careercontent
    