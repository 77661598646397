import React,{useState} from 'react'
import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Footer from './Footer'
import whoweareban from './assets/images/whoweare/who-we-are-banner.jpg';
import whoweareban2x from './assets/images/whoweare/who-we-are-banner@2x.jpg';
import Menu from './Menu'
import WhoweareContent from './WhoweareContent'
import { Helmet } from 'react-helmet-async'
import FaqBanner from './FaqBanner'
import shield from './assets/images/shield.svg';
import WhoweareInfo from './WhoweareInfo'
const WhowearePage = () => {
  const [isSticky, setSticky] = useState(true);
  return (
    <div>
      <Helmet>
        <title>
        About Ashlok: Leaders in Earthing & Lightning Protection Systems in India
        </title>
        <meta name="description" content="Discover Ashlok's journey as a pioneer in providing comprehensive Earthing and Lightning Protection solutions across India. Learn about our commitment to excellence, innovative approach, and dedication to ensuring safety and reliability in residential, commercial, and industrial sectors. Join us in our mission to protect against electrical hazards nationwide." />

      </Helmet>
    <PreHeader/>
    <Header isSticky={isSticky}/>
    <Menu isHeaderVisible={isSticky}/>
    <FaqBanner page="whoweare" banalt="who we are_ban" img1={shield} bannertext="  WHO WE ARE ?" ban1x={whoweareban} ban2x={whoweareban2x}/>
    <WhoweareInfo/>
    {/*
    <WhoweareContent/>*/}
    <Footer/>
    </div>
  )
}

export default WhowearePage
