import React, { useState,useEffect } from 'react';
import styles from './Timeline.module.css';
import YearwiseDetails from './YearwiseDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Transition } from '@headlessui/react';
import cent25 from  './assets/images/heritage/25th_2024.png';
import cent23 from './assets/images/heritage/CLP_image_2023.png';
import quesmark from './assets/images/heritage/question_mark.jpg';
import quesmark2x from  './assets/images/heritage/question_mark@2x.jpg';
const Timeline = () => {
   /* const timelineData = {
        '2024-2020': {
            2024: { event: '25th Anniversary | New Unit Started in Ahmedabad', image: cent25, description: 'Celebrating its 25th year, Ashlok marked the milestone by inaugurating a new unit in Ahmedabad. This expansion signifies continued growth and commitment to excellence in the industry.' },
            2023: { event: 'Product Launch | Conventional Protection System', image: cent23, description: 'Ashlok made waves with the launch of their Conventional Protection System, a milestone in electrical safety. Precision engineering and advanced technology combine to offer a reliable and effective protection solution.' },
            2022: { event: 'Product Launch | Sentinel Earthing Electrode', image: 'path/to/image8.jpg', description: 'Ashlok’s relentless dedication led to the successful development and launch of the Sentinel Earthing Electrode. Meticulous engineering and innovation define this groundbreaking product, setting new benchmarks in electrical safety.' },
            2021: { event: 'COVID - 19 | Survival', image: 'path/to/image9.jpg', description: 'Ashlok navigated the challenges posed by the COVID-19 pandemic through strategic planning and resilient efforts. Implementing safety measures ensured the team’s well-being while maintaining operational efficiency and adaptability.' },
            2020: { event: 'ELECRAMA 2020', image: 'path/to/image10.jpg', description: 'In ELECRAMA - 2020, we unveiled an impressive array of products, encompassing Lightning Protection Systems and Maintenance-Free Earthing Electrodes, offering the ultimate solution to address all your Lightning and Earthing requirements.' },
        },
        '2019-2015': {
            2019: { event: '25th Anniversary | New Unit Started in Ahmedabad', image: cent25, description: 'Celebrating its 25th year, Ashlok marked the milestone by inaugurating a new unit in Ahmedabad. This expansion signifies continued growth and commitment to excellence in the industry.' },
            2018: { event: 'Product Launch | Conventional Protection System', image: cent23, description: 'Ashlok made waves with the launch of their Conventional Protection System, a milestone in electrical safety. Precision engineering and advanced technology combine to offer a reliable and effective protection solution.' },
            2017: { event: 'Product Launch | Sentinel Earthing Electrode', image: 'path/to/image8.jpg', description: 'Ashlok’s relentless dedication led to the successful development and launch of the Sentinel Earthing Electrode. Meticulous engineering and innovation define this groundbreaking product, setting new benchmarks in electrical safety.' },
            2016: { event: 'COVID - 19 | Survival', image: 'path/to/image9.jpg', description: 'Ashlok navigated the challenges posed by the COVID-19 pandemic through strategic planning and resilient efforts. Implementing safety measures ensured the team’s well-being while maintaining operational efficiency and adaptability.' },
            2015: { event: 'ELECRAMA 2020', image: 'path/to/image10.jpg', description: 'In ELECRAMA - 2020, we unveiled an impressive array of products, encompassing Lightning Protection Systems and Maintenance-Free Earthing Electrodes, offering the ultimate solution to address all your Lightning and Earthing requirements.' },
        },
        '2014-2010': {
            2014: { event: '25th Anniversary | New Unit Started in Ahmedabad', image: cent25, description: 'Celebrating its 25th year, Ashlok marked the milestone by inaugurating a new unit in Ahmedabad. This expansion signifies continued growth and commitment to excellence in the industry.' },
            2013: { event: 'Product Launch | Conventional Protection System', image: cent23, description: 'Ashlok made waves with the launch of their Conventional Protection System, a milestone in electrical safety. Precision engineering and advanced technology combine to offer a reliable and effective protection solution.' },
            2012: { event: 'Product Launch | Sentinel Earthing Electrode', image: 'path/to/image8.jpg', description: 'Ashlok’s relentless dedication led to the successful development and launch of the Sentinel Earthing Electrode. Meticulous engineering and innovation define this groundbreaking product, setting new benchmarks in electrical safety.' },
            2011: { event: 'COVID - 19 | Survival', image: 'path/to/image9.jpg', description: 'Ashlok navigated the challenges posed by the COVID-19 pandemic through strategic planning and resilient efforts. Implementing safety measures ensured the team’s well-being while maintaining operational efficiency and adaptability.' },
            2010: { event: 'ELECRAMA 2020', image: 'path/to/image10.jpg', description: 'In ELECRAMA - 2020, we unveiled an impressive array of products, encompassing Lightning Protection Systems and Maintenance-Free Earthing Electrodes, offering the ultimate solution to address all your Lightning and Earthing requirements.' },
        },
        '2009-2005': {
            2009: { event: '25th Anniversary | New Unit Started in Ahmedabad', image: cent25, description: 'Celebrating its 25th year, Ashlok marked the milestone by inaugurating a new unit in Ahmedabad. This expansion signifies continued growth and commitment to excellence in the industry.' },
            2008: { event: 'Product Launch | Conventional Protection System', image: cent23, description: 'Ashlok made waves with the launch of their Conventional Protection System, a milestone in electrical safety. Precision engineering and advanced technology combine to offer a reliable and effective protection solution.' },
            2007: { event: 'Product Launch | Sentinel Earthing Electrode', image: 'path/to/image8.jpg', description: 'Ashlok’s relentless dedication led to the successful development and launch of the Sentinel Earthing Electrode. Meticulous engineering and innovation define this groundbreaking product, setting new benchmarks in electrical safety.' },
            2006: { event: 'COVID - 19 | Survival', image: 'path/to/image9.jpg', description: 'Ashlok navigated the challenges posed by the COVID-19 pandemic through strategic planning and resilient efforts. Implementing safety measures ensured the team’s well-being while maintaining operational efficiency and adaptability.' },
            2005: { event: 'ELECRAMA 2020', image: 'path/to/image10.jpg', description: 'In ELECRAMA - 2020, we unveiled an impressive array of products, encompassing Lightning Protection Systems and Maintenance-Free Earthing Electrodes, offering the ultimate solution to address all your Lightning and Earthing requirements.' },
        },
        '2004-2000': {
            2004: { event: '25th Anniversary | New Unit Started in Ahmedabad', image: cent25, description: 'Celebrating its 25th year, Ashlok marked the milestone by inaugurating a new unit in Ahmedabad. This expansion signifies continued growth and commitment to excellence in the industry.' },
            2003: { event: 'Product Launch | Conventional Protection System', image: cent23, description: 'Ashlok made waves with the launch of their Conventional Protection System, a milestone in electrical safety. Precision engineering and advanced technology combine to offer a reliable and effective protection solution.' },
            2002: { event: 'Product Launch | Sentinel Earthing Electrode', image: 'path/to/image8.jpg', description: 'Ashlok’s relentless dedication led to the successful development and launch of the Sentinel Earthing Electrode. Meticulous engineering and innovation define this groundbreaking product, setting new benchmarks in electrical safety.' },
            2001: { event: 'COVID - 19 | Survival', image: 'path/to/image9.jpg', description: 'Ashlok navigated the challenges posed by the COVID-19 pandemic through strategic planning and resilient efforts. Implementing safety measures ensured the team’s well-being while maintaining operational efficiency and adaptability.' },
            2000: { event: 'ELECRAMA 2020', image: 'path/to/image10.jpg', description: 'In ELECRAMA - 2020, we unveiled an impressive array of products, encompassing Lightning Protection Systems and Maintenance-Free Earthing Electrodes, offering the ultimate solution to address all your Lightning and Earthing requirements.' },
        },
        '1999-1995': {
            1999: { event: '25th Anniversary | New Unit Started in Ahmedabad', image: cent25, description: 'Celebrating its 25th year, Ashlok marked the milestone by inaugurating a new unit in Ahmedabad. This expansion signifies continued growth and commitment to excellence in the industry.' },
            1998: { event: 'Product Launch | Conventional Protection System', image: cent23, description: 'Ashlok made waves with the launch of their Conventional Protection System, a milestone in electrical safety. Precision engineering and advanced technology combine to offer a reliable and effective protection solution.' },
            1997: { event: 'Product Launch | Sentinel Earthing Electrode', image: 'path/to/image8.jpg', description: 'Ashlok’s relentless dedication led to the successful development and launch of the Sentinel Earthing Electrode. Meticulous engineering and innovation define this groundbreaking product, setting new benchmarks in electrical safety.' },
            1996: { event: 'COVID - 19 | Survival', image: 'path/to/image9.jpg', description: 'Ashlok navigated the challenges posed by the COVID-19 pandemic through strategic planning and resilient efforts. Implementing safety measures ensured the team’s well-being while maintaining operational efficiency and adaptability.' },
            1995: { event: 'ELECRAMA 2020', image: 'path/to/image10.jpg', description: 'In ELECRAMA - 2020, we unveiled an impressive array of products, encompassing Lightning Protection Systems and Maintenance-Free Earthing Electrodes, offering the ultimate solution to address all your Lightning and Earthing requirements.' },
        }
        
        
        // ...add more groups
        
      };*/
  const [timelineData, settimelineData] = useState(null);
  /*const firstGroup = Object.keys(timelineData)[0];*/
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [openGroup, setOpenGroup] = useState(null);

  

  useEffect(() => {
    // Fetch the JSON file from the public/data folder
    fetch('/data/heritage.json')
      .then((response) => response.json())
      .then((jsonData) => {
        settimelineData(jsonData);
        if(jsonData)
          {
            const firstGroup = Object.keys(jsonData)[0];
            setExpandedGroups([firstGroup]);
            setOpenGroup(firstGroup);
            const firstYear = Object.keys(jsonData[firstGroup]).sort((a, b) => b - a)[0];
            setSelectedYear(firstYear);
          }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array ensures this effect runs only once

  {/*const handleGroupClick = (group) => {
    setExpandedGroups((prevExpandedGroups) =>
      prevExpandedGroups.includes(group)
        ? prevExpandedGroups.filter((g) => g !== group)
        : [...prevExpandedGroups, group]
    );
  };*/}
  const handleGroupClick = (group) => {
    console.log("group cliked");
    setExpandedGroups((prevExpandedGroups) =>
      prevExpandedGroups.includes(group) ? [] : [group]
    );
    setSelectedYear(null); 
    setOpenGroup( group); 
  };

  const handleYearClick = (year) => {
    setSelectedYear(prevYear => prevYear === year ? null : year);
  };
  if (!timelineData) {
    return <p>Loading timeline data...</p>;
  }

  return (
    <div className="timeline container mx-auto max-w-screen-xl flex my-3">
        {/* Sidebar for timeline */}
      {timelineData && (
        <div className={`${styles.groups} w-1/6`}>
        <div className=' bg-ashred text-white flex justify-center items-center border-s'>
          <p >Years</p>
        </div>
        {Object.keys(timelineData).map((group, index) => (
          <div key={group} className={`${styles.groupcontainer}`}>
            
            
            <div className={`${styles.groupheader} bg-blue-500 text-white font-bold cursor-pointer hover:bg-blue-700 transition duration-300 ease-in-out flex justify-center items-center mt-2`} onClick={() => handleGroupClick(group)}>

                <span className={`${styles.groupyear2}`}>{group}</span>
                <div className={`${styles.groupbutton2}`}>
                <FontAwesomeIcon icon={expandedGroups === group ? faChevronDown : faChevronRight} className="group-icon" />
                </div>
            </div>
                
            {expandedGroups.includes(group) && (
                
              <div className={`${styles.years}`}>
                
                
                {Object.keys(timelineData[group])
                .sort((a, b) => b - a)
                .map((year) => (
                  <div key={year} className={`${styles.yearcontent}`}>
                    <Transition
            show={openGroup === group}
            enter="transition-opacity duration-300 ease-in-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300 ease-in-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="ml-4"
          >
                    
                        <button
                        className={`flex justify-center year-button w-2/3 text-left px-2 py-2 rounded-lg cursor-pointer ${
                            selectedYear === year ? 'bg-green-500 text-white' : 'bg-gray-300'
                        } hover:bg-green-400 transition duration-300 ease-in-out`}
                        onClick={() => handleYearClick(year)}
                        >
                        {year}
                        </button>
                    </Transition>
                    {/*selectedYear === year && (
                      <div className="year-details p-4 bg-white border rounded-lg mt-2">
                        <img src={timelineData[group][year].image} alt={timelineData[group][year].event} className="w-full h-40 object-cover mb-4" />
                        <p>{timelineData[group][year].description}</p>
                      </div>
                    )*/}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}
        
        {/* Main content area for detailed view */}
        <div className="w-5/6 h-full pl-4 bg-white flex items-end">
          {selectedYear ? (
            <div className="year-details">
              {Object.keys(timelineData).map((group) => (
                Object.keys(timelineData[group]).map((year) => (
                  selectedYear === year && (
                    <YearwiseDetails
            year={selectedYear}
            event={timelineData[expandedGroups][selectedYear].event}
            image={timelineData[expandedGroups][selectedYear].image}
            image2x={timelineData[expandedGroups][selectedYear].image2x}
            description={timelineData[expandedGroups][selectedYear].description}
                />
                   
                  )
                ))
              ))}
            </div>
          ) : (
            <>
            <div className={`${styles.outerbox} w-full h-full pl-4 bg-white flex items-end  h-96`}>
          <div className='w-full   bg-ashred flex flex-col justify-center items-center   rounded-lg'>
          <h2 className="text-2xl font-semibold my-2  text-white">Select a year to see details....</h2>
          </div>
          <div className='flex w-full justify-center items-center my-2'>
            
            <img className='mt-[-1px] ' src={quesmark} alt="Choose Years" 
                      srcSet={`${quesmark} 1x, ${quesmark2x} 2x`}
                  />

              
              
              
           </div>
        </div>
            </>
          )}
        </div>
      </div>    
        
    
  );
};

export default Timeline;

{/*
main block content

                    <div key={year}>
                      <h2 className="text-2xl font-semibold mb-4">{year}: {timelineData[group][year].event}</h2>
                      <img src={timelineData[group][year].image} alt={timelineData[group][year].event} className="w-full h-64 object-cover mb-4" />
                      <p>{timelineData[group][year].description}</p>
                    </div>


    <div className={`${styles.timeline}`}>
      <div className="groups">
        {Object.keys(timelineData).map((group) => (
          <div key={group} className={`${styles.groupsection}`}>
            <button
              className={`${styles.groupbutton} ${expandedGroups.includes(group) ? `${styles.active}` : ''}`}
              onClick={() => handleGroupClick(group)}
            >
              {group}
            </button>
            {expandedGroups.includes(group) && (
              <div className={`${styles.years}`}>
                {Object.keys(timelineData[group]).map((year) => (
                  <div key={year} className={`${styles.yearcontent}`}>
                    
                    <button
                      className={`year-button w-full text-left px-4 py-2 rounded-lg cursor-pointer ${
                        selectedYear === year ? 'bg-green-500 text-white' : 'bg-gray-300'
                      }`}
                      onClick={() => handleYearClick(year)}
                    >
                      {year}
                    </button>
                    {selectedYear === year && (
                      <div className="year-details p-4 bg-white border rounded-lg mt-2">
                        <img src={timelineData[group][year].image} alt={timelineData[group][year].event} className="w-full h-40 object-cover mb-4" />
                        <p>{timelineData[group][year].description}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>*/}   