    import React,{useState} from 'react'
    import { useForm } from 'react-hook-form';
    import get_doodle from './assets/images/contact/get_doodle.svg';
    import ReCAPTCHA from "react-google-recaptcha";
    import * as Yup from 'yup';
    import { yupResolver } from '@hookform/resolvers/yup';
    import { motion } from 'framer-motion';

    const Contactform = () => {
      const [isSubmitted, setIsSubmitted] = useState(false); // state for form submission
      const [showAlert, setShowAlert] = useState(false);     // state for showing success alert
    

      const validationSchema = Yup.object().shape({
        Name: Yup.string().required('Name is required'),
        Email: Yup.string().email('Invalid email').required('Email is required'),
        Phone: Yup.string().required('Phone is required'),
        Message: Yup.string().required('Message is required'),
      });
        const { register, handleSubmit,reset, formState: { errors } } = useForm({
          resolver: yupResolver(validationSchema),
        });
       
  // const onSubmit = data => console.log(data);
  const onSubmit = async (data) => {
    // Send the form data to the PHP backend
    // const response = await fetch('https://salasartech.com/website/details.php', {
    const response = await fetch('https://salasartech.com/marketing_tally/marketing/web_mail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    try {
    const result = await response.json();
    if (result.status === 'success') {
      setIsSubmitted(true);
      setShowAlert(true);  // Show success alert
      //alert('Email Sent');
      reset();  // Clear the form
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);

    } else {
      alert('Error: ' + result.message);
    }
  //  console.log(result);  // Check the result returned from the PHP file
  } catch (error) {
    alert('Failed to submit form. Please try again.');
    console.error('Error during fetch:', error);
  }
   
  };

  console.log(errors);
      return (
        <div className=' container mx-auto max-w-screen-xl flex  items-center     bg-white  my-10     '>
              <div className='w-1/2 flex items-center justify-center  '>
             
                            <form className='w-full  space-y-4 border border-black-500 px-12 py-20 mx-3' onSubmit={handleSubmit(onSubmit)}>
                            {showAlert && (
              <div className="alert alert-success" style={{color:'green', fontSize:'20px'}}>
                  Email Sent!
                  </div>
              )}
                            
                            <p className=' text-2xl'>Get in Touch with us</p>
                     
                          <input className=' border border-gray-300 rounded-md  w-full p-2 ' type="text" placeholder="Name" {...register("Name", {required: true})} width="200" />
                          {errors.Name && <p style={{ color: 'red', fontSize:'14px' }}>{errors.Name.message}</p>}
                     
                     
                          <input className='border border-gray-300 rounded-md  w-full p-2 ' type="text" placeholder="eMail" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} />
                          {errors.Email && <p style={{ color: 'red', fontSize:'14px' }}>{errors.Email.message}</p>}
                     
                       
                            <input className='border border-gray-300 rounded-md  w-full p-2 ' type="tel" placeholder="Phone" {...register("Phone", {required: true, maxLength: 12})} />
                            {errors.Phone && <p style={{ color: 'red', fontSize:'14px' }}>{errors.Phone.message}</p>}
                       
                       
                          <textarea className='border border-gray-300 rounded-md  w-full p-2 ' placeholder="Message" {...register("Message", {required: true})} />
                          {errors.Message && <p style={{ color: 'red', fontSize:'14px' }}>{errors.Message.message}</p>}
                            <div className='flex justify-end basis-1/4'>
                              <button type="submit" className=" flexm basis-1/4     bg-ashred text-white py-2 px-4 rounded-md hover:bg-ashred2" >Send</button>
                            </div>
                            
                        
                    </form>
                
              </div>
              <div className='w-1/2 flex items-center justify-end '>
                  <img className=" " src={get_doodle}/>
              </div>
        </div>  
      )
    }
    
    export default Contactform

