import React,{useState} from 'react'
import HeritageLine from './HeritageLine'
import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import HeritageBanner from './HeritageBanner'
import banner1 from './assets/images/heritage/legacy_banner.jpg';
import banner1_2x from  './assets/images/heritage/legacy_banner@2x.jpg';
import HeritageDecades from './HeritageDecades'
import {HelmetProvider } from 'react-helmet-async'
import Timeline from './Timeline'
import ScrollToTopButton from './ScrollToTopButton'
const HeritagePage = () => {
  const [isSticky, setSticky] = useState(true);
  const displline=true;
  return (
    <div>
      <HelmetProvider>
        <title>The AshlokWay: Revolutionizing Lightning Protection Across India</title>
        <meta name='description' content="Discover the AshlokWay – our unique approach to comprehensive lightning protection that secures various sectors across India. Learn about our innovative techniques, commitment to safety, and how we tailor our systems to meet diverse industry needs. Experience the blend of expertise and passion that makes Ashlok a leader in electrical safety solutions."/>
      </HelmetProvider>
   
    <PreHeader/>
    <Header isSticky={isSticky}/>
    <Menu isHeaderVisible={isSticky}/>
    <HeritageBanner page="heritage" banalt="heritage ban1" bannertext="" ban1x={banner1} ban2x={banner1_2x}/>
   <HeritageDecades/>
   <ScrollToTopButton/>
    <HeritageLine/>
   {/* <Timeline/>*/}
    <Footer linedisp={displline}/>
    
    </div>
  )
}

export default HeritagePage
