import React,{useState} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';

import cfcimg from './assets/images/earthing/carbon_form_cfc/conducto_cast_big.png';
import cfcimg2x from './assets/images/earthing/carbon_form_cfc/conducto_cast_big@2x.png';

    const Aseel_cfc_earthing = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };
    
        return (
            <>
            <EarthingelectrodeSubMenu onImageClick={handleImageClick}/>
            
            <div className='w-full my-3 flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                <p className='flex  justify-center text-2xl text-ashred my-2'>Singleton Earthing EcoSystem ConductoCast Electrode<span style={{fontSize:'80%'}}>(s)</span> </p>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-3'>
                        <img src={cfcimg}  
                                                srcSet={`${cfcimg} 1x,${cfcimg2x} 2x`} alt=" ASEEl CFC logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start mt-4'>
                        <p className=' text-2xl text-ashred font-bold'><span className='vollkorn-sc-semibold'>ASEEL CFC <span className=' text-4xl px-1'>|</span> CarboForm ConductoCasting</span></p>
                        <p className=' text-base text-justify p-common '><span className='vollkorn-sc-semibold'>Ashlok's</span> Singleton Earthing EcoSystem based ConductoCast Electrodes has been conceptualized & designed such that the primary conductor comes encased within a tightly formed conductive matrix of CarboForm Compound. It is a blend of different environmentally-friendly elements, carefully designed for this purpose</p>
                        <p className=' text-base text-justify p-common '>In regions characterized by excessively loose soil conditions that lead to intermittent contact between the primary electrode and the surrounding earth’s surface area, <span className='vollkorn-sc-semibold'>Ashlok's</span> Singleton Earthing EcoSystem based ConductoCast Electrodes offers a suitable solution.</p>

                       
                    </div>
                    {
                        <div className='flex flex-col items-center justify-center  my-3'>
                            <div className='flex justify-center items-center text-xl text-ashred'>With that in mind, we humbly present to you the crown jewel.. </div>
                            <div className='flex justify-center items-center text-2xl'><span className='vollkorn-sc-semibold font-bold text-ashred mr-2'>Ashlok’s  <span className=' text-black'>    Singleton Earthing EcoSystem </span></span></div>
                            <div className='flex justify-center items-center text-2xl vollkorn-sc-semibold'>ConductoCast Electrode(<span  style={{fontSize:'80%'}}>s</span>)</div>
                    </div>
                    }
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                
                    <div className='flex  space-x-10'>
                   
    
                        <div className='w-1/2'>
                        <p className=' text-1xl text-ashred font-extrabold'>Features & Benefits</p>
                            <ul className="list-none ">
                            <li className="flex items-start p-common py-1"><img src={boltico} className="mr-2  my-1.5"/>Zero galvanic corrosion</li>
                            <li className="flex items-start p-common py-1"><img src={boltico} className="mr-2   my-1.5"/>Proprietary CFC technology </li>
                            <li className="flex items-start p-common py-1"><img src={boltico} className="mr-2   my-1.5"/>Negative thermal coefficient</li>

                                             </ul>
                        </div>
                        <div className='w-1/2'>
                        <p className=' text-1xl'></p>
                            <ul className="list-none">
                            <li className="flex items-center p-common text-ashgray1">Proprietary CarboForm Compound </li>
                            <li className="flex items-center p-common "><img src={boltico} className="mr-2  my-1.5 pt-1"/>Proprietary carbo form compound </li>
                            <li className="flex items-center p-common py-1"><img src={boltico} className="mr-2  my-1.5 pt-1"/>Low resistivity  <span className='text-gray-500 text-2xl px-1'>|</span>  long life</li>
                            <li className="flex items-center p-common "><img src={boltico} className="mr-2  my-1.5 pt-1"/>Customisation is available as per customers requirement</li>

                                             </ul>
                        </div>
                       
                        <div className='w-1/4'></div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                      <div className='flex'>
                        <div className='w-full  mb-2 mt-2'>
                                    <table className=" w-full divide-y divide-gray-200  p-common">
                                        <thead className='w-full'>
                                            
                                            <tr className="bg-ashred">
    
                                            <th className=" px-4 py-2 text-white">Model</th>
                                            <th className="px-4 py-2 text-white">Length (Mtr)</th>
                                            <th className="  px-4 py-2 text-white">Primary Solid Conductor (mm)</th>
                                            <th className="  px-4 py-2 text-white">Casted Outer Dia (mm)</th>

                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 172 CF</td>
                                            <td className=" px-4 py-2 text-center">2 </td>
                                            <td className=" px-4 py-2 text-center">17</td>
                                            <td className=" px-4 py-2 text-center">70 - 72</td>
                                           </tr>
                                           <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 173 CF</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">17</td>
                                            <td className=" px-4 py-2 text-center">70 - 72</td>
                                           </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 202 CF</td>
                                            <td className=" px-4 py-2 text-center">2 </td>
                                            <td className=" px-4 py-2 text-center">20</td>
                                            <td className=" px-4 py-2 text-center">84 - 86</td>
            
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 203 CF</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">20</td>
                                            <td className=" px-4 py-2 text-center">84 - 86</td>
            
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 252 CF</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">25</td>
                                            <td className=" px-4 py-2 text-center">105 - 106</td>
                                           </tr>
                                           <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 253 CF</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">25</td>
                                            <td className=" px-4 py-2 text-center">105 - 106</td>
                                           </tr>
    
                                           
                                        
                                        </tbody>
                                    </table>
                        </div>
                        
                    </div>
                
                
                       
                       
                    
                </div>
                
            </div>
            </>
          )
    }
    
    export default Aseel_cfc_earthing
    