    import React,{useState} from 'react'
import Producthomecontent from './Producthomecontent'
import Footer from './Footer'
import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu'
import ProducthomeBanner from './ProducthomeBanner'
import ban1x from  './assets/images/producthome/product-home-banner.jpg';
import ban2x from  './assets/images/producthome/product-home-banner@2x.jpg';
    const ProducthomePage = () => {
      const [isSticky, setSticky] = useState(true);
      const displline=true;
      return (
        <div>
           <PreHeader/>
           <Header isSticky={isSticky}/>
            <Menu isHeaderVisible={isSticky}/>
           <ProducthomeBanner page="product home" banalt="our products" bannertext="" ban1x={ban1x} ban2x={ban2x}/>
         <Producthomecontent/>
          <Footer linedisp={displline}/>
        </div>
      )
    }
    
    export default ProducthomePage
    