import React,{useContext} from 'react'
import { ProductContext } from './context/Productcontext';
import { Link,useNavigate } from 'react-router-dom';

import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Footer from './Footer'
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';
import AseelHdearthing from './AseelHdearthing';
import Aseel_z_earthing from './Aseel_z_earthing';
import Menu from './Menu';
import InstallationBanner from './InstallationBanner';
import ProductsubMenu from './ProductsubMenu';
import Aseel_pvd_earthing from './Aseel_pvd_earthing';
import Aseel_alloy_earthing from './Aseel_alloy_earthing';
import Aseel_cp_earthing from './Aseel_cp_earthing';
import Aseel_cfc_earthing from './Aseel_cfc_earthing';
import Aseel_cc_earthing from './Aseel_cc_earthing';
import Aseel_cbr_earthing from './Aseel_cbr_earthing';
import Earthing_accessories_earthing from './Earthing_accessories_earthing';
import banner1 from './assets/images/designcommission/design_commission_banner.jpg';
import banner1_2x from  './assets/images/designcommission/design_commission_banner@2x.jpg';

import hdban from './assets/images/earthing/hd/hotdip_galvanized_page_banner.jpg';
import hdban_2x from './assets/images/earthing/hd/hotdip_galvanized_page_banner@2x.jpg';

import zban from './assets/images/earthing/zinc_z/zinc_coated_page_banner.jpg';
import zban_2x from './assets/images/earthing/zinc_z/zinc_coated_page_banner@2x.jpg';

import pvdban from './assets/images/earthing/pvd/pvd_page_banner.jpg';
import pvdban_2x from './assets/images/earthing/pvd/pvd_page_banner@2x.jpg';

import alloyban from './assets/images/earthing/alloy_a/alloy_banner_page.jpg';
import alloyban_2x from './assets/images/earthing/alloy_a/alloy_banner_page@2x.jpg';

import cpban from './assets/images/earthing/copper_plated_cp/copper_plated_page_banner.jpg';
import cpban_2x from  './assets/images/earthing/copper_plated_cp/copper_plated_page_banner@2x.jpg';

import cbrban from './assets/images/earthing/copper_bonded_rod_cbr/copper_bonded_rod_page_banner.jpg';
import cbrban_2x from  './assets/images/earthing/copper_bonded_rod_cbr/copper_bonded_rod_page_banner@2x.jpg';


import ccban from './assets/images/earthing/carbon_coating_cc/carbon_coating_page_banner.jpg';
import ccban_2x from  './assets/images/earthing/carbon_coating_cc/carbon_coating_page_banner@2x.jpg';


import cfc_ban from './assets/images/earthing/carbon_form_cfc/conductocasting_page_banner.jpg';
import cfc_ban_2x from  './assets/images/earthing/carbon_form_cfc/conductocasting_page_banner@2x.jpg';


import acc_ban from './assets/images/earthing/accessories/earthing_accessories_page_banner.jpg'
import acc_ban_2x from  './assets/images/earthing/accessories/earthing_accessories_page_banner@2x.jpg';

    const EarthingElementsPage = () => {
        let navigate = useNavigate();
        const { selectedProduct, setProduct ,setCategory } = useContext(ProductContext);

      return (
        <div>
            
              <PreHeader/>
              <Header/>
              <Menu/>
             
              <div>
              {selectedProduct === 'hd_aseel' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore=""  apostropheAfter="" ban1x={hdban} ban2x={hdban_2x}/>
                <ProductsubMenu/>
               
                <AseelHdearthing/>
            </>
            ): selectedProduct === 'z_aseel' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore=""  apostropheAfter="" ban1x={zban} ban2x={zban_2x}/>
                <ProductsubMenu/>
                
                <Aseel_z_earthing/>
                 </>
            ):selectedProduct === 'pvd_aseel' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={pvdban} ban2x={pvdban_2x}/>
                <ProductsubMenu/>
               
                <Aseel_pvd_earthing/>
                 </>
            ): selectedProduct === 'a_aseel' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore="" apostropheAfter="" ban1x={alloyban} ban2x={alloyban_2x}/>
                <ProductsubMenu/>
                
                <Aseel_alloy_earthing/>
                 </>
            ):   selectedProduct=== 'cp_aseel' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore="" apostropheAfter="" ban1x={cpban} ban2x={cpban_2x}/>
                <ProductsubMenu/>
               
                <Aseel_cp_earthing/>
                 </>
            ):  selectedProduct === 'cbr_aseel' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore="" apostropheAfter="" ban1x={cbrban} ban2x={cbrban_2x}/>
                <ProductsubMenu/>
                
                <Aseel_cbr_earthing/>
                 </>
            ):  selectedProduct === 'cc_aseel' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={ccban} ban2x={ccban_2x}/>
                <ProductsubMenu/>
                
                <Aseel_cc_earthing/>
                 </>
            ):   selectedProduct === 'cfc_aseel' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={cfc_ban} ban2x={cfc_ban_2x}/>
                <ProductsubMenu/>
                
                <Aseel_cfc_earthing/>
                 </>
            ):  selectedProduct === 'earth_acc' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore=""  apostropheAfter="" ban1x={acc_ban} ban2x={acc_ban_2x}/>
                <ProductsubMenu/>
               
                <Earthing_accessories_earthing/>
                 </>
            ):(
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={banner1} ban2x={banner1_2x}/>
                <ProductsubMenu/>
                
                <AseelHdearthing/>
                </>
            )}
            </div>
              <Footer/>
        
        </div>
      )
    }
    
    export default EarthingElementsPage
    