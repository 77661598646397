    import React,{useState} from 'react'
import FaqContent from './FaqContent'
import FaqBanner from './FaqBanner'
import Footer from './Footer'
import banner1 from './assets/images/faq/faq_banner.jpg';
import banner1_2x from './assets/images/faq/faq_banner@2x.jpg';
import MetaTags from './MetaTags';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import FaqContentnew from './FaqContnetnew';
import FaqContentnewv2 from './FaqContnetnewv2';

    const FaqPage = () => {
      const [isSticky, setSticky] = useState(true);
      const displline =true;  
      return (
        <div>
           
            <PreHeader/>
             <Header isSticky={isSticky}/>
            <Menu isHeaderVisible={isSticky}/>
            <FaqBanner page="Faq" banalt="faq ban1" bannertext="" ban1x={banner1} ban2x={banner1_2x}/>
            {/*<FaqContentnew/>*/}
            <FaqContentnewv2/>
            <Footer linedisp={displline}/>
        </div>
      )
    }
    
    export default FaqPage
    