    import React,{useState} from 'react'
    import zeus from './assets/images/zeusdetails/zeus_big.png';
    import zeus2x from './assets/images/zeusdetails/zeus_big@2x.png';

    import lprotection from './assets/images/thordetails/level_of_protection_image.svg';
    import lplogo from './assets/images/thordetails/thor_big.png';
import lplogo2x from './assets/images/thordetails/thor_big@2x.png';
    import boltico from './assets/images/thordetails/bolt_icon.svg';

import LightningProductsSubMenu from './LightningProductsSubMenu';
    
    const Zeusdetailscontent = ({ onImageClick }) => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
            
            <div className='w-full my-3 flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center'>
                        
                        <div className='flex justify-center items-center my-5'>
                        <img src={lplogo}  
                                                srcSet={`${zeus} 1x,${zeus2x} 2x`} alt=" zeus logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred stitle'><span className='vollkorn-sc-semibold'>Ashlok’s Zeus® Safe Lightning Protection Rod(<span style={{fontSize:'80%'}}>s</span>)</span></p>
                        <p className=' text-base text-justify p-common'>
                        <span className='vollkorn-sc-semibold'>Ashlok’s</span> <span className='vollkorn-sc-semibold'>Zeus</span> Safe Lightning Protection Rod System represents an upgraded iteration of the FRANKLYN type lightning protection system.</p>
                    
                        <p className=' text-base text-justify mt-2 mb-2 p-common'>The <span className='vollkorn-sc-semibold'>Zeus</span> Safe Lightning Protection System comprises one primary and four secondary air terminals. The principal air terminal takes the form of a vertical conductive rod, featuring a pointed tip at one extremity. Fixed to the vertical rod, the four secondary air terminals are positioned on a bowl-shaped circular disc. Importantly, the acute tips of these secondary air terminals are strategically spaced and oriented to effectively intercept maximum side flashes. </p>
                        <p className=' text-base text-justify mt-2 mb-2 p-common'>The functioning of the <span className='vollkorn-sc-semibold'>Zeus</span> Safe Lightning Protection Rod is as follows: It attracts and captures direct lightning approaching the structure, while the secondary air terminals arrest side flashes. Subsequently, these terminals redirect high voltage lightning currents into the earth via conductors, thereby thwarting structural damage and fire hazards.</p>
                        <p className=' text-base text-justify mt-2 mb-2 p-common'>Broadly speaking, the protection angle is set at 45° at a 15-meter height and 30° at a 25-meter height.</p>
                    </div>
                    
                      
        
                </div>
               
            </div>
            <div className="w-full flex justify-center items-center bg-ashgray1 ">   
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                        <p className='text-xl'>Applications</p>
                        <p> Power plants & Transmission towers | Solar farms | Office | buildings | Residential complexes | Commercial structures | Telecommunication towers | Wind farms | Power stations | Mines | Any kind of installation</p>
                    </div>  
            </div>  
            {/*
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <p className=' text-2xl'></p>
                    <div className='flex'>
                        
                        <div className='w-1/2'>
                            <ul className="list-none ml2">
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-1.5 leading-4"/>Available in SS</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Copper variant.</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Side Flash Capture Head SFCH</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Overall Length is 1010 mm</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Base plate dimension is 150mm x 150mm x 6mm</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Conforms to IEC 62305 i.e., Code Of Practice For Lightning. </li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Conceptualised | Developed | Designed by ASHLOK</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Tested to withstand multiple 40 kA impulse current</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Maintenance Free.</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Fit & Forget. </li>

                            </ul>
                        </div>
                        <div className='w-1/2'>
                            <ul className="list-none ml2">
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-1.5 leading-4"/>Zeus Safe Lightning Protection system contains one primary and four secondary air terminals</li>
                           
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-1.5 leading-4"/>The secondary air terminals are shaped as a bowl-shaped circular disc that’s fixed on the vertical rod</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-1.5 leading-4"/>Moreover, the acute points of the secondary air terminals are spaced and placed in such a way to receive the maximum side flashes.</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>The primary air terminal is a vertical conductive rod having a sharp tip at one end </li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>CPRI tested for 40kA</li>
                            <li className="flex items-start p-common"><img src={boltico} className="mr-2 pr-2 my-2 leading-4"/>Updated version of FRANKLYN Lightning Rod.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <p className='text-xl text-center'> <span className='vollkorn-sc-semibold'>Zeus®</span> SLPR Variants</p>
                    
                        <div className=''>
                                    <table className=" w-full divide-y divide-gray-200 my-3  p-common">
                                        <thead className='w-full'>
                                            <tr className=" bg-ashred   ">
                                            <th className=" px-4 py-2 text-white">Type</th>
                                            <th className=" px-4 py-2 text-white"><div  className='vollkorn-sc-semibold'>Zeus 1.0 SS</div></th>
                                            <th className=" px-4 py-2 text-white" ><span className='vollkorn-sc-semibold'>Zeus 1.0 Cu</span></th>
                                            <th className=" px-4 py-2 text-white"><span className='vollkorn-sc-semibold'>Zeus 1.2 SS</span></th>

                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">Applicable Standard</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                            <td className=" px-4 py-2 text-center">IS/IEC - 62305</td>
                                          
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Material Of Construction</td>
                                            <td className=" px-4 py-2 text-center">SS 304 L</td>
                                            <td className=" px-4 py-2 text-center" style={{fontSize:'120%'}}>Copper with Niplated Tip</td>
                                            <td className=" px-4 py-2 text-center">SS 304 L</td>

                                            </tr>
    
                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Total Length</td>
                                            <td className=" px-4 py-2 text-center">1010 mm</td>
                                            <td className=" px-4 py-2 text-center">1010 mm</td>
                                            <td className=" px-4 py-2 text-center">505 mm</td>
                                          
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Diameter of Main Rod</td>
                                            <td className=" px-4 py-2 text-center">22 mm</td>
                                            <td className=" px-4 py-2 text-center">22 mm</td>
                                            <td className=" px-4 py-2 text-center">19 mm</td>
                                          
                                            </tr>
    
                                            <tr className="bg-gray-100">
                                            <td className=" px-4 py-2 text-center">Diameter of SFCH</td>
                                            <td className=" px-4 py-2 text-center">125 mm</td>
                                            <td className=" px-4 py-2 text-center">125 mm</td>
                                            <td className=" px-4 py-2 text-center">110 mm</td>
                                  
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">Base Plate</td>
                                            <td className=" px-4 py-2 text-center"> 150 x 150 x 10mm</td>
                                            <td className=" px-4 py-2 text-center">150 x 150 x 10mm</td>
                                            <td className=" px-4 py-2 text-center">70 x 70 x 5 mm</td>

                                        
                                            </tr>
                                        
                                        </tbody>
                                    </table>
                        </div>
                        
                   
                
               
                       
                    
                </div>
                
            </div> */}
            </>
          )
    }
    
    export default Zeusdetailscontent
    