import React,{useContext,useState,useEffect} from 'react'
import lp from './assets/images/producthome/lightning protection logo.svg';
import es from './assets/images/producthome/earth solution logo.svg';
import epit from './assets/images/producthome/epcc logo.svg';
import bfc from './assets/images/producthome/bfc logo.svg';
import prshield from './assets/images/producthome/product_shield.png';
import prshield2x from './assets/images/producthome/product_shield@2x.png';
import clp from './assets/images/producthome/clp_logo.svg';
import {useNavigate } from 'react-router-dom';
import { ProductContext } from './context/Productcontext';
    
    const Producthomecontent = () => {
        let navigate = useNavigate();
        const [leftPosition, setLeftPosition] = useState('10%');
        const { setCategory,selectedCategory } = useContext(ProductContext);

        const divStyle={
            backgroundImage:`url(${prshield})`
            
        }

        useEffect(() => {
            function handleResize() {
              const screenWidth = window.innerWidth;
              // Calculate the left position dynamically based on screen width
              const newLeftPosition = `${(screenWidth ) / 100}px`; // Adjust the percentage value as needed
              setLeftPosition(newLeftPosition);
            }
        
            // Initial call to handleResize
            handleResize();
        
            // Add event listener for window resize
            window.addEventListener('resize', handleResize);
        
            // Clean up event listener on component unmount
            return () => {
              window.removeEventListener('resize', handleResize);
            };
          }, []); // Empty dependency array to ensure effect runs only once
        

        const handleItemClick = (item) => {
            setCategory(item);
            navigate('/lpcat')
           /* if(item==='slpr')
                navigate('/lpcat')
            else if(item==='clp')
                navigate('/clp')
            else if(item==='bfc')
                navigate('/bfc')
            else if(item==='epcc')
                navigate('/epcc')
           else if(item==='ele')
                navigate('/clp')*/
        }

      return (
        <div className=' w-full flex justify-center items-center mx-4 my-5'>
            {/*
            <div className=' container mx-auto max-w-screen-xl justify-center items-center flex-row '>
                <div className="flex flex-wrap justify-center">
                    <div className="w-1/3  overflow-hidden">
                        <img src={lp} alt="Image 1" className="object-cover h-full" />
                    </div>
                    <div className="w-1/3  overflow-hidden">
                        <img src={prshield} alt="Image 2" className="object-cover h-full" />
                    </div>
                    <div className="w-1/3  overflow-hidden">
                        <img src={es} alt="Image 3" className="object-cover h-full" />
                    </div>
            
                    <div className="w-1/3 h-48 overflow-hidden">
                        <img src={es} alt="Image 1" className="object-cover h-full" />
                    </div>
                    <div className="w-1/3 h-48 overflow-hidden">
                            
                        <div className="relative">
                            <img src="image2.jpg" alt="Image 2" className="object-cover h-full absolute inset-0" />
                           
                        </div>
                    </div>
                </div>
            </div>
      */}
        <div className='flex flex-col space-x-14'>
            <div className='relative container max-w-screen-2xl flex flex-row'>
                    
                    <div className='flex flex-col'>
                        <div className='relative flex flex-row justify-end mx-5 '  onClick={() => handleItemClick('ele')}>
                            <div className="overflow-hidden cursor-pointer">
                                <img   src={es}/>
                            </div>
                            
                        </div>
                        <div className=' absolute top-1/2' onClick={() => handleItemClick('epcc')}>
                            <div className="overflow-hidden cursor-pointer">
                                <img   src={epit}/>
                            </div>
                          
                        </div>

                    </div>
                    <div className="flex justify-center items-center overflow-hidden ml-2">
                            <img src={prshield} alt="Image 1" className="object-cover h-full" 
                            srcSet={`${prshield} 1x, ${prshield2x} 2x`}
                            />


                    </div>
                    <div className='flex flex-col'>       
                        <div className='realtive flex flex-row  justify-start mx-5'  onClick={() => handleItemClick('slpr')}>
                                    <div className="overflow-hidden cursor-pointer">
                                        <img src={lp}/>
                                    </div>
                        </div>
                        {/* <div className='absolute top-1/2 items-start'  onClick={() => handleItemClick('bfc')}> */}
                        <div className='absolute top-1/2 left-3/4 translate-x-22'  onClick={() => handleItemClick('bfc')}>
                                <div className="overflow-hidden cursor-pointer">
                                    <img src={bfc}/>
                                </div>
                        </div>
                    </div>
                    {/* }
                    <div className='absolute flex flex-row'>
                        <div className="w-1/4  overflow-hidden">
                             <img className="top-10 left-50" src={lp}/>
                        </div>
                       
                        <div className="w-1/4  overflow-hidden">
                                <img className="top-10 left-50" src={es}/>
                        </div>
                                            
                            
                    </div>
                  
                    <div className='flex flex-row'>
                    <div className="w-1/3  overflow-hidden">
                        <img src={es} alt="Image 1" className="object-cover h-full" />
                    </div>
                    <div className="w-1/3  overflow-hidden">
                        <img src={es} alt="Image 1" className="object-cover h-full" />
                    </div>
                    </div> */}
                
                
            </div>
            <div className='flex justify-center items-center mt-5 cursor-pointer' onClick={() => handleItemClick('clp')}>
                <img src={clp}/>
                    </div>
            
           
        </div>
    </div>
          
        
      )
    }
    export default Producthomecontent
    
    
    