import React,{useState} from 'react'
import PreHeader from "./PreHeader";
import Header from "./Header";
import Menu from "./Menu";
import HomeBanner from "./HomeBanner";
import Footer from "./Footer";
import MetaTags from "./MetaTags";
import EathingNecessary from './EathingNecessary';
import EarthingBanner from './EarthingBanner';
import banner1 from './assets/images/earthing/earthing-banner.jpg';
import banner1_2x from  './assets/images/earthing/earthing-banner_2x.jpg';
import { Helmet } from 'react-helmet-async';


const EarthingPage = () => {
  const [isSticky, setSticky] = useState(true);
  return (
    <div>
    <Helmet>
      <title> Advanced Earthing Solutions by Ashlok Safe Earthing Electrode Ltd | Chennai</title>
      <meta name="description" content="Explore Ashlok Safe Earthing Electrode Ltd's range of advanced earthing solutions designed for optimal safety and efficiency. Located in Chennai, our products ensure robust protection against electrical hazards for residential, commercial, and industrial applications. Discover our innovative earthing systems tailored to meet your specific needs." />

    </Helmet>
    <PreHeader/>
    <Header isSticky={isSticky}/>
    <Menu isHeaderVisible={isSticky}/>
   
    <EarthingBanner page="earthing" banalt="earthing ban1" bannertext="LIGHTNING & EARTHING PROTECTION SYSTEM" ban1x={banner1} ban2x={banner1_2x}/>
    <EathingNecessary/> 
    <Footer/>
     </div>
  )
}

export default EarthingPage
