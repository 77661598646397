import React,{useState} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';

import pvdimg from './assets/images/earthing/pvd/pvd_big.png';
import pvdimg2x from './assets/images/earthing/pvd/pvd_big@2x.png';

    const Aseel_pvd_earthing = () => {
        const [selectedSubmenu, setSelectedSubmenu] = useState('');

        const handleImageClick = (system) => {
            setSelectedSubmenu(system);
            console.log("selcet system is"+system);
        };

        return (
            <>
            <EarthingelectrodeSubMenu onImageClick={handleImageClick}/>
            <div className='w-full my-3 flex justify-center items-center'>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
                    <div className='flex justify-center '>
                        
                        <div className='flex justify-center items-center'>
                        <img src={pvdimg}  
                                                srcSet={`${pvdimg} 1x,${pvdimg2x} 2x`} alt=" ASEEl PVD logo"
                                                /> 
                        </div>
                       
                    </div>
                    <div className='flex flex-col items-start'>
                        <p className=' text-2xl text-ashred tracking-wider  font-semibold'><span className='vollkorn-sc-semibold'>ASEEL PVD <span className=' text-4xl px-1'>|</span> Zinc 150 Microns Earthing Electrode(<span style={{fontSize:'80%'}}>s</span>) </span></p>
                        <p className=' text-base text-justify p-common'> <span className='vollkorn-sc-semibold'>Ashlok’s</span> PVD Earthing Electrodes, where precision meets reliability. Our PVD-coated electrodes redefine grounding solutions, offering unmatched performance and safety. Designed with cutting-edge technology, these electrodes ensure stable and secure grounding, protecting your assets from electrical hazards. Trust <span className='vollkorn-sc-semibold'>Ashlok’s</span> expertise and commitment to quality for a safer and more efficient grounding system. Explore our range and elevate your grounding to a new level of precision with <span className='vollkorn-sc-semibold'>Ashlok</span>.</p>
                       
                    </div>
                    
                    
                </div>
            </div>
            <div className='w-full flex justify-center items-center bg-ashgray1 '>
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                
                    <div className='flex  space-x-10'>
                    

                        <div className='w-1/2'>
                        <p className=' text-1xl text-ashred font-extrabold'>Features & Benefits</p>
                            <ul className="list-none ">
                            <li className="flex items-center p-common"><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>In compliance with IS 3043 <span className='text-gray-500 text-2xl px-1'>|</span> revised</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Coated using a SPM <span className='text-gray-500 text-2xl px-1'>|</span> PVD technology </li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Coating thickness exceeding 150 microns</li>
                                             </ul>
                        </div>
                        <div className='w-1/2'>
                            <ul className="list-none ml-2 ">
                            <li className="flex items-center p-common text-ashgray1">Uniform surface coating</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Uniform surface coating</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Cathodic corrosion protection</li>
                            <li className="flex items-center p-common"><img src={boltico} className="mr-1 pr-1 my-1.5 pt-1"/>Customisation is available as per customers requirement </li>
                                 </ul>
                        </div>
                        <div className='w-1/4'></div>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
             
                <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
                <p className='text-xl'>Zinc Coated Earthing Electrode Variant</p>
                    <div className='flex'>
                        <div className='container w-full  mb-1'>
                                <table className=" w-full divide-y divide-gray-200 p-common">
                                        <thead className='w-full'>
                                            
                                            <tr className="bg-ashred">

                                            <th className=" px-4 py-2 text-white">Model</th>
                                            <th className="  px-4 py-2 text-white">Length (Mtr)</th>
                                            <th className="  px-4 py-2 text-white">Inner Pipe Dia  (mm)</th>
                                            <th className="  px-4 py-2 text-white">Outer Pipe Dia  (mm)</th>
                                           
                                            </tr>
                                        </thead>
                                        <tbody className="bg-gray-100">
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 192 PVD</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48 </td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 193 PVD</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">26</td>
                                            <td className=" px-4 py-2 text-center">48 </td>
                                            </tr>
                                            <tr className="bg-white">
                                            <td className=" px-4 py-2 text-center">ASEEL 392 PVD</td>
                                            <td className=" px-4 py-2 text-center">2</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">76</td>
                                            </tr>
                                            <tr className="bg-gray-200">
                                            <td className=" px-4 py-2 text-center">ASEEL 393 PVD</td>
                                            <td className=" px-4 py-2 text-center">3</td>
                                            <td className=" px-4 py-2 text-center">42</td>
                                            <td className=" px-4 py-2 text-center">76</td>
                                            </tr>
    
                                           
                                        
                                        </tbody>
                                    </table>
                        </div>
                        
                    </div>
                
                
                       
                       
                    
                </div>
                
            </div>
            </>
          )
    }
    
    export default Aseel_pvd_earthing
    