import React,{useState,useEffect} from 'react'
import Fontloader from './Fontloader';
const PreHeader = () => {
  const [isSticky, setSticky] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* <div className={`w-full flex flex-row py-1 bg-ashred text-white   ${isSticky ? 'sticky top-0 z-50 ' : ' '}`}> */}
    <div className={`w-full flex flex-row py-1    ${isSticky ? 'sticky top-0 z-50 bg-white text-ashred' : ' bg-ashred text-white'}`}>
      <section className=' container mx-auto max-w-screen-xl flex justify-center items-center'>
    <div className=''>Your Ultimate Solution For Earthing & Lightning Protection</div>
      
      </section>
    </div>
    </>
  )
}

export default PreHeader
