import React,{useState,useEffect, useRef} from 'react'
import {PhotoAlbum,RenderRowContainer} from "react-photo-album";
import { Curtains, Plane } from 'react-curtains';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import lpage_2x from './assets/images/gallery/Gallery@2x.jpg';
import PreHeader from './PreHeader';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';

import gallery_banner from './assets/images/gallery/banner/gallery-banner.jpg';
import gallery_banner2x from './assets/images/gallery/banner/gallery-banner@2x.jpg';

/* Media Images */
import me_image1 from './assets/images/gallery/imge.jpg';
import me_image1_2x from './assets/images/gallery/imge@2x.jpg';
import me_image2 from './assets/images/gallery/image.jpg';
import me_image2_2x from './assets/images/gallery/image@2x.jpg';
import me_image3 from './assets/images/gallery/image2.jpg';
import me_image3_2x from './assets/images/gallery/image2@2x.jpg';

import me_big1 from './assets/images/gallery/IMG_0594.jpg';
import me_big2 from './assets/images/gallery/IMG_5534.jpg';
import me_big3 from './assets/images/gallery/IMG_5717.jpg';




/* milan photos */
import m1 from './assets/images/gallery/Milan/Small_Image/IMG_1837.jpg';
import m1_2x from './assets/images/gallery/Milan/Small_Image/IMG_1837@2x.jpg';
import m1_big from './assets/images/gallery/Milan/IMG_1837.jpg';

import m2 from './assets/images/gallery/Milan/Small_Image/IMG_1842.jpg';
import m2_2x from './assets/images/gallery/Milan/Small_Image/IMG_1842@2x.jpg';
import m2_big from './assets/images/gallery/Milan/IMG_1842.jpg';

import m3 from './assets/images/gallery/Milan/Small_Image/IMG_1850.jpg';
import m3_2x from './assets/images/gallery/Milan/Small_Image/IMG_1850@2x.jpg';
import m3_big from './assets/images/gallery/Milan/IMG_1850.jpg';

import m4 from './assets/images/gallery/Milan/Small_Image/IMG_1856.jpg';
import m4_2x from './assets/images/gallery/Milan/Small_Image/IMG_1856@2x.jpg';
import m4_big from './assets/images/gallery/Milan/IMG_1856.jpg';

import m5 from './assets/images/gallery/Milan/Small_Image/IMG_1864.jpg';
import m5_2x from './assets/images/gallery/Milan/Small_Image/IMG_1864@2x.jpg';
import m5_big from './assets/images/gallery/Milan/IMG_1864.jpg';

import m6 from './assets/images/gallery/Milan/Small_Image/IMG_1890.jpg';
import m6_2x from './assets/images/gallery/Milan/Small_Image/IMG_1890@2x.jpg';
import m6_big from './assets/images/gallery/Milan/IMG_1890.jpg';

import m7 from './assets/images/gallery/Milan/Small_Image/IMG_1925.jpg';
import m7_2x from './assets/images/gallery/Milan/Small_Image/IMG_1925@2x.jpg';
import m7_big from './assets/images/gallery/Milan/IMG_1925.jpg';

import m8 from './assets/images/gallery/Milan/Small_Image/IMG_1962.jpg';
import m8_2x from './assets/images/gallery/Milan/Small_Image/IMG_1962@2x.jpg';
import m8_big from './assets/images/gallery/Milan/IMG_1962.jpg';

import m9 from './assets/images/gallery/Milan/Small_Image/IMG_1964.jpg';
import m9_2x from './assets/images/gallery/Milan/Small_Image/IMG_1964@2x.jpg';
import m9_big from './assets/images/gallery/Milan/IMG_1964.jpg';

import m10 from './assets/images/gallery/Milan/Small_Image/IMG_1966.jpg';
import m10_2x from './assets/images/gallery/Milan/Small_Image/IMG_1966@2x.jpg';
import m10_big from './assets/images/gallery/Milan/IMG_1966.jpg';

import m11 from './assets/images/gallery/Milan/Small_Image/IMG_1995.jpg';
import m11_2x from './assets/images/gallery/Milan/Small_Image/IMG_1995@2x.jpg';
import m11_big from './assets/images/gallery/Milan/IMG_1995.jpg';

/*Events photos */
import E1 from './assets/images/gallery/Events/Small_Image/DSC_0038.jpg';
import E1_2x from './assets/images/gallery/Events/Small_Image/DSC_0038@2x.jpg';
import E1_big from './assets/images/gallery/Events/DSC_0038.jpg';

import E2 from './assets/images/gallery/Events/Small_Image/DSC_0044.jpg';
import E2_2x from './assets/images/gallery/Events/Small_Image/DSC_0044@2x.jpg';
import E2_big from './assets/images/gallery/Events/DSC_0044.jpg';

import E3 from './assets/images/gallery/Events/Small_Image/DSC_0045.jpg';
import E3_2x from './assets/images/gallery/Events/Small_Image/DSC_0045@2x.jpg';
import E3_big from './assets/images/gallery/Events/DSC_0045.jpg';

// import E4 from './assets/images/gallery/Events/Small_Image/DSC_0048.jpg';
// import E4_2x from './assets/images/gallery/Events/Small_Image/DSC_0048@2x.jpg';
// import E4_big from './assets/images/gallery/Events/DSC_0048.jpg';

import E5 from './assets/images/gallery/Events/Small_Image/DSC_0050.jpg';
import E5_2x from './assets/images/gallery/Events/Small_Image/DSC_0050@2x.jpg';
import E5_big from './assets/images/gallery/Events/DSC_0050.jpg';

import E6 from './assets/images/gallery/Events/Small_Image/DSC_0052.jpg';
import E6_2x from './assets/images/gallery/Events/Small_Image/DSC_0052@2x.jpg';
import E6_big from './assets/images/gallery/Events/DSC_0052.jpg';

import E7 from './assets/images/gallery/Events/Small_Image/DSC_0058.jpg';
import E7_2x from './assets/images/gallery/Events/Small_Image/DSC_0058@2x.jpg';
import E7_big from './assets/images/gallery/Events/DSC_0058.jpg';

import E8 from './assets/images/gallery/Events/Small_Image/DSC_0060.jpg';
import E8_2x from './assets/images/gallery/Events/Small_Image/DSC_0060@2x.jpg';
import E8_big from './assets/images/gallery/Events/DSC_0060.jpg';

import E9 from './assets/images/gallery/Events/Small_Image/Elecrama_2020-1.jpg';
import E9_2x from './assets/images/gallery/Events/Small_Image/Elecrama_2020-1@2x.jpg';
import E9_big from './assets/images/gallery/Events/Elecrama_2020-1.jpg';

import E10 from './assets/images/gallery/Events/Small_Image/IMG_0350.jpg';
import E10_2x from './assets/images/gallery/Events/Small_Image/IMG_0350@2x.jpg';
import E10_big from './assets/images/gallery/Events/IMG_0350.jpg';

import E11 from './assets/images/gallery/Events/Small_Image/IMG_0603.jpg';
import E11_2x from './assets/images/gallery/Events/Small_Image/IMG_0603@2x.jpg';
import E11_big from './assets/images/gallery/Events/IMG_0603.jpg';

import E12 from './assets/images/gallery/Events/Small_Image/IMG_1016.jpg';
import E12_2x from './assets/images/gallery/Events/Small_Image/IMG_1016@2x.jpg';
import E12_big from './assets/images/gallery/Events/IMG_1016.jpg';

import E13 from './assets/images/gallery/Events/Small_Image/IMG_2610.jpg';
import E13_2x from './assets/images/gallery/Events/Small_Image/IMG_2610@2x.jpg';
import E13_big from './assets/images/gallery/Events/IMG_2610.jpg';

import E14 from './assets/images/gallery/Events/Small_Image/IMG_2613.jpg';
import E14_2x from './assets/images/gallery/Events/Small_Image/IMG_2613@2x.jpg';
import E14_big from './assets/images/gallery/Events/IMG_2613.jpg';

import E15 from './assets/images/gallery/Events/Small_Image/IMG_2615.jpg';
import E15_2x from './assets/images/gallery/Events/Small_Image/IMG_2615@2x.jpg';
import E15_big from './assets/images/gallery/Events/IMG_2615.jpg';

import E16 from './assets/images/gallery/Events/Small_Image/IMG_2619.jpg';
import E16_2x from './assets/images/gallery/Events/Small_Image/IMG_2619@2x.jpg';
import E16_big from './assets/images/gallery/Events/IMG_2619.jpg';

import E17 from './assets/images/gallery/Events/Small_Image/kovai_expo.jpg';
import E17_2x from './assets/images/gallery/Events/Small_Image/kovai_expo@2x.jpg';
import E17_big from './assets/images/gallery/Events/kovai_expo_big.jpg';

/* Certificate Photos  */
import CE1 from './assets/images/gallery/Certificates/Small_Image/alice-digital-cpri.jpg';
import CE1_2x from './assets/images/gallery/Certificates/Small_Image/alice-digital-cpri@2x.jpg';
import CE1_big from './assets/images/gallery/Certificates/alice-digital-cpri.jpg';

import CE2 from './assets/images/gallery/Certificates/Small_Image/big-round-pit.jpg';
import CE2_2x from './assets/images/gallery/Certificates/Small_Image/big-round-pit@2x.jpg';
import CE2_big from './assets/images/gallery/Certificates/big-round-pit.jpg';

import CE3 from './assets/images/gallery/Certificates/Small_Image/certificate-of-compliance-E490020-20170314.jpg';
import CE3_2x from './assets/images/gallery/Certificates/Small_Image/certificate-of-compliance-E490020-20170314@2x.jpg';
import CE3_big from './assets/images/gallery/Certificates/certificate-of-compliance-E490020-20170314.jpg';

import CE4 from './assets/images/gallery/Certificates/Small_Image/cpri-19-1.jpg';
import CE4_2x from './assets/images/gallery/Certificates/Small_Image/cpri-19-1@2x.jpg';
import CE4_big from './assets/images/gallery/Certificates/cpri-19-1.jpg';

import CE5 from './assets/images/gallery/Certificates/Small_Image/cpri-39-1.jpg';
import CE5_2x from './assets/images/gallery/Certificates/Small_Image/cpri-39-1@2x.jpg';
import CE5_big from './assets/images/gallery/Certificates/cpri-39-1.jpg';

import CE6 from './assets/images/gallery/Certificates/Small_Image/cpri-aseel-50.jpg';
import CE6_2x from './assets/images/gallery/Certificates/Small_Image/cpri-aseel-50@2x.jpg';
import CE6_big from './assets/images/gallery/Certificates/cpri-aseel-50.jpg';

import CE7 from './assets/images/gallery/Certificates/Small_Image/electrodite-leachable-element-rest.jpg';
import CE7_2x from './assets/images/gallery/Certificates/Small_Image/electrodite-leachable-element-rest@2x.jpg';
import CE7_big from './assets/images/gallery/Certificates/electrodite-leachable-element-rest.jpg';

import CE8 from './assets/images/gallery/Certificates/Small_Image/electrodite-rohs.jpg';
import CE8_2x from './assets/images/gallery/Certificates/Small_Image/electrodite-rohs@2x.jpg';
import CE8_big from './assets/images/gallery/Certificates/electrodite-rohs.jpg';

import CE9 from './assets/images/gallery/Certificates/Small_Image/iso-test.jpg';
import CE9_2x from './assets/images/gallery/Certificates/Small_Image/iso-test@2x.jpg';
import CE9_big from './assets/images/gallery/Certificates/iso-test.jpg';

import CE10 from './assets/images/gallery/Certificates/Small_Image/thor-bt-tr-test.jpg';
import CE10_2x from './assets/images/gallery/Certificates/Small_Image/thor-bt-tr-test@2x.jpg';
import CE10_big from './assets/images/gallery/Certificates/thor-bt-tr-test.jpg';

import CE11 from './assets/images/gallery/Certificates/Small_Image/thor-cpri.jpg';
import CE11_2x from './assets/images/gallery/Certificates/Small_Image/thor-cpri@2x.jpg';
import CE11_big from './assets/images/gallery/Certificates/thor-cpri.jpg';

import CE12 from './assets/images/gallery/Certificates/Small_Image/thor-helios-test.jpg';
import CE12_2x from './assets/images/gallery/Certificates/Small_Image/thor-helios-test@2x.jpg';
import CE12_big from './assets/images/gallery/Certificates/thor-helios-test.jpg';

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";

import Zoom from "yet-another-react-lightbox/plugins/zoom";
import './Gallerypage.css';
// import AnimatePlane from './AnimatePlane';
// import PlaneTest from './PlaneTest';

import { gsap } from 'gsap';
//import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollToTopButton from './ScrollToTopButton';
import InstallationBanner from './InstallationBanner';

//gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);



const company_photos = [
  { src: `${me_image1}`, width: 237, height: 227,srcSet: [
    { src: `${me_image1}`, width: 237, height: 227 },
    { src: `${me_image1_2x}`, width: 474, height: 454 },
  ], },
  { src: `${me_image2}`,width: 237, height: 227,
  srcSet: [
    { src: `${me_image2}`, width: 237, height: 227 },
    { src: `${me_image2_2x}`, width: 474, height: 454 },
    ] },
  { src: `${me_image3}`, width: 237, height: 227,
  srcSet: [
    { src: `${me_image3}`, width: 237, height: 227 },
    { src: `${me_image3_2x}`, width: 474, height: 454 },
  ] },
  //  { src: `${smimage3}`, width: 237, height: 227,
  // srcSet: [
  //   { src: `${smimage3}`, width: 237, height: 227 },
  //   { src: `${smimage3_2x}`, width: 474, height: 454 },
  // ] }, { src: `${smimage3}`, width: 237, height: 227,
  // srcSet: [
  //   { src: `${smimage3}`, width: 237, height: 227 },
  //   { src: `${smimage3_2x}`, width: 474, height: 454 },
  // ] },{ src: `${smimage3}`, width: 237, height: 227,
  // srcSet: [
  //   { src: `${smimage3}`, width: 237, height: 227 },
  //   { src: `${smimage3_2x}`, width: 474, height: 454 },
  // ] }, { src: `${smimage3}`, width: 237, height: 227,
  // srcSet: [
  //   { src: `${smimage3}`, width: 237, height: 227 },
  //   { src: `${smimage3_2x}`, width: 474, height: 454 },
  // ] }, 
 
]
const company_photosfull = [
  { src: `${me_big1}` },
  { src: `${me_big2}` },
  { src: `${me_big3}` },
  
  // { src: `${bigimage3}` },
  // { src: `${bigimage3}` },
  // { src: `${bigimage3}` },
  // { src: `${bigimage3}` },
 
]


const milanPhotos = [
  { src: `${m1}`, width: 237, height: 227,srcSet: [
    { src: `${m1}`, width: 237, height: 227 },
    { src: `${m1_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m2}`, width: 237, height: 227,srcSet: [
    { src: `${m2}`, width: 237, height: 227 },
    { src: `${m2_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m3}`, width: 237, height: 227,srcSet: [
    { src: `${m3}`, width: 237, height: 227 },
    { src: `${m3_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m4}`, width: 237, height: 227,srcSet: [
    { src: `${m4}`, width: 237, height: 227 },
    { src: `${m4_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m5}`, width: 237, height: 227,srcSet: [
    { src: `${m5}`, width: 237, height: 227 },
    { src: `${m5_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m6}`, width: 237, height: 227,srcSet: [
    { src: `${m6}`, width: 237, height: 227 },
    { src: `${m6_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m7}`, width: 237, height: 227,srcSet: [
    { src: `${m7}`, width: 237, height: 227 },
    { src: `${m7_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m8}`, width: 237, height: 227,srcSet: [
    { src: `${m8}`, width: 237, height: 227 },
    { src: `${m8_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m9}`, width: 237, height: 227,srcSet: [
    { src: `${m9}`, width: 237, height: 227 },
    { src: `${m9_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m10}`, width: 237, height: 227,srcSet: [
    { src: `${m10}`, width: 237, height: 227 },
    { src: `${m10_2x}`, width: 474, height: 454 },
  ], },
  { src: `${m11}`, width: 237, height: 227,srcSet: [
    { src: `${m11}`, width: 237, height: 227 },
    { src: `${m11_2x}`, width: 474, height: 454 },
  ], },
]

const millan_photosfull = [
  { src: `${m1_big}` },
  { src: `${m2_big}` },
  { src: `${m3_big}` },
  { src: `${m4_big}` },
  { src: `${m5_big}` },
  { src: `${m6_big}` },
  { src: `${m7_big}` },
  { src: `${m8_big}` },
  { src: `${m9_big}` },
  { src: `${m10_big}` },
  { src: `${m11_big}` },
 
]

const eventsPhotos=[
  { src: `${E1}`, width: 237, height: 227,srcSet: [
    { src: `${E1}`, width: 237, height: 227 },
    { src: `${E1_2x}`, width: 474, height: 454 },
  ], },

  { src: `${E2}`, width: 237, height: 227,srcSet: [
    { src: `${E2}`, width: 237, height: 227 },
    { src: `${E2_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E3}`, width: 237, height: 227,srcSet: [
    { src: `${E3}`, width: 237, height: 227 },
    { src: `${E3_2x}`, width: 474, height: 454 },
  ], },
  // { src: `${E4}`, width: 237, height: 227,srcSet: [
  //   { src: `${E4}`, width: 237, height: 227 },
  //   { src: `${E4_2x}`, width: 474, height: 454 },
  // ], },
  { src: `${E5}`, width: 237, height: 227,srcSet: [
    { src: `${E5}`, width: 237, height: 227 },
    { src: `${E5_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E6}`, width: 237, height: 227,srcSet: [
    { src: `${E6}`, width: 237, height: 227 },
    { src: `${E6_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E7}`, width: 237, height: 227,srcSet: [
    { src: `${E7}`, width: 237, height: 227 },
    { src: `${E7_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E8}`, width: 237, height: 227,srcSet: [
    { src: `${E8}`, width: 237, height: 227 },
    { src: `${E8_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E9}`, width: 237, height: 227,srcSet: [
    { src: `${E9}`, width: 237, height: 227 },
    { src: `${E9_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E10}`, width: 237, height: 227,srcSet: [
    { src: `${E10}`, width: 237, height: 227 },
    { src: `${E10_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E11}`, width: 237, height: 227,srcSet: [
    { src: `${E11}`, width: 237, height: 227 },
    { src: `${E11_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E12}`, width: 237, height: 227,srcSet: [
    { src: `${E12}`, width: 237, height: 227 },
    { src: `${E12_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E13}`, width: 237, height: 227,srcSet: [
    { src: `${E13}`, width: 237, height: 227 },
    { src: `${E13_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E14}`, width: 237, height: 227,srcSet: [
    { src: `${E14}`, width: 237, height: 227 },
    { src: `${E14_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E15}`, width: 237, height: 227,srcSet: [
    { src: `${E15}`, width: 237, height: 227 },
    { src: `${E15_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E16}`, width: 237, height: 227,srcSet: [
    { src: `${E16}`, width: 237, height: 227 },
    { src: `${E16_2x}`, width: 474, height: 454 },
  ], },
  { src: `${E17}`, width: 237, height: 227,srcSet: [
    { src: `${E17}`, width: 237, height: 227 },
    { src: `${E17_2x}`, width: 474, height: 454 },
  ], },


];

const events_photosfull = [
  { src: `${E1_big}` },
  { src: `${E2_big}` },
  { src: `${E3_big}` },
  // { src: `${E4_big}` },
  { src: `${E5_big}` },
  { src: `${E6_big}` },
  { src: `${E7_big}` },
  { src: `${E8_big}` },
  { src: `${E9_big}` },
  { src: `${E10_big}` },
  { src: `${E11_big}` },
  { src: `${E12_big}` },
  { src: `${E13_big}` },
  { src: `${E14_big}` },
  { src: `${E15_big}` },
  { src: `${E16_big}` },
  { src: `${E17_big}` },
]
const mediaPhotos=[];
const certificationsPhotos=[

  { src: `${CE1}`, width: 237, height: 227,srcSet: [
    { src: `${CE1}`, width: 237, height: 227 },
    { src: `${CE1_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE2}`, width: 237, height: 227,srcSet: [
    { src: `${CE2}`, width: 237, height: 227 },
    { src: `${CE2_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE3}`, width: 237, height: 227,srcSet: [
    { src: `${CE3}`, width: 237, height: 227 },
    { src: `${CE3_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE4}`, width: 237, height: 227,srcSet: [
    { src: `${CE4}`, width: 237, height: 227 },
    { src: `${CE4_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE5}`, width: 237, height: 227,srcSet: [
    { src: `${CE5}`, width: 237, height: 227 },
    { src: `${CE5_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE6}`, width: 237, height: 227,srcSet: [
    { src: `${CE6}`, width: 237, height: 227 },
    { src: `${CE6_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE7}`, width: 237, height: 227,srcSet: [
    { src: `${CE7}`, width: 237, height: 227 },
    { src: `${CE7_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE8}`, width: 237, height: 227,srcSet: [
    { src: `${CE8}`, width: 237, height: 227 },
    { src: `${CE8_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE9}`, width: 237, height: 227,srcSet: [
    { src: `${CE9}`, width: 237, height: 227 },
    { src: `${CE9_2x}`, width: 474, height: 454 },
  ], },
  
  { src: `${CE10}`, width: 237, height: 227,srcSet: [
    { src: `${CE10}`, width: 237, height: 227 },
    { src: `${CE10_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE11}`, width: 237, height: 227,srcSet: [
    { src: `${CE11}`, width: 237, height: 227 },
    { src: `${CE11_2x}`, width: 474, height: 454 },
  ], },
  { src: `${CE12}`, width: 237, height: 227,srcSet: [
    { src: `${CE12}`, width: 237, height: 227 },
    { src: `${CE12_2x}`, width: 474, height: 454 },
  ], },

];
const certificationsPhotosfull=[
  { src: `${CE1_big}` },
  { src: `${CE2_big}` },
  { src: `${CE3_big}` },
  { src: `${CE4_big}` },
  { src: `${CE5_big}` },
  { src: `${CE6_big}` },
  { src: `${CE7_big}` },
  { src: `${CE8_big}` },
  { src: `${CE9_big}` },
  { src: `${CE10_big}` },
  { src: `${CE11_big}` },
  { src: `${CE12_big}` },
];



const allPhotos = [...eventsPhotos, ...milanPhotos, ...company_photos, ...certificationsPhotos]; // Combine all photos
const allbig_Photos = [...events_photosfull, ...millan_photosfull, ...company_photosfull, ...certificationsPhotosfull]; // Combine all photos

const renderRowContainer = ({ rowContainerProps, rowIndex, rowsCount, children }) => (
  <>
    <div {...rowContainerProps}>{children}</div>
    {rowIndex < rowsCount - 1 && (
      <div
        style={{
          borderTop: "5px solid #eee",
          marginBottom: "20px",
        }}
      />
    )}
  </>
);
const GalleryPageContent =()=> {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(-1);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [selectedSection, setSelectedSection] = useState('all');

    const containerRef = useRef(null);
    const photoRefs = useRef([]);

    // useEffect(() => {
    //   if (containerRef.current) {
    //     // Apply GSAP animation to the photo album container
    //     gsap.to(containerRef.current, {
    //       //x: -100, // Adjust this value based on your scroll effect
    //       scrollTrigger: {
    //         trigger: containerRef.current,
    //         start: 'top top',
    //         end: 'bottom top',
    //         scrub: true,
    //       },
    //     });
    //   }
    // }, []);
    

    const getPhotosBySection = (section) => {
      switch (section) {
        case 'events':
          return eventsPhotos;
        case 'milan':
          return milanPhotos;
        case 'media':
          return company_photos;
        case 'certifications':
          return certificationsPhotos;
        case 'all':
        default:
          return allPhotos;
      }
    };

    const getBigPhotosBySection = (section) => {
      switch (section) {
        case 'events':
          return events_photosfull;
        case 'milan':
          return millan_photosfull;
        case 'media':
          return company_photosfull;
        case 'certifications':
          return certificationsPhotosfull;
        case 'all':
        default:
          return allbig_Photos;
      }
    };
  
    const photos = getPhotosBySection(selectedSection);
    const photosfull = getBigPhotosBySection(selectedSection);

    // useEffect(() => {
    //   if (containerRef.current) {
    //     gsap.fromTo(containerRef.current,
    //       {
    //         opacity: 1,
    //         y: 100,
    //       },
    //       {
    //         opacity: 0,
    //         y: 0,
    //         duration: 1,
    //         scrollTrigger: {
    //           trigger: containerRef.current,
    //           start: 'top bottom',
    //           end: 'bottom top',
    //           scrub: true,
    //         },
    //       }
    //     );
    //   }
    // }, [photos]);
    // useEffect(() => {
    //   if (containerRef.current) {
    //     gsap.fromTo(containerRef.current,
    //       {
    //         scale: 0.8,
    //       },
    //       {
    //         scale: 1,
    //         duration: 1,
    //         scrollTrigger: {
    //           trigger: containerRef.current,
    //           start: 'top bottom',
    //           end: 'bottom top',
    //           scrub: true,
    //         },
    //       }
    //     );
    //   }
    // }, [photos]);
    // Rotation effect
    // useEffect(() => {
    //   if (containerRef.current) {
    //     gsap.fromTo(containerRef.current,
    //       {
    //         rotation: 0,
    //       },
    //       {
    //         rotation: 360,
    //         duration: 2,
    //         ease: "none",
    //         scrollTrigger: {
    //           trigger: containerRef.current,
    //           start: 'top bottom',
    //           end: 'bottom top',
    //           scrub: true,
    //         },
    //       }
    //     );
    //   }
    // }, [photos]);

    useEffect(() => {
         if (containerRef.current) {
          const containerHeight = containerRef.current.scrollHeight;
          const viewportHeight = window.innerHeight;
          const scrollHeight = containerHeight - viewportHeight;
    
          // Delay the scroll animation by 1 minute (60000 milliseconds)
          setTimeout(() => {
          gsap.to(window, {
            scrollTo: {
              y:'max', //   y: scrollHeight, Scroll to the bottom of the page
              autoKill: true
            },
            duration: 15, // Duration of the scroll in seconds
            ease: 'none',  // power2.inOut
          });

         },15000); // 60000 milliseconds = 1 minute
        }
    }, [photos]);

    const handleClick = (index) => {
      console.log('Clicked photo index:', index);
      setIndex(index);
    };
    const photosWithIndex = photos.map((photo, index) => ({ photo, index }));

  return (
    <div>
         <PreHeader/>
 
    <Header/>
      <Menu/>
      <InstallationBanner page="Gallery" banalt="Gallery ban1" bannertext="" ban1x={gallery_banner} ban2x={gallery_banner2x}/>
                      <div className=' flex items-center justify-center space-x-5 m-3' style={{zIndex:'300'}}>
                          {/*}  <p className='text-3xl text-ashred  '>Earthing System </p>  style={{zIndex:'10'}} */}
                            
                            <button
                            onClick={() => setSelectedSection('events')}
                            className={`px-4 py-2  bg-ashred2 text-white rounded-full hover:bg-ashred      ${selectedSection === 'events' ? 'focus:outline-none focus:ring-2 focus:ring-red-500' : 'bg-gray-500'}`}>
                                 Events
                            </button>
                            <button 
                            onClick={() => setSelectedSection('milan')}
                            className={`px-4 py-2 bg-ashred2 text-white rounded-full hover:bg-ashred  ${selectedSection === 'milan' ? 'focus:outline-none focus:ring-2 focus:ring-red-500' : 'bg-gray-500'}`}>
                            Milan
                            </button>
                            <button 
                            onClick={() => setSelectedSection('media')}
                            className={`px-4 py-2 bg-ashred2 text-white rounded-full hover:bg-ashred ${selectedSection === 'media' ? 'focus:outline-none focus:ring-2 focus:ring-red-500':'bg-gray-500'} `}>
                            Ad media
                            </button>
                            <button 
                            onClick={() => setSelectedSection('certifications')}
                            className={`px-4 py-2 bg-ashred2 text-white rounded-full hover:bg-ashred ${selectedSection === 'certifications' ? 'focus:outline-none focus:ring-2 focus:ring-red-500':'bg-gray-500'} `}>
                            Certifications
                            </button>
                            <button
                            onClick={() => setSelectedSection('all')}
                            className={`px-4 py-2  bg-ashred2 text-white rounded-full hover:bg-ashred      ${selectedSection === 'all' ? 'focus:outline-none focus:ring-2 focus:ring-red-500' : 'bg-gray-500'}`}>
                                 All
                            </button>

                        </div>
          <div className='w-full flex'>
          
          <div className='w-full px-1 pb-3'>
         
          
            <div className="photo-album-container" ref={containerRef}>
             <PhotoAlbum  layout="masonry"  
              photos={photos} 
              // renderPhoto={({ photo }) => (
              //   <AnimatePlane image={photo} />
              // )}
              
              // renderPhoto={({ photo, index }) => (
              //   <span
              //     ref={el => (photoRefs.current[index] = el)}
              //     key={index}
              //     className="photo"
              //     onClick={() => setIndex(index)} // Set the index on click
              //   >
              //     <img src={photo.src} alt="" />
              //   </span>
              // )}
             
               onClick={({ index }) => setIndex(index)}
               
               />
               {/* <PlaneTest>
               <PhotoAlbum  layout="masonry"  
              photos={photos} 
             
               onClick={({ index }) => setIndex(index)}
               
               />
               </PlaneTest> */}
               </div>
               
             <Lightbox
        //slides={photosfull}
        slides={photosfull.map(photo => ({ src: photo.src }))}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        
       plugins={[ Slideshow /*, Fullscreen,Zoom*/]}
                /> 
               
             </div>
             <ScrollToTopButton/>
          </div>
         <Footer/>
          </div>
          )
   
  
}
export default GalleryPageContent

{/*} <div className='container max-w-screen-2xl mx-auto flex'>
           <img src={lpage_2x}/>    
      
    </div>*/}