    import React,{useState} from 'react'
    import bolticon from './assets/images/bolt.svg';
    
    const ExapndabelMenu = ({ title, heading1,content,content2,content3,content4,content5,bullet1,bullet2,bgColor, textColor,onToggle, customClasses, children}) => {
        const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (onToggle) {
        onToggle(!isExpanded);
      }
  };
  const handleSendMail = (subject, email) => {
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  window.open(mailtoUrl, '_blank');
  };
      return (
        <div className={`${customClasses} w-full`}>
            <div className={`flex justify-start  cursor-pointer  ${bgColor} ${textColor} p-1 items-center`} onClick={toggleExpand}>
                <h2 className="text-lg font-bold p2 pl-5">{title}</h2>
                <span className='ml-auto text-3xl pr-5'>{isExpanded ? '-' : '+'}</span>
            </div>
            {isExpanded && (
            <div className="w-full">
                {heading1 && <div className='flex justify-center items-center'><p className="mt-2 text-xl ">{heading1}</p></div>}
                {content && <p className="mt-2 text-justify ">{content}</p>}
                {bullet1 && (
                        <ul className="list-disc ml-6 ml-40 my-5">
                            {bullet1.map((bullet, index) => (
                                <li  key={index} className=" flex items-start"><img className='mr-2 pt-1.5' src={bolticon}/>{bullet}</li>
                            ))}
                        </ul>
                    )}
                {content2 && <p className="mt-2 text-justify">{content2}</p>}
                {bullet2 && (
                        <ul className="list-disc ml-6">
                            {bullet2.map((bullet, index) => (
                                <li key={index} className="flex items-start"><img className='mr-2 my-2' src={bolticon}/>{bullet}</li>
                            ))}
                        </ul>
                    )}
                
                  {content3 && <p className="mt-2 text-justify ">{content3}</p>}

                  {content4 && <p className="mt-2 text-justify ">{content4}</p>}
                  {content5 && <p className="mt-2 text-justify ">{content5}</p>}
                  {children && React.Children.map(children, child => React.cloneElement(child, { onClick: () => handleSendMail(child.props.subject, child.props.emailid) }))}
            </div>
                )}
        </div>
      )
    }
    
    export default ExapndabelMenu
    