import React,{useState,useEffect} from 'react'
import cmap from './assets/images/career_map/680.jpg';



import { ReactSVG } from 'react-svg';

const URL = 'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.jpg';
const MAP = {
  name: 'my-map',
  // GET JSON FROM BELOW URL AS AN EXAMPLE
  areas: 'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.json',
};


const CareerMap = ()=> {
    const [hoveredArea, setHoveredArea] = useState(null);
    const [highlightStyle, setHighlightStyle] = useState({ fill: 'none' });
    const [highlightedArea, setHighlightedArea] = useState(null);

    const [tooltipText, setTooltipText] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    // Example areas with percentage coordinates
   const handleAreaClick=(event) => {
      // Handle click event for each area here
      console.log("cliekded");
    }
    
    const containerStyle = {
      position: 'relative',
      width: '1000px', // Adjust dimensions based on your image or container size
      height: '1000px',
      margin: '0 auto',
      zIndex:1,
     // backgroundImage:`url(${cmap})`,
     // backgroundSize: 'cover'
    };
  
     {/* caree1 01 block */}
    const polygonVertices = [
      { top: '23%', left: '23.5%' },    // Define your polygon vertices
      { top: '14.4%', left: '38.5%' },
      { top: '10%', left: '55.8%' },

      { top: '30%', left: '56%' },
     
      { top: '43%', left: '55.8%' },
      { top: '47%', left: '47%' },
      { top: '35%', left: '35%' },
       
      
      
    ];

    {/* caree1 02 block */}
    const polygonVertices2 = [
      { top: '55%', left: '15%' },    // Define your polygon vertices
      { top: '55%', left: '45%' },
      { top: '47%', left: '47%' },

      { top: '37.7%', left: '37%' },
     
      { top: '28%', left: '26%' },
      
       
      
      
    ];

    
    const divStyle = {
      position: 'absolute',
      top: '10%',    // Adjust these values as per your layout requirements
      left: '10%',
      width: '200px',
      height: '200px',
      backgroundColor: 'lightblue',
      border: '1px solid blue',
      zIndex: 10,     // Optionally, set z-index for layering
    };
    const convertToPixels = (percent, size) => (parseFloat(percent) / 100) * size;
    const pixelsToPercentage =(px, containerSize) => (px / containerSize) * 100;
  const svgPoints = polygonVertices.map(vertex => {
    const x = Math.round(convertToPixels(vertex.left, 1000)); // 500 is the width of the container
    const y = Math.round(convertToPixels(vertex.top, 1000)); // 500 is the height of the container
    return `${x},${y}`;
  }).join(',');

  const svgPoints2 = polygonVertices2.map(vertex => {
    const x = Math.round(convertToPixels(vertex.left, 1000)); // 500 is the width of the container
    const y = Math.round(convertToPixels(vertex.top, 1000)); // 500 is the height of the container
    return `${x},${y}`;
  }).join(',');


  const handleAreaMouseOver = (e,areaId) => {
   // setHighlightedArea(areaId);
   // console.log(areaId)
   //const containerWidth = e.target.offsetWidth;
   // const containerHeight = e.target.offsetHeight;
   const containerWidth =2100;
   const containerHeight=1000;
    console.log("container width="+containerWidth);
    console.log("container height="+ containerHeight);
    const tooltipX = (Math.round((e.clientX / containerWidth) * 100));
    const tooltipY = (Math.round((e.clientY / containerHeight) * 100));
   setTooltipText(areaId);
   //console.log(e.clientX," , ",e.clientY);
   console.log(tooltipX," .  ", tooltipY );
   //setTooltipPosition({ x: e.clientX  , y: Math.round(pixelsToPercentage(e.clientY,1000))  }); // Offset to avoid covering the mouse
   setTooltipPosition({ x: tooltipX, y: tooltipY   });
  };


  const handleAreaMouseOut = () => {
   // setHighlightStyle(null);
   setTooltipText(null);
  };
  const renderHighlightOverlay = () => {
    const vertex = polygonVertices;

      // Calculate pixel coordinates for the highlight
      const x = Math.round(convertToPixels(vertex.left, 1000));
      const y = Math.round(convertToPixels(vertex.top, 1000));
    if (highlightedArea && x && y) {
      return (
        <div
          style={{
            position: 'absolute',
            top: `${tooltipPosition.y}%`,
            left: `${tooltipPosition.x}%`,
            width: '10px',
            height: '10px',
            backgroundColor: 'green',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 100,
          }}
        />
      );
    }
    return null;
  };

    return (
        <div style={containerStyle} className=" mx-auto p-4" useMap="#image-map">
            <div className="w-full mx-auto" >
    
                 <img src={cmap} alt="Your Image" useMap="#image-map" style={{ width: '100%', height: '100%', display: 'block' }} />
                     <map name="image-map" id="image-map"  style={{ cursor: 'pointer' }}>
        <area key="ch1" shape="poly" coords={svgPoints} href="https://www.google.com" alt="Polygon Area" onClick={handleAreaClick}
         onMouseOver={(e) => handleAreaMouseOver(e,"ch1")}

        />
        <area key="ch2" shape="poly" coords={svgPoints2} href="https://www.amazon.com" alt="Polygon Area" onClick={handleAreaClick}
           onMouseOver={(e) => handleAreaMouseOver(e,"ch2")}

        />
      </map>
      {tooltipText && (
        <div
          style={{
            position: 'absolute',
            top: `${tooltipPosition.y}%`,
            left: `${tooltipPosition.x}%`,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '4px',
            borderRadius: '4px',
            zIndex: 100,
          }}
        >
          {tooltipText}
        </div>
      )}
           

                    
               {/*     
                      
                     {polygonVertices.map((vertex, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: vertex.top,
            left: vertex.left,
            width: '10px',
            height: '10px',
            backgroundColor: 'red',
            borderRadius: '50%',
            border:'1px solid red',
            zIndex: 100,
          }}
        />
      ))}

                    <svg style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <polygon
          points={svgPoints}
          fill="none"
          stroke="blue"
          strokeWidth="2"
          style={{ cursor: 'pointer' }}
        />
      </svg>  

      {polygonVertices2.map((vertex, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: vertex.top,
            left: vertex.left,
            width: '10px',
            height: '10px',
            backgroundColor: 'red',
            borderRadius: '50%',
            border:'1px solid red',
          }}
        />
      ))}

                    <svg style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <polygon
          points={svgPoints2}
          fill="none"
          stroke="blue"
          strokeWidth="2"
          style={{ cursor: 'pointer' }}
        />
      </svg>  
                    
                    */}


                     
        </div>
        </div>
    );
}
export default CareerMap

