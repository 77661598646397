    import React,{useState,useEffect,useContext} from 'react'
import MetaTags from './MetaTags'
import PreHeader from './PreHeader'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import LightningCategoryContent from './LightningCategoryContent'

import banner1 from './assets/images/lightningcategory/lightning-category-banner.jpg';
import banner1_2x from  './assets/images/lightningcategory/lightning-category-banner@2x.jpg';

import ele_banner1 from './assets/images/lightningcategory/earthing-category-banner.jpg';
import ele_banner1_2x from  './assets/images/lightningcategory/earthing-category-banner@2x.jpg';



import clpban1 from './assets/images/lightningcategory/clp_page_banner.jpg';
import clpban1_2x from  './assets/images/lightningcategory/clp_page_banner@2x.jpg';


import bfcban1 from './assets/images/lightningcategory/bfc_page_banner.jpg';
import bfcban1_2x from  './assets/images/lightningcategory/bfc_page_banner@2x.jpg';

import epccban from './assets/images/lightningcategory/epcc_page_banner.jpg';
import epccban_2x from  './assets/images/lightningcategory/epcc_page_banner@2x.jpg';

import { useNavigate } from 'react-router-dom';
import { ProductContext } from './context/Productcontext';

import InstallationBanner from './InstallationBanner';
import ProductsubMenu from './ProductsubMenu';
import ClpdetailsContent from './ClpdetailsContent';
import SafeearthingelectrodeContent from './SafeearthingelectrodeContent';
import Bfcdetailscontent from './Bfcdetailscontent';
import Epccdetailscontent from './Epccdetailscontent';
import EarthingCategoryPage from './EarthingCategoryPage';
import { HelmetProvider } from 'react-helmet-async';    
import CLPPagecontent from './CLPPagecontent'
    const LightningCategoryPage = () => {
        const [pageData, setPageData] = useState('lightnining');
        const {selectedCategory,setCategory} = useContext(ProductContext);
        //const [selectedSubmenu, setSelectedSubmenu] = useState(selectedCategory);
       

        const handleImageClick = (system) => {
            //setSelectedSubmenu(system);
            
            console.log("selcet system is"+system);
        };

        const handleLoad = () => {
            // Do something when the component loads
            console.log('Component loaded!' + selectedCategory);
            //setCategory(selectedSubmenu);
          };
          useEffect(() => {
            handleLoad(); // Call your event handler function
          }, [selectedCategory]); 
        
         

      return (
        <div>
            <HelmetProvider>‍
            <title>Ashlok's Advanced Lightning Protection Products - Safeguarding India</title>‍
            <meta name="description" content="Explore Ashlok's range of state-of-the-art Lightning Protection products, designed to offer unparalleled safety across India's diverse sectors. Our innovative solutions are tailored to meet the unique challenges of protecting against lightning hazards. Discover how our products ensure reliability and compliance with the highest safety standards." /> 
            <meta property="og:url" content="Ashlok.abc" />
            </HelmetProvider>
            <PreHeader/>
            <Header/>            
            <Menu/>
            <div>
            {selectedCategory === 'lpr' ? (
                <>
            <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={banner1} ban2x={banner1_2x}/>
            <ProductsubMenu/>
            <LightningCategoryContent/>
            </>
            ): selectedCategory === 'clp' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={clpban1} ban2x={clpban1_2x}/>
            <ProductsubMenu/>
            {/*<ClpdetailsContent/>*/}
            <CLPPagecontent/>
            
            </>
            ): selectedCategory === 'ele' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore="" apostropheAfter="" ban1x={ele_banner1} ban2x={ele_banner1_2x}/>
            <ProductsubMenu/>
             <SafeearthingelectrodeContent/>
              {/*<EarthingCategoryPage/>*/}

            </>
            ): selectedCategory === 'bfc' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore="" apostropheAfter="" ban1x={bfcban1} ban2x={bfcban1_2x}/>
            <ProductsubMenu/>
            <Bfcdetailscontent/>
            </>
            ): selectedCategory === 'epcc' ? (
                <>
                <InstallationBanner page="installation" banalt="earthing ban1" apostropheBefore="" apostropheAfter="" ban1x={epccban} ban2x={epccban_2x}/>
            <ProductsubMenu/>
            <Epccdetailscontent/>
            </>
            )
            :(
                <>
            <InstallationBanner page="installation" banalt="earthing ban1" bannertext="" ban1x={banner1} ban2x={banner1_2x}/>
            <ProductsubMenu/>
            <LightningCategoryContent/>
            </>
            )}
            </div>
            <Footer/>

          
        </div>
      )
    }
    
    export default LightningCategoryPage
    