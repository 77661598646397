import React,{useState} from 'react'
import boltico from './assets/images/thordetails/bolt_icon.svg';
import EarthingelectrodeSubMenu from './EarthingelectrodeSubMenu';

import alloyimg from './assets/images/earthing/alloy_a/alloy_big.png';
import alloyimg2x from './assets/images/earthing/alloy_a/alloy_big@2x.png';
    
const Aseel_alloy_earthing = () => {
    const [selectedSubmenu, setSelectedSubmenu] = useState('');

    const handleImageClick = (system) => {
        setSelectedSubmenu(system);
        console.log("selcet system is"+system);
    };

    return (
        <>
        <EarthingelectrodeSubMenu onImageClick={handleImageClick}/>
        <div className='w-full my-3 flex justify-center items-center'>
            <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center'>
         
                <div className='flex justify-center'>
                    
                    <div className='flex justify-center items-center '>
                    <img src={alloyimg}  
                                            srcSet={`${alloyimg} 1x,${alloyimg2x} 2x`} alt=" ASEEl Alloy logo"
                                            /> 
                    </div>
                   
                </div>
                <div className='flex flex-col items-start'>
                    <p className=' text-2xl text-ashred tracking-wider  font-semibold' > <span className='vollkorn-sc-semibold'>ASEEL A <span className='text-3xl px-1'>|</span> Alloy Coated Earthing Electrode(<span style={{fontSize:'80%'}}>s</span>)</span></p>
                    <p className=' text-base text-justify p-common'>Curated for harsh environments with low pH value, <span className='vollkorn-sc-semibold'>Ashlok’s</span> Alloy Coated Electrodes are designed by <span className='vollkorn-sc-semibold'>Ashlok’s</span> Design Production Process filled with Crystalline Conductive Compound(CCC) CompactFill complying to our Pipe-In-Pipe technology.</p>
                   
                </div>
                
                
            </div>
        </div>
        <div className='w-full flex justify-center items-center bg-ashgray1 '>
            <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
            
                <div className='flex  space-x-10'>
               

                    <div className='w-1/2'>
                    <p className=' text-1xl text-ashred font-extrabold'>Features & Benefits</p>
                        <ul className="list-none">
                        <li className="flex items-start p-common"><img src={boltico} className="mr-1 pr-1 my-1.5"/>Tailored alloy coating with a minimum thickness of 100 microns</li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-1 pr-1 my-1.5"/>Appropriate for soil conditions with pH levels spanning from 3.5 to 12</li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-1 pr-1 my-1.5"/>Anodic protection</li>
                        <li className="flex items-start p-common"><img src={boltico} className="mr-1 pr-1 my-1.5"/>Customisation is available as per customers requirement</li>
                                         </ul>
                    </div>
                   
                    <div className='w-1/4'></div>
                </div>
            </div>
        </div>
        <div className='w-full flex justify-center items-center'>
         
            <div className=' container mx-auto max-w-screen-xl flex flex-col justify-center my-3'>
            <p className='text-xl'>Alloy Coated Earthing Electrode(<span style={{fontSize:'80%'}}>s</span>) Variant</p>
                <div className='flex'>
                    <div className='w-full mb-1'>
                                <table className=" w-full divide-y divide-gray-200 p-common">
                                    <thead className='w-full'>
                                        
                                        <tr className="bg-ashred">

                                        <th className=" px-4 py-2 text-white">Model</th>
                                        <th className=" px-4 py-2 text-white">Length (Mtr)</th>
                                        <th className="  px-4 py-2 text-white">Inner Pipe Dia  (mm)</th>
                                        <th className="  px-4 py-2 text-white">Outer Pipe Dia  (mm)</th>
                                       
                                        </tr>
                                    </thead>
                                    <tbody className="bg-gray-100">
                                        <tr className="bg-white">
                                        <td className=" px-4 py-2 text-center">ASEEL 192 A</td>
                                        <td className=" px-4 py-2 text-center">2</td>
                                        <td className=" px-4 py-2 text-center">26</td>
                                        <td className=" px-4 py-2 text-center">48 </td>
                                        </tr>
                                        <tr className="bg-gray-200">
                                        <td className=" px-4 py-2 text-center">ASEEL 193 A</td>
                                        <td className=" px-4 py-2 text-center">3</td>
                                        <td className=" px-4 py-2 text-center">26</td>
                                        <td className=" px-4 py-2 text-center">48 </td>
                                        </tr>

                                        <tr className="bg-white">
                                        <td className=" px-4 py-2 text-center">ASEEL 392 A</td>
                                        <td className=" px-4 py-2 text-center">2</td>
                                        <td className=" px-4 py-2 text-center">42</td>
                                        <td className=" px-4 py-2 text-center">76</td>
                                         </tr>
                                         <tr className="bg-gray-200">
                                        <td className=" px-4 py-2 text-center">ASEEL 393 A</td>
                                        <td className=" px-4 py-2 text-center">3</td>
                                        <td className=" px-4 py-2 text-center">42</td>
                                        <td className=" px-4 py-2 text-center">76</td>
                                         </tr>

                                       
                                    
                                    </tbody>
                                </table>
                    </div>
                    
                </div>
            
            
                   
                   
                
            </div>
            
        </div>
        </>
      )
}
    
export default Aseel_alloy_earthing
    